
import { url } from "../context/token";
import { isStillConnected } from "./areYouStillConnected";
import { UserToken, UserSetToken } from "../context/token";
import React from "react";
export async function Connexion () {
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)
    if (!isStillConnected (token, setToken)) {
        return 
    }
    const response = fetch (url + '/auth/refresh', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token["refreshToken"]}`
        }
      })
    const json = await (await response).json();
    const temp = token
    temp.accessToken = json.accessToken
    temp.refreshToken = json.refreshToken
    setToken (temp)
}