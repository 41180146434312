import React from 'react';
import Icon_World from '../../assets/Icon_World.svg';
import Mail from "../../assets/Mail.png";
import Position from "../../assets/Position.png"
import Phone from "../../assets/Phone.png"
import Clipboard from "../../assets/clipboard.png"
import Check from "../../assets/check.png"
import { url, UserToken, UserSetToken, widthContext } from "../../context/token";
import { isStillConnected } from '../../utils/areYouStillConnected';
import { TextInput } from '../../components/input';
import { SelectInput } from '../../components/select';
import { MenuItem } from '@mui/material';

function ContactPage  ()  {
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)

    const [firstName, setFirstName] = React.useState ("");
    const [identifiant, setIdentifiant] = React.useState ("");
    const [lastName, setLastName] = React.useState ("");
    const [object, setObject] = React.useState ("");
    const [message, setMessage] = React.useState ("");
    const width = React.useContext (widthContext)

    async function Connexion () {
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/auth/refresh', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["refreshToken"]}`
            }
          })
        const json = await (await response).json();
        setIdentifiant (json.user.email)
        setFirstName (json.user.firstName)
        setLastName (json.user.lastName)
        const temp = token
        temp.accessToken = json.accessToken
        temp.refreshToken = json.refreshToken
        setToken (temp)
    }

    React.useEffect(() => { 
      
        Connexion()
        
        // eslint-disable-next-line
    }, [])
   
       
    

    async function sendMail () {
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/user/contact', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
              "object": object,
              "body": message
             })
          });
          const json = await (await response).json();
          console.log (json)
    }
    const [hoverMail, setHoverMail] = React.useState (false)
    const [mailCopy, setMailCopy] = React.useState (false)
    const [hoverAdresse, setHoverAdresse] = React.useState (false)
    const [adresseCopy, setAdresseCopy] = React.useState (false)
    const [hoverPhone, setHoverPhone] = React.useState (false)
    const [phoneCopy, setPhoneCopy] = React.useState (false)
    
    return (
        <div  
            style= { width>750 ? {width:"100%", height:"100%", flexDirection:"row-reverse", display:"flex", padding:"0px 140px", boxSizing: "border-box"}
                                :{width:"100%", height:"100%", flexDirection:"column", display:"flex", boxSizing: "border-box", justifyItems:"center", alignItems:"center"}}
            >
            
            <div style={{flex:1, zIndex:2}}>
                <div className="Contact">
                    <h1>Supply<span style={{color:"#e8b356"}}>Zen</span></h1>
                
                    <span style={{cursor:"pointer"}} className='Contact' onClick={() => [navigator.clipboard.writeText ("contact.sz@supplyzen.fr"), setMailCopy(true)]} onMouseEnter={()=>setHoverMail(true)} onMouseLeave={()=>[setHoverMail(false), setMailCopy(false)]}>
                        <img alt='position icon' src={Position} className="Contact" draggable={false}/>
                        <p className='Contact'>contact.sz@supplyzen.fr</p>
                        {hoverMail &&
                        <img alt='clipboard' src={mailCopy ? Check : Clipboard} className="Contact" style={{position:"absolute", right:0}} draggable={false}/>
                        }
                    </span>
                    <span style={{cursor:"pointer"}} className='Contact' onClick={() => [navigator.clipboard.writeText ("45 Rue d'Hautpoul, 75019 Paris"), setAdresseCopy(true)]} onMouseEnter={()=>setHoverAdresse(true)} onMouseLeave={()=>[setHoverAdresse(false), setAdresseCopy(false)]}>
                        <img alt='mail icon' src={Mail} className="Contact" draggable={false}/>
                        <p className='Contact'>45 Rue d'Hautpoul, 75019 Paris</p>
                        {hoverAdresse &&
                        <img alt='clipboard' src={adresseCopy ? Check : Clipboard} className="Contact" style={{position:"absolute", right:0}} draggable={false}/>
                        }
                    </span>
                    <span style={{cursor:"pointer"}} className='Contact' onClick={() => [navigator.clipboard.writeText ("01 83 90 13 14"), setPhoneCopy (true)]}  onMouseEnter={()=>setHoverPhone(true)} onMouseLeave={()=>[setHoverPhone(false), setPhoneCopy(false)]}>
                        <img alt='phone icon' src={Phone} className="Contact" draggable={false}/>
                        <p className='Contact'>01 83 90 13 14</p>
                        {hoverPhone &&
                        <img alt='clipboard' src={phoneCopy ? Check : Clipboard} className="Contact" style={{position:"absolute", right:0}} draggable={false}/>
                        }
                    </span>
                    
                    
                </div>
                <div className='Contact' style={{paddingTop: "0px", display:"grid", justifyItems:"center"}}>
                    <h1 className="Contact">Conta<p style={{color:"#e8b356"}}>cter</p></h1>
                    
                    <TextInput label="Adresse Mail" value={identifiant} onChange={(props)=>setIdentifiant (props.target.value)} bordercolor="black"/>
                    <TextInput label="Prénom" value={firstName} onChange={(props)=>setFirstName (props.target.value)} bordercolor="black"/>
                    <TextInput label="Nom" value={lastName} onChange={(props)=>setLastName (props.target.value)} bordercolor="black"/>
                    
                    <SelectInput value={object} onChange={(props: { target: { value: React.SetStateAction<string>; }; })=>setObject (props.target.value)} bordercolor="black" label="Objet de la demande" style={{marginBottom:20}}>
                        <MenuItem value="1">Proposition 1</MenuItem>
                        <MenuItem value="2">Proposition 2</MenuItem>
                    </SelectInput>

                    <TextInput label="Corps du message" value={message} onChange={(props)=>setMessage (props.target.value)} bordercolor="black" multiline/>
                    {/* <textarea placeholder='Corps du message' className='TextArea' value={message} onChange={handleChangeMessage} rows={9}/> */}
                    <div className='Input-button2' onClick={() => sendMail ()} style={{backgroundColor:"#e7ac4f", color:"#fff", margin:"20px", borderRadius:"15px", padding:5, textAlign:"center", width:"80%", cursor:"pointer"}}>
                        <p>Envoyer un message</p>
                    </div>
                    
                    
                </div>
            </div>
            <div style={{ flex:1, boxSizing: "border-box"}}>
                <div style={{width:"40vw", height:"40vw", }}>
                    <iframe title='google map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.6555024686413!2d2.385262714994315!3d48.88384350686934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66dc665dd0e09%3A0x8d4b46d1392cc2c9!2s45%20Rue%20d&#39;Hautpoul%2C%2075019%20Paris!5e0!3m2!1sfr!2sfr!4v1665677238367!5m2!1sfr!2sfr" width="800" height="600" style={{border:0, height:"100%", width:"100%", borderRadius:"15px", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"}} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <img src={Icon_World} width="90%" alt="World" style={{opacity:'75%'}} draggable={false}/>
            </div>
        </div>
    )
}
export default ContactPage