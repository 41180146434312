import React from "react";
import { Tooltip, Skeleton } from "@mui/material";

import { UserToken, widthContext } from "../../context/token";

import { decodeToken } from "react-jwt";

import {ReactComponent as Bonhomme} from "../../assets/bonhomme.svg";
import {ReactComponent as Colis} from "../../assets/colis.svg";
import {ReactComponent as Calendar1} from "../../assets/calendar1.svg";
import {ReactComponent as Calendar2} from "../../assets/calendar2.svg";
import {ReactComponent as Price} from "../../assets/price.svg";
import {ReactComponent as Eye} from "../../assets/eye.svg"
import {ReactComponent as ArrowDown} from "../../assets/arrowDown.svg"
import {ReactComponent as ArrowUp} from "../../assets/arrowUp.svg"
import {ReactComponent as Bin} from "../../assets/bin.svg";

export default function FactureLoading () {

    // handle authentification
    const token = React.useContext (UserToken)
    
    let decodedToken:any = decodeToken (token["accessToken"]) 
    const role = decodedToken["role"]


    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    /////////////////////////////////////////////////
   
    const [isHoverBin, setIsHoverBin] = React.useState (false)

    return (
        <div style={{ display:"flex", gap:10, flexDirection:"column", width:"100%", justifyContent:"left", justifyItems:"left", alignItems:"center", maxWidth:"100vw"}}>

            <div style={{ display:"flex", gap:width>=1200?30:10, flexDirection:width>=1200?"row":"column", gridTemplateColumns:width>=1200?"15% 85%":"100%", boxSizing:"border-box", width:"100%", position:"relative", alignItems:width>1200?"center":"flex-start", padding:width>1200?undefined:20, justifyContent:"space-between"}}>

                    {role!=="sub_account" && <div style={{textDecoration:"none", textAlign:"left",  display: "flex", flexDirection:"row", alignItems:"center", flex:1, justifySelf:"left"}}>
                        
                        <Bonhomme style={{ justifySelf:"center", resize:"none", width:50, margin:5}}/>
                        <div style={{display:"flex", flexDirection:width>1200?"column":"column-reverse", flexGrow:1}}>
                            <Skeleton style={{width:"100%", fontSize:22, fontWeight:700}}/>
                            <Skeleton style={{width:"100%", }}/>
                        </div>
                        
                    </div>}
                    <div style={{textAlign:"left",  display: "flex", flexDirection:"row", alignItems:"center", flex:0.5, justifySelf:"left"}}>
                        <Colis style={{ justifySelf:"center", resize:"none", width:50, margin:5}}/>
                        <div style={{display:"flex", flexDirection:width>1200?"column":"column-reverse", flexGrow:1}}>
                            <Skeleton style={{width:"100%", fontSize:22, fontWeight:700}}/>
                            <Skeleton style={{width:"100%", }}/>
                        </div>
                    </div>

                    <div style={{textAlign:"left",  display: "flex", flexDirection:"row", alignItems:"center", flex:1}}>
                        <Calendar1 style={{ justifySelf:"center", resize:"none", width:50, margin:5 }}/>
                        <div style={{display:"flex", flexDirection:width>1200?"column":"column-reverse", flexGrow:1}}>
                            <Skeleton style={{width:"100%", fontSize:22, fontWeight:700}}/>
                            <Skeleton style={{width:"100%", }}/>
                        </div>
                    </div>

                    <div style={{textAlign:"left",  display: "flex", flexDirection:"row", alignItems:"center", flex:1}}>
                        <Calendar2 style={{ justifySelf:"center", resize:"none", width:50, margin:5 }}/>
                        <div style={{display:"flex", flexDirection:width>1200?"column":"column-reverse", flexGrow:1}}>
                            <Skeleton style={{width:"100%", fontSize:22, fontWeight:700}}/>
                            <Skeleton style={{width:"100%", }}/>
                        </div>
                    </div>

                    <div style={{display:"flex", flexDirection:"row", flex:1, width:width>1200?undefined:"100%", justifyItems:width<1200?"space-between":"center"}}>
                        <div style={{textAlign:"left", display: "flex",  flexDirection:"row", alignItems:"center", flex:1, flexGrow:1}}>
                            <Price/>
                            <div style={{display:"flex", flexDirection:"column", flexGrow:1}}>
                                <Skeleton style={{width:"100%", fontSize:22, fontWeight:700}}/>
                                <Skeleton style={{width:"100%", }}/>
                            </div>
                        </div>
                        
                        
                        <div style={{textAlign:"left", display: "flex",  flexDirection:"row", alignItems:"center", flex:1}}>
                            <Price/>
                            <div style={{display:"flex", flexDirection:"column", flexGrow:1}}>
                                <Skeleton style={{width:"100%", fontSize:22, fontWeight:700}}/>
                                <Skeleton style={{width:"100%", }}/>
                            </div>
                        </div>
                    </div>
                    
                    <div style={{flex:0.5, display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-evenly"}}>
                    {width>1200 &&
                    <div style={{flex:0.5, display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-evenly"}}>
                        
                        <div style={{display:'flex', flexDirection:"column", width:"50%"}}>
                            {role==="admin" && <Tooltip title={"Écraser la facture"}>
                                    <div style={{width:50, height:50}}>
                                        <ArrowUp className="IconHover" style={{ height:35,width:35, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", flex:1}}/>
                                    </div>
                            </Tooltip>}

                            <Tooltip title={"Télécharger la facture"} style={{flex:1}}>
                                <div style={{ width:50, height:50}}>
                                    <ArrowDown className="IconHover" style={{ height:35,width:35, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", flex:1}}/>
                                </div>
                            </Tooltip>
                        </div>
                        
                        <div style={{display:'flex', flexDirection:"column", width:"50%"}}>
                            {role==="admin" &&
                                <Tooltip title={`Supprimer la facture "fichier.pdf"`} style={{flex:1}}>
                                    <Bin style={{height:50,width:50, resize:"none", alignSelf:"center", justifySelf:"center", fill:isHoverBin?"#FA1205":"#a2a2a2", cursor:"pointer"}} onMouseEnter={()=>setIsHoverBin(true)} onMouseLeave={()=>setIsHoverBin(false)}/>
                                </Tooltip>
                            }
                            <Tooltip title={"Aperçu de la facture"} style={{flex:1}}>
                                <div style={{ width:50, height:50}}>
                                    <Eye className="IconHover" style={{height:35,width:35, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer"}}/>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                }
                    </div>
                    {width<=1200 &&
                    <div className="Change-Color-Hover" style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"left", border:"2px solid #747474", borderRadius:8, padding:"8px 12px", cursor:"pointer", userSelect:"none", width:"max-content", boxSizing:"border-box", alignSelf:"center"}}>
                        <ArrowDown style={{ height:20,width:20, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", flex:2, marginRight:10, fill:"#747474"}}/>
                        <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left", margin:0}}>Télécharger la facture</p>
                    </div>
                    }

            </div>

        </div>
    );
}
