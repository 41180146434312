import React from "react";
import { Pagination } from "@mui/material";
import { UserToken, UserSetToken,url } from "../../../context/token";
import { Expedition } from "../../../types";
import { isStillConnected } from "../../../utils/areYouStillConnected";
import PersonLoading from "../../../pages/sub_account/carnetdAdresses/personLoading";
import ExpeditionComponent from "./expedition";
import { Connexion } from "../../../utils/connexion";

function ExpeditionSelection  (props:{choose:Function, subaccountid:string}){

    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)

    async function getInfo (){
        setIsLoading (true)
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/expedition/false', {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            },
            // body: JSON.stringify ({
            //     "subAccountId": props.subaccountid
            // })
          })
        const json:Array<Expedition> = await (await response).json();
        for (let i=0; i<json.length; i++){
            json[i].checked = false
        }
        // setExpeditionsList (json)
        // setFilteredContact (json)
        setIsLoading (false)
    }
    const [isLoading, setIsLoading] = React.useState (true)
    React.useEffect (()=>{
        Connexion () 
        getInfo ()
        // eslint-disable-next-line
    }, [])
    ////////////////////////////////////////////////////////
    

    const [expeditionsList, setExpeditionsList] = React.useState (new Array<Expedition> ())

    const [pageNb, setPageNb] = React.useState (1)
    const [filteredExpeditionArray, setFilteredExpeditionArray] = React.useState (new Array<Expedition> ())
    const reductedList = filteredExpeditionArray.slice ((pageNb-1)*10, (pageNb*10))

    function handleChangeIsGoing (index:number, changed:boolean){
        var temp = expeditionsList.slice ()
        temp[index].checked = changed

        setExpeditionsList (temp)
        setFilteredExpeditionArray (temp)
    }

    return (
        <div style={{ height:"100%", display:"grid", margin:"0px 20px", position:"relative"}}>
            
            {expeditionsList.filter ((element)=>{return element.checked===true}).length!==0 &&
                <div style={{position:"fixed", top:10, left:10, opacity:1, zIndex:3}} className="Cadre-blanc">
                    <p>{expeditionsList.filter ((element)=>{return element.checked===true}).length} expédition{expeditionsList.filter ((element)=>{return element.checked===true}).length>1&&"s"} sélectionnée{expeditionsList.filter ((element)=>{return element.checked===true}).length>1&&"s"}</p>
                </div>
            }
            {!isLoading ? <div style={{width:"100%", justifySelf:"center", display:"flex", gap:10, flexDirection:"column"}}>
                {expeditionsList.length>0?
                    <>
                        {reductedList.map ((element:Expedition, index:number)=>
                            // <p>{JSON.stringify (element)}</p>
                            <ExpeditionComponent element={element} index={(pageNb-1)*10+index} handleChange={handleChangeIsGoing} key={element.id}/>
                        )}
                        <div style={{width:"100%", justifyContent:"center", display:"flex"}}>
                            <Pagination count={filteredExpeditionArray.length!==0? Math.floor((filteredExpeditionArray.length-1)/10)+1:1} size="large"  onChange={(_event, number)=>setPageNb (number)}/>
                        </div>
                    </>
                    :
                    <>    
                        <p>Aucune expédition à sélectionner</p>
                    </>
                }
            </div>
            : 
            <div style={{width:"100%", justifySelf:"center"}}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map ((index:number)=>
                    // <p>{JSON.stringify (element)}</p>
                    <PersonLoading key={index}/>
                )}
                <div style={{width:"100%", justifyContent:"center", display:"flex"}}>
                    <Pagination count={filteredExpeditionArray.length!==0? Math.floor((filteredExpeditionArray.length-1)/10)+1:1} size="large"  onChange={(_event, number)=>setPageNb (number)}/>
                </div>
            </div>
        }
            <div style={{display:"grid", gridTemplateColumns:"100%", justifyContent:"center", padding:30, paddingBottom:0, paddingTop:20}}>
                <div style={{display:"flex", flexDirection:"row-reverse", justifyContent:"space-between"}}>
                    
                    <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}} onClick={()=>props.choose (expeditionsList.filter ((element)=>{return element.checked===true}))}>
                        <p style={{color:"white", margin:0}}>Confirmer</p>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default ExpeditionSelection