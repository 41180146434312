import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { url } from '../../context/token';


function ForgotPassword  ()  {
    const location = useLocation ()
    const navigate = useNavigate ()
    const [changePassword1, setPassword1] = React.useState ("");
    const [changePassword2, setPassword2] = React.useState ("");
    function handleChangePassword1 (event:React.FormEvent<HTMLInputElement>):void {
        setPassword1 (event.currentTarget.value )
    } 
    function handleChangePassword2 (event:React.FormEvent<HTMLInputElement>):void {
        setPassword2 (event.currentTarget.value )
    }
    const passwordRef2 = React.useRef() as React.MutableRefObject<HTMLInputElement>;

    async function changePassword () {
        if (changePassword1===changePassword2){
            fetch (url + location.pathname, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                //   'Authorization': `Bearer ${location.pathname.slice(22)}`
                },
                body: JSON.stringify ({
                    "password":changePassword1,
                    "passwordConfirmation":changePassword2
                 })
              })
            // const json = await (await response).json();
            navigate ("/")
        }
    }
    const confirm = changePassword1===changePassword2 && changePassword1 !== ''
    
    return (
        <div>
        {location.pathname.slice(0,21) === "/auth/reset-password/" && 
        <div style={{position: "fixed", left: "50%", top:"0%", zIndex:5}}>
          <div className='Input' style={{position: "relative", left: "-50%", top:"0%", border: "dotted #deae5f 1px"}}>
            <p style={{margin:10}}>Changement de mot de passe pour l'adresse mail :</p>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", width:"70%"}}>
                <form style={{display:"flex",  flexDirection:"column", width:"100%"}}>
                <input style={{alignSelf:"center", width:"100%"}} type="password" placeholder='Nouveau mot de passe' className='Input' value={changePassword1} onChange={handleChangePassword1} onKeyPress={event => event.key === 'Enter' && passwordRef2.current.focus()}/>
                <input style={{alignSelf:"center", width:"100%"}} type="password" placeholder='Confirmez votre nouveau mot de passe' className='Input' value={changePassword2} onChange={handleChangePassword2} onKeyPress={event => event.key === 'Enter' && changePassword()} ref={passwordRef2}/>
                </form>
                <div className={'Cadre-blanc Confirmer Grow-Hover'} style={{position:"relative", margin:10, backgroundColor:confirm?"":"white", transform:confirm!?"":"none", fontWeight:confirm?"":"unset"}}>
                    <p style={{color:confirm?"":"lightgrey", transform:confirm?"":"none"}}>Confirmer</p>
                </div>
            </div>
          </div>
        </div>
        }
        </div>
    )
}
export default ForgotPassword