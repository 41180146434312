import { Backdrop } from "@mui/material";
import { TextInput } from "../../components/input";
import ExpeditionSelection from "../admin/subaccount/expeditionSelection";
import { UserToken, url, widthContext } from "../../context/token";
import React from "react";
import { Expedition } from "../../types";

export function CreateFacturation ({subaccountId, isOpen, setIsOpen, refresh}:{subaccountId:string|undefined, isOpen:boolean, setIsOpen:Function, refresh:Function}) {
    const token = React.useContext (UserToken)
    const width = React.useContext (widthContext)

    const [tryToFacture2, setTryToFacture2] = React.useState (false)
    const [relatedExpedition, setRelatedExpedition] = React.useState (new Array<Expedition> ())
    
    function chooseRelatedExpedition (e){
        setRelatedExpedition  (e)
        setTryToFacture2 (false)
    }
    
    async function createFacturation (){
        
        var temp = new Array<{expeditionId:string}> ()
        for (let i=0; i<relatedExpedition.length; i++){
            temp.push ({"expeditionId":relatedExpedition[i].id})
        }
        // formData.append('relatedExpeditions', JSON.stringify (temp));
        
        // formData.append('file', newFileOfFacturation);
        const date1 = new Date (newEmissionDate)
        var receiptDate = new Date ()
        receiptDate.setDate (date1.getDate ())
        receiptDate.setMonth (date1.getMonth ())
        receiptDate.setFullYear (date1.getFullYear ())
        console.log (`LA DATE 1 : ${receiptDate.toJSON ()}`)
        

        const date2 = new Date (newMonthOfFacturation)
        var billingDate = new Date ()
        billingDate.setDate (date2.getDate ())
        billingDate.setMonth (date2.getMonth ())
        billingDate.setFullYear (date2.getFullYear ())
        console.log (`LA DATE 2 : ${billingDate.toJSON ()}`)
        
        
        const test2={
            "receiptDate": receiptDate.toJSON (),
            "billingDate": billingDate.toJSON (),
            "subAccountId":subaccountId,
            "amount": +newPriceHT,
            "relatedExpeditions": temp
        }
        // const test2 = {
        //     "receiptDate": "2023-07-09T11:43:42.856Z",
        //     "billingDate": "2023-07-10T11:43:42.856Z",
        //     "subAccountId": "3a196b22-c57b-4d52-b83a-058d89b5da3f",
        //     "amount": 70,
        //     "relatedExpeditions": [
        //         {
        //             "expeditionId": "0a2a4975-30a5-45f6-850f-9248e52cbbf3"
        //         },
        //         {
        //             "expeditionId": "7b583818-426c-41bb-87e7-df7f2d136c86"
        //         },
        //         {
        //             "expeditionId": "bf637db9-8f18-4595-83d5-5e5c36587783"
        //         }
        //     ]
        // }
        console.log (test2)
        const response = fetch (url + '/billing' , {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`,
            },
            body: JSON.stringify (test2),
        }
        )
        await response.catch(function(error) {
            console.log (error)
          });;
        const json = await (await response).json ()
        console.log (json)
        
        setIsOpen (false)
        await refresh ()
    }

    const [newEmissionDate, setNewEmissionDate] = React.useState (new Date ().toJSON ().slice (0, 10))
    const [newMonthOfFacturation, setNewMonthOfFacturation] = React.useState (new Date ().toJSON ().slice (0, 7))
    const [newPriceHT, setNewPriceHT] = React.useState ("")
    function handleChangeNewPriceHT (value:string){
        if (!Number.isNaN(+value)){
            setNewPriceHT (value)
            setNewPriceTTC (JSON.stringify (1.2*+value))
        }
    }
    const [newPriceTTC, setNewPriceTTC] = React.useState ("")
    function handleChangeNewPriceTTC (value:string){
        if (!Number.isNaN(+value)){
            setNewPriceTTC (value)
        }
    }
    const [newFileOfFacturation, setNewFileOfFacturation] = React.useState (null as unknown as File)
    function handleChange (e:any){
        setNewFileOfFacturation (e.target.files[0])
    }
    const fileInputRef = React.useRef () as React.MutableRefObject<HTMLInputElement>;
    return (
        <>
        <Backdrop sx={{ zIndex: 111 }} open={isOpen} onClick={()=>setIsOpen (false)}>
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 400px)", padding:20, zIndex:"inherit"}}>
                    <h2 style={{ margin:0, marginBottom:20}}>Créer une nouvelle facture</h2>
                    <TextInput width="100%" type="date" label={newEmissionDate.length!==0?"Date de facturation":""} bordercolor="#deae5f" value={newEmissionDate} onChange={(props)=>setNewEmissionDate (props.target.value)}/>
                    <TextInput width="100%" type="date" label={newMonthOfFacturation.length!==0?"Date d'échéance":""}  bordercolor="#deae5f" value={newMonthOfFacturation} onChange={(props)=>setNewMonthOfFacturation (props.target.value)}/>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <TextInput width="49%" type="text" bordercolor="#deae5f" value={newPriceHT} onChange={(props)=> {handleChangeNewPriceHT (props.target.value)}} label="Prix HT" InputProps={{ inputProps: { min: 0, max: 10 } }}/>
                        <TextInput width="49%" type="text" bordercolor="#deae5f" value={newPriceTTC} onChange={(props)=>handleChangeNewPriceTTC (props.target.value)} label="Prix TTC" InputProps={{ inputProps: { min: 0, max: 10 } }}/>
                    </div>
                    <div style={{width:"100%", position:"relative", marginBottom:"20px"}}>
                        <TextInput sx={{margin:0}} width="100%" label="PDF de la facture" bordercolor="#deae5f" value={newFileOfFacturation!==undefined && newFileOfFacturation!==null ?newFileOfFacturation.name:"Aucun fichier choisi"}/>
                        <div style={{width:"100%", height:"100%", position:"absolute", top:0, left:0, cursor:"pointer",borderRadius:10 }} onClick={()=>fileInputRef.current.click ()}></div>
                        <input type="file" onChange={(e)=>handleChange (e)} accept="application/pdf" ref={fileInputRef} style={{width:0, height:0}}/>
                    </div>
                    <div style={{width:"100%", position:"relative", marginBottom:"20px"}}>
                        <TextInput sx={{margin:0}} width="100%" label="Expédition liées à la facture" bordercolor="#deae5f" value={`${relatedExpedition.length===0?"Aucune":relatedExpedition.length} expédition${relatedExpedition.length>1?"s":""} sélectionnée${relatedExpedition.length>1?"s":""}`}/>
                        <div style={{width:"100%", height:"100%", position:"absolute", top:0, left:0, cursor:"pointer",borderRadius:10 }} onClick={()=>setTryToFacture2 (true)}></div>
                        
                    </div>
                    <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
                        <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setIsOpen (false)}}>
                            <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                        </div>
                        <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {createFacturation()}}>
                            <p style={{color:"white", margin:0}}>Confirmer</p>
                        </div>
                    </div>
                </div>
            </div>
        </Backdrop>
        <Backdrop open={tryToFacture2} onClick={()=>setTryToFacture2 (false)} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
            <div style={{position: "fixed", left: "50%", top:width<1200?"0%":"10%"}}>
                
                <div style={{position: "relative", left: "-50%", top:"-50%", border: "dotted #deae5f 1px", padding:30, width:width<1200?"95vw":"80vw", paddingLeft:0, paddingRight:0, maxHeight:"80vh", overflow:"auto", display:"grid", opacity:1}} className="Cadre-blanc" onClick={(e)=>e.stopPropagation ()}>
                    
                    <p style={{margin:10, fontSize:25, fontWeight:500, justifySelf:"center"}}>Choisissez les expéditions à associer avec la facture</p>
                    {subaccountId!==undefined && 
                        <ExpeditionSelection choose={chooseRelatedExpedition} subaccountid={subaccountId}/>
                    }
                    
                </div>
            </div>
        </Backdrop>
        </>
    );
}