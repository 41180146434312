import React from "react";

import { UserToken, UserSetToken, url, widthContext } from "../../context/token";
import { isStillConnected } from "../../utils/areYouStillConnected";

import { Colis, Expedition, Manifeste } from "../../types";

// Images
import {ReactComponent as PDFLogo} from "../../assets/pdf.svg";
import position from "../../assets/Position.png"
import Check from "../../assets/yellowCheck.svg"
import pdf from "../../assets/pdf.png"
import {ReactComponent as Calendar1} from "../../assets/calendar1.svg";
import Pdf from "../../assets/pdf2.png"
import {ReactComponent as Chevron} from "../../assets/chevron.svg";

import { Backdrop, CircularProgress, Pagination } from "@mui/material";
import { TextInput } from "../../components/input";
import { Connexion } from "../../utils/connexion";




function DemandeDeRamassage  ()  {
    // Pour gérer l'authentification
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)

    ////////////////////////////////

    // Pour gérer la liste des expéditions, et le système de pagination
    const [expeditionsList, setExpeditionList] = React.useState ([
        new Array<Colis> (),
        new Array<Colis> (),
        new Array<Colis> (),
        new Array<Colis> (),
    ]) 
    const [pageNb, setPageNb] = React.useState (1)
    
    const listOfScreen = [
        "En attente d'instruction",
        "En attente de ramassage",
        "Manifeste de fin de journée"
    ]


    const [nbListOnScreen, setnbListOnScreen] = React.useState (0)

    function goToPreviousScreen():void{
        var nbTemp = nbListOnScreen - 1
        if (nbTemp < 0) {
            nbTemp += 3
        }
        setFilteredExpeditionList (expeditionsList[nbTemp])
        setnbListOnScreen (nbTemp)
    }
    function goToNextScreen ():void{
        var nbTemp = nbListOnScreen + 1
        if (nbTemp > 2) {
            nbTemp -= 3
        }
        setFilteredExpeditionList (expeditionsList[nbTemp])
        setnbListOnScreen (nbTemp)
    }

    const [filteredExpeditionList, setFilteredExpeditionList] = React.useState (new Array<Colis> ())
    const reductedExpeditionList = filteredExpeditionList.slice ((pageNb-1)*20, (pageNb*20))

    function changeListOnScreen (nbListToPrint:number){
        // console.log (JSON.stringify (nbListToPrint)+ JSON.stringify (nbGoing))
        // console.log (expeditionsList[nbListToPrint])
        setFilteredExpeditionList (expeditionsList[nbListToPrint]);
        setnbListOnScreen (nbListToPrint); 
        setPageNb (1); 
        const nbGoingForThisScreen:number = expeditionsList[nbListToPrint].filter((obj) => {return obj["isGoing"] === true}).length
        setNbGoing (nbGoingForThisScreen)
        setIsSelectingAll (isEverythingChecked (expeditionsList[nbListToPrint]))
    }

    ///////////////////////////////////////////////////////////////////

    // Pour gérer le système de suppression de masse d'expédition et de demandes de ramassage
    const [nbGoing, setNbGoing] = React.useState (0)
    const [isSelectingAll, setIsSelectingAll] = React.useState (false)
    const [isHoverSelectAll, setIsHoverSelectAll] = React.useState (false)
    function SelectAll () {
        // expeditionsList[nbListOnScreen].map (element => element.isGoing=!isSelectingAll)
        const temp = expeditionsList.slice ()
        for (let i=0; i<temp.length; i++){
            if (filteredExpeditionList.includes (temp[nbListOnScreen][i])){
                temp[nbListOnScreen][i]["isGoing"]=!isSelectingAll
            }
        }
        setExpeditionList (temp)
        setFilteredExpeditionList (temp[nbListOnScreen])
        
        setNbGoing (temp[nbListOnScreen].filter ((element)=>{return element.isGoing===true}).length)
        
        setIsSelectingAll (!isSelectingAll)
    }
    function isEverythingChecked (list:Array<Colis>):React.SetStateAction<boolean>{
        console.log (list, isLoading, isSelectingAll)
        for (let i=0; i<list.length; i++){
            if (list[i]["isGoing"]===false){
                return false
            }
        }
        return true
    }
   
    React.useEffect (()=>{
        setIsSelectingAll (isEverythingChecked(filteredExpeditionList)) // eslint-disable-next-line
    }, [expeditionsList, filteredExpeditionList])
    const [tryToRemove, setTryToRemove] = React.useState (false)

    async function archiveExpedition(id:string){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        fetch (url + '/expedition/archive', {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
                "expeditionId":id
            })
        })
    }  

    async function cancelPickup(id:string){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        fetch (url + '/expedition/pickup', {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
                "expeditionId":id
            })
        })
    }  

    async function removeAction (){
        const listToArchive:Array<Colis> = expeditionsList[nbListOnScreen].filter((obj) => {return obj["isGoing"] === true})
        const listOfExpeditionsId:Array<string> = listToArchive.map(item => item.expeditionId).filter((value, index, self) => self.indexOf(value) === index)
        if (nbListOnScreen===1){
            listOfExpeditionsId.map ((element:string)=>archiveExpedition (element))
        } else {
            listOfExpeditionsId.map ((element:string)=>cancelPickup (element))
        }
        getExpeditions ()
        setNbGoing (0)
    }
    ///////////////////////////////////////////////////////////////////


    // Pour gérer l'obtention et l'actualisation des expéditions
    const [isLoading, setIsLoading] = React.useState (true)
    async function getExpeditions () {
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/expedition/false', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token["accessToken"]}`
            },
        });
        const json = await (await response).json();
        defineList (json)
        setIsLoading (false)
    }

    function compare (a:Expedition, b:Expedition){
        const date1 = new Date (a.pickupDate)
        const date2 = new Date (b.pickupDate)
        if (date1<date2){
            return 1
        } else {
            return -1
        }
    }
    async function defineList (json:Array<Expedition>){
        await json.sort (compare)
        const list1 =  new Array<Colis> ()
        const list2 =  new Array<Colis> ()
        const list3 =  new Array<Colis> ()
        const list4 =  new Array<Colis> ()
        for (let i=0; i<json.length; i++) {
            const expedition = json[i]
            for (let i=0; i<expedition.parcels.length; i++) {
                const colis = expedition.parcels[i]
                var element = {
                    id:                   colis.id,
                    expeditionId:         expedition.id,
                    parcelNumber:         colis.parcelNumber,
                    weight:               colis.weight,
                    dimensions:           colis.dimensions,
                    expeditionPath:       colis.expeditionPath,
                    lastStatus:           colis.lastStatus,
                    statusHistory:        colis.statusHistory,
                    isGoing:              false,
                    carrier:              expedition.carrier.name,
                    deliveryName:         expedition.deliveryName,
                    deliveryAddressLine1: expedition.deliveryAddressLine1,
                    deliveryAddressLine2: expedition.deliveryAddressLine2,
                    deliveryZipCode:      expedition.deliveryZipCode,
                    deliveryCity:         expedition.deliveryCity,
                    pickupDate:           expedition.pickupDate,
                    pickupName:           expedition.pickupName,
                    pickupAddressLine1:   expedition.pickupAddressLine1,
                    pickupAddressLine2:   expedition.pickupAddressLine2,
                    pickupZipCode:        expedition.pickupZipCode,
                    pickupCity:           expedition.pickupCity,
                    pickupPhoneNumber:    expedition.pickupPhoneNumber,
                    pickupClosingTime:    expedition.pickupClosingTime,
                    deliveryDate:         expedition.deliveryDate,
                    pickupNumber:         expedition.pickupNumber,
                    subAccount:           expedition.subAccount,
                    price:                expedition.price
                }

                
                if (expedition["pickupNumber"]!==null) {
                    list2.push (element)
                } else {
                    list1.push (element)
                }
            }
        }
        setExpeditionList ([list1, list2, list3, list4])
        setFilteredExpeditionList ([list1, list2, list3, list4][nbListOnScreen]) 
    }
    
    React.useEffect (()=>{
        Connexion ()
        getExpeditions ()
         // eslint-disable-next-line
    }, [])
    /////////////////////////////////////////////////


    // Pour obtenir les PDF des étiquettes
    async function getPDF (pdf:string){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/expedition/parcellabel/'+pdf, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
                },
            });
        const url2 = (await (await response).json()).url;
        const response2 = fetch (url2, {
            method: 'GET',
        });
        const file = new Blob(
            [await (await response2).blob()],
            {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    ///////////////////////////////////////

    // Component Expedition à boucler
    function ExpeditionComponent (props:{element:Colis, index:number}) {
        const [isGoing, setIsGoing] = React.useState (props.element["isGoing"])
        const index:number = props.index
        const element:Colis = props.element

        
        function handleChangeIsGoing (){
            
            const temp:Array<Array<Colis>> = expeditionsList.slice ()
            temp[nbListOnScreen][index+(pageNb-1)*20]["isGoing"] = !isGoing
            if (!isGoing){
                setNbGoing (nbGoing+1)
            } else {
                setNbGoing (nbGoing-1)
            }
            setExpeditionList(temp)
            setFilteredExpeditionList (temp[nbListOnScreen])
            setIsGoing (!isGoing)
            
        }
       
        return (
            <div className="table3" key={element["id"]} style={{margin:20, marginBottom:"3vh", justifyContent:width>1200?"space-evenly":"flex-start", display:"flex", flexDirection:width>1200?"row":"column"}}>
                            
                                
                <div style={{textAlign:"left",  display: "flex", flexDirection:"column", paddingLeft:30}}>
                    <span style={{color:"#e7ac4f", fontSize:22, fontWeight:700, marginRight:0}}>{element.parcelNumber}</span>
                    {width>1200 && <span style={{color:"#747474", fontWeight:600}}>Numéro d'expédition</span>}
                </div>

                <div style={{textAlign:"left",  display: "flex", flexDirection:width>1200?"column":"row-reverse", paddingLeft:30, alignItems:width>1200?"":"center"}}>
                    <div className="Profil-Logo" style={{margin:0}}>
                        <div className="Profil-Logo2"></div>
                        <div className="Profil-Logo3"></div>
                        <p style={{zIndex:1, position:"relative", margin:15, font: "italic normal normal 15px/1 Verdana, Geneva, sans-serif"}}>{element.carrier}</p>
                    </div>
                    <span style={{color:"#747474", fontWeight:600}}>Transporteur {width<1200 && ": "}</span>
                </div>

                <div style={{textAlign:"left", display: "flex",  flexDirection:"row", paddingLeft:30, alignItems:"center"}}>
                    {width<1200 && <p style={{marginRight:10}}>Poids : </p>}
                    <span style={{color:"#e7ac4f", fontSize:30, fontWeight:700}}>{element["weight"]}</span>
                    <span style={{color:"#747474", fontWeight:600, margin:5}}>Kg</span>
                </div>

                <div  style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"left" }}>
                    {/* <img src={position} alt='' style={{height:40, resize:"none", margin:12}}/> */}
                    {width<1200 && <p style={{marginRight:10}}>Type : </p>}
                    <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"center"}}>Colis</p>
                </div>
            
                <div className="table4" style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"left" }}>
                    <img src={position} alt='' style={{height:40, resize:"none", margin:12}}/>
                    <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left"}}>{element.deliveryAddressLine1} {element.deliveryAddressLine2}</p>
                </div>
                {width>1200 ?
                <div className="table4" style={{ cursor:"pointer", flex:0.1, justifyContent:"center", display:"flex", flexDirection:"row", alignItems:"center", alignContent:"center"}} onClick={()=>{getPDF (element.expeditionPath)}}>
                    {/* <p style={{color:isHoverCancel?"#FD1937":"#747474",  fontSize:20, textAlign:"left"}}>PDF</p> */}
                    <PDFLogo style={{width: "20px", height: "20px", resize: "none", marginRight:20, }}/>
                </div>
                :
                <div style={{display:"grid", gridTemplateColumns:"20% 80%", flexDirection:"row", alignItems:"center", cursor:"pointer"}} className="JE" onClick={()=>getPDF(element.expeditionPath)}>
                    <img src={pdf} alt='' style={{height:50, resize:"none", margin:12, justifySelf:"center"}}/>
                    <p style={{display:"flex", flexDirection:"row", alignItems:"center"}}>Afficher l'étiquette</p>
                </div>
                }
                {/* {nbListOnScreen===0 && */}
                <div className="table4 Change-Color-Hover" style={{  cursor:"pointer", justifyContent:"center", display:"flex",   border: isGoing?"5px solid #e7ac4f":"2px solid #9a9a9a", width:60, height:60, paddingLeft:0, borderRadius:10, boxSizing:"border-box", flexDirection:"row", alignItems:"center", alignContent:"center"}} onClick={()=>{handleChangeIsGoing()}}>
                    <img src={Check} alt='' style={{height:40, resize:"none", margin:12, paddingLeft:0, visibility:isGoing?"visible":"hidden"}}/>
                </div>  
            </div>
        )
    }
    /////////////////////////////////////////////////////////////


    // Manifestes de fin de journée

    const [manifesteList, setManifesteList] = React.useState (new Array<Manifeste> ())
    // const [filteredManifesteList, setFilteredManifesteList] = React.useState (new Array<Manifeste> ())
    const reductedManifesteList = manifesteList.slice ((pageNb-1)*20, (pageNb*20))

    React.useEffect (()=>{
        const temp = [
            {
                id: "azertyuiop",
                path:"un_fichier.pdf",
                createdAt: "2023-04-01",
                isGoing: false
            },
            {
                id: "qsdfghjklm",
                path:"un_autre_fichier.pdf",
                createdAt: "2023-03-31",
                isGoing: false
            },
            {
                id: "wxcvbnwxcv",
                path:"encore_un_autre_fichier.pdf",
                createdAt: "2023-03-31",
                isGoing: false
            }
        ]
        setManifesteList (temp)
        // setFilteredManifesteList (temp)
    }, [])
    function ManifesteComponent (props:{element:Manifeste}){    
        const element = props.element       
        return (
                        // <Tooltip  style={{width:"100%", height:"100%", margin:0}} title={"Dernière modification le 28/02/2023"}>
            <div key={element["id"]} style={{margin:0, marginBottom:width>1200?"0vh":"", justifyContent:width>1200?"space-evenly":"left", display:"flex", flexDirection:width>1200?"row":"column", alignItems:"center", gap:width>1200?"":9, padding:"20px 0px"}}>
                        
                <div style={{textAlign:"left",  display: "flex", flexDirection:"row", paddingLeft:30, alignItems:"center"}}>
                    <Calendar1 style={{ marginRight:10 }}/>
                    <div style={{display:"flex", flexDirection:width>1200?"column":"column-reverse", marginLeft:5}}>
                        <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{element.createdAt}</span>
                        <span style={{color:"#747474"}}>Date d'émission {width<=1200 && ":"}</span>
                    </div>
                </div>

                {/* <div style={{textAlign:"left",  display: "flex", flexDirection:"row", paddingLeft:30, alignItems:"center"}}>
                    <Calendar2 style={{ marginRight:10 }}/>
                    <div style={{display:"flex", flexDirection:width>1200?"column":"column-reverse", marginLeft:5}}>
                        <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{element.monthOfFacturation}</span>
                        <span style={{color:"#747474"}}>Mois de la facturation {width<=1200 && ":"}</span>
                    </div>
                </div> */}
                
                {/* <div style={{display:"flex", flexDirection:"row"}}>
                    <div style={{textAlign:"left", display: "flex",  flexDirection:"row", paddingLeft:30, alignItems:"center"}}>
                        <Price/>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <span style={{color:"#747474", fontSize:30, fontWeight:700, marginBottom:0}}>{element.price}</span>
                                <span style={{color:"#e7ac4f", fontWeight:600, margin:5}}>€</span>
                            </div>
                            <span style={{color:"#747474"}}>TTC</span>
                        </div>
                    </div>
                    
                    
                    <div style={{textAlign:"left", display: "flex",  flexDirection:"row", paddingLeft:30, alignItems:"center"}}>
                        <Price/>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <span style={{color:"#747474", fontSize:30, fontWeight:700, marginBottom:0}}>{element.price}</span>
                                <span style={{color:"#e7ac4f", fontWeight:600, margin:5}}>€</span>
                            </div>
                            <span style={{color:"#747474"}}>HT</span>
                        </div>
                    </div>
                </div> */}
                <div>
                    <div className="Change-Color-Hover" style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"left", border:"2px solid #747474", borderRadius:8, padding:"8px 12px", cursor:"pointer", userSelect:"none", width:"max-content"}}>
                        <img src={Pdf} alt='' style={{height:20, resize:"none", marginRight:10}} draggable={false}/>
                        <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left", margin:0}}>Télécharger le manifeste</p>
                    </div>
                </div>
                 
            </div>
                // </Tooltip>    
        )
    }

    // Pour gérer l'upload de fichier
    const uploadFileRef = React.useRef () as React.MutableRefObject<HTMLInputElement>;
    // const [selectedFile, setSelectedFile] = React.useState(null);
    function handleChange(selectorFiles: FileList|null){
          console.log(selectorFiles);  
      // setSelectedFile (selectorFiles[0])
    }


    ///////////////////////////////
    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////

    const [headerHeight, setHeaderHeight] = React.useState (60)
    React.useEffect(() => {
        let header = document.getElementById("header");
        if (header) {
            setHeaderHeight (header.clientHeight)
        }
    
      }, [])
    const [dateFiltreManisfeste, setDateFiltreManisfeste] = React.useState ("")
    return (
        <>
        
        <div className="table2" style={{margin:"0px 20px"}}>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%", position:"sticky", top:headerHeight+40, zIndex:3, pointerEvents: "none", userSelect:"none"}}>
        {width>1200 ?
            <>
            <div style={nbListOnScreen===0?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={() => {!isLoading && changeListOnScreen(0)}}>
                <p>En attente d'instruction</p>
            </div>
            <div style={nbListOnScreen===1?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={() => {!isLoading && changeListOnScreen(1)}}>
                <p>En attente de ramassage</p>
            </div>
            <div style={nbListOnScreen===2?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={() => {!isLoading && changeListOnScreen(2)}}>
                <p>Manifeste de fin de journée</p>
            </div>
            </>
            :
                <div style={{display:"flex", flexDirection:"row", position:"sticky", top:"19vh", alignItems:"center", zIndex:3, userSelect:"none"}}>
                    <Chevron style={{margin:"0px 30px", rotate:"90deg", cursor:"pointer", pointerEvents:"visible"}} onClick={()=>goToPreviousScreen()}/>
                    <div style={{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default"}} className="Cadre-blanc Header-expedition Change-Color-Hover">
                        <p>{listOfScreen[nbListOnScreen]}</p>
                    </div>
                    <Chevron style={{margin:"0px 30px", rotate:"270deg", cursor:"pointer", pointerEvents:"visible"}} onClick={()=>goToNextScreen()}/>
                </div>
            
        }
        </div>
            {nbGoing>0 &&
            <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column-reverse", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, userSelect:"none"}}>
                    
                

                <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw"}} onClick={()=>{console.log (expeditionsList[nbListOnScreen].filter((obj) => {return obj["isGoing"] === true}));console.log (nbGoing); setTryToRemove (true)}}>
                    <p>Supprimer</p>
                </div>

                {nbListOnScreen===0 &&
                    <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:"20vw"}} onClick={()=>{console.log (expeditionsList[nbListOnScreen].filter((obj) => {return obj["isGoing"] === true}));console.log (nbGoing)}}>
                        <p>Confirmer</p>
                    </div>
                }
                
                {nbListOnScreen===1 &&
                    <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:"20vw", padding:"3px 10px"}} onClick={()=>{console.log (expeditionsList[nbListOnScreen].filter((obj) => {return obj["isGoing"] === true}));console.log (nbGoing)}}>
                        <p>Confier au transporteur</p>
                    </div>
                }
                
            </div>
            }
           
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={tryToRemove && nbGoing>0} onClick={()=>setTryToRemove (false)}>
                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 200px)", padding:20}}>
                        <h2 style={{ marginBottom:20}}>Supprimer {nbGoing} {nbListOnScreen===1?"expédition":"demande"}{nbGoing>1?"s":""} {nbListOnScreen===0 && "d'expédition"}?</h2>
                        
                        <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
                            <div className="Change-Color-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setTryToRemove(false)}}>
                                <p style={{color:"#deae5f"}}>Annuler</p>
                            </div>
                            <div className="Change-Color-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setTryToRemove (false); removeAction()}}>
                                <p style={{color:"white"}}>Confirmer</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Backdrop>
            
            <div className="table" style={{margin:"20px 0px", width:"100%"}}>
                
                {((expeditionsList[nbListOnScreen].length>0 && nbListOnScreen<2)||(manifesteList.length>0 && nbListOnScreen===2)) && !isLoading ?
                <>
                <div style={{ display:"flex" ,alignItems:"flex-end", flexDirection:"row-reverse", userSelect:"none"}}>
                    
                    {/* Bouton pour "Tout sélectionner" */}
                    
                   {nbListOnScreen<2 &&
                    <div onClick={()=>SelectAll()}  style={{display:"flex", flexDirection:"column",  alignItems:"center", margin:30, width:"min-content", justifyContent:"center", justifyItems:"center"}} >
                        <div className="test" style={{display:"flex",  alignItems:"center", width:"max-content"}} onMouseEnter={()=>setIsHoverSelectAll (true)} onMouseLeave={()=>setIsHoverSelectAll (false)}>
                            <p style={{margin:20, fontWeight:500}}>{isSelectingAll?"Tout désélectionner":"Tout sélectionner"}</p>
                            </div>
                        <div  style={{width:"100%", height:3, backgroundColor:isHoverSelectAll?"#f9b233":"#747474"}}></div>
                    </div>}
                    
                    {/* /////////////////////////////// */}
                        
                </div>
                
                {nbListOnScreen!==2?
                    <>
                        {reductedExpeditionList.map ((element:Colis, index:number) =>
                            <div key={index} style={{display:"flex" ,justifyItems:"center", flexDirection:"column"}}>
                                <ExpeditionComponent element={element} index={index}/>
                                
                                {index!==reductedExpeditionList.length-1 &&
                                    <div style={{width:"90%", alignSelf:"center", justifySelf:"center", height:3, backgroundColor:'#9a9a9a', borderRadius:10}}></div>
                                }
                                
                            </div>
                        )} 
                    </>
                    :
                    <>
                        <div style={{width:width>1200?"30%":"100%", padding:width>1200?40:0, paddingBottom:0, paddingTop:10, boxSizing:"border-box"}}>
                            <TextInput width="100%" label={"Date"} bordercolor="#747474" value={dateFiltreManisfeste} onChange={(props)=>setDateFiltreManisfeste (props.target.value)} type="date" InputProps={{startAdornment:<></>}}/>
                        </div>
                        {reductedManifesteList.map ((element:Manifeste, index:number) =>
                            <div key={index} style={{display:"flex" ,justifyItems:"center", flexDirection:"column"}}>
                                <ManifesteComponent element={element}/>
                                
                                {index!==reductedManifesteList.length-1 &&
                                    <div style={{width:"90%", alignSelf:"center", justifySelf:"center", height:3, backgroundColor:'#9a9a9a', borderRadius:10}}></div>
                                }
                                
                            </div>
                        )} 
                        <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>
                            <input onChange={ (e) => handleChange(e.target.files) } type="file" ref={uploadFileRef} style={{width:0, height:0, visibility:"hidden"}}/>
                            <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:"3px 10px", pointerEvents: "visible"}} onClick={()=>uploadFileRef.current.click ()}>
                                <p>Upload file</p>
                            </div>
                            
                        </div>
                    </>
                }      
            
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", userSelect:"none", marginBottom:20}}>
                    {/* <span className="table" style={pageNb>1?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}} onClick={()=> {goToPreviousPage()}}>
                        <p className="table" style={{color:"white"}}>Page précédente</p>
                    </span>
                    <p style={{cursor:"default"}}>{pageNb}/{Math.floor((filteredList.length-1)/20)+1}</p>
                    <span className="table" style={pageNb<filteredList.length/20?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}}  onClick={()=> {goToNextPage()}}>
                        <p className="table" style={{color:"white"}}>Page suivante</p>
                    </span> */}
                    <Pagination count={filteredExpeditionList.length!==0? Math.floor((filteredExpeditionList.length-1)/20)+1:1} size="large"  onChange={(_event, number)=>setPageNb (number)}/>
                </div>
                </>:isLoading?<CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>: <p>Pas de données à afficher</p>}
                
            </div>
        </div>
    </>
    )
}
export default DemandeDeRamassage;