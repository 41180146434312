import React from "react"
import { UserToken, url, UserSetToken, widthContext } from "../../context/token";
import Position from "../../assets/Position.png"
import Truck from "../../assets/Truck.png"
import Check from "../../assets/yellowCheck.svg"

import { isStillConnected } from "../../utils/areYouStillConnected";
import { TextInput } from "../../components/input";
import { CircularProgress } from "@mui/material";
import { CarrierRegistration, SubAccount } from "../../types";
function MonProfilSubAccount  ()  {
    const [userInfo, setUserInfo] = React.useState ({} as SubAccount)
    
    const [transporteur, setTransporteur] = React.useState (new Array<CarrierRegistration> ())
    React.useEffect (() => {
        // setUserInfo ({
        //     "physicalSign":"Juniorisep",
        //     "address":"28 rue Notre Dame des Champs",
        //     "address2":"10 Rue de Vanves, 92130 Issy-les-Moulineaux",
        //     "transporteur":[{"nom":"TNT", "type":"Ramassage en vélo", "numéro":"3"}, {"nom":"TNT2", "type":"Ramassage en camion", "numéro":"4"}]
        // })
        
        getInfo ()
        // eslint-disable-next-line
    }, [])

    const width = React.useContext (widthContext)
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)


    
    
    async function getInfo (){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/sub-account/user', {
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token["accessToken"]}`
                    },
                });
        const json = await (await response).json();
        
        setTransporteur (json.carrierRegistrations)
        // console.log (json)
        setUserInfo (json)
        
        setIsLoading (false)
    }
    
    

    const [oldPassword, setOldPassword] = React.useState ("")
    const [newPassword1, setNewPassword1] = React.useState ("")
    const [newPassword2, setNewPassword2] = React.useState ("")

    const [changeSucceed, setChangeSucceed] = React.useState (false)
    async function changePassword (){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = await fetch (url + '/user/update-password', {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
                "previousPassword": oldPassword,
                "password": newPassword1,
                "passwordConfirmation": newPassword2
                })
            })
            if (response.ok){
                setChangeSucceed (true)
                alert ("Le mot de passe a été changé")
            } else {
                const json = await (response).json()
                alert (json.message)
            }
    }
    const [isLoading, setIsLoading] = React.useState (true)
    return (

        <div style={{display:"flex", justifyContent:"center", flexDirection:"column", position:"relative", boxSizing:  "border-box"}}>
            <div className="Cadre-blanc Cadre-profil" style={{justifySelf:"center", alignSelf:"center",  padding: width>780 ?"10px 50px":"0px 20px", boxSizing:  "border-box", marginBottom:30, display:"flex", flexDirection:"column"}}>
                {!isLoading ?
                <>
                <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:width<600?"center":"normal"}}>    
                    <div className="Profil-Logo">
                        <div className="Profil-Logo2"></div>
                        <div className="Profil-Logo3"></div>
                        <p style={{zIndex:1, position:"relative", margin:30, font: "italic normal normal 25px/1 Verdana, Geneva, sans-serif"}}>{userInfo.name}</p>
                    </div>
                </div>
                <div>
                    <h2 style={{textAlign:"left", color:"#474746"}}>Adresse :</h2>
                    
                    <div style={{display:'flex', flexDirection:"row", alignItems:'center',  left:"10%"}}>
                        <img src={Position} alt="" style={{height:30, resize:"none"}} draggable={false}/>
                        <p style={{marginLeft:20, textAlign:"left"}}>{userInfo.address?.streetLine1} {userInfo.address?.streetLine2} {userInfo.address?.city} {userInfo.address?.zip}, {userInfo.address?.country}</p>
                    </div>
                    <h2 style={{textAlign:"left", color:"#474746"}}>Adresse de ramassage :</h2>
                    
                    <div style={{display:'flex', flexDirection:"row", alignItems:'center',  left:"10%"}}>
                        <img src={Position} alt="" style={{height:30, resize:"none"}}/>
                        <p style={{marginLeft:20, textAlign:"left"}}>{userInfo.billingAddress.streetLine1} {userInfo.billingAddress.streetLine2} {userInfo.billingAddress.city} {userInfo.billingAddress.zip}, {userInfo.billingAddress.country}</p>
                    </div>

                    <div style={{display:'flex', flexDirection:"column", alignItems:'center',  left:"10%"}}>
                        
                        <p style={{marginLeft:20, textAlign:"left"}}>SIRET : {userInfo.siret}</p>
                        <p style={{marginLeft:20, textAlign:"left"}}>Numéro TVA : {userInfo.tvaNumber}</p>
                        <p style={{marginLeft:20, textAlign:"left"}}>Activité : {userInfo.activity}</p>
                        <p style={{marginLeft:20, textAlign:"left"}}>Statut légal : {userInfo.legalStatus}</p>
                        <p style={{marginLeft:20, textAlign:"left"}}>Statut légal : {userInfo.legalStatus}</p>
                        <p style={{marginLeft:20, textAlign:"left"}}>{userInfo.isBillingManager?"Gère ses factures":"Ne gère pas ses factures"}</p>
                        <p style={{marginLeft:20, textAlign:"left"}}>{userInfo.saturdayExpedition?"Expédition le samedi":"Pas d'expédition le samedi"}</p>
                    </div>
                </div>
                {transporteur.length>0 &&
                    <div style={{width:width>780?"100%":"80%", backgroundColor:"#a2a2a2", height:3, alignSelf:"center", justifySelf:"center", borderRadius:10, margin:width>780?"50px 0px":"30px 0px"}}></div>
                }
                <div style={{display:"grid", gridTemplateColumns: width>600?'50% 50%':"100%", justifyItems:"center"}}>
                    {transporteur.map (element =>
                        <div className="Transporteur" key={element.id}>
                            <span style={{color:"#e7ac4f", fontSize:20, fontWeight: 650, margin:20, marginBottom:10}}>{element.carrier.name}</span>
                            <span style={{color:"#727271", fontWeight:550, margin:"0px 20px"}}>Type de ramassage : {element.pickupType}</span>
                            <span style={{color:"#727271", fontWeight:550, margin:"0px 20px"}}>Numéro associé : {element.associatedNumber}</span>
                            <span style={{color:"#727271", fontWeight:550, margin:"0px 20px", marginBottom:10}}>Type de facturation : {element.billingType}</span>
                        </div>    
                    )}
                </div>
                </>
                :
                <CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>}
            </div>
            <div className="Cadre-blanc Cadre-profil" style={{display:"grid", position:"initial", alignSelf:"center", gridTemplateColumns: width>780?'49% 2% 49%':"100%", padding:"25px 0px", boxSizing: "border-box", marginBottom:50, zIndex:3}}>
                <div>
                    {/* <input type="password" placeholder='Ancien mot de passe' className='Input' value={oldPassword} onChange={handleChangeOldPassword} style={{borderColor:"#a0a0a0", margin:"10px 0px", width:width<600?"90%":""}}/> */}
                    <TextInput type="password" label='Ancien mot de passe' value={oldPassword} onChange={(props)=>setOldPassword (props.target.value)} bordercolor="#a0a0a0" style={{margin:"10px 0px", width:width<600?"90%":""}}/>
                </div>
                {width>780 &&
                    <div style={{width:3, backgroundColor:"#a2a2a2", height:"104%", alignSelf:"center", justifySelf:"center", borderRadius:10, margin:"50px 0px"}}></div>
                }
                <div>
                    <div style={{display:"flex", justifyContent:"center", justifyItems:"center", flexDirection:"column", alignItems:"center"}}>
                        
                        <TextInput type="password" label='Nouveau mot de passe' value={newPassword1} onChange={(props)=>setNewPassword1(props.target.value)} bordercolor="#f0ba61" style={{ margin:"10px 0px", width:width<600?"90%":""}}/>
                        <TextInput type="password" label='Confirmez le mot de passe' value={newPassword2} onChange={(props)=>setNewPassword2 (props.target.value)} bordercolor="#f0ba61" style={{margin:"10px 0px", width:width<600?"90%":""}}/>
                        <div className='Input-button2'  style={{backgroundColor:changeSucceed?"#a2a2a2":"#e7ac4f", color:"#fff", margin:"20px", borderRadius:"15px",  textAlign:"center", width:"50%", cursor:"pointer"}} onClick={()=>changePassword()}>
                            {changeSucceed ? 
                                <img src={Check} alt='' style={{height:40, resize:"none", margin:12, paddingLeft:0}} draggable={false}/>
                            :
                                <p>Confirmation</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <img src={Truck} alt="" style={{position:"absolute", right:0, bottom:0, width:"30%", opacity:"60%", zIndex:2}} draggable={false}/>
        </div>
    )
}
export default MonProfilSubAccount