import React from "react";

import { UserToken, UserSetToken, url, widthContext } from "../../context/token";

import { Account } from "../../types";
// Images
import {ReactComponent as Bonhomme} from "../../assets/bonhomme.svg";
import Position from "../../assets/Position.png"
import {ReactComponent as CodePostaIMG} from "../../assets/code-postal.svg";
import {ReactComponent as Maison} from "../../assets/maison.svg";
import {ReactComponent as Bin} from "../../assets/bin.svg";

import { isStillConnected } from "../../utils/areYouStillConnected";

import { useNavigate } from "react-router-dom";
import { TextInput } from "../../components/input";
import { Backdrop, CircularProgress, Pagination, Tooltip } from "@mui/material";
import { Connexion } from "../../utils/connexion";

function ListeDesClientsInscrits  ()  {
    // Pour gérer l'authentification
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)

    ////////////////////////////////

    // Pour gérer la liste des expéditions, et le système de pagination
    const [accountList, setAccountList] = React.useState (
        new Array<Account> ()
    ) 
    const [pageNb, setPageNb] = React.useState (1)
    

    const [filteredList, setFilteredList] = React.useState (accountList)
    const reductedList = filteredList.slice ((pageNb-1)*20, (pageNb*20))

    const [isLoading, setIsLoading] = React.useState (true)
    ///////////////////////////////////////////////////////////////////

   
    // Pour gérer les filtres


    const [nameRespoFilter, setNameRespoFilter] = React.useState ("")
    const [addressRespoFilter, setAddressRespoFilter] = React.useState ("")
    const [zipCodeRespoFilter, setZipCodeRespoFilter] = React.useState ("")
    const [siretRespoFilter, setSiretRespoFilter] = React.useState ("")

    React.useEffect (()=>{
        var temp = accountList.slice ()
        
        if (nameRespoFilter!==""){
            temp = temp.filter((obj) => {return (obj.name.slice (0, nameRespoFilter.length).localeCompare (nameRespoFilter, undefined, {sensitivity:"base"}))===0}) 
        }  
        if (addressRespoFilter!==""){
            temp = temp.filter((obj) => {return (obj.name.slice (0, addressRespoFilter.length).localeCompare (addressRespoFilter, undefined, {sensitivity:"base"}))===0}) 
        }  
        if (zipCodeRespoFilter!==""){
            temp = temp.filter((obj) => {return (obj.name.slice (0, zipCodeRespoFilter.length).localeCompare (zipCodeRespoFilter, undefined, {sensitivity:"base"}))===0}) 
        }  
        if (siretRespoFilter!==""){
            temp = temp.filter((obj) => {return (obj.siret.slice (0, siretRespoFilter.length).localeCompare (siretRespoFilter, undefined, {sensitivity:"base"}))===0}) 
        }  
        
        setFilteredList (temp)
    }, [accountList, nameRespoFilter, addressRespoFilter, zipCodeRespoFilter, siretRespoFilter])
    ///////////////////////////////////////////////

    // Pour gérer l'obtention de la liste des comptes responsables
    async function getAccounts () {
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/account', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token["accessToken"]}`
            },
        });
        const json:Array<Account> = await (await response).json();
        // console.log (json)
        setAccountList (json)
        setFilteredList (json)
        setIsLoading (false)
    }

    
    React.useEffect (()=>{
        Connexion ()
        getAccounts ()
         // eslint-disable-next-line
    }, [])
    /////////////////////////////////////////////////

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////


    // const [isHover3, setIsHover3] = React.useState (false)

    // const [tryToFilter, setTryToFilter] = React.useState (false)
    // function removeAllFilter (){
    //     setDateFiltre ("")
    //     setInternationalDeliveryFiltre (false)
    // }

    // Component Transporteur à boucler
    function AccountComponent (props:{element:Account, index:number}){
        const element:Account = props.element
        const [isHoverBin, setIsHoverBin] = React.useState (false)
        const [tryToRemove, setTryToRemove] = React.useState (false)
        async function remove (){
            if (!isStillConnected (token, setToken)) {
                return 
            }
            
            const response = await fetch (url + '/account/'+element.id, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
                },
            });
            if (response.ok){
                setIsLoading (true)
                await getAccounts ()
            }
        }
        return (
            <div className="hoverClickable" key={element["id"]}  style={{cursor:"pointer", width:"100%", margin:0, boxSizing:"border-box", paddingBottom:20, paddingTop:20, justifyContent:"center", justifyItems:"center", display:"flex"}} onClick={()=>navigate ("/account/"+element["id"].toString())}>
                <div style={{display:"flex", flexDirection:width>1200?"row":"column", width:"90%", justifyContent:"space-around", gap:10}}>

                    <div style={{justifyContent:"left", padding:5, paddingTop:0, paddingBottom:0, boxSizing:"border-box", display: "flex", flexDirection:"row", alignItems:"center", flex:1.5}}>
                        <div style={{textAlign:"left", display:'flex', flexDirection:"row"}}>
                            <Bonhomme style={{ marginRight:10, justifySelf:"center", resize:"none", width:40 }}/>
                            <div style={{display:"flex", flexDirection:width>1200?"column":"column-reverse", marginLeft:5}}>
                                <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{element.name}</span>
                                <span style={{color:"#747474"}}>Entité {width<=1200 && ":"}</span>
                            </div>
                        </div>
                        {/* {width>1200 && <span style={{color:"#747474", fontWeight:600}}>Numéro d'expédition</span>} */}
                    </div>

                    <div style={{justifyContent:"left",  display: "flex", flexDirection:"row", alignItems:"center", flex:2}}>
                        <div style={{textAlign:"left", display:'flex', flexDirection:"row"}}>
                            <img src={Position} alt='' style={{width:40, height:40, resize:"none", marginRight:10, alignSelf:"center", }} draggable={false}/>
                            <div style={{display:"flex", flexDirection:width>1200?"column":"column-reverse", marginLeft:5}}>
                                <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{element.address.streetLine1} {element.address.streetLine2}</span>
                                <span style={{color:"#747474"}}>Adresse postale {width<=1200 && ":"}</span>
                            </div>
                        </div>
                    </div>

                    <div style={{justifyContent:"left", display: "flex",  flexDirection:"row", alignItems:"center", flex:1}}>
                        <div style={{display:"flex", flexDirection:"row", textAlign:"left"}}>
                        <CodePostaIMG style={{width:40, resize:"none", height:40, marginRight:10, alignSelf:"center"}}/>
                        <div style={{display:"flex", flexDirection:width>1200?"column":'column-reverse', alignSelf:"center"}}>
                            <span style={{color:"#747474", fontSize:30, fontWeight:700}}>75006</span>
                            <span style={{color:"#747474"}}>Code postal {width<=1200 && ":"}</span>
                        </div>
                        </div>
                    </div>

                    <div style={{  display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"left", flex:1.5}}>
                        <div style={{display:"flex", flexDirection:"row", textAlign:"left", alignContent:"left", alignItems:"left", justifyContent:"left", justifyItems:"left"}}>
                            <Maison style={{width:40, resize:"none", height:"min-content", marginRight:10}}/>
                            <div style={{display:"flex", flexDirection:width>1200?"column":"column-reverse", marginLeft:0, textAlign:"left"}}>
                                <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{element.siret}</span>
                                <span style={{color:"#747474"}}>SIRET {width<=1200 && ":"}</span>
                            </div>
                        </div>
                    </div>
                    <Tooltip title={'Supprimer le compte "'+element.name+'"'}>
                        <Bin style={{height:40,width:50, resize:"none", alignSelf:"center", justifySelf:"center", fill:isHoverBin?"#FA1205":"#a2a2a2", cursor:"pointer", flex:0.2}} onClick={(e)=>{setTryToRemove(true);e.stopPropagation ()}} onMouseEnter={()=>setIsHoverBin(true)} onMouseLeave={()=>setIsHoverBin(false)}/>
                    </Tooltip>

                    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={tryToRemove} onClick={()=>setTryToRemove (false)}>
                        <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 300px)", padding:20}}>
                                <h2 style={{ margin:0, marginBottom:20}}>Supprimer le responsable entreprise "{element.name} ?</h2>                                
                                
                                <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
                                    <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setTryToRemove (false)}}>
                                        <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                                    </div>
                                    <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {remove ();setTryToRemove (false)}}>
                                        <p style={{color:"white", margin:0}}>Confirmer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Backdrop>
                    
                </div>
  
            </div>
        )
    }
    /////////////////////////////////////////////////////////////

    
    // Handle the main header size to adapt
    const [headerHeight, setHeaderHeight] = React.useState (60)
    React.useEffect(() => {
        let header = document.getElementById("header");
        if (header) {
            setHeaderHeight (header.clientHeight)
        }
      }, [])
    ////////////////////////////////////////////////

    const navigate = useNavigate ()
    return (
        <>
        
        <div className="table2" style={{padding:0, margin:"0px 20px 20px 20px",}}>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%", justifySelf:'center', position:"sticky", top:headerHeight+40, zIndex:3, pointerEvents: "none", userSelect:"none", marginBottom:20}} >
                <div style={{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition" >
                    <p>Liste des responsables entreprise</p>
                </div>
            </div>
                

                <div className="table" style={{width:"100%", display:"flex"}}>
                {accountList.length>0 && !isLoading ?
                <>
                <div style={{ display:"flex" ,alignItems:"flex-end", flexDirection:"row-reverse"}}>
                    
                    {/* {width<1000 &&
                        <div onClick={()=>setTryToFilter(true)} style={{display:"flex", flexDirection:"column",  alignItems:"center", margin:30, width:"min-content"}} >
                            <div className="test Input-password" style={{display:"flex", flexDirection:"row", alignItems:"center", width:"max-content"}} onMouseEnter={()=>setIsHover3 (true)} onMouseLeave={()=>setIsHover3 (false)}>
                                <p style={{marginLeft:20, marginRight:20, fontWeight:500}}>Filtrer</p>
                            </div>
                            <div  style={{width:"100%", height:3, backgroundColor:isHover3?"#f9b233":"#747474"}}></div>
                        </div>
                    } */}

                    
                </div>
                {width>1000 &&
                    <div style={{display:"flex" , flexDirection:"row", alignSelf:"center", marginTop:20, width:"100%", boxSizing:"border-box", justifyContent:"center"}}>
                        <div style={{display:"flex", flexDirection:width>1200?"row":"column", width:"100%", justifyContent:"center", alignSelf:"center"}}>
                            <div style={{display:"flex" , alignSelf:"center",  width:"90%", justifyContent:width>780?"space-evenly":"left"}}>
                                <div style={{flex:1.5, padding:0}}>
                                    <TextInput width="98%" bordercolor="#7d7d7d" label="Entité" value={nameRespoFilter} onChange={(value)=>setNameRespoFilter(value.target.value)}/>
                                </div>
                                <div style={{flex:2}}>
                                    <TextInput width="98%" bordercolor="#7d7d7d" label="Adresse du responsable" value={addressRespoFilter} onChange={(value)=>setAddressRespoFilter(value.target.value)}/>
                                </div>
                                <div style={{flex:1}}>
                                    <TextInput width="98%" bordercolor="#7d7d7d" label="Code postal" value={zipCodeRespoFilter} onChange={(value)=>{if (!Number.isNaN (+value.target.value)){setZipCodeRespoFilter(value.target.value);}}}/>
                                </div>
                                <div style={{flex:1.5}}>
                                    <TextInput width="98%" bordercolor="#7d7d7d" label="Numéro de SIRET" value={siretRespoFilter} onChange={(value)=>{if (!Number.isNaN (+value.target.value)){setSiretRespoFilter(value.target.value);}}}/>
                                </div>
                                <div style={{flex:0.2, height:40,width:50,}}></div>
                            </div>
                        </div>
                    </div>
                }
                {/* {width<1000 && tryToFilter &&
                <div style={{display:"flex" ,justifyItems:"center", flexDirection:"column", width:"100vw",height:"100vh", top:0,alignSelf:"center", position:"fixed", backgroundColor:"white", zIndex:5, justifyContent:"center", borderRadius:30, padding:20, boxSizing:"border-box", overflowY:'auto',}}>
                    <h2>Filtres</h2>                    
                  
                    <select value={LivraisonFiltre} className='Change-Color-Hover' onChange={handleChangeLivraisonFiltre} style={{cursor:livraisonList.length>1?"pointer":"default", backgroundColor:livraisonList.length>1?"":"white", marginBottom:10, marginTop:10, borderRadius:10, border: "3px solid #7d7d7d", padding:15, width:"80%", alignSelf:"center"}} disabled={livraisonList.length<=1}>
                        <option value="">Livraison</option>
                        {livraisonList.map ((element, index)=>
                            <option value={element} key={index}>{element}</option> 
                        )}
                    </select>  
                    <div style={{ display:"flex", flexDirection:"row", alignItems:"center",alignSelf:"center", justifySelf:"center", margin:20, marginBottom:10, marginTop:10, borderRadius:10, border: "3px solid #7d7d7d", width:"80%"}}>
                        <Checkbox checked={internationalDeliveryFiltre} onChange={()=>setInternationalDeliveryFiltre (!internationalDeliveryFiltre)}/>
                        <p style={{margin:"0px 20px 0px 0px"}}>International</p>
                    </div>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                    <span className="table" style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center"}} onClick={()=> {removeAllFilter()}}>
                        <p className="table" style={{color:"white"}}>Supprimer tous les filtres</p>
                    </span>
                    <span className="table" style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center"}}  onClick={()=> {setTryToFilter(false)}}>
                        <p className="table" style={{color:"white"}}>Appliquer</p>
                    </span>
                </div>
                </div>
                }  */}
                {reductedList.map ((element:Account, index:number) =>
                    <div key={index} style={{display:"grid" ,justifyItems:"center", flexDirection:"column", width:"100%", position:"relative"}}>
                        <AccountComponent element={element} index={index}/>
                        
                        {index!==reductedList.length-1 &&
                            <div style={{width:"90%", alignSelf:"center", justifySelf:"center", height:3, backgroundColor:'#9a9a9a', borderRadius:10}}></div>
                        }
                        
                    </div>
                )}       
                <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>

                    <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:"3px 10px", pointerEvents: "visible"}} onClick={()=>navigate ("/create_account")}>
                        <p>Nouveau compte responsable</p>
                    </div>
                    
                </div>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", marginTop:10, marginBottom:20, userSelect:"none"}}>
                    {/* <span className="table" style={pageNb>1?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}} onClick={()=> {goToPreviousPage()}}>
                        <p className="table" style={{color:"white"}}>Page précédente</p>
                    </span>
                    <p style={{cursor:"default"}}>{pageNb}/{Math.floor((filteredList.length-1)/20)+1}</p>
                    <span className="table" style={pageNb<filteredList.length/20?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}}  onClick={()=> {goToNextPage()}}>
                        <p className="table" style={{color:"white"}}>Page suivante</p>
                    </span> */}
                    <Pagination count={filteredList.length!==0? Math.floor((filteredList.length-1)/20)+1:1} size="large"  onChange={(_event, number)=>setPageNb (number)}/>
                </div>
                </>:isLoading?<CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>: <p>Pas de données à afficher</p>
                }
            </div>
        </div>
        
        
    </>
    )
}
export default ListeDesClientsInscrits;