import React from "react";

import { UserToken, UserSetToken, url, widthContext } from "../../context/token";

import { SubAccount, } from "../../types";
import { TextInput } from "../../components/input";

// Images
import Position from "../../assets/Position.png"
import Mail2 from "../../assets/Mail.png"
import {ReactComponent as Bonhomme} from "../../assets/bonhomme.svg";
import {ReactComponent as CodePostaIMG} from "../../assets/code-postal.svg";

import { isStillConnected } from "../../utils/areYouStillConnected";

import { useNavigate } from "react-router-dom";
import { CircularProgress, Pagination } from "@mui/material";
import { Connexion } from "../../utils/connexion";


function MesSousComptes  ()  {
    // Pour gérer l'authentification
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)

    ////////////////////////////////



    async function GetInfo () {
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/sub-account/', {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            }
          })
        const json:Array<SubAccount> = await (await response).json();
        
        json.map ((element)=>element.checked=false)
        setSubAccountList (json)
        setFilteredSubAccountList (json)

        setIsLoading (false)
    }

    const [isLoading, setIsLoading] = React.useState (true)
    //////////////////////////////////////////////////////////////////


    // Pour gérer l'obtention et l'actualisation des infos du compte
    React.useEffect (()=>{
        Connexion ()
        GetInfo ()
         // eslint-disable-next-line
    }, [])
    /////////////////////////////////////////////////

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////

    const navigate = useNavigate ()


    // Gérer la liste des sous-compte
    const [subAccountList, setSubAccountList] = React.useState (
        new Array<SubAccount> ()
    ) 
    const [pageNbSubAccountList, setPageNbSubAccountList] = React.useState (1)


    const [filteredSubAccountList, setFilteredSubAccountList] = React.useState (subAccountList)
    const reductedSubaccountList = filteredSubAccountList.slice ((pageNbSubAccountList-1)*20, (pageNbSubAccountList*20))


    // Pour gérer les filtres
    const [nameRespoFilter, setNameRespoFilter] = React.useState ("")
    const [addressRespoFilter, setAddressRespoFilter] = React.useState ("")
    const [zipCodeRespoFilter, setZipCodeRespoFilter] = React.useState ("")
    const [siretRespoFilter, setSiretRespoFilter] = React.useState ("")

    React.useEffect (()=>{
        var temp = subAccountList.slice ()
        
        if (nameRespoFilter!==""){
            temp = temp.filter((obj) => {return (obj.name.slice (0, nameRespoFilter.length).localeCompare (nameRespoFilter, undefined, {sensitivity:"base"}))===0}) 
        }  
        if (addressRespoFilter!==""){
            temp = temp.filter((obj) => {return (obj.name.slice (0, addressRespoFilter.length).localeCompare (addressRespoFilter, undefined, {sensitivity:"base"}))===0}) 
        }  
        if (zipCodeRespoFilter!==""){
            temp = temp.filter((obj) => {return (obj.address?.zip.toString().slice (0, zipCodeRespoFilter.length).localeCompare (zipCodeRespoFilter, undefined, {sensitivity:"base"}))===0}) 
        }  
        if (siretRespoFilter!==""){
            temp = temp.filter((obj) => {return (obj.siret.slice (0, siretRespoFilter.length).localeCompare (siretRespoFilter, undefined, {sensitivity:"base"}))===0}) 
        }  
        
        setFilteredSubAccountList (temp)
    }, [subAccountList, nameRespoFilter, addressRespoFilter, zipCodeRespoFilter, siretRespoFilter])
    ///////////////////////////////////////////////


   

    function SubAccountComponent (props:{element:SubAccount, index:number}){
        
        const element = props.element
        React.useEffect (()=>{
            console.log (element)
        })
        // Gère l'affichage des emails concernées
        function ToolTip2 (e:{children: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined, style?:{}}){
            const [isHover, setIsHover] = React.useState (false)
            return (
                <div style={{...e.style, position:"relative", zIndex:2}} onMouseLeave={()=>setIsHover (false)} onMouseEnter={()=>setIsHover (true)} onClick={(e)=>{e.stopPropagation ()}}>
                    {e.children}
                    {isHover && <div>
                        {props.element.users?.map ((user)=>
                            <div className="Cadre-blanc" style={{position:"absolute", left:0, width:"max-content", padding:"0px 10px", bottom:"-100%", transform:"translate (100%, 100%)", maxWidth:"70vw", opacity:1, backgroundColor:"white", zIndex:2}}>
                                <div style={{width: 0, height: 0, border: "solid 30px", borderColor: "transparent transparent white transparent", position:"absolute", bottom:"100%", left:"10%"}}></div>
                                <p style={{color:"black"}}>{user.firstName} {user.lastName} : {user.email}</p>
                            </div>
                        )}
                    </div>}
                </div>
            )
        }

        return (
            <div className="Change-Color-Hover" key={element["id"]} style={{padding:20, paddingRight:0, paddingLeft:0, display:"flex", flexDirection:width>1200?"row":"column", width:"100%", alignItems:"center", justifyContent:"center", cursor:"pointer", zIndex:1}} onClick={()=>navigate ("/subaccount/"+element.id)}>
                <div style={{display:"flex", width:"95%", justifySelf:"center"}}>
                    <div style={{flex:6.5, display:"flex", flexDirection:width>1200?"row":"column", height:"100%", gap:15}}>
                        <div style={{textAlign:"left",  display: "grid", gridTemplateColumns:"20% 80%", flexDirection:"row", alignItems:"center", flex:1.8}}>
                            <div style={{ width:50,  resize:"none" }}>
                            <Bonhomme style={{ width:"100%", height:"100%", resize:"none" }}/>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", marginLeft:5}}>
                                <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{element.name}</span>
                                <span style={{color:"#747474"}}>Responsable entreprise</span>
                            </div>
                            {/* {width>1200 && <span style={{color:"#747474", fontWeight:600}}>Numéro d'expédition</span>} */}
                        </div>

                        <div style={{textAlign:"left",  display: "flex", flexDirection:"row", paddingLeft:30, alignItems:"center", flex:2}}>
                            <img src={Position} alt='' style={{width:40, resize:"none", marginRight:10}} draggable={false}/>
                            <div style={{display:"flex", flexDirection:"column", marginLeft:5}}>
                                <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{element.address?.streetLine1}</span>
                                <span style={{color:"#747474"}}>Adresse</span>
                            </div>
                            {/* {width>1200 && <span style={{color:"#747474", fontWeight:600}}>Numéro d'expédition</span>} */}
                        </div>
                        

                        <div style={{textAlign:"left", display: "flex",  flexDirection:"row", paddingLeft:30, alignItems:"center", flex:1}}>
                            <CodePostaIMG style={{width:40, height:40, resize:"none", marginRight:10}}/>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <span style={{color:"#747474", fontSize:30, fontWeight:700}}>{element.address?.zip}</span>
                                <span style={{color:"#747474"}}>Code postal</span>
                            </div>
                        </div>

                        <ToolTip2 style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"left", flex:1.7}}>
                            {/* <Maison style={{width:40, resize:"none", height:"min-content", marginRight:10}}/> */}
                            <img src={Mail2} alt="" style={{width:40, height:40, resize:"none", marginRight:10, zIndex:1}}/>
                            <div style={{display:"flex", flexDirection:"column", marginLeft:0, textAlign:"left", zIndex:1}}>
                                <span style={{color:"#747474", fontWeight:700, marginRight:0}}>{element.users!==undefined?element.users.length:0} compte{element.users!==undefined && element.users?.length>1?"s":""}</span>
                                <span style={{color:"#747474"}}>Adresse mail</span>
                            </div>
                        </ToolTip2>
                    </div>
                    
                </div>       
            </div>
        )
    }
    return (
        <>
        
        <div className="Cadre-blanc" style={{margin:"0px 20px", padding:0}}>
            
                

            <div style={{margin:0, display:"flex", flexDirection:"column", width:"100%"}}>
                

                {!isLoading?
                <>
                    {subAccountList.length>0?
                    <>
                    {width>1000 &&
                    <div style={{display:"flex" , flexDirection:"row", alignSelf:"center", marginTop:20, width:"100%", boxSizing:"border-box", justifyContent:"center"}}>
                        <div style={{display:"flex", flexDirection:width>1200?"row":"column", width:"100%", justifyContent:"center", alignSelf:"center"}}>
                            <div style={{display:"flex" , alignSelf:"center",  width:"95%", justifyContent:width>780?"space-evenly":"left"}}>
                                <div style={{display:"flex", flex:6.5}}>
                                    <div style={{flex:1.8, padding:0}}>
                                        <TextInput width="98%" bordercolor="#7d7d7d" label="Nom du responsable" value={nameRespoFilter} onChange={(value)=>setNameRespoFilter(value.target.value)}/>
                                    </div>
                                    <div style={{flex:2}}>
                                        <TextInput width="98%" bordercolor="#7d7d7d" label="Adresse du responsable" value={addressRespoFilter} onChange={(value)=>setAddressRespoFilter(value.target.value)}/>
                                    </div>
                                    <div style={{flex:1}}>
                                        <TextInput width="98%" bordercolor="#7d7d7d" label="Code postal" value={zipCodeRespoFilter} onChange={(value)=>{if (!Number.isNaN (+value.target.value)){setZipCodeRespoFilter(value.target.value);}}}/>
                                    </div>
                                    <div style={{flex:1.7}}>
                                        <TextInput width="98%" bordercolor="#7d7d7d" label="Numéro de SIRET" value={siretRespoFilter} onChange={(value)=>{if (!Number.isNaN (+value.target.value)){setSiretRespoFilter(value.target.value);}}}/>
                                    </div>
                                </div>
                                <div style={{flex:0.5}}></div>
                            </div>
                        </div>
                    </div>
                }
                    {reductedSubaccountList.map ((element:SubAccount, index:number) =>
                        <div key={index} style={{display:"grid" ,justifyItems:"center", flexDirection:"column", width:"100%", position:"relative"}}>
                            <SubAccountComponent element={element} index={index}/>
                            
                            {index!==reductedSubaccountList.length-1 &&
                                <div style={{width:"90%", alignSelf:"center", justifySelf:"center", height:3, backgroundColor:'#9a9a9a', borderRadius:10}}></div>
                            }
                            
                        </div>
                    )}       
                    
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", userSelect:"none", marginBottom:20}}>
                        <Pagination count={filteredSubAccountList.length!==0? Math.floor((filteredSubAccountList.length-1)/20)+1:1} size="large"  onChange={(_event, number)=>setPageNbSubAccountList (number)}/>
                    </div>
                    </>
                    :
                    <>
                        <p>Pas de données à afficher</p>
                    </>
                    }
                        <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>
                            <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:"3px 10px", pointerEvents: "visible"}} onClick={()=>navigate ("/create_subaccount/")}>
                                <p>Créer un sous-compte</p>
                            </div>
                        </div>
                
                </>:isLoading?<CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>: <p>Pas de données à afficher</p>}
                {/* /////////////////////////////////////////////////////////////////////////// */}
            </div>
        </div>
        
        
    </>
    )
}
export default MesSousComptes;