import React from "react";

import { UserToken, UserSetToken, url, widthContext } from "../../../context/token";

import { SubAccount, CarrierRegistration, Carrier, PriceList } from "../../../types";
import { TextInput } from "../../../components/input";
import { SelectInput } from "../../../components/select";
// Images
import transporteur from "../../../assets/livraison-de-colis.svg"
import Truck2 from "../../../assets/truck2.svg"
import Back from "../../../assets/back.png"
import {ReactComponent as Mail} from "../../../assets/mail2.svg";
import {ReactComponent as Maison} from "../../../assets/maison.svg";
import Position from "../../../assets/Position.png"
import Pen from "../../../assets/pen.png"
import Truck from "../../../assets/Truck.png"
import {ReactComponent as Bin} from "../../../assets/bin.svg";
import Check from "../../../assets/yellowCheck.svg"
import {ReactComponent as Chevron} from "../../../assets/chevron.svg";

import { isStillConnected } from "../../../utils/areYouStillConnected";
import { decodeToken } from "react-jwt";

import { useNavigate, useLocation } from "react-router-dom";
import { Backdrop, Checkbox, CircularProgress, MenuItem, Pagination } from "@mui/material";
import { AntSwitch } from "../../../components/switch";
import { Connexion } from "../../../utils/connexion";
import { SearchAddresses } from "../../../components/searchAddresses";
import FacturationPage from "../../facturation/facturation";

function SubAccountPage  ()  {
    // Pour gérer l'authentification
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)

    let decodedToken:any = decodeToken (token["accessToken"]) 
    const role = decodedToken["role"]
    ////////////////////////////////


    // Pour gérer ce qui est affiché à l'écran
    const [nbScreen, setNBScreen] = React.useState (0)
    const listOfScreen = [
        "Informations générales",
        "Liste des transporteurs",
        "Facturation",
    ]
    //////////////////////////////////////////

    // Pour avoir les données du sous-compte concerné
    const subaccountId = useLocation ().pathname.slice (12)
    // const [subaccount, setSubAccount] = React.useState<SubAccount> ({} as SubAccount)
    const subaccount = React.useRef ({} as SubAccount)

    const [isLoading, setIsLoading] = React.useState (true)

    async function GetInfo () {
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/sub-account/'+subaccountId, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            }
          })
        const json:SubAccount = await (await response).json();
        if (json.carrierRegistrations!==undefined){
            for (let i=0; i<json.carrierRegistrations?.length; i++){
                json.carrierRegistrations[i].checked = false
            }
            await getAvailableCarrierList ()
            setCarrierList (json.carrierRegistrations)
            // console.log (json.carrierRegistrations)
            setFilteredCarrierList (json.carrierRegistrations)
            setAPE ("123")
            var mail = ""
            const j = json.users!==undefined?json.users.length:0
            for (let i=0; i<j; i++){
                if (json.users!==undefined) {
                    mail += json.users[i].email
                }
            }
            setMailAddress (mail)
            setAddress (json.address?.streetLine1 + ", " + json.address?.streetLine2 + (json.address?.streetLine2!==""?", ":"") + json.address?.zip + " " + json.address?.city + ", " + json.address?.country)
            setSiret (json.siret)
            setTVA (JSON.stringify (json.tvaNumber))
            // setSubAccount (json)
            subaccount.current = json
        }
        setAddress1 (json.address!==undefined?json.address.streetLine1:"")
        setAddress2 (json.address!==undefined?json.address.streetLine2:"")
        setZipCode (JSON.stringify (json.address!==undefined?json.address?.zip:""))
        setCity (json.address!==undefined?json.address.city:"")
        setCountry (json.address!==undefined?json.address.country:"")
        setIsLoading (false)
        setIsLoading (false)
    }
    //////////////////////////////////////////////////////////////////


    // Pour gérer l'affichage et modification des infos du sous-compte
    
    // function getDateInLitteral (a:string){
    //     const jour = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"]
    //     const mois = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
    //     const date:Date = new Date (a)
    //     return jour[date.getDay ()] + " " + date.getDate () + " " + mois[date.getMonth ()] + " " + date.getFullYear () + " à " + date.getHours () + ":" + date.getMinutes ()
    // }

    const [address, setAddress] = React.useState ("")
    const [address1, setAddress1] = React.useState ("")
    const [address2, setAddress2] = React.useState ("")
    const [zipCode, setZipCode] = React.useState ("")
    const [city, setCity] = React.useState ("")
    const [country, setCountry] = React.useState ("")
    const [editAddress, setEditAddress] = React.useState (false)

    // Pour gérer l'autocomplétion d'adresse
    const [isAutoCompleteOpen, setIsAutoCompleteOpen] = React.useState (false)

    async function updateAddress (){
        const response = fetch (url + '/account/account/', {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
                "id": subaccountId,
                streetLine1: address1,
                streetLine2: address2,
                city: city,
                zip: zipCode,
                country: country
            })
          })
        const json:string = await (await response).json();
        if (json==="Le compte a bien été mis à jour"){
            GetInfo ()
        }
    }
    function cancelUpdateAddress (){
        setEditAddress (false);
        setAddress1 (subaccount.current.address!==undefined?subaccount.current.address.streetLine1:"")
        setAddress2 (subaccount.current.address!==undefined?subaccount.current.address.streetLine2:"")
        setZipCode (JSON.stringify (subaccount.current.address!==undefined?subaccount.current.address.zip:""))
        setCity (subaccount.current.address!==undefined?subaccount.current.address.city:"")
        setCountry (subaccount.current.address!==undefined?subaccount.current.address.country:"")
    }
    /////////////////////////////////////////////////////


    const siretRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [siret, setSiret] = React.useState ("")
    const [editSiret, setEditSiret] = React.useState (false)
    // eslint-disable-next-line
    React.useEffect (()=>{!isLoading && siretRef.current.focus ()}, [editSiret])
    const siretRefIMG = React.useRef(null)
    useOutsideAlerterForSiret(siretRefIMG);

    const mailAddressRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [mailAddress, setMailAddress] = React.useState ("")
    const [editMailAddress, setEditMailAddress] = React.useState (false)
    // eslint-disable-next-line
    React.useEffect (()=>{!isLoading && mailAddressRef.current.focus ()}, [editMailAddress])
    const addressMailRefIMG = React.useRef(null)
    useOutsideAlerterForMailAddress(addressMailRefIMG);
    
    const TVARef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [TVA, setTVA] = React.useState ("")
    const [editTVA, setEditTVA] = React.useState (false)
    // eslint-disable-next-line
    React.useEffect (()=>{!isLoading && TVARef.current.focus ()}, [editTVA])
    const TVARefIMG = React.useRef(null)
    useOutsideAlerterForTVA(addressMailRefIMG);
    
    const APERef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [APE, setAPE] = React.useState ("")
    const [editAPE, setEditAPE] = React.useState (false)
    // eslint-disable-next-line
    React.useEffect (()=>{!isLoading && APERef.current.focus ()}, [editAPE])
    const APERefIMG = React.useRef(null)
    useOutsideAlerterForAPE(addressMailRefIMG);


    function useOutsideAlerterForSiret(ref:any) {
        React.useEffect(() => {
            function handleClickOutside(event:any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setEditSiret (false)
                    if (subaccount.current.siret!==undefined){
                        setSiret (subaccount.current.siret)
                    }
                }
            }
            document.addEventListener("mouseup", handleClickOutside);
            return () => {
                document.removeEventListener("mouseup", handleClickOutside);
        };
        }, [ref]);
      }
    function useOutsideAlerterForMailAddress(ref:any) {
        React.useEffect(() => {
            function handleClickOutside(event:any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setEditMailAddress (false)
                }
                // if (account.current.mail!==undefined){
                //     setSiret (account.current.mail)
                // }
                // setMailAddress ("addressMail@juniorisep.com")
            }
            document.addEventListener("mouseup", handleClickOutside);
            return () => {
                document.removeEventListener("mouseup", handleClickOutside);
        };
        }, [ref]);
      }
    
    function useOutsideAlerterForTVA(ref:any) {
        React.useEffect(() => {
            function handleClickOutside(event:any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    editTVA && setTVA (JSON.stringify (subaccount.current.tvaNumber))
                    setEditTVA (false)
                    if (subaccount.current.tvaNumber!==undefined){
                        setTVA (JSON.stringify (subaccount.current.tvaNumber))
                    }
                }
            }
            document.addEventListener("mouseup", handleClickOutside);
            return () => {
                document.removeEventListener("mouseup", handleClickOutside);
        };
        }, [ref]);
    }
    
    function useOutsideAlerterForAPE(ref:any) {
        React.useEffect(() => {
            function handleClickOutside(event:any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setEditAPE (false)
                    // if (account.current.ape!==undefined){
                    //     setSiret (account.current.ape)
                    // }
                    setAPE ("123")
                }
            }
            document.addEventListener("mouseup", handleClickOutside);
            return () => {
                document.removeEventListener("mouseup", handleClickOutside);
        };
        }, [ref]);
    }

    async function sendUpdate (){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        setIsLoading (true)
        setEditAPE (false)
        setEditAddress (false)
        setEditMailAddress (false)
        setEditSiret (false)
        setEditTVA (false)
        const response:Promise<any> = fetch (url + '/sub-account/admin/' + subaccountId, {
            method: 'PATCH',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
                // address: address,
                id: subaccount.current.id,
                siret: siret,
                tvaNumber: +TVA
                // email: mailAddress
            })
          })
        try {
            const json = await (await response).json ()
            if (typeof json!=="string"){
                if (json.statusCode===400) {
                    setError (json.message)
                    setAddress (subaccount.current.address!==undefined?subaccount.current.address.streetLine1 + ", " + subaccount.current.address.streetLine2 + (subaccount.current.address.streetLine2!==""?", ":"") + subaccount.current.address.zip + " " + subaccount.current.address.city + ", " + subaccount.current.address.country:"")
                    setSiret (subaccount.current.siret)
                    setTVA (JSON.stringify (subaccount.current.tvaNumber))
                    setAPE ("123")
                    setAddress1 (subaccount.current.address!==undefined?subaccount.current.address.streetLine1:"")
                    setAddress2 (subaccount.current.address!==undefined?subaccount.current.address.streetLine2:"")
                    setZipCode (JSON.stringify (subaccount.current.address!==undefined?subaccount.current.address.zip:""))
                    setMailAddress ("adresseMail@juniorisep.com")
                    setCity (subaccount.current.address!==undefined?subaccount.current.address.city:"")
                    setCountry (subaccount.current.address!==undefined?subaccount.current.address.country:"")
                    setIsLoading (false)
                }
            }
        } catch {
            await GetInfo ()
        }
        setIsLoading (false)
    }
    const [error, setError] = React.useState (new Array<string> ())

    async function editSiretFunction (){
        if (editSiret){
            sendUpdate ()
        } 
        setEditSiret (!editSiret)
    }
    async function editMailAddressFunction (){
        if (editMailAddress){
            sendUpdate ()
        }
        setEditMailAddress (!editMailAddress)
    }
    async function editTVAFunction (){
        if (editTVA){
            sendUpdate ()
        }
        setEditTVA (!editTVA)
    }
    async function editAPEFunction (){
        if (editAPE){
            sendUpdate ()
        }
        setEditAPE (!editAPE)
    }
    /////////////////////////////////////////////////////////////////




    // Pour gérer la liste des transporteurs, et le système de pagination
    const [carrierList, setCarrierList] = React.useState (
        new Array<CarrierRegistration> ()
    ) 
    const [pageNbCarrierList, setPageNbCarrierList] = React.useState (1)
    
    
    const [filteredCarrierList, setFilteredCarrierList] = React.useState (carrierList)
    const reductedCarrierList = filteredCarrierList.slice ((pageNbCarrierList-1)*20, (pageNbCarrierList*20))
    
    ///////////////////////////////////////////////////////////////////


   
    // Pour gérer les filtres des transporteurs
    var carrierListFiltre = carrierList.map(item => item.carrier!==undefined?item.carrier.name:undefined).filter((value, index, self) => self.indexOf(value) === index)
    const [carrierNameFiltre, setCarrierNameFiltre] = React.useState ("");
    
    const [carrierNumFiltre, setCarrierNumFiltre] = React.useState ("");

    var ramassageTypeListFiltre = carrierList.map(item => item.pickupType).filter((value, index, self) => self.indexOf(value) === index)
    const [ramassageTypeFiltre, setRamassageTypeFiltre] = React.useState ("");

    React.useEffect (()=>{
        var temp = carrierList.slice ()
        if (carrierNameFiltre!==""){
            temp = temp.filter((obj) => {return (obj.carrier!==undefined?obj.carrier.name:undefined === carrierNameFiltre)})
        } 
        if (carrierNumFiltre!==""){
            temp = temp.filter((obj) => {return ((obj.carrier!==undefined?obj.carrier.id:"").toString ().slice (0, carrierNumFiltre.length).localeCompare (carrierNumFiltre, undefined, {sensitivity:"base"}))===0}) 
        } 
        if (ramassageTypeFiltre!==""){
            temp = temp.filter((obj) => {return (obj.pickupType===ramassageTypeFiltre)})
        }  
        
        setFilteredCarrierList (temp)
    }, [carrierList, carrierNameFiltre, carrierNumFiltre, ramassageTypeFiltre])
    const [tryToFilter, setTryToFilter] = React.useState (false)
    function removeAllCarrierFilter (){
        setCarrierNameFiltre ("")
        setCarrierNumFiltre ("")
        setRamassageTypeFiltre ("")
    }
    ///////////////////////////////////////////////

    // Pour gérer l'obtention et l'actualisation des infos du sous-compte
    React.useEffect (()=>{
        Connexion ()
        GetInfo ()
        getAvailableCarrierList ()
         // eslint-disable-next-line
    }, [])
    /////////////////////////////////////////////////

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////


    const [isHover3, setIsHover3] = React.useState (false)
    const navigate = useNavigate ()

    // Component Carrier à boucler pour l'écran n°2

    async function removeFromCarrierList (id:string){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        setIsLoading (true)
        await fetch (url + '/carrier-registration/' + id, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
                },
            });
        await GetInfo () 
        await getAvailableCarrierList ()
        
        setIsLoading (false)
    }

    function getIndex (a:PriceList|Carrier, list:Array<PriceList|Carrier>){
        for (let i=0; i<list.length; i++){
            if (a.id === list[i].id){
                return i
            }
        }
        return -1
    }
    function CarrierComponent (props:{element:CarrierRegistration, index:number}){
        
        const element:CarrierRegistration = props.element
        const [tryToRemove, setTryToRemove] = React.useState (false)
        const [clickToRemove, setClickToRemove] = React.useState (false)

        const priceList1 = element.priceLists[0]        
        const priceList2 = element.priceLists[1]

        const [priceListMono, setPriceListMono] = React.useState (new Array<PriceList> ())
        const [priceListMulti, setPriceListMulti] = React.useState (new Array<PriceList> ())

        async function getPriceLists (){
            if (!isStillConnected (token, setToken)) {
                return 
            }
            const response = fetch (url + '/carrier/' + element.carrier?.id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token["accessToken"]}`
                },
            });
            const json:Carrier = await (await response).json ()
            const temp1:Array<PriceList> = new Array <PriceList> ()
            const temp2:Array<PriceList> = new Array <PriceList> ()

            for (let i=0; i<json.priceLists.length; i++) {
                // if (priceList1.id!==json.priceLists[i].id && priceList2.id!==json.priceLists[i].id){

                    if (json.priceLists[i].priceListType==="single"){
                        temp1.push (json.priceLists[i])
                    } else {
                        temp2.push (json.priceLists[i])
                    }
                    
                // }
            }
            setPriceListMono (temp1)
            setPriceListMulti (temp2)
        }
        
        React.useEffect (()=>{
            if (priceList1!==undefined && priceList1.priceListType==="single"){
                setPriceList1UpdatedIndex (priceListMono.indexOf (priceList1))
                setPriceList1UpdatedIndex (getIndex (priceList1, priceListMono))
                console.log ("CA MARCHE !!!! "+ JSON.stringify (priceListMono.indexOf (priceList1)) + JSON.stringify (priceList1))
            }
            if (priceList2!==undefined && priceList2.priceListType==="single"){
                setPriceList2UpdatedIndex (getIndex (priceList2, priceListMono))
            }// eslint-disable-next-line
        }, [priceListMono])
        React.useEffect (()=>{
            if (priceList1!==undefined && priceList1.priceListType==="multi"){
                // setPriceList1UpdatedIndex (priceListMono.indexOf (priceList1))
                setPriceList1UpdatedIndex (getIndex (priceList1, priceListMulti))
                // console.log ("CA MARCHE !!!! "+ JSON.stringify (priceListMulti.indexOf (priceList1)) + JSON.stringify (priceList1))
            }
            if (priceList2!==undefined && priceList2.priceListType==="multi"){
                setPriceList2UpdatedIndex (getIndex (priceList2, priceListMulti))
            } // eslint-disable-next-line
        }, [priceListMulti])
        React.useEffect (()=>{
            getPriceLists () // eslint-disable-next-line
        }, [])
        const [priceList1UpdatedIndex, setPriceList1UpdatedIndex] = React.useState (-1)
        // React.useEffect (()=>{
        //     console.log (JSON.stringify (priceList1) + "PriceList1")
        //     console.log (JSON.stringify (priceList2) + "PriceList2")
        //     if (priceList1.priceListType==="single"){
        //         setPriceList1UpdatedIndex (priceListMono.indexOf (priceList1))
        //     } else {
        //         setPriceList1UpdatedIndex (priceListMulti.indexOf (priceList1))
        //     }
        //     if (priceList2!==undefined) {
        //         if (priceList2.priceListType==="single"){
        //             setPriceList2UpdatedIndex (priceListMono.indexOf (priceList2))
        //         } else {
        //             setPriceList2UpdatedIndex (priceListMulti.indexOf (priceList2))
        //         }
        //     }
        // }, [priceListMono, priceListMulti])
        const [priceList2UpdatedIndex, setPriceList2UpdatedIndex] = React.useState (-1)
        return (
            <div key={element["id"]} style={{margin:0, justifyContent:width>1200?"space-evenly":"flex-start", display:"flex", flexDirection:width>1200?"row":"column",  alignItems:"center", width:"100%", boxSizing:"border-box", padding:"20px 0px"}}>           
                                
                <div style={{textAlign:"center",  display: "flex", flexDirection:width>780?"row":"column",  alignItems:"center", flex:2, boxSizing:"border-box", gap:width>780?"":9, width:"100%", justifyContent:"space-around", alignContent:"space-between"}}>
                    <div style={{textAlign:"left",  display: "flex", flexDirection:"row",  alignItems:"center"}}>
                        <img src={transporteur} alt='' style={{width:60, resize:"none", marginRight:10}} draggable={false}/>
                        <div style={{display:"flex", flexDirection:width>780?"column":"column-reverse", marginLeft:5}}>
                            <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{element.carrier!==undefined?element.carrier.name:"Erreur"}</span>
                            <span style={{color:"#747474"}}>Nom du transporteur {width<=780 && ":"}</span>
                        </div>
                        {/* {width>1200 && <span style={{color:"#747474", fontWeight:600}}>Numéro d'expédition</span>} */}
                    </div>
                

                    <div style={{textAlign:"center", display: "flex", justifyContent:"center", justifyItems:"center", alignContent:"center", alignItems:"center", width:width<780 ?"100%":undefined}}>
                        <div style={{textAlign:"left",  display: "flex", flexDirection:width>780?"column":"column-reverse", justifyContent:"center"}}>
                            <span style={{color:"#e7ac4f", fontSize:22, fontWeight:700}}>{element.carrier!==undefined?element.carrier.id:"Erreur"}</span>
                            <span style={{color:"#747474", fontWeight:600}}>Numéro de transporteur {width<=780 && ":"}</span>
                        </div>
                    </div>


                    <div style={{  display: "flex", flexDirection:"row",  alignItems:"left", width:width<780 ?"100%":undefined}}>
                        <div style={{textAlign:"left",  display: "flex", flexDirection:"row",  alignItems:"center"}}>
                            <img src={Truck2} alt='' style={{width:40, resize:"none", marginRight:10}} draggable={false}/>
                            <div style={{display:"flex", flexDirection:width>780?"column":"column-reverse", marginLeft:5}}>
                                <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{element.pickupType}</span>
                                <span style={{color:"#747474"}}>Mode de ramassage {width<=780 && ":"}</span>
                            </div>
                            {/* {width>1200 && <span style={{color:"#747474", fontWeight:600}}>Numéro d'expédition</span>} */}
                        </div>
                    </div>
                    <div style={{display:'grid', gridTemplateColumns:"100%", width:width<780 ?"100%":undefined, flexDirection:"column", maxWidth:width<780?undefined:"25%"}} onClick={(e)=>e.stopPropagation ()}>
                        {/* <div style={{ justifyItems:"center", display:"flex", flexDirection:"row",justifyContent:"center", alignItems:"center", flex:1, cursor:"pointer"}}  onClick={(e)=>e.stopPropagation ()}>
                            <img src={ArrowDown} alt='' style={{height:40, resize:"none"}} draggable={false}/>
                        </div> */}

                        {/* <div className="Change-Color-Hover" style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", border:"2px solid #747474", borderRadius:8, padding:"8px 12px", cursor:"pointer", userSelect:"none", flex:1.5, width:"max-content"}} onClick={(e)=>e.stopPropagation ()}>
                            <img src={ArrowUp} alt='' style={{width:20, resize:"none", marginRight:10}} draggable={false}/>
                            <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"center", margin:0}}>Grille tarifaire</p>
                        </div> */}
                        {role==="admin" && priceList1!==undefined && priceList1UpdatedIndex!==-1 && <SelectInput bordercolor="#deae5f" value={priceList1UpdatedIndex} onChange={(props: { target: { value: number; }; })=>setPriceList1UpdatedIndex (props.target.value)} label={priceList1.priceListType==="single"?"Grille mono":"Grille multi"} style={{width:"100%", maxWidth:"100%", marginBottom:priceList2!==undefined?10:0}}>
                            {/* <MenuItem value={priceList1.priceListName} style={{backgroundColor:(priceList1.priceListName===priceList1.priceListPath)?"#ffe695":""}}>{priceList1.priceListName}</MenuItem> */}
                            <MenuItem disabled value='' style={{width:"0%", height:"0%"}}></MenuItem>
                            {priceListMono.map ((element:PriceList, index:number)=>
                                <MenuItem key={index} value={index} style={{backgroundColor:(priceList1.id===element.id)?"#ffe695":""}} >{element.priceListName}</MenuItem>
                            )}
                            
                        </SelectInput>}
                        {role==="admin" && priceList2!==undefined && priceList2UpdatedIndex!==-1 && <SelectInput bordercolor="#deae5f" value={priceList2UpdatedIndex} onChange={(props: { target: { value: number; }; })=>setPriceList2UpdatedIndex (props.target.value)} label={priceList2.priceListType==="single"?"Grille mono":"Grille multi"} style={{width:"100%", maxWidth:"100%", marginBottom:0}}>
                            
                            <MenuItem disabled value='' style={{width:"0%", height:"0%"}}></MenuItem>
                            {priceListMulti.map ((element:PriceList, index:number)=>
                                <MenuItem key={index} value={index} style={{backgroundColor:(priceList2.id===element.id)?"#ffe695":""}}>{element.priceListName}</MenuItem>
                            )}
                             
                        </SelectInput>}
                        {role==="account" && priceList1!==undefined && <TextInput disabled label={priceList1.priceListType==="single"?"Grille mono":"Grille multi"} value={priceList1.priceListName} style={{width:"100%", maxWidth:"100%", marginBottom:priceList2!==undefined?10:0}} bordercolor="#deae5f" sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>}
                        {role==="account" && priceList2!==undefined && <TextInput disabled label={priceList2.priceListType==="single"?"Grille mono":"Grille multi"} value={priceList2.priceListName} style={{width:"100%", maxWidth:"100%", marginBottom:0}} bordercolor="#deae5f" sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>}
                    </div>
                
                   
                    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={clickToRemove} onClick={()=>setClickToRemove (false)}>
                        <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 400px)", padding:20}}>
                                <h2 style={{ marginBottom:20}}>Voulez vous vraiment supprimer ce transporteur pour ce compte ?</h2>
                                
                                <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
                                    <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setClickToRemove (false)}}>
                                        <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                                    </div>
                                    <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {removeFromCarrierList(element.id);setClickToRemove (false)}}>
                                        <p style={{color:"white", margin:0}}>Confirmer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Backdrop>
                    {role==="admin" && <Bin style={{height:40,width:50, resize:"none", alignSelf:"center", justifySelf:"center", fill:tryToRemove?"#FA1205":"#a2a2a2", cursor:"pointer"}} onClick={(e)=>{setClickToRemove (true);e.stopPropagation ()}} onMouseEnter={()=>setTryToRemove(true)} onMouseLeave={()=>setTryToRemove(false)}/>}
                </div>
            </div>
        )
    }
    /////////////////////////////////////////////////////////////    

    // Pour gérer l'ajout de transporteur à un sous-compte
    const [availableCarrierIndex, setAvailableCarrierIndex] = React.useState (-1)
    
    function setAvailableCarrierIndex2 (e:number){
        setAvailableCarrierIndex (e)
        setIsMono (false)
        setIsMulti (false)
        setAvailableMonoPriceListIndex (-1)
        setAvailableMultiPriceListIndex (-1)
    }
    const [availableCarrierList, setAvailableCarrierList] = React.useState (new Array<Carrier> ())
    
    const selectedCarrier = availableCarrierIndex!==-1 ? availableCarrierList[availableCarrierIndex]:{} as Carrier
    
    async function getAvailableCarrierList (){
        if (role==="admin"){
            const response = fetch (url + '/carrier/' , {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token["accessToken"]}`
                }
            })
            var json:Array<Carrier> = await (await response).json()
            var temp = new Array<Carrier> ()
            const temp2 = carrierList.map ((element)=>element.carrier)
            for (let i=0; i<json.length; i++) {
                // if (temp2.indexOf (json[i])!==-1){
                //     temp.push (json[i])
                // }
                if ( getIndex (json[i], temp2)===-1){
                    temp.push (json[i])
                }
            }
            // setAvailableCarrierList (json)
            setAvailableCarrierList (temp)
        }
    }
    const [availableMonoPriceListIndex, setAvailableMonoPriceListIndex] = React.useState (-1)
    const [availableMultiPriceListIndex, setAvailableMultiPriceListIndex] = React.useState (-1)
    const availableMonoPriceList = availableCarrierIndex!==-1 ? selectedCarrier.priceLists.filter((obj) => {return obj.priceListType === "single"}) : new Array<PriceList> ()
    const availableMultiPriceList = availableCarrierIndex!==-1 ? selectedCarrier.priceLists.filter((obj) => {return obj.priceListType === "multi"}) : new Array<PriceList> ()
    const [selectedMonoPriceList, setSelectedMonoPriceList] = React.useState ({} as PriceList)
    React.useEffect (()=>{
        (availableMonoPriceListIndex!==-1) && setSelectedMonoPriceList (availableMonoPriceList[availableMonoPriceListIndex])  // eslint-disable-next-line
    }, [availableMonoPriceListIndex])
    const [selectedMultiPriceList, setSelectedMultiPriceList] = React.useState ({} as PriceList)
    React.useEffect (()=>{
        availableMultiPriceListIndex!==-1 && setSelectedMultiPriceList (availableMultiPriceList[availableMultiPriceListIndex])   // eslint-disable-next-line
    }, [availableMultiPriceListIndex])
    // const selectedMultiPriceList = availableMultiPriceListIndex!==-1 ? availableMultiPriceList[availableMultiPriceListIndex]:{} as PriceList
    
    React.useEffect (()=>{
        getAvailableCarrierList () // eslint-disable-next-line
    }, [carrierList])
    const [isAddingACarrier, setIsAddingACarrier] = React.useState (false)
   
    const [newAssociatedNumber, setNewAssociatedNumber] = React.useState ("")
   
    const [isPonctual, setIsPonctual] = React.useState (false)

    const [isMono, setIsMono] = React.useState (false)
    const [isMulti, setIsMulti] = React.useState (false)
    
    async function createCarrierRegistration (){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        setIsLoading (true)
        const bodyPriceList = new Array<any> ()
        if (availableMonoPriceListIndex!==-1){
            bodyPriceList.push ({"priceListId":selectedMonoPriceList.id})
        }
        if (availableMultiPriceListIndex!==-1){
            bodyPriceList.push ({"priceListId":selectedMultiPriceList.id})
        }
        // const response = 
        await fetch (url + '/carrier-registration', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
                "carrierId": selectedCarrier.id,
                "subAccountId": subaccountId,
                "associatedNumber": newAssociatedNumber,
                "pickupType":isPonctual?"Ponctuel":"Quotidien",
                "billingType": isMono && isMulti ? "both": isMono ? "single":"multi",
                "priceLists": bodyPriceList
            })
        })
        // const json = await response.json ()
        // console.log (json)
        await GetInfo ()
        
        setAvailableCarrierIndex2 (-1)
        setNewAssociatedNumber ("")
        setIsMono (false)
        setIsMulti (false)
        setIsAddingACarrier (false)
        setIsLoading (false)
    }

    
    // Handle the main header size to adapt
    const [headerHeight, setHeaderHeight] = React.useState (60)
    React.useEffect(() => {
        let header = document.getElementById("header");
        if (header) {
            setHeaderHeight (header.clientHeight)
        }
      }, [])
    ////////////////////////////////////////////////
    return (
        <>
        
        <div style={{ margin:"0px 20px 20px 20px"}}>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"left", justifyItems:"center", width:"100%", justifySelf:'center', position:"sticky", top:headerHeight+40, zIndex:1, pointerEvents: "none", userSelect:"none", boxSizing:"border-box"}}>
                <div style={{ marginRight:width>1200 ?20:0, boxSizing:"border-box", display:"grid", alignContent:"center", justifyContent:"center", cursor:"pointer", width:width>780?"unset":"10%", pointerEvents: "visible"}} className="Cadre-blanc Change-Color-Hover Input-password" onClick={()=>{navigate (-1)}}>
                    <img src={Back} style={{height:40, resize:"none", width:40}} alt="go back" draggable={false}/>
                </div>
                {width>1200 ?
                <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", pointerEvents: "none", zIndex:6}}>
                    <div style={nbScreen===0?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible", zIndex:6}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible", zIndex:6}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={()=>setNBScreen(0)}>
                        <p>Informations générales</p>
                    </div>
                    <div style={nbScreen===1?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible", zIndex:6}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible", zIndex:6}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={()=>setNBScreen(1)}>
                        <p>Liste des transporteurs</p>
                    </div>
                    <div style={nbScreen===2?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible", zIndex:6}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible", zIndex:6}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={()=>setNBScreen(2)}>
                        <p>Facturation</p>
                    </div>
                </div>
                :
                <div style={{display:"flex", flexDirection:"row", top:headerHeight+40, alignItems:"center", zIndex:6, userSelect:"none", boxSizing:"border-box", width:"90%"}}>
                    <Chevron style={{margin:"0px 30px", rotate:"90deg", cursor:"pointer", pointerEvents:"visible", boxSizing:"border-box"}} onClick={()=>nbScreen!==0? setNBScreen((nbScreen-1)%3):setNBScreen (2)}/>
                    <div style={{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents:"visible", boxSizing:"border-box"}} className="Cadre-blanc Header-expedition Change-Color-Hover">
                        <p>{listOfScreen[nbScreen]}</p>
                    </div>
                    <Chevron style={{margin:"0px 30px", rotate:"270deg", cursor:"pointer", pointerEvents:"visible", boxSizing:"border-box"}} onClick={()=>setNBScreen((nbScreen+1)%3)}/>
                </div>
                }
            </div>
                

            <div className="Cadre-blanc" style={{margin:0, marginTop:20, display:"flex", padding:0}}>
                {/* ///////////////////////////// Ecran numéro 1 ////////////////////////////// */}
                {!isLoading?
                <>
                {nbScreen===0 &&
                <>
                    {/* <div style={{position:"absolute", right:20, top:10, fontStyle:"italic", color:"#aeaeae", cursor:"default", userSelect:"none"}}>
                        <p>Dernière modification le {getDateInLitteral (subaccount.current.updatedAt)}</p>
                    </div> */}
                    <div className=" Cadre-profil" style={{justifySelf:"center", alignSelf:"center",  padding: width>780 ?"10px 50px":"0px 20px", boxSizing:  "border-box", marginBottom:30, display:"flex", flexDirection:"column"}}>
                        <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:width<600?"center":"normal"}}>    
                            <div className="Profil-Logo">
                                <div className="Profil-Logo2"></div>
                                <div className="Profil-Logo3"></div>
                                <p style={{zIndex:0, position:"relative", margin:30, font: "italic normal normal 25px/1 Verdana, Geneva, sans-serif"}}>{subaccount.current.name}</p>
                            </div>
                        </div>
                        <div>
                            <h2 style={{textAlign:"left", color:"#474746"}}>Adresse :</h2>
                            <div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
                                <div style={{width:30, height:30}}>
                                    <img src={Position} alt="" style={{width:"100%", resize:"none"}} draggable={false}/>
                                </div>
                                {/* <input ref={addressRef} value={address} onChange={(value)=>setAddress (value.target.value)} style={{ width:  address.length + 'ch', fontSize:"1em", borderStyle:"none", backgroundColor:"white", marginLeft:5, color:"black" }} disabled={!editAddress} onKeyUp={(key)=>key.key==="Enter"?editAddressFunction ():key.key==="Escape"?setEditAddress (!editAddress):null}/> */}
                                <p style={{margin:0, marginLeft:5}}>{address}</p>
                                {role==="admin" && <img src={Pen} alt="" style={{height:20, resize:"none", marginLeft:10, cursor:"pointer"}} draggable={false} onClick={()=>{setEditAddress (true)}}/>}
                            </div>
                            <h2 style={{textAlign:"left", color:"#474746"}}>Numéro de SIRET :</h2>
                            <div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
                                <div style={{width:30, height:30, display:"flex", alignContent:"center", alignItems:"center"}}>
                                    <Maison style={{width:"100%", resize:"none"}}/>
                                </div>
                                {/* <p style={{marginLeft:20, textAlign:"left"}}>1234567890</p> */}
                                <input ref={siretRef} value={siret} onChange={(value)=>{if (!Number.isNaN (+value.target.value)){setSiret (value.target.value)}}} style={{ width:  siret.length + 'ch', fontSize:"1em", borderStyle:"none", backgroundColor:"white", marginLeft:5, color:"black" }} disabled={!editSiret} onKeyUp={(key)=>key.key==="Enter"?editSiretFunction ():key.key==="Escape"?[setEditSiret (!editSiret),setSiret (subaccount.current.siret)]:null}/>
                                {role==="admin" && <img ref={siretRefIMG} src={editSiret?Check:Pen} alt="" style={{height:20, resize:"none", marginLeft:10, cursor:"pointer"}} draggable={false} onClick={()=>{editSiretFunction ()}}/>}
                            </div>
                            {role==="admin" && <><h2 style={{textAlign:"left", color:"#474746"}}>Adresse mail :</h2>
                            <div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
                                <div style={{width:30, height:30, display:"flex", alignItems:"center" }}>
                                    <Mail style={{ resize:"none", width:30}}/>
                                </div>
                                {/* <p style={{marginLeft:20, textAlign:"left"}}>subao@aeofu.com</p> */}
                                <input ref={mailAddressRef} value={mailAddress} onChange={(value)=>setMailAddress (value.target.value)} style={{ width:  mailAddress.length + 'ch', fontSize:"1em", borderStyle:"none", backgroundColor:"white", marginLeft:5, color:"black" }} disabled={!editMailAddress}  onKeyUp={(key)=>key.key==="Enter"?setEditMailAddress (false):key.key==="Escape"?[setEditMailAddress (!editMailAddress),setMailAddress ("adresseMail@juniorisep.com")]:null}/>
                                {role==="admin" && <img ref={addressMailRefIMG} src={editMailAddress?Check:Pen} alt="" style={{height:20, resize:"none", marginLeft:10, cursor:"pointer"}} draggable={false} onClick={()=>{editMailAddressFunction ()}}/>}
                            </div></>}
                            <h2 style={{textAlign:"left", color:"#474746"}}>Numéro TVA:</h2>
                            <div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
                                <div style={{width:30, height:30, display:"flex", alignItems:"center" }}>
                                    <Mail style={{ resize:"none", width:30}}/>
                                </div>
                                <input ref={TVARef} value={TVA} onChange={(value)=>!Number.isNaN (value) && setTVA (value.target.value)} style={{ width:  TVA.length + 'ch', fontSize:"1em", borderStyle:"none", backgroundColor:"white", marginLeft:5, color:"black" }} disabled={!editTVA} onKeyUp={(key)=>key.key==="Enter"?editTVAFunction ():key.key==="Escape"?[setEditTVA (!editTVA),setTVA (JSON.stringify (subaccount.current.tvaNumber))]:null}/>
                                {role==="admin" && <img ref={TVARefIMG} src={editTVA?Check:Pen} alt="" style={{height:20, resize:"none", marginLeft:10, cursor:"pointer"}} draggable={false} onClick={()=>{editTVAFunction ()}}/>}
                            </div>
                            <h2 style={{textAlign:"left", color:"#474746"}}>Numéro APE:</h2>
                            <div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
                                <div style={{width:30, height:30, display:"flex", alignItems:"center" }}>
                                    <Mail style={{ resize:"none", width:30}}/>
                                </div>
                                <input ref={APERef} value={APE} onChange={(value)=>setAPE (value.target.value)} style={{ width:  APE.length + 'ch', fontSize:"1em", borderStyle:"none", backgroundColor:"white", marginLeft:5, color:"black" }} disabled={!editAPE} onKeyUp={(key)=>key.key==="Enter"?editAPEFunction ():key.key==="Escape"?[setEditAPE (!editAPE),setAPE ("123")]:null}/>
                                {role==="admin" && <img ref={APERefIMG} src={editAPE?Check:Pen} alt="" style={{height:20, resize:"none", marginLeft:10, cursor:"pointer"}} draggable={false} onClick={()=>{editAPEFunction ()}}/>}
                            </div>
                            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={error.length!==0} onClick={()=>setError (new Array<string>())}>
                                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 400px)", padding:20}}>
                                        <h2 style={{ marginBottom:20}}>Erreur</h2>
                                        
                                        <div style={{display:"flex", flexDirection:"column", width:"100%", maxHeight:"40vh", overflowY:"scroll"}}>
                                            {error.map ((element, index)=>
                                                <div key={index} style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"100%"}} >
                                                    
                                                    <p style={{margin:0, textAlign:"left", marginLeft:20, width:"70%"}}>{element}</p>
                                                    
                                                </div>
                                            )}
                                        </div>
                                        <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
                                            <div className="Change-Color-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setError (new Array<string>())}}>
                                                <p style={{color:"#deae5f"}}>Continuer</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </Backdrop>
                            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={editAddress} onClick={()=>cancelUpdateAddress ()}>
                                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <div className="Cadre-blanc"  onClick={(e)=>{console.log ("coucou Lucas"); e.stopPropagation()}} style={{height:"min-content", width:"min (100%, 200px)", padding:20}}>
                                        <h2 style={{ marginBottom:20}}>Modifier l'adresse</h2>
                                        <div style={{zIndex:0, display:"flex", flexDirection:"column", alignItems:"center", width:"100%", alignSelf:"flex-start"}}>
                                            <SearchAddresses address={address1} setAddress={setAddress1} setZipCode={setZipCode} setCity={setCity} setCountry={setCountry} isOpen={isAutoCompleteOpen} setIsOpen={setIsAutoCompleteOpen}/>
                                            <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Indication postale supplémentaire" value={address2} onChange={(value)=>setAddress2(value.target.value)}/>
                                        
                                            <div style={{display:"flex", flexDirection:"row", width:width>780?"80%":"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Code postal" value={zipCode} onChange={(value)=>setZipCode(value.target.value)}/>
                                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom de la ville" value={city} onChange={(value)=>setCity(value.target.value)}/>   
                                            </div>
                                            <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Pays" value={country} onChange={(value)=>setCountry(value.target.value)}/>
                                        </div>
                                        <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
                                            <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {cancelUpdateAddress ()}}>
                                                <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                                            </div>
                                            <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {updateAddress ();setEditAddress (false)}}>
                                                <p style={{color:"white", margin:0}}>Confirmer</p>
                                            </div>
                                        </div>
                                            
                                    </div>
                                </div>
                            </Backdrop>
                            <img src={Truck} alt="" style={{position:"absolute", right:0, bottom:0, width:"30%", opacity:"60%", zIndex:0}} draggable={false}/>
                        </div>
                    </div>
                </>
                }
                {/* /////////////////////////////////////////////////////////////////////////// */}


                {/* ///////////////////////////// Ecran numéro 2 ////////////////////////////// */}
                {nbScreen===1 &&
                    <>
                    {carrierList.length>0?
                    <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                    <div style={{ display:"flex" ,alignItems:"flex-end", flexDirection:"row-reverse", width:"100%"}}>
                        
                        {width<1000 &&
                            <div onClick={()=>setTryToFilter(true)} style={{display:"flex", flexDirection:"column",  alignItems:"center", margin:30, width:"min-content"}} >
                                <div className="test Input-password" style={{display:"flex", flexDirection:"row", alignItems:"center", width:"max-content"}} onMouseEnter={()=>setIsHover3 (true)} onMouseLeave={()=>setIsHover3 (false)}>
                                    <p style={{marginLeft:20, marginRight:20, fontWeight:500}}>Filtrer</p>
                                </div>
                                <div  style={{width:"100%", height:3, backgroundColor:isHover3?"#f9b233":"#747474"}}></div>
                            </div>
                        }

                        
                    </div>
                    {width>1000 &&
                        <div style={{display:"flex" , flexDirection:"row", alignSelf:"center", justifyContent:"space-between", width:"100%", padding:30, boxSizing:"border-box"}}>
                            
                            <div style={{flex:2, display:"flex", justifyContent:"center"}}>
                                <SelectInput width="98%" label="Transporteur" bordercolor="#7d7d7d" value={carrierNameFiltre} onChange={(value:any)=>setCarrierNameFiltre (value.target.value)}>
                                <MenuItem value="" style={{fontStyle:"italic"}}>Vide (par défaut)</MenuItem>
                                    {carrierListFiltre.map ((element:string|undefined)=>
                                        <MenuItem key={element} style={{backgroundColor:(carrierNameFiltre===element)?"#ffe695":""}} value={element!==undefined?element:""}>{element!==undefined?element:"Erreur"}</MenuItem>
                                    )}
                                </SelectInput>
                            </div>
                            <div style={{flex:2}}>
                                <TextInput width="98%"bordercolor="#7d7d7d" label="Num. de transporteur" value={carrierNumFiltre} onChange={(value)=>setCarrierNumFiltre(value.target.value)}/>
                            </div>
                            <div style={{flex:2}}>
                                <SelectInput width="98%" label="Type de ramassage" bordercolor="#7d7d7d" value={ramassageTypeFiltre} onChange={(value: { target: { value: React.SetStateAction<string>; }; })=>setRamassageTypeFiltre (value.target.value)}>
                                    <MenuItem value="" style={{fontStyle:"italic"}}>Vide (par défaut)</MenuItem>
                                    {ramassageTypeListFiltre.map ((element:string)=>
                                        <MenuItem style={{backgroundColor:(ramassageTypeFiltre===element)?"#ffe695":""}} value={element} key={element}>{element}</MenuItem>
                                    )}
                                </SelectInput>
                            </div>
                            <div style={{flex:1}}></div>
                            <div style={{flex:1.5}}></div>
                            {role==="admin" && <div style={{flex:1,width:40}}></div>}
                        </div>
                    }
                    {width<1000 && tryToFilter &&
                    <div style={{display:"flex" ,justifyItems:"center", flexDirection:"column", width:"100vw",height:"100vh", top:0,alignSelf:"center", position:"fixed", backgroundColor:"white", zIndex:5, justifyContent:"center", borderRadius:30, padding:20, boxSizing:"border-box", overflowY:'auto',}}>
                        <h2>Filtres</h2>                    
                    
                        
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                        <span className="table" style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center"}} onClick={()=> {removeAllCarrierFilter()}}>
                            <p className="table" style={{color:"white"}}>Supprimer tous les filtres</p>
                        </span>
                        <span className="table" style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center"}}  onClick={()=> {setTryToFilter(false)}}>
                            <p className="table" style={{color:"white"}}>Appliquer</p>
                        </span>
                    </div>
                    </div>
                    }
                    <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                        {reductedCarrierList.map ((element:CarrierRegistration, index:number) =>
                            <div key={index} style={{display:"grid" ,justifyItems:"center", flexDirection:"column", width:"100%", position:"relative", gridTemplateColumns:"100%", padding:0}}>
                                <CarrierComponent element={element} index={index}/>
                                
                                {index!==reductedCarrierList.length-1 &&
                                    <div style={{width:"90%", alignSelf:"center", justifySelf:"center", height:3, backgroundColor:'#9a9a9a', borderRadius:10}}></div>
                                }
                                
                            </div>
                        )}   
                    </div>    
                    
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", userSelect:"none", marginTop:10, marginBottom:20}}>
                        {/* <span className="table" style={pageNbCarrierList>1?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}} onClick={()=> {goToPreviousPageForCarrier()}}>
                            <p className="table" style={{color:"white"}}>Page précédente</p>
                        </span>
                        <p style={{cursor:"default"}}>{pageNbCarrierList}/{Math.floor((filteredCarrierList.length-1)/20)+1}</p>
                        <span className="table" style={pageNbCarrierList<filteredCarrierList.length/20?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}}  onClick={()=> {goToNextPageForCarrier()}}>
                            <p className="table" style={{color:"white"}}>Page suivante</p>
                        </span> */}
                        <Pagination count={filteredCarrierList.length!==0? Math.floor((filteredCarrierList.length-1)/20)+1:1} size="large"  onChange={(_event, number)=>setPageNbCarrierList (number)}/>
                    </div>
                    </div>
                    :
                    <>
                    <p>Pas de données à afficher</p>
                    
                    </>
                    }
                    <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>

                        {role==="admin" && <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:"3px 10px", pointerEvents: "visible"}} onClick={()=>setIsAddingACarrier (true)}>
                            <p>Ajouter un transporteur</p>
                        </div>}

                        </div>
                        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isAddingACarrier} onClick={()=>{setIsAddingACarrier (false); setAvailableCarrierIndex2 (-1); setNewAssociatedNumber ("")}}>
                            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 400px)", padding:20}}>
                                    <h2 style={{ margin:0, marginBottom:20}}>Ajouter un transporteur</h2>
                                    <SelectInput bordercolor="#deae5f" value={availableCarrierIndex!==-1?availableCarrierIndex:""} onChange={(props: { target: { value: number; }; })=>{setAvailableCarrierIndex2 (props.target.value)}} label="Transporteur à ajouter" style={{marginBottom:0, width:"100%"}}>
                                        {availableCarrierList.map ((element:Carrier, index:number)=>
                                            <MenuItem style={{backgroundColor:(selectedCarrier===element)?"#ffe695":""}} key={element.id} value={index}>{element.name}</MenuItem>
                                        )}
                                    </SelectInput>
                                    <div style={{display:"flex", flexDirection:"row", alignContent:"center", alignItems:"center", justifyContent:"space-around", marginBottom:10, marginTop:0}}>
                                        <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                            <Checkbox checked={isMono} onChange={()=>setIsMono (!isMono)} disabled={availableMonoPriceList.length===0}/>
                                            <p style={{margin:"0px 20px 0px 0px"}}>Mono</p>
                                        </div>
                                        <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                            <Checkbox checked={isMulti} onChange={()=>setIsMulti (!isMulti)} disabled={availableMultiPriceList.length===0}/>
                                            <p style={{margin:"0px 20px 0px 0px"}}>Multi</p>
                                        </div>
                                        
                                    </div>
                                    {isMono && <SelectInput bordercolor="#deae5f" value={availableMonoPriceListIndex!==-1?availableMonoPriceListIndex:""} onChange={(props: { target: { value: number; }; })=>setAvailableMonoPriceListIndex (props.target.value)} label="Grille mono" disabled={availableCarrierIndex===-1} style={{marginBottom:20, width:"100%"}}>
                                        {availableMonoPriceList.map ((element:PriceList, index:number)=>
                                            <MenuItem style={{backgroundColor:(selectedMonoPriceList===element)?"#ffe695":""}} key={element.id} value={index}>{element.priceListName}</MenuItem>
                                        )}           
                                    </SelectInput>}
                                    {isMulti &&<SelectInput bordercolor="#deae5f" value={availableMultiPriceListIndex!==-1?availableMultiPriceListIndex:""} onChange={(props: { target: { value: number; }; })=>setAvailableMultiPriceListIndex (props.target.value)} label="Grille multi" disabled={availableCarrierIndex===-1} style={{marginBottom:20, width:"100%"}}>
                                        {availableMultiPriceList.map ((element:PriceList, index:number)=>
                                            <MenuItem style={{backgroundColor:(selectedMultiPriceList===element)?"#ffe695":""}} key={element.id} value={index}>{element.priceListName}</MenuItem>
                                        )}           
                                    </SelectInput>}
                                    <TextInput bordercolor="#f0ba61" width="100%" label="Numéro associé" value={newAssociatedNumber} onChange={(value)=>setNewAssociatedNumber(value.target.value)}/>
                                    <p style={{margin:0}}>Type de ramassage :</p>
                                    <div style={{display:"flex", flexDirection:"row", alignContent:"center", alignItems:"center", justifyContent:"space-around", marginBottom:10}}>
                                        <p style={{color:!isPonctual?"#e7ac4f":"#a2a2a2", fontWeight:!isPonctual?'bold':"normal", cursor:"pointer"}} onClick={()=>setIsPonctual (false)}>Quotidien</p>
                                        <AntSwitch checked={isPonctual} onChange={(e)=>setIsPonctual (e.currentTarget.checked)}/>
                                        <p style={{color:isPonctual?"#e7ac4f":"#a2a2a2", fontWeight:isPonctual?'bold':"normal", cursor:"pointer"}} onClick={()=>setIsPonctual (true)}>Ponctuel</p>
                                    </div>
                                    
                                    

                                    <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
                                        <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setIsAddingACarrier (false); setAvailableCarrierIndex2 (-1); setNewAssociatedNumber ("")}}>
                                            <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                                        </div>
                                        <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {createCarrierRegistration ();}}>
                                            <p style={{color:"white", margin:0}}>Confirmer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Backdrop>
                </>
                }
                {/* /////////////////////////////////////////////////////////////////////////// */}


                {/* ///////////////////////////// Ecran numéro 3 ////////////////////////////// */}
                {nbScreen===2 &&
                    <>
                        <FacturationPage subaccountId={subaccountId}/>
                        
                    </>
                }</>:isLoading?<CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>: <p>Pas de données à afficher</p>}
                {/* /////////////////////////////////////////////////////////////////////////// */}
            </div>
            
        </div>
    </>
    )
}
export default SubAccountPage;