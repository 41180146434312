import React from "react";

import { UserToken, UserSetToken, url, widthContext } from "../../context/token";
import { Connexion } from "../../utils/connexion";
import { Carrier, CarrierRegistration, PriceList, SubAccount } from "../../types";
import { TextInput } from "../../components/input";
import { AntSwitch } from "../../components/switch";
// Images
import bonhomme from "../../assets/bonhomme.svg"
import lien from "../../assets/reference.svg"
import Back from "../../assets/back.png"
import Check from "../../assets/yellowCheck.svg"
import {ReactComponent as ArrowDown} from "../../assets/arrowDown.svg"
import {ReactComponent as ArrowUp} from "../../assets/arrowUp.svg"
import hand from "../../assets/livraison.svg"
import {ReactComponent as Eye} from "../../assets/eye.svg"
import {ReactComponent as Plus} from "../../assets/plus.svg";
import {ReactComponent as Chevron} from "../../assets/chevron.svg";
import {ReactComponent as Calendar1} from "../../assets/calendar1.svg";

import { isStillConnected } from "../../utils/areYouStillConnected";

import { useNavigate, useLocation } from "react-router-dom";
import { Backdrop, CircularProgress, MenuItem, Pagination } from "@mui/material";
import { SelectInput } from "../../components/select";

import Tooltip from '@mui/material/Tooltip';

function TransporteurPage  ()  {
    // Pour gérer l'authentification
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)
    
    ////////////////////////////////

    // Pour gérer la liste des expéditions, et le système de pagination
    const [carrier, setCarrier] = React.useState ({} as Carrier) 
    const [pageNb, setPageNb] = React.useState (1)
    

    const [subAccountList, setSubAccountList] = React.useState (new Array<CarrierRegistration>())
    const [filteredList, setFilteredList] = React.useState (new Array<CarrierRegistration>())
    const reductedList = filteredList.slice ((pageNb-1)*20, (pageNb*20))

    ///////////////////////////////////////////////////////////////////

   
    // Pour gérer les filtres

    const [carrierNumFiltre, setCarrierNumFiltre] = React.useState ("");
    const [profilNameFiltre, setProfilNameFiltre] = React.useState ("");
    const [referenceFiltre, setReferenceFiltre] = React.useState ("");
    
    React.useEffect (()=>{
        var temp = subAccountList.slice ()
        
        if (carrierNumFiltre!==""){
            temp = temp.filter((obj) => {return (obj!==undefined && obj.carrier?.id.toString ().slice (0, carrierNumFiltre.length).localeCompare (carrierNumFiltre, undefined, {sensitivity:"base"}))===0}) 
        } 
        if (profilNameFiltre!==""){
            temp = temp.filter((obj) => {return ((obj.subAccount!==undefined ? obj.subAccount.name.slice (0, profilNameFiltre.length):'').localeCompare (profilNameFiltre, undefined, {sensitivity:"base"}))===0}) 
        } 
        if (referenceFiltre!==""){
            temp = temp.filter((obj) => {return obj!==undefined && obj.associatedNumber.toString ().slice (0, referenceFiltre.length).localeCompare (referenceFiltre, undefined, {sensitivity:"base"})===0}) 
        } 
        
        setFilteredList (temp) // eslint-disable-next-line
    }, [carrier, carrierNumFiltre, profilNameFiltre, referenceFiltre, subAccountList])

    const [tryToFilter, setTryToFilter] = React.useState (false)
    function removeAllFilter (){
        setCarrierNumFiltre ("")
        setProfilNameFiltre ("")
        setReferenceFiltre ("")
    }
    ///////////////////////////////////////////////

    // Pour gérer l'obtention et l'actualisation des expéditions
    const [isLoading, setIsLoading] = React.useState (true)
    async function getInfo () {
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/carrier/'+carrierId, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token["accessToken"]}`
            },
        });
        const json:Carrier = await (await response).json();
        
        setSubAccountList (json.carrierRegistrations!==undefined?json.carrierRegistrations:[])
        setFilteredList (json.carrierRegistrations!==undefined?json.carrierRegistrations:[])
        setCarrier (json)
        
        // console.log (json)
        const temp:Array<PriceList> = json.priceLists
        for (let i=0; i<temp.length; i++){
            temp[i].reference = "ref"
        }
        setGrilleList (temp)
        setFilteredGrilleList (temp)
        setIsLoading (false)
    }

    
    React.useEffect (()=>{
        Connexion ()
        getInfo ()
         // eslint-disable-next-line
    }, [])
    /////////////////////////////////////////////////

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////


    const [isHover3, setIsHover3] = React.useState (false)

    const location = useLocation ()
    const carrierId = location.pathname.slice (27)
    
    // Pour gérer la sélection de masse
    const [isSelectingAll, setIsSelectingAll] = React.useState (false)
    const [isHoverSelectAll, setIsHoverSelectAll] = React.useState (false)
    function selectAll () {
        const temp = subAccountList.slice ()
        for (let i=0; i<temp.length; i++){
            if (filteredList.includes (temp[i])){
                temp[i]["checked"]=!isSelectingAll
            }
        }
        setSubAccountList (temp)
        setFilteredList (temp)
        setNbChecked (temp.filter ((element)=>{return element["checked"]===true}).length)
        
        setIsSelectingAll (!isSelectingAll)
    }
    const [isUpdatingGrille, setIsUpdatingGrille] = React.useState (false)
    const [nbChecked, setNbChecked] = React.useState (0)
    function isEverythingChecked (list:Array<CarrierRegistration>):React.SetStateAction<boolean>{
        for (let i=0; i<list.length; i++){
            if (list[i]["checked"]===false || list[i]["checked"]===undefined){
                return false
            }
        }
        return true && list.length>0
    }
    React.useEffect (()=>{
        setIsSelectingAll (isEverythingChecked(filteredList))
    }, [filteredList])
    // Component Sous-compte à boucler
    function CarrierComponent (props:{element:CarrierRegistration, index:number}){
        const element:SubAccount = props.element.subAccount!==undefined?props.element.subAccount:{} as SubAccount
        const carrier:CarrierRegistration = props.element
        const [isChecked, setIsChecked] = React.useState (carrier.checked)
        const index = subAccountList.indexOf (carrier)
        function handleChangeIsGoing (){
            
            const temp:Array<CarrierRegistration> = subAccountList.slice ()
            temp[index+(pageNb-1)*20]["checked"] = !isChecked
            if (!isChecked){
                setNbChecked (nbChecked+1)
            } else {
                setNbChecked (nbChecked-1)
            }
            setSubAccountList(temp)
            var temp2 = temp.slice ()
            if (carrierNumFiltre!==""){
                temp2 = temp2.filter((obj) => {return (obj!==undefined && obj.carrier?.id.toString ().slice (0, carrierNumFiltre.length).localeCompare (carrierNumFiltre, undefined, {sensitivity:"base"}))===0}) 
            } 
            if (profilNameFiltre!==""){
                temp2 = temp2.filter((obj) => {return ((obj.subAccount!==undefined ? obj.subAccount.name.slice (0, profilNameFiltre.length):'').localeCompare (profilNameFiltre, undefined, {sensitivity:"base"}))===0}) 
            } 
            if (referenceFiltre!==""){
                temp2 = temp2.filter((obj) => {return obj!==undefined && obj.associatedNumber.toString ().slice (0, referenceFiltre.length).localeCompare (referenceFiltre, undefined, {sensitivity:"base"})===0}) 
            } 
            setFilteredList (temp2)
            setIsChecked (!isChecked)
            
        }
        return (
            <div className="table3 hoverClickable" key={element["id"]}  style={{cursor:"pointer", width:"100%", padding:"20px 0px", boxSizing:"border-box", display:"flex", alignItems:"center"}} onClick={()=>navigate ("/subaccount/"+element["id"].toString())}>
                <div style={{display:"flex", flexDirection:width>1200?"row":"column", width:"100%", justifyContent:"center", alignSelf:"center", alignItems:"center"}}>
                    <div style={{display:"flex", flexDirection:width>780?'row':"column", width:"90%", justifyContent:width>780?"space-evenly":"flex-start"}}>
                        <div style={{ alignItems:"center",  justifyContent:"left", display:"flex", flexDirection:"row", flex:3}}>
                            {/* {width<780 && <div style={{width:24, height:60}}></div>} */}
                            <div style={{textAlign:"left",  display: "flex", flexDirection:"column", justifyContent:"center", padding:0, flex:3}}>
                                <span style={{color:"#e7ac4f", fontSize:22, fontWeight:700, marginRight:0}}>{carrier.id}</span>
                                <span style={{color:"#747474", fontWeight:600}}>Numéro de transporteur</span>
                            </div>
                        </div>
                        
                        <div style={{ alignItems:"center",  justifyContent:"left", display:"flex", flexDirection:"row", flex:2}}>
                            <img src={bonhomme} alt='' style={{width:60, height:60, resize:"none", marginRight:12}} draggable={false}/>
                            <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left", width:"max-content"}}>{element.name}</p>
                        </div>
                    
                    
                        <div style={{ justifyItems:"center", display:"flex", flexDirection:"row",justifyContent:"left", alignItems:"center", flex:1}}>
                            <img src={lien} alt='' style={{height:60, width:60, resize:"none", marginRight:12}} draggable={false}/>
                            <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left"}}>{carrier.associatedNumber}</p>
                        </div>

                        <div style={{flex:1, display:"flex", alignItems:"center", justifyContent:"center"}}>
                            <div className=" Change-Color-Hover" style={{  cursor:"pointer", justifyContent:"center", display:"flex",   border: isChecked?"5px solid #e7ac4f":"2px solid #9a9a9a", width:60, height:60, paddingLeft:0, borderRadius:10, boxSizing:"border-box", flexDirection:"row", alignItems:"center", alignContent:"center"}} onClick={(e)=>{handleChangeIsGoing();e.stopPropagation()}}>
                                <img src={Check} alt='' style={{height:40, resize:"none", margin:12, paddingLeft:0, visibility:isChecked?"visible":"hidden"}} draggable={false}/>
                            </div>
                        </div>
                    </div>

                    
                </div>
                
            </div>
        )
    }
    /////////////////////////////////////////////////////////////

    // Gère l'écran transporteur
    const [nbListOnScreen, setnbListOnScreen] = React.useState (0)
    const listOfScreen = ["Sous-comptes", "Grilles tarifaires"]
    function goToPreviousScreen():void{
        var nbTemp = nbListOnScreen - 1
        if (nbTemp < 0) {
            nbTemp += 2
        }
        setnbListOnScreen (nbTemp)
    }
    function goToNextScreen ():void{
        var nbTemp = nbListOnScreen + 1
        if (nbTemp > 1) {
            nbTemp -= 2
        }
        setnbListOnScreen (nbTemp)
    }
    ////////////////////////////

    // Grilles tarifaires

    const [nbChecked2, setNbChecked2] = React.useState (0)
    const [pageNbGrille, setPageNbGrille] = React.useState (1)
    // Component Grille à boucler
    function GrilleComponent (props:{element:PriceList, index:number}){
        const element = props.element
        const [isChecked, setIsChecked] = React.useState (element.checked)
        
        function handleChangeIsGoing (){
            const temp:Array<PriceList> = grilleList.slice ()
            temp[props.index+(pageNbGrille-1)*20]["checked"] = !isChecked
            if (!isChecked){
                setNbChecked2 (nbChecked2+1)
            } else {
                setNbChecked2 (nbChecked2-1)
            }
            setGrilleList (temp)
            setFilteredGrilleList (temp)
            setIsChecked (!isChecked)
        }
        const [isLoaded, setIsLoaded] = React.useState (false)
        const [fileBlob, setFileBlob] = React.useState ({} as Blob)
        const [isShowingPriceList, setIsShowingPriceList] = React.useState (false)
        
        const [error, setError] = React.useState ("")
        
        async function showPriceList (){
            if (!isStillConnected (token, setToken)) {
                return 
            }
            setIsShowingPriceList (true)
            
            const response = fetch (url + '/price-list/path/'+element.id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token["accessToken"]}`
                    },
                });
            if (!(await response).ok){
                setError ("Fichier non disponible")
                return
            }
            let url2 = (await (await response).json()).url;
            console.log(url2)

            const response2 = fetch (url2, {
                method: 'GET',

            });
            const file = new Blob(
                [await (await response2).blob()],
                {type: '.xlsx'});
            setFileBlob (file)
            if (file!==null) {
                const fileURL = URL.createObjectURL(file);
                // console.log (fileURL)
                const list = fileURL.split ("/")
                const imp = list[list.length-1]
                
                window.open(window.location.href.replace (location.pathname, "")+"/price-list/"+imp+"/"+element.priceListPath);
                setIsLoaded (true)
                setIsShowingPriceList (false)
            }
            
                        
                   
            
        }
        async function downloadPriceList (){
            if (!isLoaded){
                if (!isStillConnected (token, setToken)) {
                return 
                }
                const response = fetch (url + '/price-list/path/' + element.id, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token["accessToken"]}`
                        },
                    });
                const url2 = (await (await response).json()).url;
                const response2 = fetch (url2, {
                    method: 'GET',
                });
                const file = new Blob(
                    [await (await response2).blob()],
                    );

                setFileBlob (file)
                const fileURL = URL.createObjectURL(file);
                
                const tempLink = document.createElement('a');
                tempLink.href = fileURL;
                tempLink.setAttribute('download', element.priceListName);
                tempLink.click();
                
            } else {
                const fileURL = URL.createObjectURL(fileBlob);
                // window.open(fileURL);
                const tempLink = document.createElement('a');
                tempLink.href = fileURL;
                tempLink.setAttribute('download', element.priceListName);
                tempLink.click();
            }
            
        }

        const [updatedGrille, setUpdatedGrille] = React.useState (null as unknown as File)
        const [isReplacingGrille, setIsReplacingGrille] = React.useState (false)
        const [hasConfirmSolo, setHasConfirmSolo] = React.useState (false)
        const [isUploadingSolo, setIsUploadingSolo] = React.useState (false)
        const [resultTextSolo, setResultTextSolo] = React.useState ("")
        const filePickerRefSolo = React.useRef () as React.MutableRefObject<HTMLInputElement>;
        function handleChangeSolo (e:any){
            if (e!==null){
                setUpdatedGrille (e.target.files[0])
            } else {
                setNewGrille (null as unknown as File)
            }
        }
        async function replaceGrille (){
            setIsLoading (true)
            setIsUploadingSolo (true)
            setHasConfirmSolo (true)
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token["accessToken"]}`);

            var formdata = new FormData();
            formdata.append("file", updatedGrille);

            var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: formdata
            }
            // console.log (url + "/price-list/" + (isMulti?"multi/":"single/") + carrierId)
            await fetch(url + "/price-list/path/" + element.id, requestOptions)
            .then(response => response.text())
            .then(result => {setResultTextSolo(result); })
            .catch(error => {setResultTextSolo (error);});
            await getInfo ()
            setIsUploading (false)
            setIsLoading (false)
        }
        return (
            <div className="table3" key={element["id"]}  style={{width:"100%", margin:0, padding:width>780?"":"10%", boxSizing:"border-box", display:"flex", alignItems:"center"}}>
                <div style={{display:"flex", flexDirection:width>1200?"row":"column", width:"100%", justifyContent:"center", alignSelf:"center", alignItems:"center"}}>
                    <div style={{display:"flex", flexDirection:width>780?'row':"column", width:"90%", justifyContent:width>780?"space-evenly":"flex-start"}}>
                        <div style={{ alignItems:"center",  justifyContent:"left", display:"flex", flexDirection:"row", flex:3}}>
                            {/* {width<780 && <div style={{width:24, height:60}}></div>} */}
                            <div style={{textAlign:"left",  display: "flex", flexDirection:"column", justifyContent:"center", padding:0, flex:3}}>
                                <span style={{color:"#e7ac4f", fontSize:22, fontWeight:700, marginRight:0}}>{element.id}</span>
                                <span style={{color:"#747474", fontWeight:600}}>Numéro de grille</span>
                            </div>
                        </div>

                        <div style={{   justifyContent:"left", display:"flex", flexDirection:"row", flex:3, textAlign:"left", justifyItems:"left"}}>
                            {/* {width<780 && <div style={{width:24, height:60}}></div>} */}
                            <img src={hand} alt='' style={{height:60, resize:"none", margin:12, marginLeft:width>1200?12:0, justifySelf:"left", alignSelf:"baseline"}} draggable={false}/>
                            <div style={{textAlign:"left",  display: "flex", flexDirection:"column", justifyContent:"center", padding:0, flex:3}}>
                                <span style={{color:element.reference!==""?"#e7ac4f":"#a2a2a2", fontSize:20, fontWeight:element.reference!==""?700:undefined, marginRight:0, fontStyle:element.reference!==""?undefined:"italic"}}>{element.reference!==""?element.reference:"Pas de référence"}</span>
                                <span style={{color:"#747474", fontWeight:600}}>Référence</span>
                            </div>
                        </div>

                        <div style={{ alignItems:"center",  justifyContent:"left", display:"flex", flexDirection:"row", flex:2}}>
                            {/* <img src={Calendar1} alt='' style={{width:60, height:60, resize:"none", marginRight:12}} draggable={false}/> */}
                            <Calendar1/>
                            {/* <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left", width:"max-content"}}>{element.name}</p> */}
                            <Tooltip title={getDateInLitteral (element.updatedAt)}>
                                <div style={{textAlign:"left",  display: "flex", flexDirection:"column", justifyContent:"center", padding:0, flex:3, paddingLeft:20}}>
                                    <span style={{color:"#e7ac4f", fontSize:22, fontWeight:700, marginRight:0}}>{getDate (element.updatedAt)}</span>
                                    <span style={{color:"#747474", fontWeight:600}}>Dernière modification</span>
                                </div>
                            </Tooltip>
                        </div>
                    
                        <div style={{display:"flex", flexDirection:"row", flex:2, alignContent:"center", justifyContent:"space-around", padding:"0px 20px"}}>
                            <Tooltip className="IconHover" title={"Télécharger la grille " + element.priceListPath}>
                                {/* <img src={ArrowDown} alt='' style={{width:20, resize:"none", fill:"blue", cursor:"pointer"}} draggable={false}/> */}
                                <ArrowDown style={{ height:40,width:50, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", flex:1}} onClick={()=>downloadPriceList ()}/>
                            </Tooltip>
                            <Tooltip className="IconHover" title={"Écraser la grille " + element.id}>
                                {/* <img src={ArrowUp} alt='' style={{width:20, resize:"none", cursor:"pointer"}} draggable={false}/> */}
                                <ArrowUp style={{height:40,width:50, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", flex:1}} onClick={()=>setIsReplacingGrille (true)}/>
                            </Tooltip>
                            <Tooltip className="IconHover" title={"Aperçu de la grille " + element.id}>
                                {/* <img src={ArrowUp} alt='' style={{width:20, resize:"none", cursor:"pointer"}} draggable={false}/> */}
                                <Eye style={{height:40,width:50, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", flex:1}} onClick={()=>showPriceList ()}/>
                            </Tooltip>
                        </div>

                        <div style={{flex:1, display:"flex", alignItems:"center", justifyContent:"center"}}>
                            <div className=" Change-Color-Hover" style={{  cursor:"pointer", justifyContent:"center", display:"flex",   border: isChecked?"5px solid #e7ac4f":"2px solid #9a9a9a", width:60, height:60, paddingLeft:0, borderRadius:10, boxSizing:"border-box", flexDirection:"row", alignItems:"center", alignContent:"center"}} onClick={(e)=>{handleChangeIsGoing();e.stopPropagation()}}>
                                <img src={Check} alt='' style={{height:40, resize:"none", margin:12, paddingLeft:0, visibility:isChecked?"visible":"hidden"}} draggable={false}/>
                            </div>
                        </div>
                    </div>

                    
                </div>
                <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isShowingPriceList} onClick={()=>setIsShowingPriceList (false)}>
                    <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 300px)", padding:20, position:"relative"}}>
                                {error===""?<CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>:<p>{error}</p>}
                        </div>
                    </div>
                </Backdrop>
                <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isReplacingGrille} onClick={()=>{setIsReplacingGrille (false);  setUpdatedGrille (null as unknown as File)}}>
                        <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 300px)", padding:20, display:"grid", gridTemplateColumns:"100%"}}>
                                {!hasConfirmSolo?
                                <>
                                    <h2 style={{ margin:0, marginBottom:0}}>Écraser une grille tarifaire</h2>                                

                                    <div onClick={()=>filePickerRefSolo.current.click ()} className="Cadre-blanc Grow-Hover" style={{ margin:20, padding:10, boxSizing:"border-box", display:"flex", alignItems:"center", justifyContent:"center", justifyItems:"center",flex:1, cursor:"pointer", width:"100%", justifySelf:"center"}}>
                                        <Plus style={{margin:"0px 10px 0px 20px"}}/>
                                        <p style={{margin:"0px 20px 0px 0px"}}>{updatedGrille===null?"Choisir un fichier":updatedGrille.name}</p>
                                        <input width="0%" type="file" onChange={(e)=>handleChangeSolo (e)} accept=".xlsx" ref={filePickerRefSolo} style={{width:0, height:0}}/>
                                    </div>
                                    
                                    <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
                                        <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setIsReplacingGrille (false)}}>
                                            <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                                        </div>
                                        <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {replaceGrille ()}}>
                                            <p style={{color:"white", margin:0}}>Confirmer</p>
                                        </div>
                                    </div>
                                </>
                                :
                                !isUploadingSolo?
                                    <>
                                        <p>{resultTextSolo}</p>
                                        <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setIsReplacingGrille (false); setResultTextSolo (""); setHasConfirmSolo (false); setUpdatedGrille (null as unknown as File)}}>
                                            <p style={{color:"white", margin:0}}>OK</p>
                                        </div>
                                    </>
                                    :
                                    <CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>}
                            </div>
                        </div>
                    </Backdrop>
               
            </div>
        )
    }

    // Gestion de la liste des grilles tarifaires
    const [grilleList, setGrilleList] = React.useState (new Array<any> ())
    const [filteredGrilleList, setFilteredGrilleList] = React.useState (new Array<any> ())
    const reductedGrilleList = filteredGrilleList.slice ((pageNbGrille-1)*20, pageNbGrille*20)

    
    // async function getPriceListList (){
    //     if (!isStillConnected (token, setToken)) {
    //         return 
    //     }
    //     const response = fetch (url + '/price-list', {
    //     method: 'GET',
    //     headers: {
    //         Accept: 'application/json',
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${token["accessToken"]}`
    //         },
    //     });
    //     const json:Array<PriceList> = await (await response).json();
    //     for (let i=0; i<json.length; i++){
    //         json[i].reference = "ref"
    //     }
    //     setGrilleList (json)
    //     setFilteredGrilleList (json)
    // }
    // React.useEffect (()=>{
    //     getPriceListList () 
    // }, [])
    
    // Pour créer une nouvelle grille

    async function deletePriceList (id:string){
        if (!isStillConnected (token, setToken)) {
            return 
            }
        await fetch (url + '/price-list/' + id, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
                },
            });
    }
    async function deleteMultiplePriceList (){
        setIsLoading (true)
        const temp = grilleList.filter((obj:any) => {return (obj.checked===true)}) 
        for (let i=0; i<temp.length; i++){
            await deletePriceList (temp[i].id)
        }
        
        getInfo ()
        setIsLoading (false)
    }
    async function createPriceList (){
        setIsUploading (true)
        setHasConfirm (true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token["accessToken"]}`);

        var formdata = new FormData();
        formdata.append("file", newGrille);

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata
        }
        // console.log (url + "/price-list/" + (isMulti?"multi/":"single/") + carrierId)
        await fetch(url + "/price-list/" + (isMulti?"multi/":"single/") + carrierId, requestOptions)
        .then(response => response.text())
        .then(result => {setResultText(result); })
        .catch(error => {setResultText (error);});
        setIsUploading (false)
        getInfo ()
    }
    const [isUploading, setIsUploading] = React.useState (false)
    const [hasConfirm, setHasConfirm] = React.useState (false)
    const [resultText, setResultText] = React.useState ("")
    const fileInputRef = React.useRef () as React.MutableRefObject<HTMLInputElement>;
    const [newGrille, setNewGrille] = React.useState (null as unknown as File)
    const [newRefGrille, setNewRefGrille] = React.useState ("")

    const [isMulti, setIsMulti] = React.useState (false)
    function handleChange (e:any){
        if (e!==null){
            setNewGrille (e.target.files[0])
        } else {
            setNewGrille (null as unknown as File)
        }
    }
    const [isAddingGrille, setIsAddingGrille] = React.useState (false)

    // Pour supprimer une ou plusieurs grilles
    const [isRemovingGrille, setIsRemovingGrille] = React.useState (false)
    //////////////////////////////////////////////////

    // Filtre des grilles
    const [numGrilleFiltre, setNumGrilleFiltre] = React.useState ("")
    const [refGrilleFiltre, setRefGrilleFiltre] = React.useState ("")
    const [dateGrilleFiltre, setDateGrilleFiltre] = React.useState ("")

    function getDate (a:string):string{
        const date = new Date (a)
        var day = date.getDate ().toString ()
        if (day.length===1){
            day = "0" + day
        }
        var month = (date.getMonth ()+1).toString ()
        if (month.length===1){
            month = "0" + month
        }
        var year = date.getFullYear ().toString ()
        if (year.length===1){
            year = "0" + year
        }
        return day + "/" + month + "/" + year
    }
    function getDateInLitteral (a:string){
        const jour = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]
        const mois = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
        const date:Date = new Date (a)
        return jour[date.getDay ()] + " " + date.getDate () + " " + mois[date.getMonth ()] + " " + date.getFullYear ()
    }
    React.useEffect (()=>{
        var temp = grilleList.slice ()

        if (numGrilleFiltre!==""){
            // temp = temp.filter((obj:any) => {return obj.id.toString ().slice (0, numGrilleFiltre.length) === numGrilleFiltre}) 
            temp = temp.filter((obj:any) => {return (obj.id.slice (0, numGrilleFiltre.length).localeCompare (numGrilleFiltre, undefined, {sensitivity:"base"}))===0}) 
        }
        if (refGrilleFiltre!==""){
            // temp = temp.filter((obj:any) => {return obj["reference"].slice (0, refGrilleFiltre.length) === refGrilleFiltre}) 
            temp = temp.filter((obj:any) => {return (obj["reference"].slice (0, refGrilleFiltre.length).localeCompare (refGrilleFiltre, undefined, {sensitivity:"base"}))===0}) 
        } 
        if (dateGrilleFiltre!==""){
            // temp = temp.filter((obj:any) => {return obj["updated"].toString ().slice (0, dateGrilleFiltre.length) === dateGrilleFiltre}) 
            temp = temp.filter((obj:any) => {return (obj["updated"].slice (0, dateGrilleFiltre.length).localeCompare (dateGrilleFiltre, undefined, {sensitivity:"base"}))===0}) 
        }
        
        setFilteredGrilleList (temp)
    }, [grilleList, numGrilleFiltre, refGrilleFiltre, dateGrilleFiltre])
   
   
    // Handle the main header size to adapt
    const [headerHeight, setHeaderHeight] = React.useState (60)
    React.useEffect(() => {
        let header = document.getElementById("header");
        if (header) {
            setHeaderHeight (header.clientHeight)
        }
      }, [])
    ////////////////////////////////////////////////

    const navigate = useNavigate ()
    return (
        <>
        
        <div className="table2" style={{ margin:"0px 20px 20px 20px"}}>
            <div style={{display:"flex", flexDirection:width>600?"row":"column", justifyContent:"left", justifyItems:"center", width:"100%", justifySelf:'center', position:"sticky", top:headerHeight+40, zIndex:3, pointerEvents: "none", userSelect:"none"}}>
                <div style={{display:"flex", flexDirection:"row"}}>
                    <div style={{ marginRight:20, boxSizing:"border-box", display:"grid", alignContent:"center", justifyContent:"center", cursor:"pointer", width:width>780?"unset":60, pointerEvents: "visible"}} className="Cadre-blanc Change-Color-Hover Input-password" onClick={()=>{navigate (-1)}}>
                        <img src={Back} style={{height:40, resize:"none"}} alt="go back" draggable={false}/>
                    </div>
                    <div style={{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition" >
                        <p style={{width:"max-content"}}>{carrier.name}</p>
                    </div>
                    </div>
                    {width>1200 ?
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%", justifySelf:'center', position:"sticky", top:headerHeight+40, zIndex:3, pointerEvents: "none", userSelect:"none", paddingLeft:20}}>
                            <div style={nbListOnScreen===0?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={() => {!isLoading && setnbListOnScreen(0)}}>
                                <p>Sous-comptes</p>
                            </div>
                            <div style={nbListOnScreen===1?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={() => {!isLoading && setnbListOnScreen(1)}}>
                                <p>Grilles tarifaires</p>
                            </div>
                        </div>
                        :
                        <div style={{display:"flex", flexDirection:"row", position:"sticky", top:"19vh", alignItems:"center", zIndex:3, userSelect:"none", width:"100%", marginTop:width<600?20:0}}>
                            <Chevron style={{margin:"0px 30px", rotate:"90deg", cursor:"pointer", pointerEvents:"visible"}} onClick={()=>goToPreviousScreen()}/>
                            <div style={{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default"}} className="Cadre-blanc Header-expedition Change-Color-Hover">
                                <p>{listOfScreen[nbListOnScreen]}</p>
                            </div>
                            <Chevron style={{margin:"0px 30px", rotate:"270deg", cursor:"pointer", pointerEvents:"visible"}} onClick={()=>goToNextScreen()}/>
                        </div>
                    }
                </div>
                

            <div className="table" style={{margin:"20px 0px", width:"100%", display:"flex"}}>
                
                
                {!isLoading? 
                <>
                {nbListOnScreen===0?
                    <>
                    {subAccountList.length>0?
                    <>
                    <div style={{ display:"flex" ,alignItems:"flex-end", flexDirection:"row-reverse"}}>
                        
                        {width<1000 &&
                        
                            <div onClick={()=>setTryToFilter(true)} style={{display:"flex", flexDirection:"column",  alignItems:"center", margin:30, width:"min-content"}} >
                                <div className="test Input-password" style={{display:"flex", flexDirection:"row", alignItems:"center", width:"max-content"}} onMouseEnter={()=>setIsHover3 (true)} onMouseLeave={()=>setIsHover3 (false)}>
                                    <p style={{marginLeft:20, marginRight:20, fontWeight:500}}>Filtrer</p>
                                </div>
                                <div  style={{width:"100%", height:3, backgroundColor:isHover3?"#f9b233":"#747474"}}></div>
                            </div>
                        }
                            <div onClick={()=> selectAll ()}  style={{display:"flex", flexDirection:"column",  alignItems:"center", margin:30, width:"min-content", justifyContent:"center", justifyItems:"center"}} >
                                <div className="test" style={{display:"flex",  alignItems:"center", width:"max-content"}} onMouseEnter={()=>setIsHoverSelectAll (true)} onMouseLeave={()=>setIsHoverSelectAll (false)}>
                                    <p style={{marginLeft:20, marginRight:20, fontWeight:500}}>{isSelectingAll?"Tout désélectionner":"Tout sélectionner"}</p>
                                    </div>
                                <div  style={{width:"100%", height:3, backgroundColor:isHoverSelectAll?"#f9b233":"#747474"}}></div>
                            </div>
                        
                    </div>
                    
                    {width>1000 &&
                        <div style={{display:"flex" , flexDirection:"row", alignSelf:"center", width:"100%", padding:30, paddingTop:0, boxSizing:"border-box", justifyContent:"center"}}>
                            <div style={{display:"flex", flexDirection:width>1200?"row":"column", width:"100%", justifyContent:"center", alignSelf:"center"}}>
                                <div style={{display:"flex" , alignSelf:"center",  width:"90%", justifyContent:width>780?"space-evenly":"left"}}>
                                    <div style={{flex:3, padding:0}}>
                                        <TextInput width="98%" bordercolor="#7d7d7d" label="Num. de transporteur" value={carrierNumFiltre} onChange={(value)=>setCarrierNumFiltre(value.target.value)}/>
                                    </div>
                                    <div style={{flex:2}}>
                                        <TextInput width="98%" bordercolor="#7d7d7d" label="Nom du profil" value={profilNameFiltre} onChange={(value)=>setProfilNameFiltre(value.target.value)}/>
                                    </div>
                                    <div style={{flex:1}}>
                                        <TextInput width="98%" bordercolor="#7d7d7d" label="Référence" value={referenceFiltre} onChange={(value)=>{if (!Number.isNaN (+value.target.value)){setReferenceFiltre(value.target.value);}}}/>
                                    </div>
                                    <div style={{flex:1}}>
                                        <SelectInput bordercolor="#7d7d7d" label="Grille " style={{width:"100%"}} value={""} onChange={()=>undefined}>
                                            <MenuItem value="" style={{fontStyle:"italic"}} disabled>Pas d'options</MenuItem>
                                            <MenuItem value="AZERTY">Grille AZERTY</MenuItem>
                                            <MenuItem value="QWERTY">Grille QWERTY</MenuItem>
                                            <MenuItem value="POIUYT">Grille POIUYT</MenuItem>
                                        </SelectInput>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {width<=1000 && tryToFilter &&
                    <div style={{display:"flex",  justifyItems:"center", flexDirection:"column", width:"100vw",height:"100vh", top:0,alignSelf:"center", position:"fixed", backgroundColor:"white", zIndex:5, justifyContent:"center", borderRadius:30, padding:20, boxSizing:"border-box", overflowY:'auto',}}>
                        <h2>Filtres</h2>                    
                    
                        <TextInput bordercolor="#7d7d7d" label="Num. de transporteur" value={carrierNumFiltre} onChange={(value)=>setCarrierNumFiltre(value.target.value)} style={{alignSelf:"center"}}/>
                        <TextInput bordercolor="#7d7d7d" label="Nom du profil" value={profilNameFiltre} onChange={(value)=>setProfilNameFiltre(value.target.value)} style={{alignSelf:"center"}}/>
                        <TextInput bordercolor="#7d7d7d" label="Référence" value={referenceFiltre} onChange={(value)=>setReferenceFiltre(value.target.value)} style={{alignSelf:"center"}}/>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                        <span className="table" style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center"}} onClick={()=> {removeAllFilter()}}>
                            <p className="table" style={{color:"white"}}>Supprimer tous les filtres</p>
                        </span>
                        <span className="table" style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center"}}  onClick={()=> {setTryToFilter(false)}}>
                            <p className="table" style={{color:"white"}}>Appliquer</p>
                        </span>
                    </div>
                    </div>
                    }
                    {reductedList.map ((element:CarrierRegistration, index:number) =>
                        <div key={index} style={{display:"grid" ,justifyItems:"center", flexDirection:"column", width:"100%", position:"relative"}}>
                            <CarrierComponent element={element} index={index}/>
                            
                            {index!==reductedList.length-1 &&
                                <div style={{width:"90%", alignSelf:"center", justifySelf:"center", height:3, backgroundColor:'#9a9a9a', borderRadius:10}}></div>
                            }
                            
                        </div>
                    )}       
                    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isUpdatingGrille} onClick={()=>setIsUpdatingGrille (false)}>
                        <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 300px)", padding:20}}>
                                <h2 style={{ margin:0, marginBottom:20}}>Changer la grille tarifaire de {nbChecked} sous-compte{nbChecked>1&&"s"} ?</h2>                                
                                <div style={{margin:10}}>
                                    <SelectInput bordercolor="#7d7d7d" label="Grille " style={{width:"100%"}} value={""} onChange={()=>undefined}>
                                        <MenuItem value="" style={{fontStyle:"italic"}} disabled>Pas d'options</MenuItem>
                                        <MenuItem value="AZERTY">Grille AZERTY</MenuItem>
                                        <MenuItem value="QWERTY">Grille QWERTY</MenuItem>
                                        <MenuItem value="POIUYT">Grille POIUYT</MenuItem>
                                    </SelectInput>
                                </div>
                                <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
                                    <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setIsAddingGrille (false)}}>
                                        <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                                    </div>
                                    <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setIsAddingGrille (false)}}>
                                        <p style={{color:"white", margin:0}}>Confirmer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Backdrop>
                    <div style={{zIndex:5,bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column-reverse", justifyContent: "space-between", boxSizing:"content-box", userSelect:"none"}}>

                        {nbChecked>0 &&
                            <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:5}} onClick={()=>setIsUpdatingGrille (true)}>
                                <p>Changer la grille</p>
                            </div>
                        }

                    </div>

                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", marginTop:10, marginBottom:20}}>
                        {/* <span className="table" style={pageNb>1?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}} onClick={()=> {goToPreviousPage()}}>
                            <p className="table" style={{color:"white"}}>Page précédente</p>
                        </span>
                        <p style={{cursor:"default"}}>{pageNb}/{Math.floor((filteredList.length-1)/20)+1}</p>
                        <span className="table" style={pageNb<filteredList.length/20?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}}  onClick={()=> {goToNextPage()}}>
                            <p className="table" style={{color:"white"}}>Page suivante</p>
                        </span> */}
                        <Pagination count={filteredList.length!==0? Math.floor((filteredList.length-1)/20)+1:1} size="large"  onChange={(_event, number)=>setPageNb (number)}/>
                    </div>
                    </>:<p>Pas de données à afficher</p>
                    }
                    </>
                    :
                    <>                                
                        <div style={{width:"100%", borderRadius:8}}>
                            {width>1000 &&
                            <div style={{display:"flex" , flexDirection:"row", alignSelf:"center", width:"100%", padding:30, paddingBottom:0, boxSizing:"border-box", justifyContent:"center"}}>
                                <div style={{display:"flex", flexDirection:width>1200?"row":"column", width:"100%", justifyContent:"center", alignSelf:"center"}}>
                                    <div style={{display:"flex" , alignSelf:"center",  width:"90%", justifyContent:width>780?"space-evenly":"left"}}>
                                        <div style={{flex:1}}>
                                            <TextInput width="98%" bordercolor="#7d7d7d" label="Numéro de grille" value={numGrilleFiltre} onChange={(value)=>setNumGrilleFiltre(value.target.value)}/>
                                        </div>
                                        <div style={{flex:1}}>
                                            <TextInput width="98%" bordercolor="#7d7d7d" label="Référence" value={refGrilleFiltre} onChange={(value)=>setRefGrilleFiltre(value.target.value)}/>
                                        </div>
                                        <div style={{flex:1}}>
                                            {/* <TextInput width="98%" bordercolor="#7d7d7d" label="Dernière modification" value={referenceFiltre} onChange={(value)=>{if (!Number.isNaN (+value.target.value)){setReferenceFiltre(value.target.value);}}}/> */}
                                            <TextInput width="98%" label="Dernière modification" bordercolor="#7d7d7d" value={dateGrilleFiltre} onChange={(props)=>setDateGrilleFiltre (props.target.value)} type="date" InputProps={{startAdornment:<></>}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {width<=1000 && tryToFilter &&
                            <div style={{display:"flex",  justifyItems:"center", flexDirection:"column", width:"100vw",height:"100vh", top:0,alignSelf:"center", position:"fixed", backgroundColor:"white", zIndex:5, justifyContent:"center", borderRadius:30, padding:20, boxSizing:"border-box", overflowY:'auto',}}>
                                <h2>Filtres</h2>                    
                            
                            
                                <TextInput width="98%" bordercolor="#7d7d7d" label="Numéro de grille" value={numGrilleFiltre} onChange={(value)=>setNumGrilleFiltre(value.target.value)}/>
                            
                            
                                <TextInput width="98%" bordercolor="#7d7d7d" label="Référence" value={refGrilleFiltre} onChange={(value)=>setRefGrilleFiltre(value.target.value)}/>
                            
                                    
                                {/* <TextInput width="98%" bordercolor="#7d7d7d" label="Dernière modification" value={referenceFiltre} onChange={(value)=>{if (!Number.isNaN (+value.target.value)){setReferenceFiltre(value.target.value);}}}/> */}
                                <TextInput width="98%" label="Dernière modification" bordercolor="#7d7d7d" value={dateGrilleFiltre} onChange={(props)=>setDateGrilleFiltre (props.target.value)} type="date" InputProps={{startAdornment:<></>}}/>
                                
                                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                                <span className="table" style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center"}} onClick={()=> {removeAllFilter()}}>
                                    <p className="table" style={{color:"white"}}>Supprimer tous les filtres</p>
                                </span>
                                <span className="table" style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center"}}  onClick={()=> {setTryToFilter(false)}}>
                                    <p className="table" style={{color:"white"}}>Appliquer</p>
                                </span>
                            </div>
                        </div>
                        }
                        </div>
                        
                        {reductedGrilleList.map ((element:any, index:number) =>
                        <div key={index} style={{display:"grid" ,justifyItems:"center", flexDirection:"column", width:"100%", position:"relative"}}>
                            <GrilleComponent element={element} index={index}/>
                            
                            {index!==reductedGrilleList.length-1 &&
                                <div style={{width:"90%", alignSelf:"center", justifySelf:"center", height:3, backgroundColor:'#9a9a9a', borderRadius:10}}></div>
                            }
                        </div>
                    )} 
                    <div style={{zIndex:5,bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column-reverse", justifyContent: "space-between", boxSizing:"content-box", userSelect:"none"}}>

                        <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:5}} onClick={()=>setIsAddingGrille (true)}>
                            <p>Nouvelle grille</p>
                        </div>

                        {nbChecked2>0 &&
                            <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:5}} onClick={()=>setIsRemovingGrille (true)}>
                                <p>Supprimer</p>
                            </div>
                        }
                        
                    </div>
                    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isAddingGrille} onClick={()=>{setIsAddingGrille (false); setResultText (""); setHasConfirm (false); setNewGrille (null as unknown as File)}}>
                        <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 300px)", padding:20, display:"grid", gridTemplateColumns:"100%"}}>
                                {!hasConfirm?
                                <>
                                    <h2 style={{ margin:0, marginBottom:0}}>Créer une grille tarifaire</h2>                                

                                    <div onClick={()=>fileInputRef.current.click ()} className="Cadre-blanc Grow-Hover" style={{ margin:20, padding:10, boxSizing:"border-box", display:"flex", alignItems:"center", justifyContent:"center", justifyItems:"center",flex:1, cursor:"pointer", width:"100%", justifySelf:"center"}}>
                                        <Plus style={{margin:"0px 10px 0px 20px"}}/>
                                        <p style={{margin:"0px 20px 0px 0px"}}>{newGrille===null?"Choisir un fichier":newGrille.name}</p>
                                        <input width="0%" type="file" onChange={(e)=>handleChange (e)} accept=".xlsx" ref={fileInputRef} style={{width:0, height:0}}/>
                                    </div>
                                    <TextInput width="100%" bordercolor="#7d7d7d" label="Référence (optionnel)" value={newRefGrille} onChange={(value)=>setNewRefGrille(value.target.value)} style={{marginBottom:0}}/>
                                    <div style={{display:"flex", flexDirection:"row", alignContent:"center", alignItems:"center", justifyContent:"space-around", marginBottom:10}}>
                                        <p style={{color:!isMulti?"#e7ac4f":"#a2a2a2", fontWeight:!isMulti?'bold':"normal", cursor:"pointer"}} onClick={()=>setIsMulti (false)}>Grille Mono</p>
                                        <AntSwitch checked={isMulti} onChange={(e)=>setIsMulti (e.currentTarget.checked)}/>
                                        <p style={{color:isMulti?"#e7ac4f":"#a2a2a2", fontWeight:isMulti?'bold':"normal", cursor:"pointer"}} onClick={()=>setIsMulti (true)}>Grille Multi</p>
                                    </div>
                                    <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
                                        <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setIsAddingGrille (false)}}>
                                            <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                                        </div>
                                        <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {createPriceList ()}}>
                                            <p style={{color:"white", margin:0}}>Confirmer</p>
                                        </div>
                                    </div>
                                </>
                                :
                                !isUploading?
                                    <>
                                        <p>{resultText}</p>
                                        <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setIsAddingGrille (false); setResultText (""); setHasConfirm (false); setNewGrille (null as unknown as File)}}>
                                            <p style={{color:"white", margin:0}}>OK</p>
                                        </div>
                                    </>
                                    :
                                    <CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>}
                            </div>
                        </div>
                    </Backdrop>
                    
                    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isRemovingGrille} onClick={()=>setIsRemovingGrille (false)}>
                        <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 300px)", padding:20}}>
                                <h2 style={{ margin:0, marginBottom:20}}>Voulez-vous vraiment supprimer {nbChecked2} grille{nbChecked2>1&&"s"} ?</h2>                                

                                <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
                                    <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setIsRemovingGrille (false)}}>
                                        <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                                    </div>
                                    <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {deleteMultiplePriceList (); setIsRemovingGrille (false)}}>
                                        <p style={{color:"white", margin:0}}>Confirmer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Backdrop>

                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", marginTop:10, marginBottom:20}}>
                        <Pagination count={filteredGrilleList.length!==0? Math.floor((filteredGrilleList.length-1)/20)+1:1} size="large"  onChange={(_event, number)=>setPageNbGrille (number)}/>
                    </div>
                </>
                }
                </>:<CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>}
            </div>
        </div>
        
        
    </>
    )
}
export default TransporteurPage;