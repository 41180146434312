import React from "react";
import Person from "./person"
import { Backdrop, Pagination } from "@mui/material";
import { UserToken, UserSetToken,url, widthContext } from "../../../context/token";
import { TextInput } from "../../../components/input";
import { Contact, ServerResponse } from "../../../types";
import { isStillConnected } from "../../../utils/areYouStillConnected";
import PersonLoading from "./personLoading";
import { Connexion } from "../../../utils/connexion";
import { SearchAddresses } from "../../../components/searchAddresses";

const background = "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg clip-path='url(%26quot%3b%23SvgjsClipPath1084%26quot%3b)' fill='none'%3e%3ccircle r='52.7' cx='1349.85' cy='553.93' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3ccircle r='20.665' cx='1250.02' cy='312.06' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3ccircle r='28.775' cx='783.28' cy='151.2' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3ccircle r='25.915' cx='1263.74' cy='1.75' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3ccircle r='15.595' cx='478.17' cy='336.7' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3ccircle r='14.56' cx='635.51' cy='280.82' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3ccircle r='30.255' cx='1157.58' cy='15.3' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3ccircle r='37.895' cx='848.66' cy='76.8' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3ccircle r='29.68' cx='1163.64' cy='223.99' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3ccircle r='37.005' cx='605.07' cy='86.04' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3ccircle r='15.52' cx='421.17' cy='454.03' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3ccircle r='16.645' cx='798.64' cy='340.01' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3ccircle r='18.265' cx='768.4' cy='361.75' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3ccircle r='22.94' cx='22.8' cy='370.6' fill='rgba(249%2c 178%2c 50%2c 1)'%3e%3c/circle%3e%3c/g%3e%3cdefs%3e%3cclipPath id='SvgjsClipPath1084'%3e%3crect width='1440' height='560' x='0' y='0'%3e%3c/rect%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e"

function CarnetdAdresses  (){

    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)

    const [contacts, setContacts] = React.useState (new Array<Contact> ())

    const [pageNb, setPageNb] = React.useState (1)
    const [filteredContacts, setFilteredContact] = React.useState (new Array<Contact> ())
    const reductedList = filteredContacts.slice ((pageNb-1)*10, (pageNb*10))

    async function getInfo (){
        setIsLoading (true)
        if (!isStillConnected (token, setToken)) {
            return 
        }
        setIsLoading (true)
        setIsLoading2 (true)
        const response = fetch (url + '/contact', {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            }
          })
        const json = await (await response).json();
        setIsLoading2 (false)
        setContacts (json)
        window.setTimeout(()=>setIsLoading (false), 2000)
    }
    React.useEffect (()=>{
        !isLoading2 && setIsLoading (false) // eslint-disable-next-line
    }, [contacts])
    const [isLoading, setIsLoading] = React.useState (true)
    const [isLoading2, setIsLoading2] = React.useState (true)
    React.useEffect (()=>{
        Connexion () 
        getInfo ()
        // eslint-disable-next-line
    }, [])
    ////////////////////////////////////////////////////////

    
    const [isCreatingContact, setIsCreatingContact] = React.useState (false)
    
    
    const [isAutoCompleteOpen, setIsAutoCompleteOpen] = React.useState (false)
    
    const [newName, setNewName] = React.useState ("")
    const [newAdress1, setNewAdress1] = React.useState ("")
    const [newAdress2, setNewAdress2] = React.useState ("")
    const [newZip, setNewZip] = React.useState ("")
    const [newCity, setNewCity] = React.useState ("")
    const [newCountry, setNewCountry] = React.useState ("")

    const [newReceiverName, setNewReceiverName] = React.useState ("")
    const [newReference, setNewReference] = React.useState ("")
    const [newMail, setNewMail] = React.useState ("")
    const [newTelephone, setNewTelephone] = React.useState ("")
    const [newInstruction1, setNewInstruction1] = React.useState ("")
    const [newInstruction2, setNewInstruction2] = React.useState ("")
    const didSomethingChange =  newName!=="" ||
                                newAdress1 !=="" ||
                                newAdress2 !=="" ||
                                newZip !=="" ||
                                newCity !=="" ||
                                newCountry !=="" ||
                                newReceiverName !=="" ||
                                newReference !=="" ||
                                newMail !=="" ||
                                newTelephone !=="" ||
                                newInstruction1 !=="" ||
                                newInstruction2 !=="" 

    function cancelCreation (){
        setIsCreatingContact (false)
        setNewName ("")
        setNewAdress1 ("")
        setNewAdress2 ("")
        setNewZip ("")
        setNewCity ("")
        setNewCountry ("")
        setNewReceiverName ("")
        setNewReference ("")
        setNewMail ("")
        setNewTelephone ("")
        setNewInstruction1 ("")
        setNewInstruction2 ("")
    }
    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////

    const revealed = React.useState <string> ("")


    const [filter, setFilter] = React.useState ("")
    React.useEffect (()=>{
        var temp = contacts.slice ()
        if (filter!==""){
            temp = temp.filter((obj) => {return obj.companyName.slice (0, filter.length).localeCompare(filter, undefined, { sensitivity: 'base' })===0}) 
        }
        setFilteredContact (temp)
    }, [filter, contacts])

    async function createContact (){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        setIsLoading (true)
        const response = await fetch (url + '/contact', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`,
            },
            body: JSON.stringify ({
                "contactName": newReceiverName,
                "companyName": newName,
                "email": newMail, 
                "phone": newTelephone,
                "reference": newReference,
                "specialInstruction1": newInstruction1,
                "specialInstruction2": newInstruction2,
                "address": {
                    "streetLine1": newAdress1,
                    "streetLine2": newAdress2,
                    "city": newCity,
                    "zip": +newZip,
                    "country": newCountry
                }
            })
          })
        
        if (!response.ok){
            const json:ServerResponse = await (response).json();
            if (typeof json.message!=="string"){
                setError (json.message)
            } else {
                setError ([json.message])
            }
            setIsLoading (false)
        } else {
            getInfo ()
            cancelCreation ()
        }
    }
    const [error, setError] = React.useState (new Array<string> ())
    return (
        <div style={{ height:"100%", display:"grid", margin:"0px 20px"}}>
            <img src={background} alt="" style={{width:"100vw", height:"100vh", position:"fixed", top:0, left:0, zIndex:-1, objectFit:"cover"}}/>
            <div style={{width:"100%", justifySelf:"center"}}>
                <div style={{marginTop:30, width:width>780?"30%":"100%"}}>
                    <TextInput width="100%" label="Rechercher un contact" bordercolor="#f0ba61" sx={{backgroundColor:"#fff", borderRadius:3}} value={filter} onChange={(e)=>setFilter (e.currentTarget.value)}/>
                </div>
            </div>
            {!isLoading ? <div style={{width:"100%", justifySelf:"center"}}>
                {contacts.length>0?
                    <>
                        {reductedList.map ((element:any)=>
                            // <p>{JSON.stringify (element)}</p>
                            <Person contact={element} functions={revealed} key={element.id} refresh={getInfo}/>
                        )}
                        {!(filteredContacts.length<=10) && <div style={{width:"100%", justifyContent:"center", display:"flex"}}>
                            <Pagination count={filteredContacts.length!==0? Math.floor((filteredContacts.length-1)/10)+1:1} size="large"  onChange={(_event, number)=>setPageNb (number)}/>
                        </div>}
                    </>
                    :
                    <>    
                        {/* Contact type pour ne pas laisser la page vide si il n'y a pas encore de contact */}
                        <Person contact={
                            {
                                "id": "magic",
                                "contactName": "Harry POTTER",
                                "companyName": "Chez les Dursley",
                                "specialInstruction1": "Harry ne reçoit que les couriers par hibou",
                                "specialInstruction2": "Pas de magie devant les moldus",
                                "specialInstruction3": null,
                                "reglementPR": null,
                                "email": "hpotter@poudlar.com",
                                "phone": "06 06 06 06 06",
                                "reference": "Ceci est un contact type",
                                "address": {
                                    "id": "",
                                    "streetLine1": "4, Privet Drive, Little Whinging",
                                    "streetLine2": "Sous la cage d'escalier",
                                    "city": "Londres",
                                    "zip": "EC2P 2E",
                                    "country": "Angleterre"
                                }
                            }
                        } functions={revealed} real={false}/>  
                        
                    </>
                }
            </div>
            : 
            <div style={{width:"100%", justifySelf:"center"}}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map ((element:number)=>
                    // <p>{JSON.stringify (element)}</p>
                    <PersonLoading key={element}/>
                )}
                <div style={{width:"100%", justifyContent:"center", display:"flex"}}>
                    <Pagination count={filteredContacts.length!==0? Math.floor((filteredContacts.length-1)/10)+1:1} size="large"  onChange={(_event, number)=>setPageNb (number)}/>
                </div>
            </div>
            }
        
        
            <Backdrop open={error.length!==0} onClick={()=>!didSomethingChange && setIsCreatingContact (false)} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
                <div style={{position: "fixed", left: "50%", top:"10%"}}>
                    <div className='logout' style={{position: "relative", left: "-50%", top:"-50%", border: "dotted #deae5f 1px", padding:30, width:450, paddingLeft:0, paddingRight:0}}>
                        
                        <p style={{margin:10, fontSize:25, fontWeight:500}}>Erreur</p>
                        <div style={{display:'flex', justifyContent:"center", width:"100%"}}>
                            <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                {error.map ((element, index)=>
                                    <div key={index} style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"100%"}} >
                                        
                                        <p style={{margin:0, textAlign:"left", marginLeft:20, width:"70%"}}>{element}</p>
                                        
                                    </div>
                                )}
                            </div>
                            
                        </div>
                        
                        <div style={{display:"grid", gridTemplateColumns:"100%", justifyContent:"center", padding:30, paddingBottom:0, paddingTop:20}}>
                            <div style={{cursor:"pointer", backgroundColor:"#deae5f", margin:10, textAlign:"center", borderRadius:20, marginBottom:0,marginRight:10}} className="Grow-Hover" onClick={()=>setError(new Array<string> ())}>
                                <p style={{ color:"white", width:"100%", height:"100%"}}>Continuer</p>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </Backdrop>
            

            <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column-reverse", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>      
                <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:"20vw", pointerEvents: "visible"}} onClick={()=>setIsCreatingContact (true)}>
                    <p>Nouveau contact</p>
                </div>    
            </div>
            <Backdrop open={isCreatingContact} onClick={()=>!didSomethingChange && setIsCreatingContact (false)} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div style={{height:"min-content", width:"min (100%, 300px)", maxHeight:"80vh", overflow:"hidden", borderRadius:20}}>
                        <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 300px)", padding:20, maxHeight:"80vh", overflow:"auto", position:"relative"}}>
                            <h1>Nouveau contact</h1>
                            <div style={{zIndex:0, display:"flex", flexDirection:"column", alignItems:"center", width:"100%", alignSelf:"flex-start"}}>
                                <TextInput bordercolor="#f0ba61" width="100%" label="Nom de l'entreprise" value={newName} onChange={(value)=>setNewName(value.target.value)}/>
                                <SearchAddresses address={newAdress1} setAddress={setNewAdress1} setZipCode={setNewZip} setCity={setNewCity} setCountry={setNewCountry} isOpen={isAutoCompleteOpen} setIsOpen={setIsAutoCompleteOpen} style={{width:"100%"}}/>
                                
                                <TextInput bordercolor="#f0ba61" width="100%" label="Indication postale supplémentaire" value={newAdress2} onChange={(value)=>setNewAdress2(value.target.value)}/>
                            
                                <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Code postal" value={newZip} onChange={(value)=>!Number.isNaN (+value.target.value) && setNewZip (value.target.value)}/>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom de la ville" value={newCity} onChange={(value)=>setNewCity(value.target.value)}/>   
                                </div>
                                <TextInput bordercolor="#f0ba61" width="100%" label="Pays" value={newCountry} onChange={(value)=>setNewCountry(value.target.value)}/>
                            </div>
                            <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom du destinataire" value={newReceiverName} onChange={(value)=>setNewReceiverName(value.target.value)} />
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Référence" value={newReference} onChange={(value)=>setNewReference(value.target.value)} />
                                {/* <div style={{position:"absolute", width:"100%", height:"100%"}}></div> */}
                            </div>
                            <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Mail" value={newMail} onChange={(value)=>setNewMail(value.target.value)} />
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Téléphone" value={newTelephone} onChange={(value)=>setNewTelephone(value.target.value)} />
                            </div>
                            <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between", marginBottom:width<1024?40:undefined}}>
                                <TextInput style={{marginBottom:0}} bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Instruction particulière 1" value={newInstruction1} onChange={(value)=>setNewInstruction1(value.target.value)} />
                                <TextInput style={{marginBottom:0}} bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Instruction particulière 2" value={newInstruction2} onChange={(value)=>setNewInstruction2(value.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column-reverse", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>
                            
                    <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:"20vw", pointerEvents: "visible"}} onClick={()=>cancelCreation ()}>
                        <p>Annuler</p>
                    </div>

                    {didSomethingChange && <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:"3px 10px", pointerEvents: "visible"}} onClick={()=>createContact ()}>
                        <p>Sauvegarder</p>
                    </div>}
                    
                </div>
            </Backdrop>


        </div>
    )
}
export default CarnetdAdresses