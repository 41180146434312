import Select from '@mui/material/Select';



import { FormControl, InputLabel } from '@mui/material';


export function SelectInput (props:{
                              children:Array<any>, 
                              bordercolor?:string, 
                              width?:string|number, 
                              label?:string, 
                              style?:object,
                              value:any,
                              onChange:any,
                              disabled?:boolean,
                            }){
  return (
    <FormControl fullWidth style={{...{width:props.width===undefined?"80%":props.width, height:"3.5rem"}, ...props.style}}>
      <InputLabel style={{color:props.disabled!==true ?props.bordercolor:"", zIndex:0}}>{props.label}</InputLabel>
      <Select
          // IconComponent={() => <p>s</p>}
          // labelStyle={{ color: '#ff0000' }}
        disabled={props.disabled!==undefined?props.disabled:false}
        sx={{
          width: "100%",
          height: '3.5rem',
          color: "black",
          borderRadius:2.5,
          margin:"0px 0px 20px 0px",
          textAlign:"left",
          " & label.Mui-focused": {
            color: props.bordercolor,
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: props.bordercolor,
            borderWidth:3,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: props.bordercolor,
            borderWidth:2,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: props.disabled!==true ? props.bordercolor:"",
            borderWidth:3,
          },
          '.MuiSvgIcon-root ': {
              // fill: "white !important",
          }
        }}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        >
        {props.children.map ((element)=>element)} 
      </Select>
    </FormControl>
  )
}