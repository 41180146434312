import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';


import ProfilsDesTransporteurs from '../pages/admin/profilsDesTransporteurs'
import TransporteurPage from '../pages/admin/transporteur'
import SubAccountPage from '../pages/admin/subaccount/subaccount'
import ListeDesClientsInscrits from '../pages/admin/listeDesClientsInscrits' 
import AccountPage from '../pages/admin/account'
import CreateSubAccount from '../pages/admin/createSubAccount';
import CreateAccount from '../pages/admin/createAccount';

import StatistiquesAccount from '../pages/account/statistiques'
import ContactPage from '../pages/account/contact'
import MonProfil from '../pages/monProfil'
import ListeDesExpeditions from '../pages/listeDesExpeditions'
import MesSousComptes from '../pages/account/mesSousComptes'
import CreateSubAccountAccount from '../pages/account/createSubAccount';


import StatistiquesSubAccount from '../pages/sub_account/statistiques'
// import Test from '../pages/sub_account/test'
import MonProfilSubAccount from '../pages/sub_account/monProfil'
import CarnetdAdresses from '../pages/sub_account/carnetdAdresses/carnetdAdresses'

import DemandeDeRamassage from '../pages/sub_account/demandeDeRamassage'
import DemandeUneExpedition from '../pages/sub_account/demandeUneExpedition/demandeUneExpedition'
import Facturation from '../pages/facturation/facturation';

import ForgotPassword from "./notInRouter/forgotPassword"




import { UserToken } from "../context/token";
import { decodeToken } from 'react-jwt';


const Main = () => {
  
  const token = React.useContext (UserToken)
  const decodedToken:any = decodeToken (token["accessToken"])
  const role = decodedToken["role"]

  
  return (         
    <Routes>      
      
      <Route path='/auth/reset-password/:token' element={<ForgotPassword/>} /> 

      {role==="admin" && 
      <>
        <Route path='/profils_des_transporteurs' element={<ProfilsDesTransporteurs/>} />
        <Route path='/profils_des_transporteurs/:id' element={<TransporteurPage/>} />
        <Route path='/subaccount/:id' element={<SubAccountPage/>} />
        <Route path="/liste_des_expeditions" element={<Navigate replace to="/liste_des_expeditions/0" />}/>
        <Route path='/liste_des_expeditions/:id' element={<ListeDesExpeditions/>} />
        <Route path='/liste_des_expeditions/:id/:id' element={<ListeDesExpeditions/>} />
        <Route path='/liste_des_clients_inscrits' element={<ListeDesClientsInscrits/>} />
        <Route path='/account/:id' element={<AccountPage/>} />
        <Route path='/create_subaccount/:id' element={<CreateSubAccount/>} />
        <Route path='/create_account' element={<CreateAccount/>} />
        <Route path="/" element={<Navigate replace to="/liste_des_expeditions" />}/>
        {/* <Route path='/price-list/:id' element={<PriceListViewer/>} /> */}
      </>
      }

      {role==="account" &&
      <>
        <Route path='/statistiques' element={<StatistiquesAccount/>} />  
        <Route path='/contact' element={<ContactPage/>} />  
        <Route path='/mon_profil' element={<MonProfil/>} />
        <Route path="/liste_des_expeditions" element={<Navigate replace to="/liste_des_expeditions/0" />}/>
        <Route path='/liste_des_expeditions/:id' element={<ListeDesExpeditions/>} />
        <Route path='/liste_des_expeditions/:id/:id' element={<ListeDesExpeditions/>} />
        <Route path='/mes_sous-comptes' element={<MesSousComptes/>} />
        <Route path='/subaccount/:id' element={<SubAccountPage/>} />
        <Route path='/create_subaccount/' element={<CreateSubAccountAccount/>} />
        <Route path='/facturation' element={<Facturation/>} />
        <Route path="/" element={<Navigate replace to="/statistiques" />}/>
      </>
      }

      {role==="sub_account" &&
      <>
        <Route path='/statistiques' element={<StatistiquesSubAccount/>} />  
        <Route path='/carnet_d_adresses' element={<CarnetdAdresses/>} />  
        <Route path='/mon_profil' element={<MonProfilSubAccount/>} />
        <Route path="/liste_des_expeditions" element={<Navigate replace to="/liste_des_expeditions/0" />}/>
        <Route path='/liste_des_expeditions/:id' element={<ListeDesExpeditions/>} />
        <Route path='/liste_des_expeditions/:id/:id' element={<ListeDesExpeditions/>} />
        <Route path='/demande_de_ramassage' element={<DemandeDeRamassage/>} />
        <Route path='/demande_d_une_expedition' element={<DemandeUneExpedition/>} />
        <Route path='/facturation' element={<Facturation/>} />
        
        <Route path="/" element={<Navigate replace to="/statistiques" />}/>
      </>
      }

    </Routes>
  );
}

export default Main;