import React from "react"

import {ReactComponent as ArrowDown} from "../../assets/arrowDown.svg"

import { useLocation } from "react-router-dom";
import { OutTable } from 'react-excel-renderer';
import { readFile } from "@ramonak/react-excel";

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
function PriceListViewer  ()  {   
    const location = useLocation ()
    
    const path2 = location.pathname.split ("/")
    const blobId = path2[path2.length-2]
    const fileName = path2[path2.length-1]
    const [blobFile, setBlobFile] = React.useState ({} as Blob)
    
    const [cols, setCols] = React.useState ({})
    const [rows, setRows] = React.useState ({})
    const [data, setData] = React.useState(undefined as any);
    async function getFile (){
        const blob = await fetch("blob:"+window.location.href.replace (location.pathname, "")+"/" + blobId).then(r => r.blob())
        setBlobFile (blob)
        readFile (blob)
            .then((readedData: any) => {setData(readedData)})
            .catch((error: any) => console.error(error));
    }
        
    function downloadFile (){
        const fileURL = URL.createObjectURL(blobFile);
        const tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.setAttribute('download', fileName);
        tempLink.click();
    }
    function getCoordinates (coordinates:string){
        var letter = 0
        var number = ""
        var index = 0
        for (let i=coordinates.length-1; i>-1; i-=1) {
            const indexLetter = alphabet.indexOf (coordinates[i]) + 1

            if (indexLetter!==0){
                letter += indexLetter*(26**index)
                index = index + 1
            } else {
                number = coordinates[i] + number
            }
        }
        return [+number-1, letter-1]
    }
       
    React.useEffect (()=>{
        if (data!==undefined) {
            var tempRows = {}
            var tempCols = {}
            const tableName = data.SheetNames
            setNameList (tableName)
            setSelectedTable (tableName[0])
            for (let i=0; i<tableName.length; i++){
                const key1 = tableName[i]
                tempRows[key1]= new Array<any> () 
                tempCols[key1]= new Array<any> () 
                const positionList = Object.keys (data.Sheets[key1])
                for (let j=0; j<positionList.length; j++){
                    const key2 = positionList[j]
                    if (key2[0]!=="!"){
                        const c = getCoordinates (key2)
                        if (tempRows[key1][c[0]]===undefined){
                            tempRows[key1][c[0]] = new Array<any> ()
                        }
                        tempRows[key1][c[0]][c[1]] = data.Sheets[key1][key2]["w"]
                        
                        if (tempCols[key1].length<c[1]+1){
                            for (let i=tempCols[key1].length; i<=c[1]; i++){
                                if (alphabet[i]!==undefined) {
                                    tempCols[key1].push ({name: alphabet[i], key: i})
                                } else {
                                    tempCols[key1].push ({name: "A", key: i})
                                }
                            }
                        }
                    }
                }

            }
            setRows (tempRows)
            setCols (tempCols)
        }
    }, [data])

    React.useEffect (()=>{ 
        getFile () // eslint-disable-next-line
    }, [])
    const [nameList, setNameList] = React.useState (new Array<string> ())
    const [selectedTable, setSelectedTable] = React.useState ("")

    return (
        <div style={{padding:"0px 20px 40px 20px", display:"flex", flexDirection:"column", position:"relative"}}>
            <div style={{display:"flex", flexDirection:"column",  width:"100%", alignContent:"center", alignItems:"center", marginLeft:"auto", marginRight:"auto", }}>
                <h2>{selectedTable}</h2>
                <div style={{overflow:"auto", display:"flex", alignContent:'stretch',float:"right", maxWidth:"100%", maxHeight:"100%", marginBottom:30}}>
                    {rows[selectedTable]!==undefined  && <OutTable data={rows[selectedTable]} columns={cols[selectedTable]} tableClassName="ExcelTable2007" tableHeaderRowClass="heading"/>}
                </div>
            </div>
            <div style={{position:'fixed', display:"flex", flexDirection:"row", bottom:0, left:0, backgroundColor:"white", justifyContent:"space-around", cursor:"pointer", overflowY:"scroll", padding:"0px 20px", maxWidth:"100%",  alignContent:'stretch'}}>
                {nameList.map ((element:string)=>
                    <p style={{cursor:"pointer", opacity:selectedTable===element?"100%":"60%", minWidth:180}} onClick={()=>setSelectedTable (element)}>{element}</p>
                )}
            </div>
            <div style={{position:"absolute", top:0, right:0, padding:10}}>
            
                    <div className="Change-Color-Hover" style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"left", border:"2px solid #747474", borderRadius:8, padding:"8px 12px", cursor:"pointer", userSelect:"none", width:"max-content"}} onClick={()=>downloadFile ()}>
                        {/* <img src={Pdf} alt='' style={{height:20, resize:"none", marginRight:10}} draggable={false}/> */}
                        <ArrowDown style={{ height:20,width:20, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", flex:2, marginRight:10, fill:"#747474"}}/>
                        <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left", margin:0}}>Télécharger la grille</p>
                    </div>
                
            </div>
        </div>
    )
}
export default PriceListViewer;