import React from "react";
import { Tooltip } from "@mui/material";
import { WeekActivity } from "../types";
import { getDateInLitteral } from "../utils/getDateInLitteral";

const days = ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."]

function getDay (n:string):number{
    const a = new Date (n).getDay ()
    if (a<0){
        n += 7
    }
    return a
}

function Bargraph (props:{data:Array<WeekActivity>, style?:{}}) {

    const [max, setMax] = React.useState (0)
    React.useEffect (()=>{
        var pointer = props.data[0]
        for (let i=1; i<props.data.length; i++){
            if (pointer.expeditionNumber<props.data[i].expeditionNumber){
                pointer = props.data[i]
            }
        }
        setMax (pointer.expeditionNumber) // eslint-disable-next-line
    }, [props.data])
    
    
    
    return (
        <div style={{position:"relative", minHeight:max*15, minWidth:"min-content", width:"100%", ...props.style, borderTop: "1px solid #aeaeae"}}>
            <div style={{position:"absolute", width:"100%", height:"90%", display:"grid", gridTemplateColumns:"100%", justifyContent:"space-evenly", zIndex:1}}>
                {Array.from(Array(max).keys()).map ((index:number)=>
                    <div key={index} style={{borderBottom:"1px solid #aeaeae", borderTop:index===0?"1px solid #aeaeae":undefined, borderBottomRightRadius:index===max-1?20:undefined, borderBottomLeftRadius:index===max-1?20:undefined }}></div>
                )}
            </div>
            <div style={{ zIndex:2, height:"90%", display:"flex", flexDirection:"row-reverse", justifyContent:"space-evently", position:"relative", borderBottom: "1px solid #aeaeae", borderBottomLeftRadius:20, borderBottomRightRadius:20}}>
                {props.data.map ((element:WeekActivity, index:number)=>
                    <Tooltip title={element.expeditionNumber} style={{zIndex:3}} key={index}>
                        <div style={{height:"100%", borderRight:"1px solid #aeaeae", borderLeft:index===props.data.length-1?"1px solid #aeaeae":undefined, alignItems:"flex-end", display:"flex", width:"100%", justifyContent:"center", borderBottomRightRadius:index===0?20:0, borderBottomLeftRadius:20}}>
                            <div style={{ height:`${element.expeditionNumber/max*100}%`, width:20, backgroundColor:"#deae5f", opacity:0.8, transition: "height 0.5s"}}></div>
                        </div>
                    </Tooltip>
                )}
            </div>
            {/* <p>{JSON.stringify (props.data)}</p> */}
            <div style={{ zIndex:2, width:"100%", height:"10%", display:"flex", flexDirection:"row-reverse", justifyContent:"space-around", position:"relative"}}>
                {props.data.map ((element:WeekActivity, index:number)=>
                    <Tooltip title = {getDateInLitteral (element.date)} key={index}>
                        {/* <p style={{margin:0, fontSize:13, fontWeight:500}}>{days[getDay (todayDay-7+index)]}</p> */}
                        <p style={{margin:0, fontSize:13, fontWeight:500}}>{days[getDay (element.date)]}</p>
                    </Tooltip>
                )}
            </div>
            
        </div>
    );
}
export default Bargraph