import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const TextInput = styled(TextField, {
    shouldForwardProp:() => true
  })<{ bordercolor?: string, width?: string|number}>(({ bordercolor, width }) => ({
    width: width!==undefined ? width : "80%",
    zIndex:0,
    margin: "0px 0px 20px 0px",
    " & label.Mui-focused": {
        color: bordercolor,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: bordercolor,
          borderWidth:3,
          borderRadius: 10,
          
        },
        '&:hover fieldset': {
          borderColor: bordercolor,
        },
        '&.Mui-focused fieldset': {
          borderColor: bordercolor,
        },
        '&.Mui-disabled fieldset': {
          borderColor: bordercolor,
        },
      },
  }));
