import { Backdrop, Tooltip } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import {ReactComponent as Info} from "../../../assets/info.svg";
import {ReactComponent as ArrowDown} from "../../../assets/arrowDown.svg"
import {ReactComponent as ArrowUp} from "../../../assets/arrowUp.svg"

export function FileHandler ({isOpen, setIsOpen, file, setFile, handleChange}:{isOpen:boolean, setIsOpen:Function, file:any, setFile:Function, handleChange:Function}) {
    const refFileInput = React.useRef () as React.MutableRefObject<HTMLInputElement>;
    return (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpen} onClick={()=>setIsOpen (false)}>
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 400px)", padding:20}}>
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                        <h2 style={{ margin:0 }}>Demande d'expédition avec un fichier</h2>
                        <Tooltip title="Il faut remplir le fichier téléchargeable grâce au premier bouton et le remplir entre 1 et 200 lignes supplémentaires pour envoyer des expéditions à la chaine">
                            <Info style={{width:30, resize:"none", height:"min-content", margin:10}}/>
                        </Tooltip>
                    </div>
                    
                    <input accept=".xlsx" type="file" ref={refFileInput} onChange={ (e) => handleChange(e.target.files) } style={{width:0, margin:0, visibility:"hidden"}}/>
                    <div style={{display:"flex", flexDirection:"row", gap:20}}>
                        <Link to="/files/massExpedition.xlsx" target="_blank" download className="Cadre-blanc Grow-Hover" style={{ padding:10, boxSizing:"unset", display:"flex", alignItems:"center", justifyContent:"center", justifyItems:"center",flex:1, cursor:"pointer", textDecoration:"none", color:"black"}}>
                            {/* <Plus style={{margin:"0px 10px 0px 20px"}}/> */}
                            <ArrowDown style={{width:30, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", margin:"0px 10px 0px 20px"}}/>
                            <p style={{margin:"0px 20px 0px 0px"}}>Télécharger le fichier à remplir</p>
                            {/* <Link to="/files/massExpedition.xlsx" target="_blank" download>Download</Link> */}
                        </Link>
                        <div className="Cadre-blanc Grow-Hover" style={{ padding:10, boxSizing:"unset", display:"flex", alignItems:"center", justifyContent:"center", justifyItems:"center",flex:1, cursor:"pointer"}} onClick={()=>refFileInput.current.click ()}>
                            {/* <Plus style={{margin:"0px 10px 0px 20px"}}/> */}
                            <ArrowUp style={{width:30, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", margin:"0px 10px 0px 20px"}}/>
                            <p style={{margin:"0px 20px 0px 0px"}}>{file===null?"Upload un fichier":file.name}</p>
                        </div>
                    </div>
                    <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", marginTop:20}}>
                        <div className=" Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setIsOpen (false);setFile (null as unknown as File)}}>
                            <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                        </div>
                        {file!==null && <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setIsOpen (false);setFile (null as unknown as File)}}>
                            <p style={{color:"white", margin:0}}>Confirmer</p>
                        </div>}
                        
                    </div>
                </div>
            </div>
        </Backdrop>
    );
}