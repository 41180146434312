import { Autocomplete } from "@mui/material";
import { PlaceResponse, PlaceResponseFeature, PlaceResponseFeatureProperties } from "../types";
import React from "react";
import { TextInput } from "./input";
import { widthContext } from "../context/token";
const GOUV_ADRESSE_ENDPOINT = "https://api-adresse.data.gouv.fr/search/?"
export function SearchAddresses ({address, setAddress, setZipCode, setCity, setCountry, isOpen, setIsOpen, style, title}:{address:string, setAddress:Function, setZipCode:Function, setCity:Function, setCountry:Function, isOpen:boolean, setIsOpen:Function, style?:Object, title?:string}){
    const width = React.useContext (widthContext)

    async function searchFunction(search: string){
        if (search.length>3){
            const lien = GOUV_ADRESSE_ENDPOINT + new URLSearchParams({"q": search})
            const response = fetch (lien, {
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                }
              })
            const resp:PlaceResponse = await (await response).json();
            const temp:any = resp.features.map ((element:PlaceResponseFeature) => element.properties)
            setResult (temp)
            return resp
        } else {
            setResult (new Array<PlaceResponseFeatureProperties> ())
    
        }
    }
    const [result, setResult] = React.useState<Array<PlaceResponseFeatureProperties>> ([])
    return (
        <Autocomplete
            freeSolo={true}
            style={style==null?{width:width>780?"80%":"100%"}:style}
            disableClearable={true}
            options={result.map ((option)=>option)}
            autoComplete={true}
            open={isOpen}
            onOpen={()=>setIsOpen (true)}
            onClose={()=>setIsOpen (false)}
            noOptionsText="Pas d'adresses"
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.name}
            filterOptions={(x) => x}
            inputValue={ address }
            onInputChange={async (_event, newInputValue) => {setAddress(newInputValue)}}
            disableCloseOnSelect={false}
            renderOption={(_props, option) => (
                <div 
                    className="Change-Color-Hover"
                    onClick={()=>{
                        setAddress (option.name); 
                        setZipCode (option.postcode)
                        setCity (option.city)
                        setCountry ("France")
                        setIsOpen (false);
                    }}
                    style={{width:width>780?"100%":"100%", cursor:"pointer", padding:10, boxSizing:"border-box"}}
                    key={option.id}
                    
                >
                    <p style={{margin:0}} key={option.id}>{option.name} ({option.postcode} - {option.city})</p>
                </div>
            )}
            renderInput={(params) => (
                <TextInput
                    {...params}
                    onChange={async (event)=>await searchFunction(event.target.value)}
                    bordercolor="#f0ba61"
                    label={title?title:"Adresse Postale"}
                    width={"100%"}
                />
            )}
        />
    )
}