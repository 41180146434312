import React from "react";

import { UserToken, UserSetToken, url, widthContext } from "../../context/token";

import { Carrier } from "../../types";
// Images
// import transporteur from "../../assets/livraison-de-colis.svg"
import hand from "../../assets/livraison.svg"
import TNT from "../../assets/TNT.png"
import UPS from "../../assets/UPS.png"
import DHL from "../../assets/DHL.png"

import { isStillConnected } from "../../utils/areYouStillConnected";

import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import { CircularProgress, Pagination } from "@mui/material";
import { Connexion } from "../../utils/connexion";

function ProfilsDesTransporteurs  ()  {
    // Pour gérer l'authentification
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)

    ////////////////////////////////

    // Pour gérer la liste des expéditions, et le système de pagination
    const indexList = ["TNT", "UPS", "DHL"]
    const logoList = [TNT, UPS, DHL]
    
    const [carrierList, setCarrierList] = React.useState (
        new Array<Carrier> ()
    ) 
    const [pageNb, setPageNb] = React.useState (1)
    

    const [filteredList, setFilteredList] = React.useState (carrierList)
    const reductedList = filteredList.slice ((pageNb-1)*20, (pageNb*20))

  
    ///////////////////////////////////////////////////////////////////

   
    // Pour gérer les filtres

    const [carrierNameFiltre, setDateFiltre] = React.useState ("");
    const [internationalDeliveryFiltre, setInternationalDeliveryFiltre] = React.useState (false)
    
    // var livraisonList = carrierList.map(item => item["deliveryTime"]).filter((value, index, self) => self.indexOf(value) === index)
    var livraisonList = [5]
    const [LivraisonFiltre, setLivraisonFiltre] = React.useState ("");
    function handleChangeLivraisonFiltre (event:any) {
        setLivraisonFiltre ("")
        setLivraisonFiltre (event.target.value)
    }
    
    React.useEffect (()=>{
        var temp = carrierList.slice ()
        
        if (carrierNameFiltre!==""){
            temp = temp.filter((obj) => {return obj["name"].toString ().slice (0, carrierNameFiltre.length) === carrierNameFiltre}) 
        } 
        if (internationalDeliveryFiltre!==false){
            temp = temp.filter((obj) => {return obj["international"]===internationalDeliveryFiltre}) 
        } 
        setFilteredList (temp)
    }, [carrierList, carrierNameFiltre, internationalDeliveryFiltre])
    ///////////////////////////////////////////////

    // Pour gérer l'obtention de la liste des transporteurs
    const [isLoading, setIsLoading] = React.useState (true)
    async function getCarrier () {
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/carrier', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token["accessToken"]}`
            },
        });
        const json = await (await response).json();
        // const json:Array<Carrier> = [
        //     {
        //         id:            "23895732-d046-4577-acbc-f9b55713a5e4",
        //         name:          "TNT",
        //         international: true,
        //         deliveryTime:  5,
        //         tarifPath:     ""
        //     },
        //     // {
        //     //     id:            "memeform-atme-mesi-care-ntrepasdutou",
        //     //     name:          "Transporteur",
        //     //     international: false,
        //     //     deliveryTime:  12,
        //     //     tarifPath:     ""
        //     // }
        // ]
        setFilteredList (json)
        setCarrierList (json)
        setIsLoading (false)
    }

    
    React.useEffect (()=>{
        Connexion ()
        getCarrier ()
         // eslint-disable-next-line
    }, [])
    /////////////////////////////////////////////////

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////


    const [isHover3, setIsHover3] = React.useState (false)

    const [tryToFilter, setTryToFilter] = React.useState (false)
    function removeAllFilter (){
        setDateFiltre ("")
        setInternationalDeliveryFiltre (false)
    }

    // Component Transporteur à boucler
    function CarrierComponent (props:{element:Carrier, index:number}){
        const element:Carrier = props.element
        const indexLogo = indexList.indexOf (element.name)

        return (
            <div className="table3 hoverClickable" key={element["id"]}  style={{cursor:"pointer", width:"100%", margin:0, padding:width>780?"":0, boxSizing:"border-box", paddingBottom:20, paddingTop:20}} onClick={()=>navigate ("/profils_des_transporteurs/"+element["id"].toString())}>
                <div style={{display:"flex", flexDirection:width>1200?"row":"column", width:"100%"}}>
                    <div style={{display:"flex", flexDirection:width>780?'row':"column", width:width>1200?"70%":"100%", justifyContent:width>780?"space-evenly":"left"}}>
                        <div className="table4" style={{ alignItems:"center",  justifyContent:"left", display:"flex", flexDirection:"row"}}>
                            <img src={logoList[indexLogo]} alt='' style={{width:60, resize:"none", margin:12}} draggable={false}/>
                            <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left"}}>{element.name}</p>
                        </div>
                    
                    
                        <div className="table4" style={{ justifyItems:"center", display:"flex", flexDirection:"row",justifyContent:"left", alignItems:"center", width:"unset"}}>
                            <img src={hand} alt='' style={{height:60, resize:"none", margin:12}} draggable={false}/>
                            <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left"}}>Livraison 5h</p>
                            {/* {element.deliveryTime} */}
                        </div>
                    </div>

                    
                </div>
                
            </div>
        )
    }
    /////////////////////////////////////////////////////////////

    
    // Handle the main header size to adapt
    const [headerHeight, setHeaderHeight] = React.useState (60)
    React.useEffect(() => {
        let header = document.getElementById("header");
        if (header) {
            setHeaderHeight (header.clientHeight)
        }
      }, [])
    ////////////////////////////////////////////////


    const navigate = useNavigate ()
    return (
        <>
        
        <div className="table2" style={{marginBottom:"3vh", padding:0, margin:"0px 20px"}}>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%", justifySelf:'center', position:"sticky", top:headerHeight+40, zIndex:3, pointerEvents: "none", userSelect:"none"}} >
                <div style={{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition" >
                    <p>Liste des transporteurs</p>
                </div>
            </div>
                

            <div className="table" style={{display:"flex", width:"100%", margin:"20px 0px"}}>
                {!isLoading ? <>
                {carrierList.length>0?
                <>
                <div style={{ display:"flex" ,alignItems:"flex-end", flexDirection:"row-reverse"}}>
                    
                    {width<1000 &&
                        <div onClick={()=>setTryToFilter(true)} style={{display:"flex", flexDirection:"column",  alignItems:"center", margin:30, width:"min-content"}} >
                            <div className="test Input-password" style={{display:"flex", flexDirection:"row", alignItems:"center", width:"max-content"}} onMouseEnter={()=>setIsHover3 (true)} onMouseLeave={()=>setIsHover3 (false)}>
                                <p style={{marginLeft:20, marginRight:20, fontWeight:500}}>Filtrer</p>
                            </div>
                            <div  style={{width:"100%", height:3, backgroundColor:isHover3?"#f9b233":"#747474"}}></div>
                        </div>
                    }

                    
                </div>
                {width>1000 &&
                    <div style={{display:"flex" , flexDirection:"row-reverse", width:"90%", alignSelf:"center"}}>
                        
                        <select value={LivraisonFiltre} className='Change-Color-Hover' onChange={handleChangeLivraisonFiltre} style={{cursor:livraisonList.length>1?"pointer":"default", backgroundColor:livraisonList.length>1?"":"white", marginBottom:10, marginTop:10, borderRadius:10, border: "3px solid #7d7d7d", padding:15, width:110}} disabled={livraisonList.length<=1}>
                            <option value="">Livraison</option>
                            {livraisonList.map ((element, index)=>
                                <option value={element} key={index}>{element}</option> 
                                )}
                        </select>                        
                        <div style={{ display:"flex", flexDirection:"row", alignItems:"center", justifySelf:"center", margin:20, marginBottom:10, marginTop:10, borderRadius:10, border: "3px solid #7d7d7d", cursor:"pointer"}} onClick={()=>setInternationalDeliveryFiltre (!internationalDeliveryFiltre)}>
                            <Checkbox checked={internationalDeliveryFiltre} onChange={()=>setInternationalDeliveryFiltre (!internationalDeliveryFiltre)}/>
                            <p style={{margin:"0px 20px 0px 0px"}}>International</p>
                        </div>
                    </div>
                }
                {width<1000 && tryToFilter &&
                <div style={{display:"flex" ,justifyItems:"center", flexDirection:"column", width:"100vw",height:"100vh", top:0,alignSelf:"center", position:"fixed", backgroundColor:"white", zIndex:5, justifyContent:"flex-start", borderRadius:30, padding:20, boxSizing:"border-box", overflowY:'auto',}}>
                    <h2>Filtres</h2>                    
                  
                    <select value={LivraisonFiltre} className='Change-Color-Hover' onChange={handleChangeLivraisonFiltre} style={{cursor:livraisonList.length>1?"pointer":"default", backgroundColor:livraisonList.length>1?"":"white", marginBottom:10, marginTop:10, borderRadius:10, border: "3px solid #7d7d7d", padding:15, width:"80%", alignSelf:"center"}} disabled={livraisonList.length<=1}>
                        <option value="">Livraison</option>
                        {livraisonList.map ((element, index)=>
                            <option value={element} key={index}>{element}</option> 
                        )}
                    </select>  
                    <div style={{ display:"flex", flexDirection:"row", alignItems:"center",alignSelf:"center", justifySelf:"center", margin:20, marginBottom:10, marginTop:10, borderRadius:10, border: "3px solid #7d7d7d", width:"80%", cursor:"pointer"}} onClick={()=>setInternationalDeliveryFiltre (!internationalDeliveryFiltre)}>
                        <Checkbox checked={internationalDeliveryFiltre} onChange={()=>setInternationalDeliveryFiltre (!internationalDeliveryFiltre)}/>
                        <p style={{margin:"0px 20px 0px 0px"}}>International</p>
                    </div>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                    <span className="table" style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center"}} onClick={()=> {removeAllFilter()}}>
                        <p className="table" style={{color:"white"}}>Supprimer tous les filtres</p>
                    </span>
                    <span className="table" style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center"}}  onClick={()=> {setTryToFilter(false)}}>
                        <p className="table" style={{color:"white"}}>Appliquer</p>
                    </span>
                </div>
                </div>
                }
                {reductedList.map ((element:Carrier, index:number) =>
                    <div key={index} style={{display:"grid" ,justifyItems:"center", flexDirection:"column", width:"100%", position:"relative"}}>
                        <CarrierComponent element={element} index={index}/>
                        
                        {index!==reductedList.length-1 &&
                            <div style={{width:"90%", alignSelf:"center", justifySelf:"center", height:3, backgroundColor:'#9a9a9a', borderRadius:10}}></div>
                        }
                        
                    </div>
                )}       
            
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", marginTop:10}}>
                    {/* <span className="table" style={pageNb>1?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}} onClick={()=> {goToPreviousPage()}}>
                        <p className="table" style={{color:"white"}}>Page précédente</p>
                    </span>
                    <p style={{cursor:"default"}}>{pageNb}/{Math.floor((filteredList.length-1)/20)+1}</p>
                    <span className="table" style={pageNb<filteredList.length/20?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}}  onClick={()=> {goToNextPage()}}>
                        <p className="table" style={{color:"white"}}>Page suivante</p>
                    </span> */}
                    <Pagination count={filteredList.length!==0? Math.floor((filteredList.length-1)/20)+1:1} size="large"  onChange={(_event, number)=>setPageNb (number)}/>
                </div>
                </>:<p>Pas de données à afficher</p>
                }
                </>
                :
                <CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>
                }
            </div>
        </div>
        
        
    </>
    )
}
export default ProfilsDesTransporteurs;