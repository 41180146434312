import React from "react"

import { Backdrop, Pagination } from "@mui/material";
import { Bill } from "../../types";
import { TextInput } from "../../components/input";
import { UserToken, UserSetToken, url, widthContext } from "../../context/token";
import { isStillConnected } from "../../utils/areYouStillConnected";
import Facture from "./facture";
import { decodeToken } from "react-jwt";
import FactureLoading from "./factureLoading";
import { CreateFacturation } from "./createFacturation";

export default function FacturationPage({subaccountId}:{subaccountId?:string}) {
    
    // handle authentification
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)
    
    let decodedToken:any = decodeToken (token["accessToken"]) 
    const role = decodedToken["role"]

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////

    // Gérer la liste des factures
    const [factureList, setFactureList] = React.useState (new Array<Bill> ()) 

    const [pageNbFactureList, setPageNbFactureList] = React.useState (1)
    
    const [filteredFactureList, setFilteredFactureList] = React.useState (factureList)
    const reductedFactureList = filteredFactureList.slice ((pageNbFactureList-1)*20, (pageNbFactureList*20))

    const [isLoading, setIsLoading] = React.useState (true)
    const [isLoading2, setIsLoading2] = React.useState (true)
    async function getBills(){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        setIsLoading (true)
        setIsLoading2 (true)
        const response = fetch (url + "/billing", {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
            }
        })
        const json:Array<Bill> = await (await response).json ()
        setIsLoading2 (false)
        setFactureList (json)
        setFilteredFactureList (json)
        window.setTimeout(()=>setIsLoading (false), 2000)
    }

    React.useEffect (()=>{
        !isLoading2 && setIsLoading (false) // eslint-disable-next-line
    }, [factureList])

    React.useEffect (()=>{
       getBills ()// eslint-disable-next-line
    }, [])
    //////////////////////////////////////////////////////

    // Filtres
    const [tryToFilter, setTryToFilter] = React.useState (false)
    const [isHoverFilterButton, setIsHoverFilterButton] = React.useState (false)
    function removeAllFilter (){
        setNameFilter ("")
        setDateFilter ("")
        setMonthFilter ("")
    }

    const [nameFilter, setNameFilter] = React.useState ("")
    const [dateFilter, setDateFilter] = React.useState ("")
    const [monthFilter, setMonthFilter] = React.useState ("")

    React.useEffect (()=>{
        var temp = factureList.slice ()
        if (nameFilter!==""){
            temp = temp.filter((obj) => {return obj.subAccount.name.slice (0, nameFilter.length).localeCompare(nameFilter, undefined, { sensitivity: 'base' })===0}) 
        }
        if (dateFilter!==""){
            temp = temp.filter((obj) => {return obj.receiptDate.toString ().slice (0, dateFilter.length) === dateFilter}) 
        }
        if (monthFilter!==""){
            temp = temp.filter((obj) => {return obj.billingDate.toString ().slice (0, monthFilter.length) === monthFilter}) 
        }
        setFilteredFactureList (temp)
    }, [factureList, nameFilter, dateFilter, monthFilter])
    ///////////////////////////////////////////////////////////

    const [tryToFacture, setTryToFacture] = React.useState (false)
    return (
        <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
            <div className="Cadre-blanc" style={{width:"100%", padding:10, boxSizing:"border-box", margin:"0px 20px 20px 20px"}}>

                {width>1000 &&
                    <div style={{display:"flex" ,justifyItems:"center", flexDirection:"row", alignSelf:"center", userSelect:"none", justifyContent:"space-evenly", width:"100%", gap:20}}>
                        
                        {role!=="sub_account" && <div style={{ flex:1}}>
                            <TextInput width="100%" label="Nom du sous-compte" bordercolor="#747474" value={nameFilter}  onChange={(props)=>setNameFilter (props.target.value)} />
                        </div>}
                        <div style={{cursor:"pointer", flex:1}}>
                            <TextInput width="100%" label={"Date de facturation"} bordercolor="#747474" value={dateFilter} onChange={(props)=>setDateFilter (props.target.value)} type="date" InputProps={{startAdornment:<></>}}/>
                        </div>
                        <div style={{cursor:"pointer", flex:1}}>
                            <TextInput width="100%" label={"Date d'échéance"} bordercolor="#747474" value={monthFilter} onChange={(props)=>setMonthFilter (props.target.value)} type="date" InputProps={{startAdornment:<></>}}/>
                        </div>
                    </div>
                }
                {width<1000 && tryToFilter &&
                    <Backdrop open={tryToFilter}  sx={{ zIndex: (theme) => theme.zIndex.drawer + 3 }} >
                    <div style={{display:"flex" ,justifyItems:"center", flexDirection:"column",alignSelf:"center", position:"fixed", backgroundColor:"white", zIndex:5, justifyContent:"center", borderRadius:30, padding:20, boxSizing:"border-box", overflowY:'auto', userSelect:"none"}}>
                        <h2>Filtres</h2>
                        <TextInput width="100%" label="Nom du sous-compte" bordercolor="#747474" value={nameFilter}  onChange={(props)=>setNameFilter (props.target.value)} />
                        
                        <TextInput width="100%" label="Date de facturation" type="date" bordercolor="#747474" value={dateFilter}  onChange={(props)=>setDateFilter (props.target.value)}/>
                        <TextInput width="100%" label="Date d'échéance" type="date" bordercolor="#747474" value={monthFilter}  onChange={(props)=>setMonthFilter (props.target.value)}/>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", boxSizing:"border-box", width:"100%", gap:20}}>
                            <span style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center", flex:1, borderRadius:15}} onClick={()=> {removeAllFilter()}}>
                                <p style={{color:"white"}}>Supprimer tous les filtres</p>
                            </span>
                            <span  style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center", flex:1, borderRadius:15}}  onClick={()=> {setTryToFilter(false)}}>
                                <p style={{color:"white"}}>Appliquer</p>
                            </span>
                        </div>
                    </div>
                    </Backdrop>
                }
                {width<=1000 &&
                    <div onClick={()=>setTryToFilter(true)} style={{display:"flex", flexDirection:"column",  alignItems:"center", margin:30, width:"min-content"}} >
                        <div className="test Input-password" style={{display:"flex", flexDirection:"row", alignItems:"center", width:"max-content"}} onMouseEnter={()=>setIsHoverFilterButton (true)} onMouseLeave={()=>setIsHoverFilterButton (false)}>
                            <p style={{marginLeft:20, marginRight:20, fontWeight:500}}>Filtrer</p>
                        </div>
                        <div style={{width:"100%", height:3, backgroundColor:isHoverFilterButton?"#f9b233":"#747474"}}></div>
                    </div>
                }
                
                {!isLoading ?<>
                    {factureList.length>0?
                    <>
                        {reductedFactureList.map ((element:Bill, index:number) =>
                            <div key={index} style={{display:"flex", justifyItems:"center", flexDirection:"column", width:"100%", position:"relative", boxSizing:"border-box", alignContent:"center", alignItems:"center"}}>
                                
                                <Facture element={element}/>
                                {index!==reductedFactureList.length-1 &&
                                    <div style={{width:"90%", alignSelf:"center", justifySelf:"center", height:3, backgroundColor:'#9a9a9a', borderRadius:10, marginTop:10, marginBottom:10}}></div>
                                }
                                
                            </div>
                        )}    

                        {!(filteredFactureList.length<=20) && 
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", userSelect:"none"}}>                        
                            <Pagination count={filteredFactureList.length!==0? Math.floor((filteredFactureList.length-1)/20)+1:1} size="large"  onChange={(_event, number)=>setPageNbFactureList (number)}/>
                        </div>}
                    </>:<p>Pas de données à afficher</p>
                    }
                </> 
                :
                <>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map ((index:number) =>
                        <div key={index} style={{display:"flex", justifyItems:"center", flexDirection:"column", width:"100%", position:"relative", boxSizing:"border-box", alignContent:"center", alignItems:"center"}}>
                            <FactureLoading/>
                            {index!==reductedFactureList.length-1 &&
                                <div style={{width:"90%", alignSelf:"center", justifySelf:"center", height:3, backgroundColor:'#9a9a9a', borderRadius:10, marginTop:10, marginBottom:10}}></div>
                            }
                                
                        </div>
                    )}    
                </>
                }
                 <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>

                    {role==="admin" && <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:"3px 10px", pointerEvents: "visible"}} onClick={()=>console.log ("fq")}>
                        <p>Nouvelle facture</p>
                    </div>}


                </div>
                {role==="admin" && subaccountId!==undefined &&
                    <CreateFacturation isOpen={tryToFacture} setIsOpen={setTryToFacture} subaccountId={subaccountId} refresh={console.log}/>
                }
            </div>
        </div>
        )
}