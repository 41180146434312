import React from "react";
import Position from "../../../assets/Position.png"
import {ReactComponent as CodePostaIMG} from "../../../assets/code-postal.svg";
import Pen from "../../../assets/pen.png"
import Skeleton from '@mui/material/Skeleton';
import { widthContext } from "../../../context/token";


export default function PersonLoading () {

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext) 
    ////////////////////////////////////////

    return (
        <div style={{ display:"flex", gap:10, flexDirection:"column"}}>

            <div style={{ display:"flex", gap:width>=1024?30:10, flexDirection:width>=1024?"row":"column", gridTemplateColumns:width>=1024?"15% 85%":"100%", boxSizing:"border-box", width:"100%", position:"relative", marginBottom:10}}>

                <div className="Cadre-blanc" style={{flex:2, display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"row", margin:0, padding:0, position:"relative"}}>
                    {/* <p style={{fontWeight:700, color:"#464645", fontSize:20, margin:"10px 0px", overflow:"auto", textOverflow:"ellipsis"}}>{contact.companyName}</p> */}
                    {/* <Skeleton variant="text" sx={{ ùfontSize: "2rem", width:"50%" }} /> */}
                    <p style={{fontWeight:700, color:"#464645", fontSize:20, margin:"10px 0px", overflow:"auto", width:width>=1024?"50%":"50vw"}}><Skeleton variant="text" sx={{ width:width>=1024?"100%":"50vw", fontSize:"20px"}} /></p>
                    {width<1024 && <div style={{position:"absolute", right:20, alignSelf:"center", justifySelf:"center", alignItems:"center"}}>
                        <img src={Pen} alt="" style={{objectFit:"contain", width:20, resize:"none"}}/>
                    </div>}
                </div>

                

                <div className="Cadre-blanc" style={{display:"flex", flexDirection:"column", flex:6, color:"#575757", position:"relative", textAlign:"left", padding:width>=1024?undefined:10, boxSizing:"border-box", margin:0}}>
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:30}}>
                        <img src={Position} style={{width:30, resize:"none", height:30}} alt=""/>
                        <p><Skeleton variant="text" sx={{ width:width>=1024?"30vw":"50vw"}} /></p>
                        
                    </div>
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:30}}>
                        <CodePostaIMG style={{width:30, height:30, resize:"none"}}/>
                        <p><Skeleton variant="text" sx={{ width:width>=1024?"30vw":"50vw"}} /></p>
                        {/* <p>{contact.address.zip}, {contact.address.city}  ({contact.address.country})</p> */}
                    </div>
                    
                    {width>=1024 && <div style={{position:"absolute", right:10, bottom:0, textAlign:"center"}}>
                        <p style={{backgroundColor:"#f7f7f7", padding:"5px 10px", borderRadius:7, cursor:"pointer", userSelect:"none"}}>Voir plus</p>
                        <p style={{backgroundColor:"#f7f7f7", padding:"5px 10px", borderRadius:7, cursor:"pointer", userSelect:"none"}}>Modifier</p>
                        <p style={{padding:"0px 10px", visibility:"hidden", height:0, margin:0}}>Voir moins</p>
                    </div>}
                </div>

            </div>
        </div>
    );
}
