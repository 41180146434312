import React from "react";
import Person from "../pages/sub_account/carnetdAdresses/person"
import { Backdrop, Pagination } from "@mui/material";
import { UserToken, UserSetToken,url, widthContext } from "../context/token";
import { TextInput } from "./input";
import { Contact } from "../types";
import { isStillConnected } from "../utils/areYouStillConnected";
import PersonLoading from "../pages/sub_account/carnetdAdresses/personLoading";
import { Connexion } from "../utils/connexion";

function ContactSelection  (props:{choose:Function, isOpen:boolean, setIsOpen:Function}){

    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)

    async function getInfo (){
        setIsLoading (true)
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/contact', {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            }
          })
        const json = await (await response).json();
        setContacts (json)
        setIsLoading (false)
    }
    const [isLoading, setIsLoading] = React.useState (true)
    React.useEffect (()=>{
        Connexion () 
        getInfo ()
        // eslint-disable-next-line
    }, [])
    ////////////////////////////////////////////////////////
    

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////

    const revealed = React.useState <string> ("")

    const [contacts, setContacts] = React.useState (new Array<Contact> ())

    const [pageNb, setPageNb] = React.useState (1)
    const [filteredContacts, setFilteredContact] = React.useState (new Array<Contact> ())
    const reductedList = filteredContacts.slice ((pageNb-1)*10, (pageNb*10))

    const [filter, setFilter] = React.useState ("")
    React.useEffect (()=>{
        var temp = contacts.slice ()
        if (filter!==""){
            temp = temp.filter((obj) => {return obj.companyName.slice (0, filter.length).localeCompare(filter, undefined, { sensitivity: 'base' })===0}) 
        }
        setFilteredContact (temp)
    }, [filter, contacts])

    return (
        <Backdrop open={props.isOpen} onClick={()=>props.setIsOpen (false)} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
            <div style={{ left: "50%", top:width<1200?"0%":"10%"}}>
                
                <div style={{ left: "-50%", top:"-50%", border: "dotted #deae5f 1px", padding:30, width:width<1200?"95vw":"80vw", paddingLeft:0, paddingRight:0, maxHeight:"80vh", overflow:"auto", display:"grid"}} className="Cadre-blanc" onClick={(e)=>e.stopPropagation ()}>
                    
                    <p style={{margin:10, fontSize:25, fontWeight:500, justifySelf:"center"}}>Choisissez une adresse</p>
                    <div style={{ height:"100%", display:"grid", margin:"0px 20px"}}>
                        <div style={{width:"100%", justifySelf:"center"}}>
                            <div style={{marginTop:30, width:width>780?"30%":"100%"}}>
                                <TextInput width="100%" label="Rechercher un contact" bordercolor="#f0ba61" sx={{backgroundColor:"#fff", borderRadius:3}} value={filter} onChange={(e)=>setFilter (e.currentTarget.value)}/>
                            </div>
                        </div>
                        {!isLoading ? <div style={{width:"100%", justifySelf:"center"}}>
                            {contacts.length>0?
                                <>
                                    {reductedList.map ((element:any)=>
                                        // <p>{JSON.stringify (element)}</p>
                                        <Person contact={element} functions={revealed} key={element.id} refresh={getInfo} real={true} choose={props.choose}/>
                                    )}
                                    <div style={{width:"100%", justifyContent:"center", display:"flex"}}>
                                        <Pagination count={filteredContacts.length!==0? Math.floor((filteredContacts.length-1)/10)+1:1} size="large"  onChange={(_event, number)=>setPageNb (number)}/>
                                    </div>
                                </>
                                :
                                <>    
                                    {/* Contact type pour ne pas laisser la page vide si il n'y a pas encore de contact */}
                                    <p>Aucun contact à sélectionner</p>
                                </>
                            }
                        </div>
                        : 
                        <div style={{width:"100%", justifySelf:"center"}}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map (()=>
                                <PersonLoading/>
                            )}
                            <div style={{width:"100%", justifyContent:"center", display:"flex"}}>
                                <Pagination count={filteredContacts.length!==0? Math.floor((filteredContacts.length-1)/10)+1:1} size="large"  onChange={(_event, number)=>setPageNb (number)}/>
                            </div>
                        </div>
                    }

                    </div>
                    {/* <div style={{display:"grid", gridTemplateColumns:"100%", justifyContent:"center", padding:30, paddingBottom:0, paddingTop:20, backgroundColor:"blue", position:"absolute", bottom:0, right:0}}>
                        <div style={{cursor:"pointer", backgroundColor:"#deae5f", textAlign:"center", borderRadius:20, width:"min-content", padding:"5px 20px"}} className="Grow-Hover" onClick={()=>props.setIsOpen (false)}>
                            <p style={{ color:"white", width:"100%", height:"100%"}}>Annuler</p>
                        </div>
                    
                    </div> */}
                    <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>

                        <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:"3px 10px", pointerEvents: "visible", textAlign:"center"}} onClick={()=>props.setIsOpen (false)}>
                            <p>Annuler</p>
                        </div>

                        </div>
                </div>
            </div>
        </Backdrop>         
    )
}
export default ContactSelection