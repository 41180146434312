import React from "react";
import Position from "../../../assets/Position.png"
import {ReactComponent as CodePostaIMG} from "../../../assets/code-postal.svg";
import {ReactComponent as Bin} from "../../../assets/bin.svg";
import Collapse from '@mui/material/Collapse';
import { TextInput } from "../../../components/input";
import { Autocomplete, Backdrop } from "@mui/material";
import { PlaceResponseFeatureProperties, PlaceResponse, PlaceResponseFeature, ServerResponse } from "../../../types";
import Pen from "../../../assets/pen.png"
import Check from "../../../assets/yellowCheck.svg"
import { UserSetToken, UserToken, url, widthContext } from "../../../context/token";
import { isStillConnected } from "../../../utils/areYouStillConnected";

const GOUV_ADRESSE_ENDPOINT = "https://api-adresse.data.gouv.fr/search/?"

export default function Person (props:any) {
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////

    const [revealed, setRevealed] = props.functions
    
    const contact = props.contact

    const [companyName, setCompanyName] = React.useState (contact.companyName)
    const [address1, setAddress1] = React.useState (contact.address.streetLine1)
    const [address2, setAddress2] = React.useState (contact.address.streetLine2)
    const [zip, setZip] = React.useState (contact.address.zip)
    const [city, setCity] = React.useState (contact.address.city)
    const [country, setCountry] = React.useState (contact.address.country)

    

    const [contactName, setContactName] = React.useState (contact.contactName)
    const [reference, setReference] = React.useState (contact.reference!==null?contact.reference:"")
    const [mail, setMail] = React.useState (contact.email)
    const [telephone, setTelephone] = React.useState (contact.phone)
    const [specialInstruction1, setspecialInstruction1] = React.useState (contact.specialInstruction1!==null?contact.specialInstruction1:"")
    const [specialInstruction2, setspecialInstruction2] = React.useState (contact.specialInstruction2!==null?contact.specialInstruction2:"")

    const [isUpdatingContact, setIsUpdatingContact] = React.useState (false)
    const [result, setResult] = React.useState<Array<PlaceResponseFeatureProperties>> ([])
    const [isAutoCompleteOpen, setIsAutoCompleteOpen] = React.useState (false)
    async function searchFunction(search: string){
        if (search.length>3){
            const lien = GOUV_ADRESSE_ENDPOINT + new URLSearchParams({"q": search})
            const response = fetch (lien, {
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                }
              })
            const resp:PlaceResponse = await (await response).json();
            const temp:any = resp.features.map ((element:PlaceResponseFeature) => element.properties)
            setResult (temp)
            return resp
        } else {
            setResult (new Array<PlaceResponseFeatureProperties> ())
    
        }
    }

    const didSomethingChange =  companyName!==contact.companyName ||
                                address1 !== contact.address.streetLine1 ||
                                address2 !== contact.address.streetLine2 ||
                                zip !== contact.address.zip ||
                                city !== contact.address.city ||
                                country !== contact.address.country ||
                                contactName !== contact.contactName ||
                                (contact.reference!==null && reference !== contact.reference) ||
                                (contact.reference===null && reference !== "") ||
                                mail !== contact.email ||
                                telephone !== contact.phone ||
                                (contact.specialInstruction1!==null && specialInstruction1 !== contact.specialInstruction1) ||
                                (contact.specialInstruction1===null && specialInstruction1 !== "") ||
                                (contact.specialInstruction2!==null && specialInstruction2 !== contact.specialInstruction2) ||
                                (contact.specialInstruction2===null && specialInstruction2 !== "")

    function cancelChanges (){
        setIsUpdatingContact (false)
        setCompanyName (contact.companyName)
        setAddress1 (contact.address.streetLine1)
        setAddress2 (contact.address.streetLine2)
        setZip (contact.address.zip)
        setCity (contact.address.city)
        setCountry (contact.address.country)
        setContactName (contact.contactName)
        setMail (contact.email)
        setTelephone (contact.phone)
        setspecialInstruction1 (contact.specialInstruction1)
        setspecialInstruction2 (contact.specialInstruction2)
    }

    async function updateContact (){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = await fetch (url + '/contact/' + contact.id, {
            method: 'PATCH',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`,
            },
            body: JSON.stringify ({
                "contactName": contactName,
                "companyName": companyName,
                "email": mail, 
                "phone": telephone,
                "reference": reference,
                "specialInstruction1": specialInstruction1,
                "specialInstruction2": specialInstruction2,
                "address": {
                    "streetLine1": address1,
                    "streetLine2": address2,
                    "city": city,
                    "zip": +zip,
                    "country": country
                }
            })
          })
        
        if (!response.ok){
            const json:ServerResponse = await (response).json();
            if (typeof json.message!=="string"){
                setError (json.message)
            } else {
                setError ([json.message])
            }
            
        } else {
            window.location.reload();
        }
    }
    
    const [error, setError] = React.useState (new Array<string> ())
    const [isRemovingContact, setIsRemovingContact] = React.useState (false)
    async function removeContact (){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        setIsRemovingContact (false)
        setIsUpdatingContact (false)
        await fetch (url + '/contact/' + contact.id, {
            method: 'DELETE',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`,
            }
          })
        props.refresh ()
    }

    return (
        <div style={{ display:"flex", gap:10, flexDirection:"column"}}>

            <div style={{ display:"flex", gap:width>=1024?30:10, flexDirection:width>=1024?"row":"column", gridTemplateColumns:width>=1024?"15% 85%":"100%", boxSizing:"border-box", width:"100%", position:"relative"}} onClick={()=>{if (width<1024){contact.id===revealed?setRevealed (""):setRevealed (contact.id)}}}>

                <div className="Cadre-blanc" style={{flex:2, display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"row", margin:0, padding:0, position:"relative"}}>
                    <p style={{fontWeight:700, color:"#464645", fontSize:20, margin:"10px 0px", overflow:"auto", textOverflow:"ellipsis"}}>{contact.companyName}</p>
                    {width<1024 && <div style={{position:"absolute", right:20, alignSelf:"center", justifySelf:"center", alignItems:"center"}} onClick={()=>props.real?props.choose (contact):setIsUpdatingContact (true)}>
                        {props.real ?
                            <img src={Check} alt='' style={{objectFit:"contain", width:20, resize:"none"}} draggable={false}/>
                            :
                            <img src={Pen} alt="" style={{objectFit:"contain", width:20, resize:"none"}}/>
                        }
                    </div>}
                </div>

                

                <div className="Cadre-blanc" style={{display:"flex", flexDirection:"column", flex:6, color:"#575757", position:"relative", textAlign:"left", padding:width>=1024?undefined:10, boxSizing:"border-box", margin:0}}>
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:30}}>
                        <img src={Position} style={{width:30, resize:"none", height:30}} alt=""/>
                        <p>{contact.address.streetLine1} {contact.address.streetLine2}</p>
                    </div>
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:30}}>
                        <CodePostaIMG style={{width:30, height:30, resize:"none"}}/>
                        <p>{contact.address.zip}, {contact.address.city}  ({contact.address.country})</p>
                    </div>
                    
                    {width>=1024 && <div style={{position:"absolute", right:10, bottom:0, textAlign:"center"}}>
                        <p style={{backgroundColor:"#f7f7f7", padding:"5px 10px", borderRadius:7, cursor:"pointer", userSelect:"none"}} onClick={()=>revealed===contact.id?setRevealed(""):setRevealed (contact.id)}>Voir {revealed===contact.id?"moins":"plus"}</p>
                        {props.real && <p style={{backgroundColor:"#f7f7f7", padding:"5px 10px", borderRadius:7, cursor:"pointer", userSelect:"none"}} onClick={()=>props.choose (contact)}>Choisir</p>}
                        {(props.real !== true) && <p style={{backgroundColor:"#f7f7f7", padding:"5px 10px", borderRadius:7, cursor:"pointer", userSelect:"none"}} onClick={()=>setIsUpdatingContact (true)}>Modifier</p>}
                        <p style={{padding:"0px 10px", visibility:"hidden", height:0, margin:0}}>Voir moins</p>
                    </div>}
                </div>

            </div>

            <Collapse in={revealed===contact.id} style={{width:"100%", alignSelf:"center"}}>
                <div className="Cadre-blanc" style={{justifySelf:"center", padding:20, marginBottom:30, width:"100%", boxSizing:"border-box"}}>
                    <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                        <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom du destinataire" value={contactName} onChange={(value)=>setContactName(value.target.value)} disabled sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>
                        <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Référence" value={reference} onChange={(value)=>setReference(value.target.value)} disabled sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>
                    </div>
                    <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                        <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Mail" value={mail} onChange={(value)=>setMail(value.target.value)} disabled sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>
                        <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Téléphone" value={telephone} onChange={(value)=>setTelephone(value.target.value)} disabled sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>
                    </div>
                    <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                        <TextInput style={{marginBottom:0}} bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Instruction particulière 1" value={specialInstruction1} onChange={(value)=>setspecialInstruction1(value.target.value)} disabled sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>
                        <TextInput style={{marginBottom:0}} bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Instruction particulière 2" value={specialInstruction2} onChange={(value)=>setspecialInstruction2(value.target.value)} disabled sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>
                    </div>
                </div>
            </Collapse>

            <Backdrop open={isUpdatingContact} onClick={()=>!didSomethingChange && cancelChanges ()} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div style={{height:"min-content", width:"min (100%, 300px)", maxHeight:"80vh", overflow:"hidden", borderRadius:20}}>
                        <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 300px)", padding:20, maxHeight:"80vh", overflow:"auto", position:"relative"}}>
                            <div className="BinHover" style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", position:"absolute", top:0, right:10, cursor:"pointer", fontStyle:"italic", fontWeight:"400"}} onClick={()=> props.real!==false && setIsRemovingContact (true)}>
                                <Bin style={{height:30,width:30, resize:"none", alignSelf:"center", cursor:"pointer"}}/>
                                <p >Supprimer le contact</p>
                            </div>
                            <h1>Modifier {contact.companyName}</h1>
                            <div style={{zIndex:0, display:"flex", flexDirection:"column", alignItems:"center", width:"100%", alignSelf:"flex-start"}}>
                                <TextInput bordercolor="#f0ba61" width="100%" label="Nom de l'entreprise" value={companyName} onChange={(value)=>setCompanyName(value.target.value)} disabled={props.real===false}/>

                                <Autocomplete
                                    freeSolo={true}
                                    style={{width:"100%"}}
                                    disableClearable={true}
                                    options={result.map ((option)=>option)}
                                    autoComplete={true}
                                    open={isAutoCompleteOpen}
                                    onOpen={()=>setIsAutoCompleteOpen (true)}
                                    onClose={()=>setIsAutoCompleteOpen (false)}
                                    noOptionsText="Pas d'addresses"
                                    getOptionLabel={(option) =>
                                        typeof option === 'string' ? option : option.name}
                                    filterOptions={(x) => x}
                                    inputValue={ address1 }
                                    onInputChange={async (_event, newInputValue) => {setAddress1(newInputValue)}}
                                    disableCloseOnSelect={false}
                                    renderOption={(_props, option) => (
                                        <div 
                                            className="Change-Color-Hover"
                                            onClick={()=>{
                                                setAddress1 (option.name); 
                                                setZip (option.postcode)
                                                setCity (option.city)
                                                setCountry ("France")
                                                setIsAutoCompleteOpen (false);
                                            }}
                                            style={{width:width>780?"100%":"100%", cursor:"pointer", padding:10, boxSizing:"border-box"}}
                                            key={option.id}
                                            
                                        >
                                            <p style={{margin:0}} key={option.id}>{option.name} ({option.postcode} - {option.city})</p>
                                        </div>
                                    )}
                                    renderInput={(params) => (
                                        <TextInput
                                            {...params}
                                            onChange={async (event)=>await searchFunction(event.target.value)}
                                            bordercolor="#f0ba61"
                                            label="addresse postale"
                                            width="100%"
                                            disabled={props.real===false}
                                        />
                                    )}
                                />
                                <TextInput bordercolor="#f0ba61" width="100%" label="Indication postale supplémentaire" value={address2} onChange={(value)=>setAddress2(value.target.value)}  disabled={props.real===false}/>
                            
                                <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Code postal" value={zip} onChange={(value)=>setZip (value.target.value)} disabled={props.real===false}/>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom de la ville" value={city} onChange={(value)=>setCity(value.target.value)} disabled={props.real===false}/>   
                                </div>
                                <TextInput bordercolor="#f0ba61" width="100%" label="Pays" value={country} onChange={(value)=>setCountry(value.target.value)} disabled={props.real===false}/>
                            </div>
                            <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom du destinataire" value={contactName} onChange={(value)=>setContactName(value.target.value)} disabled={props.real===false}/>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Référence" value={reference} onChange={(value)=>setReference(value.target.value)} disabled={props.real===false}/>
                                {/* <div style={{position:"absolute", width:"100%", height:"100%"}}></div> */}
                            </div>
                            <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Mail" value={mail} onChange={(value)=>setMail(value.target.value)} disabled={props.real===false}/>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Téléphone" value={telephone} onChange={(value)=>setTelephone(value.target.value)} disabled={props.real===false}/>
                            </div>
                            <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                <TextInput style={{marginBottom:0}} bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Instruction particulière 1" value={specialInstruction1} onChange={(value)=>setspecialInstruction1(value.target.value)} disabled={props.real===false}/>
                                <TextInput style={{marginBottom:0}} bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Instruction particulière 2" value={specialInstruction2} onChange={(value)=>setspecialInstruction2(value.target.value)} disabled={props.real===false}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column-reverse", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>
                            
                    <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:"20vw", pointerEvents: "visible"}} onClick={()=>cancelChanges ()}>
                        <p>Annuler</p>
                    </div>

                    {didSomethingChange && <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:"3px 10px", pointerEvents: "visible"}} onClick={()=>updateContact ()}>
                        <p>Sauvegarder</p>
                    </div>}
                    
                </div>
            </Backdrop>

            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isRemovingContact} onClick={()=>setIsRemovingContact (false)}>
                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 400px)", padding:20}}>
                        <h2 style={{ marginBottom:20}}>Voulez vous vraiment supprimer ce contact ?</h2>
                        
                        <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
                            <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setIsRemovingContact (false)}}>
                                <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                            </div>
                            <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {removeContact ()}}>
                                <p style={{color:"white", margin:0}}>Confirmer</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Backdrop>


            <Backdrop open={error.length!==0} onClick={()=>!didSomethingChange && setIsUpdatingContact (false)} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
                <div style={{position: "fixed", left: "50%", top:"10%"}}>
                    <div className='logout' style={{position: "relative", left: "-50%", top:"-50%", border: "dotted #deae5f 1px", padding:30, width:450, paddingLeft:0, paddingRight:0}}>
                        
                        <p style={{margin:10, fontSize:25, fontWeight:500}}>Erreur</p>
                        <div style={{display:'flex', justifyContent:"center", width:"100%"}}>
                            <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                {error.map ((element, index)=>
                                    <div key={index} style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"100%"}} >
                                        
                                        <p style={{margin:0, textAlign:"left", marginLeft:20, width:"70%"}}>{element}</p>
                                        
                                    </div>
                                )}
                            </div>
                            
                        </div>
                        
                        <div style={{display:"grid", gridTemplateColumns:"100%", justifyContent:"center", padding:30, paddingBottom:0, paddingTop:20}}>
                            <div style={{cursor:"pointer", backgroundColor:"#deae5f", margin:10, textAlign:"center", borderRadius:20, marginBottom:0,marginRight:10}} className="Grow-Hover" onClick={()=>setError(new Array<string> ())}>
                                <p style={{ color:"white", width:"100%", height:"100%"}}>Continuer</p>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </Backdrop>
        </div>
    );
}
