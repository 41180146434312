import React from "react";
import { Tooltip } from "@mui/material";
import { LastWeeksActivity } from "../types";
// const list=["blue", "brown", "purple", "green", "grey", "pink", "orange", "violet"]

function Graph (props:{data:Array<LastWeeksActivity>, style?:{}}) {

    function getMax (array:Array<LastWeeksActivity>):number{
        var pointer = array[0]
        for (let i=1; i<array.length; i++){
            if (pointer<array[i]){
                pointer = array[i]
            }
        }
        return pointer.expeditionNumber
    }
    const [max, setMax] = React.useState (0)
    React.useEffect (()=>{
        setMax (getMax (props.data)) // eslint-disable-next-line
    }, [])

    return (
        <div style={{minHeight:DataTransfer.length*20, minWidth:"min-content", width:"100%", ...props.style}}>
            <div style={{ height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-evenly", overflow:"clip", borderLeft:"2px solid black", width:"100%"}}>
                {props.data.map ((element:LastWeeksActivity, index:number)=>
                    <div key={index} style={{height:"100%", alignItems:"center", display:"flex", width:"100%", justifyContent:"flex-start"}}>
                        <Tooltip title={element.expeditionNumber} style={{zIndex:3}} placement="right">
                            <div style={{ width:`${element.expeditionNumber/max*100}%`, height:20, backgroundColor:"#deae5f", opacity:0.8, position:"relative", display:"flex", alignItems:"center", justifyContent:"flex-end", transition: "width 0.5s"}}>week {element.weekNumber!==0 && "-"}{element.weekNumber} : {element.expeditionNumber}
                                <div style={{borderRight:"1px dashed #aeaeae", borderLeft:"1px dashed #aeaeae", position:"absolute", right:0, top:0, height:"100vh"}}></div>
                            </div>
                        </Tooltip>
                    </div>
                )}
            </div>
            <div style={{marginBottom:30, width:"100%", backgroundColor:"black", height:2, position:"relative", display:"flex", alignItems:"center"}}>
                {props.data.map ((element, index:number)=>
                    <Tooltip title={element.expeditionNumber} style={{zIndex:3}} key={index}>
                        <div style={{borderRadius:"100%", position:"absolute", width:6, height:6, left:`${element.expeditionNumber/max*100}%`, backgroundColor:"#deae5f", transform: "translate(-30%, 0%)"}}></div>
                    </Tooltip>
                )}
            </div>
        </div>
    );
}
export default Graph