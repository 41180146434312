import { Backdrop } from "@mui/material";

export function ErrorList ({title, errorArray, close}:{title?:String, errorArray:Array<String>, close:()=>void}) {
    return (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={errorArray.length!==0} onClick={close}>
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 400px)", padding:20}}>
                    <h2 style={{ marginBottom:20}}>{title?title:"Erreur"}</h2>
                    
                    <div style={{display:"flex", flexDirection:"column", width:"100%", maxHeight:"40vh", overflowY:"scroll"}}>
                        {errorArray.map ((element, index)=>
                            <div key={index} style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"100%"}} >
                                
                                <p style={{margin:0, textAlign:"left", marginLeft:20, width:"70%"}}>{element}</p>
                                
                            </div>
                        )}
                    </div>
                    <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
                        <div className="Change-Color-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={close}>
                            <p style={{color:"#deae5f"}}>Continuer</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </Backdrop>
    );
}