import React from "react";

import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

import { Bill } from "../../types";
import { getDateInLitteral } from "../../utils/getDateInLitteral";
import { UserSetToken, UserToken, url, widthContext } from "../../context/token";

import { isStillConnected } from "../../utils/areYouStillConnected";
import { decodeToken } from "react-jwt";

import {ReactComponent as Bonhomme} from "../../assets/bonhomme.svg";
import {ReactComponent as Colis} from "../../assets/colis.svg";
import {ReactComponent as Calendar1} from "../../assets/calendar1.svg";
import {ReactComponent as Calendar2} from "../../assets/calendar2.svg";
import {ReactComponent as Price} from "../../assets/price.svg";
import {ReactComponent as Eye} from "../../assets/eye.svg"
import {ReactComponent as ArrowDown} from "../../assets/arrowDown.svg"
import {ReactComponent as ArrowUp} from "../../assets/arrowUp.svg"
import {ReactComponent as Bin} from "../../assets/bin.svg";
import {ReactComponent as Plus} from "../../assets/plus.svg";

export default function Facture (props:{element:Bill}) {

     // handle authentification
     const token = React.useContext (UserToken)
     const setToken = React.useContext (UserSetToken)
     
    let decodedToken:any = decodeToken (token["accessToken"]) 
    const role = decodedToken["role"]

    React.useEffect (()=>{
        console.log (role)
    }, [role])
    // Pour obtenir les PDF des étiquettes
    async function getPDF (mode:number){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        setDontHaveToSelectFile (true)
        setIsLoading (true)
        setIsPopupOpen (true)
        const response = fetch (url + '/billing/doc/' + props.element.billingPath, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
                },
            });
        const url2 = (await (await response).json()).url;
        const response2 = fetch (url2, {
            method: 'GET',
        });
        const file = new Blob(
            [await (await response2).blob()],
            {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        if (mode===0) {
            window.open(fileURL);
        } else {
            // window.open(fileURL);
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', props.element.billingPath);
            tempLink.click();
            window.URL.revokeObjectURL(fileURL);
            tempLink.remove();
        }
        setIsLoading (false)
        setDontHaveToSelectFile (false)
        setIsPopupOpen (false)
    }
    ///////////////////////////////////////

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////

    
    // Gère l'affichage des expéditions concernées
    function ToolTip2 (e:{children, style?:{}}){
        const [isHover, setIsHover] = React.useState (false)
        return (
            <div style={{...e.style, position:"relative"}} onMouseLeave={()=>setIsHover (false)} onMouseEnter={()=>setIsHover (true)}>
                {e.children}
                {isHover && <div>
                    {props.element.relatedExpeditions.map ((expedition)=>
                        <div className="Cadre-blanc" style={{position:"absolute", left:0, width:"max-content", padding:"0px 10px", bottom:"-100%", transform:"translate (100%, 100%);", maxWidth:"70vw", zIndex:3}}>
                            <div style={{width: 0, height: 0, border: "solid 30px", borderColor: "transparent transparent white transparent", position:"absolute", bottom:"100%", left:"10%"}}></div>
                            <Link style={{textDecoration:"none"}} to={"/liste_des_expeditions/" + expedition.id}><p style={{color:"black"}}>{expedition.id} : {expedition.price}€</p></Link>
                        </div>
                    )}
                </div>}
            </div>
        )
    }

    const [isHoverBin, setIsHoverBin] = React.useState (false)
    const [tryToRemove, setTryToRemove] = React.useState (false)

    const [isPopupOpen, setIsPopupOpen] = React.useState (false)
    const [dontHaveToSelectFile, setDontHaveToSelectFile] = React.useState (false)
    const [isLoading, setIsLoading] = React.useState (false)
    const [resultText, setResultText] = React.useState ("")

    const filePickerRef = React.useRef () as React.MutableRefObject<HTMLInputElement>;
    const [newBillFile, setNewBillFile] = React.useState (null as unknown as File)
    
    function handleChangeSolo (e:any){
        if (e!==null){
            setNewBillFile (e.target.files[0])
        } else {
            setNewBillFile (null as unknown as File)
        }
    }
    async function uploadPDF (){
        
        setIsLoading (true)
        setDontHaveToSelectFile (true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token["accessToken"]}`);

        var formdata = new FormData();
        formdata.append("file", newBillFile);

        var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata
        }
        // console.log (url + "/price-list/" + (isMulti?"multi/":"single/") + carrierId)
        await fetch(url + "/billing/doc/" + props.element.id, requestOptions)
        .then(response => response.text())
        .then(__result => {setResultText("Succès de l'upload"); })
        .catch(error => {setResultText (error);});
        setIsLoading (false)
        // setIsUploading (false)
        
    }
    return (
        <div style={{ display:"flex", gap:10, flexDirection:"column", width:"100%", justifyContent:"left", justifyItems:"left", alignItems:"center", maxWidth:"100vw"}}>

            <div style={{ display:"flex", gap:width>=1200?20:10, flexDirection:width>=1200?"row":"column", gridTemplateColumns:width>=1200?"15% 85%":"100%", boxSizing:"border-box", width:"100%", position:"relative", alignItems:width>1200?"center":"flex-start", padding:width>1200?undefined:20, justifyContent:"space-between"}}>

                {role!=="sub_account" && <Link to={"/subaccount/" + props.element.subAccountId} style={{textDecoration:"none", textAlign:"left",  display: "flex", flexDirection:"row", alignItems:"center", flex:1, justifySelf:"left"}}>
                    
                    <Bonhomme style={{ justifySelf:"center", resize:"none", width:50, margin:5}}/>
                    <div style={{display:"flex", flexDirection:width>1200?"column":"column-reverse"}}>
                        <span style={{color:"#747474", fontSize:22, fontWeight:700}}>{props.element.subAccount.name}</span>
                        <span style={{color:"#747474"}}>Sous-compte {width<=1200 && ":"}</span>
                    </div>
                    
                </Link>}
                <ToolTip2 style={{textAlign:"left",  display: "flex", flexDirection:"row", alignItems:"center", flex:0.5, justifySelf:"left"}}>
                    <Colis style={{ justifySelf:"center", resize:"none", width:50, margin:5}}/>
                    <div style={{display:"flex", flexDirection:width>1200?"column":"column-reverse"}}>
                        <span style={{color:"#747474", fontSize:22, fontWeight:700}}>{props.element.relatedExpeditions.length}</span>
                        <span style={{color:"#747474"}}>Expedition{props.element.relatedExpeditions.length>1 && "s"} {width<=1200 && ":"}</span>
                    </div>
                </ToolTip2>

                <div style={{textAlign:"left",  display: "flex", flexDirection:"row", alignItems:"center", flex:1.5}}>
                    <Calendar1 style={{ justifySelf:"center", resize:"none", width:50, margin:5, flex:1 }}/>
                    <div style={{display:"flex", flexDirection:width>1200?"column":"column-reverse", flex:4}}>
                        <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{getDateInLitteral (props.element.receiptDate)}</span>
                        <span style={{color:"#747474"}}>Date de facturation {width<=1200 && ":"}</span>
                    </div>
                </div>

                <div style={{textAlign:"left",  display: "flex", flexDirection:"row", alignItems:"center", flex:1.5}}>
                    <Calendar2 style={{ justifySelf:"center", resize:"none", width:50, margin:5, height:50, flex:1 }}/>
                    <div style={{display:"flex", flexDirection:width>1200?"column":"column-reverse", flex:4}}>
                        <span style={{color:"#747474", fontSize:22, fontWeight:700}}>{getDateInLitteral (props.element.billingDate)}</span>
                        <span style={{color:"#747474"}}>Date d'échéance {width<=1200 && ":"}</span>
                    </div>
                </div>

                <div style={{display:"flex", flexDirection:"row", flex:2, width:width>1200?undefined:"100%", justifyItems:width<1200?"space-between":"center"}}>
                    <div style={{textAlign:"left", display: "flex",  flexDirection:"row", alignItems:"center", flex:1}}>
                        <Price/>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <span style={{color:"#747474", fontSize:30, fontWeight:700, marginBottom:0}}>{props.element.amount*1.2}</span>
                                <span style={{color:"#e7ac4f", fontWeight:600, margin:5}}>€</span>
                            </div>
                            <span style={{color:"#747474"}}>TTC</span>
                        </div>
                    </div>
                    
                    
                    <div style={{textAlign:"left", display: "flex",  flexDirection:"row", alignItems:"center", flex:1}}>
                        <Price/>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <span style={{color:"#747474", fontSize:30, fontWeight:700, marginBottom:0}}>{props.element.amount}</span>
                                <span style={{color:"#e7ac4f", fontWeight:600, margin:5}}>€</span>
                            </div>
                            <span style={{color:"#747474"}}>HT</span>
                        </div>
                    </div>
                </div>
                
                {width>1200 &&
                    <div style={{flex:0.5, display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-evenly"}}>
                        
                        <div style={{display:'flex', flexDirection:"column", width:"50%", alignContent:"space-between", justifyContent:"space-between"}}>
                            {role==="admin" && <Tooltip title={props.element.billingPath!==null?"Écraser la facture":"Upload une facture"}>
                                    <div style={{width:50, height:50, display:"flex", alignContent:"center", justifyContent:"center"}}>
                                        <ArrowUp onClick={()=>setIsPopupOpen (true)} className="IconHover" style={{ height:35,width:35, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", flex:1}}/>
                                    </div>
                            </Tooltip>}

                            {(props.element.billingPath!==null || role!=="admin") && <Tooltip title={props.element.billingPath!==null?"Télécharger la facture":""} style={{flex:1}}>
                                <div style={{ width:50, height:50, visibility:props.element.billingPath!==null?"visible":"hidden"}}>
                                    <ArrowDown onClick={()=>getPDF (1)} className="IconHover" style={{ height:35,width:35, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", flex:1}}/>
                                </div>
                            </Tooltip>}
                        </div>
                        
                        <div style={{display:'flex', flexDirection:"column", width:"50%", alignContent:"space-between", justifyContent:"space-between"}}>
                            {role==="admin" &&
                                <Tooltip title={`Supprimer la facture "${props.element.billingPath}"`} style={{flex:1}}>
                                    <Bin style={{height:50,width:50, resize:"none", alignSelf:"center", justifySelf:"center", fill:isHoverBin?"#FA1205":"#a2a2a2", cursor:"pointer"}} onClick={(e)=>{setTryToRemove(true);e.stopPropagation ()}} onMouseEnter={()=>setIsHoverBin(true)} onMouseLeave={()=>setIsHoverBin(false)}/>
                                </Tooltip>
                            }
                            {(props.element.billingPath!==null || role!=="admin") && <Tooltip title={props.element.billingPath!==null?"Aperçu de la facture":""} style={{flex:1}}>
                                <div style={{ width:50, height:50}}>
                                    <Eye onClick={()=>getPDF (0)} className="IconHover" style={{height:35,width:35, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", visibility:props.element.billingPath!==null?"visible":"hidden"}}/>
                                </div>
                            </Tooltip>}
                        </div>
                    </div>
                }
                {width<=1200 &&
                <div onClick={()=>getPDF (0)} className="Change-Color-Hover" style={{ flex:1, display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"left", border:"2px solid #747474", borderRadius:8, padding:"8px 12px", cursor:"pointer", userSelect:"none", width:"max-content", boxSizing:"border-box", alignSelf:"center"}}>
                {/* <img src={Pdf} alt='' style={{height:20, resize:"none", marginRight:10}} draggable={false}/> */}
                <ArrowDown style={{ height:20,width:20, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", flex:2, marginRight:10, fill:"#747474"}}/>
                <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left", margin:0}}>Télécharger la facture</p>
                </div>
                }

                {width<=1200 && role==="admin" &&
                <div onClick={()=>setTryToRemove (true)} className="Change-Color-Hover" style={{ flex:1, display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"left", border:"2px solid #747474", borderRadius:8, padding:"8px 12px", cursor:"pointer", userSelect:"none", width:"max-content", boxSizing:"border-box", alignSelf:"center"}}>
                {/* <img src={Pdf} alt='' style={{height:20, resize:"none", marginRight:10}} draggable={false}/> */}
                <Bin style={{ height:20,width:20, resize:"none", alignSelf:"center", justifySelf:"center", cursor:"pointer", flex:2, marginRight:10, fill:"#747474"}}/>
                <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left", margin:0}}>Supprimer la facture</p>
                </div>
                }

                
                <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={tryToRemove} onClick={()=>setTryToRemove (false)}>
                    <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 300px)", padding:20}}>
                            <h2 style={{ margin:0, marginBottom:20}}>Supprimer la facture "{props.element.billingPath} ?</h2>                                
                            
                            <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
                                <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setTryToRemove (false)}}>
                                    <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                                </div>
                                <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setTryToRemove (false)}}>
                                    <p style={{color:"white", margin:0}}>Confirmer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Backdrop>
            </div>

            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isPopupOpen} onClick={()=>{setIsPopupOpen (false);  setNewBillFile (null as unknown as File)}}>
                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 300px)", padding:20, display:"grid", gridTemplateColumns:"100%"}}>
                        {!dontHaveToSelectFile?
                        <>
                            <h2 style={{ margin:0, marginBottom:0}}>{props.element.billingPath!==null?"Ecraser":"Upload"} une facture</h2>                                

                            <div onClick={()=>filePickerRef.current.click ()} className="Cadre-blanc Grow-Hover" style={{ margin:20, padding:10, boxSizing:"border-box", display:"flex", alignItems:"center", justifyContent:"center", justifyItems:"center",flex:1, cursor:"pointer", width:"100%", justifySelf:"center"}}>
                                <Plus style={{margin:"0px 10px 0px 20px"}}/>
                                <p style={{margin:"0px 20px 0px 0px"}}>{newBillFile===null?"Choisir un fichier":newBillFile.name}</p>
                                <input width="0%" type="file" onChange={(e)=>handleChangeSolo (e)} accept=".pdf" ref={filePickerRef} style={{width:0, height:0}}/>
                            </div>
                            
                            <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
                                <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setIsPopupOpen (false)}}>
                                    <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                                </div>
                                <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {uploadPDF ()}}>
                                    <p style={{color:"white", margin:0}}>Confirmer</p>
                                </div>
                            </div>
                        </>
                        :
                        !isLoading?
                            <>
                                <p>{resultText}</p>
                                <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {window.location.reload ()}}>
                                    <p style={{color:"white", margin:0}}>OK</p>
                                </div>
                            </>
                            :
                            <CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>}
                    </div>
                </div>
            </Backdrop>

        </div>
    );
}
