import React from 'react';
import { UserToken, UserSetToken } from "./context/token";
import  Connected from './router/connected';
import Connexion from './router/connexion';
import { useCookies } from 'react-cookie'

import { useNavigate, useLocation } from 'react-router-dom';
import { isExpired } from "react-jwt";
import ForgotPassword from './router/notInRouter/forgotPassword';
import CreateUser from './router/createUser';
import PriceListViewer from './router/notInRouter/priceListViewer';
import { CircularProgress } from '@mui/material';

function App() {
  const token = React.useContext (UserToken)
  const setToken = React.useContext (UserSetToken)
  const [cookies, ] = useCookies(['accessToken', 'refreshToken'])


  const navigate = useNavigate ()
  let location = useLocation();
  
  const [isLoaded, setIsLoaded] = React.useState (false)
  
  React.useEffect (() => {
    if (cookies["accessToken"]!=="" && cookies["refreshToken"]!=="" && 
        cookies["accessToken"]!==undefined && cookies["refreshToken"]!==undefined &&
        !isExpired (cookies["accessToken"]) && !isExpired (cookies["refreshToken"]) ) {
      setToken ({"accessToken":cookies["accessToken"], "refreshToken":cookies["refreshToken"]})
      
      
    } else {
      if (token["refreshToken"]==="" && location.pathname!=="/" && location.pathname.slice(0,21)!=="/auth/reset-password/" && location.pathname.slice (0, 13)!=="/create_user/" && location.pathname.slice (0, 12)!=="/price-list/") {
        console.log ()
        navigate ("/")
      }
    }
    setIsLoaded (true)
    // eslint-disable-next-line
  }, [])
  if (isLoaded){
  if (location.pathname.slice(0,21)==="/auth/reset-password/") {
    return (
      <ForgotPassword/>
    )
  } else if (location.pathname.slice (0, 13)==="/create_user/"){
    return (
      <CreateUser/>
    )
  } else if (location.pathname.slice (0, 12)==="/price-list/"){
    return (
      <PriceListViewer/>
    )
  } else {
    if (token["refreshToken"]!=="") {
    return (
      <Connected/>
    )}
  else {
    return (
      <Connexion/>
    )
  }
}} else {
  return (
    <div>
      <CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>
    </div>
  )
}
}

export default App;
