import React from "react";
import { UserToken, UserSetToken,url, widthContext } from "../../context/token";
import {ReactComponent as Chevron} from "../../assets/chevron.svg";
import {ReactComponent as Share} from "../../assets/share.svg";
import Back from "../../assets/back.png"
import Check from "../../assets/yellowCheck.svg"
import Clipboard from "../../assets/clipboard.png"
import Check2 from "../../assets/check.png"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { CreateSubAccountResponse, PlaceResponse, PlaceResponseFeature, PlaceResponseFeatureProperties } from "../../types";
import { isStillConnected } from "../../utils/areYouStillConnected";
import { TextInput } from "../../components/input";
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from "react-router-dom";
import { Backdrop } from "@mui/material";
import { Connexion } from "../../utils/connexion";
const GOUV_ADRESSE_ENDPOINT = "https://api-adresse.data.gouv.fr/search/?"

function CreateAccount  ()  {

    // Pour gérer l'authentification
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)

    React.useEffect (()=>{
        Connexion () // eslint-disable-next-line
    }, [])
    ////////////////////////////////////////////////////////


    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////


    // Pour gérer l'autocomplétion d'adresse pour le ramassage et la facturation
    async function searchFunction(search: string){
        if (search.length>3){
            const lien = GOUV_ADRESSE_ENDPOINT + new URLSearchParams({"q": search})
            
            const response = fetch (lien, {
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                }
              })
            const resp:PlaceResponse = await (await response).json();
            const temp:any = resp.features.map ((element:PlaceResponseFeature) => element.properties)
            setResult (temp)
            return resp
        } else {
            setResult (new Array<PlaceResponseFeatureProperties> ())
    
        }
    }
    const [result, setResult] = React.useState<Array<PlaceResponseFeatureProperties>> ([])
    const [isAutoCompleteOpen, setIsAutoCompleteOpen] = React.useState (false)
    /////////////////////////////////////////////////////


    // Info du compte responsable à créer
    const [name, setName] = React.useState ("")
    const [activity, setActivity] = React.useState ("")
    const [siret, setSiret] = React.useState ("")
    const [TVANumber, setTVANumber] = React.useState ("")
    const [Adress1, setAddress1] = React.useState ("")
    const [adress2, setAddress2] = React.useState ("")
    const [zipCode, setZipCode] = React.useState ("")
    const [city, setCity] = React.useState ("")
    const [country, setCountry] = React.useState ("")

    //////////////////////////////////////////////////////////
  
    // Les refs
    const ref6 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref7 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref8 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref9 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref10 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref11 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref12 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref13 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref14 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref15 = React.useRef() as React.MutableRefObject<HTMLInputElement>;

    const [isResultRevealed, setIsResultRevealed] = React.useState (false);

    const navigate = useNavigate ()

    const [errorForTheCreation, setErrorForTheCreation] = React.useState (new Array<string> ())

    // Pour gérer la page de confirmation de la création du sous-compte
    const [isDone, setIsDone] = React.useState (false)
    const [doWeChange, ] = React.useState (false)
    const [copyLink, setCopyLink] = React.useState (false)
    const [isHoverCopyLink, setIsHoverCopyLink] = React.useState (false)
    const [response, setResponse] = React.useState ({} as CreateSubAccountResponse)
    async function Create (){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response:Promise<Response> = fetch (url + '/account', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
                "siret":siret,
                "name": name,
                "address": {
                    "streetLine1": Adress1,
                    "streetLine2": adress2,
                    "city": city,
                    "zip": +zipCode,
                    "country": country
                },
                "activity": activity,
                "tvaNumber": +TVANumber
            })
          })   
          await response.catch(function(_error) {
            setErrorForTheCreation (["Serveur inatteignable"])
          });;
        if ((await response).status===400){
            const json = await (await response).json()
            if (json.message==="Le compte existe déjà"){
                setErrorForTheCreation (["Le compte existe déjà"])
            } else {
                setErrorForTheCreation (json.message)
            }
        } else {
            const json:CreateSubAccountResponse = await (await response).json()
            setResponse (json)
            setIsDone (true)
            console.log (json)
        }
    }
    ////////////////////////////////////////////////////////////
    return (
        <div className="table2" style={{marginTop:30, width:"100%"}}>
            {!isDone ?
            <>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"left", justifyItems:"center", width:"90vw", justifySelf:'center', position:"sticky", top:"19vh", zIndex:3, pointerEvents: "none", userSelect:"none"}}>
                <div style={{ marginRight:20, boxSizing:"border-box", display:"grid", alignContent:"center", justifyContent:"center", cursor:"pointer", width:width>780?"unset":60, pointerEvents: "visible"}} className="Cadre-blanc Change-Color-Hover Input-password" onClick={()=>{navigate (-1)}}>
                    <img src={Back} style={{height:40, resize:"none"}} alt="go back" draggable={false}/>
                </div>
                <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", pointerEvents: "none"}}>
                    <div style={{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition">
                        <p>Créer un compte entreprise</p>
                    </div>  
                </div>
            </div>

            

            <div>
                
                <div className='DemandeExpeditionBox1' style={{borderRadius:30, padding:0, display:"flex", margin:30, width:"90vw", marginRight:0, marginLeft:0}}>

                    <div style={{width:"100%", borderRadius:30, display:"flex", justifyContent:"center", marginTop:30}}  >
                        <div style={{width:"90%", textAlign:"left", display:"flex", flexDirection:"row", alignItems:"center"}}>
                            {/* <Chevron style={{margin:width>780?"0px 30px":"0px 10px 0px 0px", rotate:isTransporteurRevealed?"-180deg":"0deg", transitionDuration: "0.2s"}}/> */}
                            <h1 style={{fontSize:width>780?30:20, fontWeight:500}}>Informations du nouveau compte responsable</h1>
                        </div>
                    </div>

                    <div >
                        <div style={{display:"grid", gridTemplateColumns:width>780?"49.5% 1% 49.5%":"100%", width:"100%", justifyItems:"center", alignContent:"baseline"}}>
                        
                            
                            
                            <div style={{zIndex:0, display:"flex", flexDirection:"column", alignItems:"center", width:"100%", alignSelf:"flex-start"}}>
                                
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Nom" value={name} onChange={(value)=>setName(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref7.current.focus()} inputRef={ref6}/>
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Activité" value={activity} onChange={(value)=>setActivity(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref8.current.focus()} inputRef={ref7}/>
                                <div style={{display:"flex", flexDirection:"row", justifySelf:"center", width:width>780?"80%":"100%", justifyContent:"space-between"}}>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Numéro de SIRET" value={siret} onChange={(value)=>setSiret(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref9.current.focus()} inputRef={ref8}/>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Numéro de TVA" value={TVANumber} onChange={(value)=>setTVANumber(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref10.current.focus()} inputRef={ref9}/>
                                </div>
                            </div>
                            {width>780 && <div style={{width:3, backgroundColor:"#a2a2a2", height:"95%", alignSelf:"start", justifySelf:"center", borderRadius:10, margin:"00px 0px"}}></div>}
                            <div style={{zIndex:0, display:"flex", flexDirection:"column", alignItems:"center", width:"100%", alignSelf:"flex-start"}}>
                            
                                <Autocomplete
                                    freeSolo={true}
                                    style={{width:width>780?"80%":"100%"}}
                                    disableClearable={true}
                                    options={result.map ((option)=>option)}
                                    autoComplete={true}
                                    open={isAutoCompleteOpen}
                                    onOpen={()=>setIsAutoCompleteOpen (true)}
                                    onClose={()=>setIsAutoCompleteOpen (false)}
                                    noOptionsText="Pas d'adresses"
                                    getOptionLabel={(option) =>
                                        typeof option === 'string' ? option : option.name}
                                    filterOptions={(x) => x}
                                    inputValue={ Adress1 }
                                    onInputChange={async (_event, newInputValue) => {setAddress1(newInputValue)}}
                                    disableCloseOnSelect={false}
                                    renderOption={(_props, option) => (
                                        <div 
                                            className="Change-Color-Hover"
                                            onClick={()=>{
                                                setAddress1 (option.name); 
                                                setZipCode (option.postcode)
                                                setCity (option.city)
                                                setCountry ("France")
                                                setIsAutoCompleteOpen (false);
                                            }}
                                            // sx={{ "& ": { mr: 2, flexShrink: 0, cursor:"pointer", width:"80%" } }}
                                            style={{width:width>780?"100%":"100%", cursor:"pointer", padding:10, boxSizing:"border-box"}}
                                            key={option.id}
                                            
                                        >
                                            <p style={{margin:0}} key={option.id}>{option.name} ({option.postcode} - {option.city})</p>
                                        </div>
                                    )}
                                    renderInput={(params) => (
                                        <TextInput
                                            {...params}
                                            onChange={async (event)=>await searchFunction(event.target.value)}
                                            bordercolor="#f0ba61"
                                            label="Adresse postale"
                                            width={"100%"}
                                            onKeyPress={event => event.key === 'Enter' && ref11.current.focus()} 
                                            inputRef={ref10}
                                        />
                                    )}
                                />
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Indication postale supplémentaire" value={adress2} onChange={(value)=>setAddress2(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref12.current.focus()} inputRef={ref11}/>
                            
                                <div style={{display:"flex", flexDirection:"row", width:width>780?"80%":"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Code postal pour le ramassage" value={zipCode} onChange={(value)=>setZipCode(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref13.current.focus()} inputRef={ref12}/>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom de la ville pour le ramassage" value={city} onChange={(value)=>setCity(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref14.current.focus()} inputRef={ref13}/>   
                                </div>
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Pays" value={country} onChange={(value)=>setCountry(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref15.current.focus()} inputRef={ref14}/>
                                
                            </div>
                            
                        </div>                    
                    </div>

                </div>
            </div>

            
            {/* {(errorForTheCreation.length!==0) &&
                
                <div style={{position: "fixed", left: "50%", top:"10%", zIndex:5}}>
                    <div className='logout' style={{position: "relative", left: "-50%", top:"-50%", border: "dotted #deae5f 1px", padding:30, width:450, paddingLeft:0, paddingRight:0}}>
                        
                        <p style={{margin:10, fontSize:25, fontWeight:500}}>Erreur</p>
                        <div style={{display:'flex', justifyContent:"center", width:"100%", height:"100%"}}>
                            <div style={{display:"flex", flexDirection:"column", width:"100%", maxHeight:"40vh", overflowY:"scroll"}}>
                                {errorForTheCreation.map ((element, index)=>
                                    <div key={index} style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"100%"}} >
                                        
                                        <p style={{margin:0, textAlign:"left", marginLeft:20, width:"70%"}}>{element}</p>
                                        
                                    </div>
                                )}
                            </div>
                            
                        </div>
                        
                        <div style={{display:"grid", gridTemplateColumns:"100%", justifyContent:"center", padding:30, paddingBottom:0, paddingTop:20}}>
                            <div style={{cursor:"pointer", backgroundColor:"#deae5f", margin:10, textAlign:"center", borderRadius:20, marginBottom:0,marginRight:10}} className="Grow-Hover" onClick={()=>setErrorForTheCreation([])}>
                                <p style={{ color:"white", width:"100%", height:"100%"}}>Continuer</p>
                            </div>
                           
                        </div>
                    </div>
                </div>
                } */}
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={errorForTheCreation.length!==0} onClick={()=>setErrorForTheCreation (new Array<string>())}>
                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 400px)", padding:20}}>
                        <h2 style={{ marginBottom:20}}>Erreur</h2>
                        
                        <div style={{display:"flex", flexDirection:"column", width:"100%", maxHeight:"40vh", overflowY:"scroll"}}>
                            {errorForTheCreation.map ((element, index)=>
                                <div key={index} style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"100%"}} >
                                    
                                    <p style={{margin:0, textAlign:"left", marginLeft:20, width:"70%"}}>{element}</p>
                                    
                                </div>
                            )}
                        </div>
                        <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
                            <div className="Change-Color-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setErrorForTheCreation (new Array<string>())}}>
                                <p style={{color:"#deae5f"}}>Continuer</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </Backdrop>
            <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>         
                <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:"20vw", pointerEvents: "visible"}} onClick={()=>{Create ()}}>
                    <p>Créer</p>
                </div>                  
            </div>
            
            </>
            :
            <>
                <div style={{display:"flex", flexDirection:'row', alignItems:"center", width:"100%", justifyContent:"center"}}>
                    <h1>Compte entreprise créée :</h1>
                    <img src={Check} alt='' style={{height:40, resize:"none", margin:12, paddingLeft:0}}/>
                </div>
                <div>
                    <Accordion className='DemandeExpeditionBox1' style={{borderRadius:30, padding:0, display:"flex", margin:30, width:"90vw", marginRight:0, marginLeft:0}} expanded={isResultRevealed}>
                        <AccordionSummary  style={{width:"100%", borderRadius:30}}  className="Change-Color-Hover" onClick={() => {setIsResultRevealed (!isResultRevealed);}}>
                        <div  style={{width:"100%", textAlign:"left", display:"flex", flexDirection:"row", alignItems:"center"}}>
                            <Chevron style={{margin:width>780?"0px 30px":"0px 10px 0px 0px", rotate:isResultRevealed?"-180deg":"0deg", transitionDuration: "0.2s"}}/>
                            <h1 style={{fontSize:width>780?30:20, fontWeight:500}}>Info du compte entreprise</h1>
                        </div>
                        </AccordionSummary>
                        <AccordionDetails>
                        <div style={{width:"100%", display:"grid", gridTemplateColumns:"100%", justifyItems:"center"}}>
                            <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Nom" value={name} onChange={(value)=>setName(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref7.current.focus()} inputRef={ref6} disabled={!doWeChange}/>
                            <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Activité" value={activity} onChange={(value)=>setActivity(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref8.current.focus()} inputRef={ref7} disabled={!doWeChange}/>
                            <div style={{display:"flex", flexDirection:"row", justifySelf:"center", width:width>780?"80%":"100%", justifyContent:"space-between"}}>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Numéro de SIRET" value={siret} onChange={(value)=>setSiret(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref9.current.focus()} inputRef={ref8} disabled={!doWeChange}/>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Numéro de TVA" value={TVANumber} onChange={(value)=>setTVANumber(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref10.current.focus()} inputRef={ref9} disabled={!doWeChange}/>
                            </div>
                            
                            <Autocomplete
                                freeSolo={true}
                                style={{width:width>780?"80%":"100%"}}
                                disableClearable={true}
                                options={result.map ((option)=>option)}
                                autoComplete={true}
                                open={isAutoCompleteOpen}
                                onOpen={()=>setIsAutoCompleteOpen (true)}
                                onClose={()=>setIsAutoCompleteOpen (false)}
                                noOptionsText="Pas d'adresses"
                                getOptionLabel={(option) =>
                                    typeof option === 'string' ? option : option.name}
                                filterOptions={(x) => x}
                                inputValue={ Adress1 }
                                onInputChange={async (_event, newInputValue) => {setAddress1(newInputValue)}}
                                disableCloseOnSelect={false}
                                renderOption={(_props, option) => (
                                    <div 
                                        className="Change-Color-Hover"
                                        onClick={()=>{
                                            setAddress1 (option.name); 
                                            setZipCode (option.postcode)
                                            setCity (option.city)
                                            setCountry ("France")
                                            setIsAutoCompleteOpen (false);
                                        }}
                                        // sx={{ "& ": { mr: 2, flexShrink: 0, cursor:"pointer", width:"80%" } }}
                                        style={{width:width>780?"100%":"100%", cursor:"pointer", padding:10, boxSizing:"border-box"}}
                                        key={option.id}
                                        
                                    >
                                        <p style={{margin:0}} key={option.id}>{option.name} ({option.postcode} - {option.city})</p>
                                    </div>
                                )}
                                renderInput={(params) => (
                                    <TextInput
                                        {...params}
                                        onChange={async (event)=>await searchFunction(event.target.value)}
                                        bordercolor="#f0ba61"
                                        label="Adresse postale"
                                        width={"100%"}
                                        onKeyPress={event => event.key === 'Enter' && ref11.current.focus()} 
                                        inputRef={ref10}
                                        disabled={!doWeChange}
                                    />
                                )}
                            />
                            <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Indication postale supplémentaire" value={adress2} onChange={(value)=>setAddress2(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref12.current.focus()} inputRef={ref11} disabled={!doWeChange}/>
                        
                            <div style={{display:"flex", flexDirection:"row", width:width>780?"80%":"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Code postal" value={zipCode} onChange={(value)=>setZipCode(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref13.current.focus()} inputRef={ref12} disabled={!doWeChange}/>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom de la ville" value={city} onChange={(value)=>setCity(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref14.current.focus()} inputRef={ref13} disabled={!doWeChange}/>   
                            </div>
                            <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Pays" value={country} onChange={(value)=>setCountry(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref15.current.focus()} inputRef={ref14} disabled={!doWeChange}/>
                            
                            
                            </div>
                        </AccordionDetails>

                    </Accordion>
                </div>
                <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>

                        <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:"3px 10px", pointerEvents: "visible"}} onClick={()=>navigate ("/create_subaccount/"+response.id)}>
                            <p>Créer un sous-compte</p>
                        </div>
                        
                        
                    </div>
                <div style={{width:"80%"}}>
                    <TextInput value={"https://supplyzen.juniorisep.com/create_user/account/"+response.id} 
                        
                        disabled
                        label="Lien pour créer un compte associé à ce compte"
                        onMouseEnter={()=>setIsHoverCopyLink (true)}
                        onMouseLeave={()=>{setIsHoverCopyLink (false);setCopyLink (false)}}
                        onClick={()=>{navigator.clipboard.writeText ("https://supplyzen.juniorisep.com/create_user/account/"+response.id); setCopyLink (true)}}
                        
                        InputProps={{
                            endAdornment: isHoverCopyLink ?<img alt='clipboard' src={copyLink ? Check2 : Clipboard} className="Contact" style={{position:"absolute", right:0, cursor:'pointer'}} draggable={false}/>:undefined
                        }}
                    />
                    <Share style={{cursor:"pointer"}} onClick={()=>navigator.share({title: 'Supplyzen', text: 'Créez votre compte', url: "https://supplyzen.juniorisep.com/create_user/account/"+response.id})}/>
                </div>

            </>
            }
            
        </div>
            
    )}

export default CreateAccount