import React from "react";

import { UserToken, UserSetToken, url, widthContext } from "../../context/token";

import { Account, SubAccount, } from "../../types";
import { TextInput } from "../../components/input";

// Images
import Back from "../../assets/back.png"
import {ReactComponent as Mail} from "../../assets/mail2.svg";
import {ReactComponent as Maison} from "../../assets/maison.svg";
import Position from "../../assets/Position.png"
import Pen from "../../assets/pen.png"
import Batiments from "../../assets/batiments.png"
import Check from "../../assets/yellowCheck.svg"
import {ReactComponent as Bonhomme} from "../../assets/bonhomme.svg";
import {ReactComponent as CodePostaIMG} from "../../assets/code-postal.svg";

import { isStillConnected } from "../../utils/areYouStillConnected";

import { useLocation, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, Pagination } from "@mui/material";
import { Connexion } from "../../utils/connexion";
import { SearchAddresses } from "../../components/searchAddresses";

function AccountPage  ()  {
    // Pour gérer l'authentification
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)

    ////////////////////////////////


    // Pour gérer ce qui est affiché à l'écran
    const [nbScreen, setNBScreen] = React.useState (0)
    //////////////////////////////////////////

    // Pour avoir les données du sous-compte concerné
    const accountId = useLocation ().pathname.split ("/")[2]
    // const [account, setAccount] = React.useState<Account> ({} as Account)
    const account = React.useRef ({} as Account)

    async function GetInfo () {
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/account/'+accountId, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            }
          })
        const json:Account = await (await response).json();
        
        json.subAccounts.map ((element)=>element.checked=false)
        setSubAccountList (json.subAccounts)
        setFilteredSubAccountList (json.subAccounts)
        
        // setAccount (json)
        account.current = json

        
        setAddress (json.address.streetLine1 + ", " + json.address.streetLine2 + (json.address.streetLine2!==""?", ":"") + json.address.zip + " " + json.address.city + ", " + json.address.country)
        setSiret (json.siret)
        setActivity (json.activity)
        setTVA (JSON.stringify (json.tvaNumber))
        setAPE ("123")
        setAddress1 (json.address.streetLine1)
        setAddress2 (json.address.streetLine2)
        setZipCode (JSON.stringify (json.address.zip))
        // setMailAddress (json.users[0].email)
        setMailAddress ("afe")
        setCity (json.address.city)
        setCountry (json.address.country)

        setIsLoading (false)
    }

    const [isLoading, setIsLoading] = React.useState (true)
    //////////////////////////////////////////////////////////////////


    // Pour gérer l'affichage et modification des infos du sous-compte
    
    const [address, setAddress] = React.useState ("")
    const [address1, setAddress1] = React.useState ("")
    const [address2, setAddress2] = React.useState ("")
    const [zipCode, setZipCode] = React.useState ("")
    const [city, setCity] = React.useState ("")
    const [country, setCountry] = React.useState ("")
    const [editAddress, setEditAddress] = React.useState (false)
    
    function getDateInLitteral (a:string):string{
        const jour = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]
        const mois = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
        const date:Date = new Date (a)
        var heure = ""
        if (JSON.stringify (date.getHours ()).length===1){
            heure = "0"+JSON.stringify (date.getHours ())
        } else {
            heure = JSON.stringify (date.getHours ())
        }
        if (JSON.stringify (date.getMinutes ()).length===1){
            heure = heure +":0"+JSON.stringify (date.getMinutes ())
        } else {
            heure = heure+":"+JSON.stringify (date.getMinutes ())
        }
        return jour[date.getDay ()] + " " + date.getDate () + " " + mois[date.getMonth ()] + " " + date.getFullYear () + " à " + heure
    }
    async function updateAddress (){
        const response = fetch (url + '/account/account/', {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
                "id": accountId,
                streetLine1: address1,
                streetLine2: address2,
                city: city,
                zip: zipCode,
                country: country
            })
          })
        const json:string = await (await response).json();
        if (json==="Le compte a bien été mis à jour"){
            GetInfo ()
        }
    }
    function cancelUpdateAddress (){
        setEditAddress (false);
        setAddress1 (account.current.address.streetLine1)
        setAddress2 (account.current.address.streetLine2)
        setZipCode (JSON.stringify (account.current.address.zip))
        setCity (account.current.address.city)
        setCountry (account.current.address.country)
    }
    // Pour gérer l'autocomplétion d'adresse
    const [isAutoCompleteOpen, setIsAutoCompleteOpen] = React.useState (false)
    /////////////////////////////////////////////////////
    const siretRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [siret, setSiret] = React.useState ("")
    const [editSiret, setEditSiret] = React.useState (false)
    // eslint-disable-next-line
    React.useEffect (()=>{!isLoading && siretRef.current.focus ()}, [editSiret])
    const siretRefIMG = React.useRef(null)
    useOutsideAlerterForSiret(siretRefIMG);

    const mailAddressRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [mailAddress, setMailAddress] = React.useState ("subao@aeofu.com")
    const [editMailAddress, setEditMailAddress] = React.useState (false)
    // eslint-disable-next-line
    React.useEffect (()=>{!isLoading && mailAddressRef.current.focus ()}, [editMailAddress])
    const addressMailRefIMG = React.useRef(null)
    useOutsideAlerterForMailAddress(addressMailRefIMG);
    
    const activityRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [activity, setActivity] = React.useState ("")
    const [editActivity, setEditActivity] = React.useState (false)
    // eslint-disable-next-line
    React.useEffect (()=>{!isLoading && activityRef.current.focus ()}, [editActivity])
    const activityRefIMG = React.useRef(null)
    useOutsideAlerterForActivity(activityRefIMG);
    
    const TVARef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [TVA, setTVA] = React.useState ("")
    const [editTVA, setEditTVA] = React.useState (false)
    // eslint-disable-next-line
    React.useEffect (()=>{!isLoading && TVARef.current.focus ()}, [editTVA])
    const TVARefIMG = React.useRef(null)
    useOutsideAlerterForTVA(TVARefIMG);
    
    const APERef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [APE, setAPE] = React.useState ("")
    const [editAPE, setEditAPE] = React.useState (false)
    // eslint-disable-next-line
    React.useEffect (()=>{!isLoading && APERef.current.focus ()}, [editAPE])
    const APERefIMG = React.useRef(null)
    useOutsideAlerterForAPE(APERefIMG);

    function useOutsideAlerterForSiret(ref:any) {
        React.useEffect(() => {
            function handleClickOutside(event:any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setEditSiret (false)
                    if (account.current.siret!==undefined){
                        setSiret (account.current.siret)
                    }
                }
            }
            document.addEventListener("mouseup", handleClickOutside);
            return () => {
                document.removeEventListener("mouseup", handleClickOutside);
        };
        }, [ref]);
      }
    function useOutsideAlerterForMailAddress(ref:any) {
        React.useEffect(() => {
            function handleClickOutside(event:any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setEditMailAddress (false)
                }
                // if (account.current.mail!==undefined){
                //     setSiret (account.current.mail)
                // }
            }
            document.addEventListener("mouseup", handleClickOutside);
            return () => {
                document.removeEventListener("mouseup", handleClickOutside);
        };
        }, [ref]);
      }
      
    
    function useOutsideAlerterForActivity(ref:any) {
        React.useEffect(() => {
            function handleClickOutside(event:any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setEditActivity (false)
                    if (account.current.activity!==undefined){
                        setActivity (account.current.activity)
                    }
                }
            }
            document.addEventListener("mouseup", handleClickOutside);
            return () => {
                document.removeEventListener("mouseup", handleClickOutside);
        };
        }, [ref]);
    }
    
    function useOutsideAlerterForTVA(ref:any) {
        React.useEffect(() => {
            function handleClickOutside(event:any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    editTVA && setTVA (JSON.stringify (account.current.tvaNumber))
                    setEditTVA (false)
                    if (account.current.tvaNumber!==undefined){
                        setTVA (JSON.stringify (account.current.tvaNumber))
                    }
                }
            }
            document.addEventListener("mouseup", handleClickOutside);
            return () => {
                document.removeEventListener("mouseup", handleClickOutside);
        };
        }, [ref]);
    }
    
    function useOutsideAlerterForAPE(ref:any) {
        React.useEffect(() => {
            function handleClickOutside(event:any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setEditAPE (false)
                    // if (account.current.ape!==undefined){
                    //     setSiret (account.current.ape)
                    // }
                }
            }
            document.addEventListener("mouseup", handleClickOutside);
            return () => {
                document.removeEventListener("mouseup", handleClickOutside);
        };
        }, [ref]);
    }

    async function sendUpdate (){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        setIsLoading (true)
        setEditAPE (false)
        setEditActivity (false)
        setEditAddress (false)
        setEditMailAddress (false)
        setEditSiret (false)
        setEditTVA (false)
        
        const response:Promise<any> = fetch (url + '/account/account', {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
                // address: address,
                id: account.current.id,
                siret: siret,
                activity: activity,
                tvaNumber: +TVA
                // email: mailAddress
            })
          })
        try {
            const json = await (await response).json ()
            if (typeof json!=="string"){
                if (json.statusCode===400) {
                    setError (json.message)
                    setAddress (account.current.address.streetLine1 + ", " + account.current.address.streetLine2 + (account.current.address.streetLine2!==""?", ":"") + account.current.address.zip + " " + account.current.address.city + ", " + account.current.address.country)
                    setSiret (account.current.siret)
                    setActivity (account.current.activity)
                    setTVA (JSON.stringify (account.current.tvaNumber))
                    setAPE ("123")
                    setAddress1 (account.current.address.streetLine1)
                    setAddress2 (account.current.address.streetLine2)
                    setZipCode (JSON.stringify (account.current.address.zip))
                    setMailAddress ("adresseMail@juniorisep.com")
                    setCity (account.current.address.city)
                    setCountry (account.current.address.country)
                    setIsLoading (false)
                }
            }
        } catch {
            await GetInfo ()
        }
        setIsLoading (false)
    }
    const [error, setError] = React.useState (new Array<string> ())
    async function editSiretFunction (){
        if (editSiret){
            sendUpdate ()
        } 
        setEditSiret (!editSiret)
    }
    async function editMailAddressFunction (){
        if (editMailAddress){
            sendUpdate ()
        }
        setEditMailAddress (!editMailAddress)
    }
    async function editActivityFunction (){
        if (editActivity){
            sendUpdate ()
        }
        setEditActivity (!editAPE)
    }
    async function editTVAFunction (){
        if (editTVA){
            sendUpdate ()
        }
        setEditTVA (!editAPE)
    }
    async function editAPEFunction (){
        if (editAPE){
            sendUpdate ()
        }
        setEditAPE (!editAPE)
    }
    /////////////////////////////////////////////////////////////////



    // Pour gérer l'obtention et l'actualisation des infos du compte
    React.useEffect (()=>{
        Connexion ()
        GetInfo ()
         // eslint-disable-next-line
    }, [])
    /////////////////////////////////////////////////

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////

    const navigate = useNavigate ()


    // Gérer la liste des sous-compte
    const [subAccountList, setSubAccountList] = React.useState (
        new Array<SubAccount> ()
    ) 
    const [pageNbSubAccountList, setPageNbSubAccountList] = React.useState (1)


    const [filteredSubAccountList, setFilteredSubAccountList] = React.useState (subAccountList)
    const reductedSubaccountList = filteredSubAccountList.slice ((pageNbSubAccountList-1)*20, (pageNbSubAccountList*20))

    // function goToPreviousPageForSubaccount (){
    //     if (pageNbSubAccountList>1) {
    //         setPageNbSubAccountList(pageNbSubAccountList-1); window.scrollTo(0, 0)
    //     }
    // }
    // function goToNextPageForSubAccount (){
    //     if (pageNbSubAccountList<filteredSubAccountList.length/20) {
    //         setPageNbSubAccountList(pageNbSubAccountList+1); window.scrollTo(0, 0)
    //     }
    // }

    // Pour gérer les filtres


    const [nameRespoFilter, setNameRespoFilter] = React.useState ("")
    const [addressRespoFilter, setAddressRespoFilter] = React.useState ("")
    const [zipCodeRespoFilter, setZipCodeRespoFilter] = React.useState ("")
    const [siretRespoFilter, setSiretRespoFilter] = React.useState ("")

    React.useEffect (()=>{
        var temp = subAccountList.slice ()
        
        if (nameRespoFilter!==""){
            temp = temp.filter((obj) => {return (obj.name.slice (0, nameRespoFilter.length).localeCompare (nameRespoFilter, undefined, {sensitivity:"base"}))===0}) 
        }  
        if (addressRespoFilter!==""){
            temp = temp.filter((obj) => {return (obj.name.slice (0, addressRespoFilter.length).localeCompare (addressRespoFilter, undefined, {sensitivity:"base"}))===0}) 
        }  
        if (zipCodeRespoFilter!==""){
            temp = temp.filter((obj) => {return (obj.address?.zip.toString().slice (0, zipCodeRespoFilter.length).localeCompare (zipCodeRespoFilter, undefined, {sensitivity:"base"}))===0}) 
        }  
        if (siretRespoFilter!==""){
            temp = temp.filter((obj) => {return (obj.siret.slice (0, siretRespoFilter.length).localeCompare (siretRespoFilter, undefined, {sensitivity:"base"}))===0}) 
        }  
        
        setFilteredSubAccountList (temp)
    }, [subAccountList, nameRespoFilter, addressRespoFilter, zipCodeRespoFilter, siretRespoFilter])
    ///////////////////////////////////////////////

    const [tryToRemoveSubAccounts, setTryToRemoveSubAccounts] = React.useState (false)
    const [nbGoing, setNbGoing] = React.useState (0)
    function deleteSubAccount (id:string){
        fetch (url + '/sub-account/' + id, {
            method: 'DELETE',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            }
          })
    }

    function deleteSubAccounts (){
        setIsLoading (true)
        const temp = subAccountList.filter((obj) => {return (obj.checked === true)}) 
        for (let i=0; i<temp.length; i++){
            deleteSubAccount (subAccountList[i].id)
        }
        setTryToRemoveSubAccounts (false)
        setIsLoading (false)
    }

    function SubAccountComponent (props:{element:SubAccount, index:number}){
        const [isChecked, setIsChecked] = React.useState (props.element.checked)
        const element = props.element
        const index:number = subAccountList.indexOf (element)

        
        function handleChangeIsGoing (){
            
            var temp:Array<SubAccount> = subAccountList.slice ()
            temp[index+(pageNbSubAccountList-1)*20].checked = !isChecked
            if (!isChecked){
                setNbGoing (nbGoing+1)
            } else {
                setNbGoing (nbGoing-1)
            }
            setSubAccountList(temp)
            if (nameRespoFilter!==""){
                temp = temp.filter((obj) => {return (obj.name.slice (0, nameRespoFilter.length).localeCompare (nameRespoFilter, undefined, {sensitivity:"base"}))===0}) 
            }  
            if (addressRespoFilter!==""){
                temp = temp.filter((obj) => {return (obj.name.slice (0, addressRespoFilter.length).localeCompare (addressRespoFilter, undefined, {sensitivity:"base"}))===0}) 
            }  
            if (zipCodeRespoFilter!==""){
                temp = temp.filter((obj) => {return (obj.address?.zip.toString().slice (0, zipCodeRespoFilter.length).localeCompare (zipCodeRespoFilter, undefined, {sensitivity:"base"}))===0}) 
            }  
            if (siretRespoFilter!==""){
                temp = temp.filter((obj) => {return (obj.siret.slice (0, siretRespoFilter.length).localeCompare (siretRespoFilter, undefined, {sensitivity:"base"}))===0}) 
            }  
            setFilteredSubAccountList (temp)
            setIsChecked (!isChecked)
        }
       
        return (
            <div className="Change-Color-Hover" key={element["id"]} style={{padding:20, paddingRight:0, paddingLeft:0, display:"flex", flexDirection:width>1200?"row":"column", width:"100%", alignItems:"center", justifyContent:"center", cursor:"pointer"}} onClick={()=>navigate ("/subaccount/"+element.id)}>
                <div style={{display:"flex", width:"95%", justifySelf:"center"}}>
                    <div style={{flex:6.5, display:"flex", flexDirection:width>1200?"row":"column", height:"100%", gap:15}}>
                        <div style={{textAlign:"left",  display: "grid", gridTemplateColumns:"20% 80%", flexDirection:"row", alignItems:"center", flex:1.8}}>
                            <div style={{ width:50, height:50 }}>
                                <Bonhomme style={{ width:"100%", height:"100%", resize:"none" }}/>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", marginLeft:5}}>
                                <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{element.name}</span>
                                <span style={{color:"#747474"}}>Responsable entreprise</span>
                            </div>
                            {/* {width>1200 && <span style={{color:"#747474", fontWeight:600}}>Numéro d'expédition</span>} */}
                        </div>

                        <div style={{textAlign:"left",  display: "flex", flexDirection:"row", paddingLeft:30, alignItems:"center", flex:2}}>
                            <img src={Position} alt='' style={{width:40, height:40, resize:"none", marginRight:10}} draggable={false}/>
                            <div style={{display:"flex", flexDirection:"column", marginLeft:5}}>
                                <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{element.address?.streetLine1}</span>
                                <span style={{color:"#747474"}}>Adresse</span>
                            </div>
                            {/* {width>1200 && <span style={{color:"#747474", fontWeight:600}}>Numéro d'expédition</span>} */}
                        </div>
                        

                        <div style={{textAlign:"left", display: "flex",  flexDirection:"row", paddingLeft:30, alignItems:"center", flex:1}}>
                            <CodePostaIMG style={{width:40, height:40, resize:"none", marginRight:10}}/>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <span style={{color:"#747474", fontSize:30, fontWeight:700}}>{element.address?.zip}</span>
                                <span style={{color:"#747474"}}>Code postal</span>
                            </div>
                        </div>

                        <div style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"left", flex:1.7}}>
                            <Maison style={{width:40, resize:"none", height:"min-content", marginRight:10}}/>
                            <div style={{display:"flex", flexDirection:"column", marginLeft:0, textAlign:"left"}}>
                                <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{element.siret}</span>
                                <span style={{color:"#747474"}}>SIRET</span>
                            </div>
                        </div>
                    </div>
                    <div style={{flex:0.5, margin:12, alignSelf:"center"}}>
                        <div className="table4 Change-Color-Hover" style={{  cursor:"pointer", justifyContent:"center", display:"flex",   border: isChecked?"5px solid #e7ac4f":"2px solid #9a9a9a", width:60, height:60, paddingLeft:0, borderRadius:10, boxSizing:"border-box", flexDirection:"row", alignItems:"center", alignContent:"center",}} onClick={(e)=>{handleChangeIsGoing();e.stopPropagation()}}>
                            <img src={Check} alt='' style={{height:40, resize:"none", margin:12, paddingLeft:0, visibility:isChecked?"visible":"hidden"}} draggable={false}/>
                        </div>  
                    </div>
                </div>       
            </div>
        )
    }
    
    // Handle the main header size to adapt
    const [headerHeight, setHeaderHeight] = React.useState (60)
    React.useEffect(() => {
        let header = document.getElementById("header");
        if (header) {
            setHeaderHeight (header.clientHeight)
        }
      }, [])
    ////////////////////////////////////////////////
    return (
        <>
        
        <div className="" style={{marginBottom:"3vh", margin:"0px 20px"}}>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"left", justifyItems:"center", width:"100%", justifySelf:'center', position:"sticky", top:headerHeight+40, zIndex:3, pointerEvents: "none", userSelect:"none"}}>
                <div style={{ marginRight:20, boxSizing:"border-box", display:"grid", alignContent:"center", justifyContent:"center", cursor:"pointer", width:width>780?"unset":60, pointerEvents: "visible"}} className="Cadre-blanc Change-Color-Hover Input-password" onClick={()=>{navigate (-1)}}>
                    <img src={Back} style={{height:40, resize:"none"}} alt="go back" draggable={false}/>
                </div>
                <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", pointerEvents: "none"}}>
                    <div style={nbScreen===0?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={()=>setNBScreen(0)}>
                        <p>Informations générales</p>
                    </div>
                    <div style={nbScreen===1?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={()=>setNBScreen(1)}>
                        <p>Liste des sous-comptes</p>
                    </div>
                </div>
            </div>
                

            <div className="table" style={{margin:"20px 0px", width:"100%", display:"flex"}}>
                {/* ///////////////////////////// Ecran numéro 1 ////////////////////////////// */}

                {!isLoading?
                <>
                {nbScreen===0 &&
                <>
                    <div style={{position:width>1200?"absolute":"relative", right:width>1200?20:"undefined", top:width>1200?10:"undefined", fontStyle:"italic", color:"#aeaeae", cursor:"default", userSelect:"none", textAlign:"left", padding:"0px 15px"}}>
                        <p>Dernière modification le {getDateInLitteral (account.current.updatedAt)}</p>
                    </div>
                    <div className=" Cadre-profil" style={{justifySelf:"center", alignSelf:"center",  padding: width>780 ?"10px 50px":"0px 20px", boxSizing:  "border-box", marginBottom:30, display:"flex", flexDirection:"column"}}>
                        <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:width<600?"center":"normal"}}>    
                            <div className="Profil-Logo">
                                <div className="Profil-Logo2"></div>
                                <div className="Profil-Logo3"></div>
                                <p style={{zIndex:1, position:"relative", margin:30, font: "italic normal normal 25px/1 Verdana, Geneva, sans-serif"}}>{account.current.name}</p>
                            </div>
                        </div>
                        <div>
                            <h2 style={{textAlign:"left", color:"#474746"}}>Adresse :</h2>
                            <div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
                                <div style={{width:30, height:30}}>
                                    <img src={Position} alt="" style={{width:"100%", resize:"none"}} draggable={false}/>
                                </div>
                                {/* <input ref={addressRef} value={address} onChange={(value)=>setAddress (value.target.value)} style={{ width:  address.length + 'ch', fontSize:"1em", borderStyle:"none", backgroundColor:"white", marginLeft:5, color:"black" }} disabled={!editAddress} onKeyUp={(key)=>key.key==="Enter"?editAddressFunction ():key.key==="Escape"?setEditAddress (!editAddress):null}/> */}
                                <p style={{margin:0, marginLeft:5}}>{address}</p>
                                <img src={Pen} alt="" style={{height:20, resize:"none", marginLeft:10, cursor:"pointer"}} draggable={false} onClick={()=>{setEditAddress (true)}}/>
                            </div>
                            <h2 style={{textAlign:"left", color:"#474746"}}>Numéro de SIRET :</h2>
                            <div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
                                <div style={{width:30, height:30}}>
                                    <Maison style={{width:"100%", resize:"none"}}/>
                                </div>
                                {/* <p style={{marginLeft:20, textAlign:"left"}}>1234567890</p> */}
                                <input ref={siretRef} value={siret} onChange={(value)=>{if (!Number.isNaN (+value.target.value)){setSiret (value.target.value)}}} style={{ width:  siret.length + 'ch', fontSize:"1em", borderStyle:"none", backgroundColor:"white", marginLeft:5, color:"black" }} disabled={!editSiret} onKeyUp={(key)=>key.key==="Enter"?editSiretFunction ():key.key==="Escape"?[setEditSiret (!editSiret),setSiret (account.current.siret)]:null}/>
                                <img ref={siretRefIMG} src={editSiret?Check:Pen} alt="" style={{height:20, resize:"none", marginLeft:10, cursor:"pointer"}} draggable={false} onClick={()=>{editSiretFunction ()}}/>
                            </div>
                            <h2 style={{textAlign:"left", color:"#474746"}}>Adresse mail :</h2>
                            <div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
                                <div style={{width:30, height:30, display:"flex", alignItems:"center" }}>
                                    <Mail style={{ resize:"none", width:30}}/>
                                </div>
                                {/* <p style={{marginLeft:20, textAlign:"left"}}>subao@aeofu.com</p> */}
                                <input ref={mailAddressRef} value={mailAddress} onChange={(value)=>setMailAddress (value.target.value)} style={{ width:  mailAddress.length + 'ch', fontSize:"1em", borderStyle:"none", backgroundColor:"white", marginLeft:5, color:"black" }} disabled={!editMailAddress}  onKeyUp={(key)=>key.key==="Enter"?setEditMailAddress (false):key.key==="Escape"?[setEditMailAddress (!editMailAddress),setMailAddress ("adresseMail@juniorisep.com")]:null}/>
                                <img ref={addressMailRefIMG} src={editMailAddress?Check:Pen} alt="" style={{height:20, resize:"none", marginLeft:10, cursor:"pointer"}} draggable={false} onClick={()=>{editMailAddressFunction ()}}/>
                            </div>
                            <h2 style={{textAlign:"left", color:"#474746"}}>Activité:</h2>
                            <div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
                                <div style={{width:30, height:30, display:"flex", alignItems:"center" }}>
                                    <Mail style={{ resize:"none", width:30}}/>
                                </div>
                                <input ref={activityRef} value={activity} onChange={(value)=>setActivity (value.target.value)} style={{ width:  activity.length + 'ch', fontSize:"1em", borderStyle:"none", backgroundColor:"white", marginLeft:5, color:"black" }} disabled={!editActivity} onKeyUp={(key)=>key.key==="Enter"?editActivityFunction ():key.key==="Escape"?[setEditActivity (!editActivity),setActivity (account.current.activity)]:null}/>
                                <img ref={activityRefIMG} src={editActivity?Check:Pen} alt="" style={{height:20, resize:"none", marginLeft:10, cursor:"pointer"}} draggable={false} onClick={()=>{editActivityFunction ()}}/>
                            </div>
                            <h2 style={{textAlign:"left", color:"#474746"}}>Numéro TVA:</h2>
                            <div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
                                <div style={{width:30, height:30, display:"flex", alignItems:"center" }}>
                                    <Mail style={{ resize:"none", width:30}}/>
                                </div>
                                <input ref={TVARef} value={TVA} onChange={(value)=>!Number.isNaN (value) && setTVA (value.target.value)} style={{ width:  TVA.length + 'ch', fontSize:"1em", borderStyle:"none", backgroundColor:"white", marginLeft:5, color:"black" }} disabled={!editTVA} onKeyUp={(key)=>key.key==="Enter"?editTVAFunction ():key.key==="Escape"?[setEditTVA (!editTVA),setTVA (JSON.stringify (account.current.tvaNumber))]:null}/>
                                <img ref={TVARefIMG} src={editTVA?Check:Pen} alt="" style={{height:20, resize:"none", marginLeft:10, cursor:"pointer"}} draggable={false} onClick={()=>{editTVAFunction ()}}/>
                            </div>
                            <h2 style={{textAlign:"left", color:"#474746"}}>Numéro APE:</h2>
                            <div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
                                <div style={{width:30, height:30, display:"flex", alignItems:"center" }}>
                                    <Mail style={{ resize:"none", width:30}}/>
                                </div>
                                <input ref={APERef} value={APE} onChange={(value)=>setAPE (value.target.value)} style={{ width:  APE.length + 'ch', fontSize:"1em", borderStyle:"none", backgroundColor:"white", marginLeft:5, color:"black" }} disabled={!editAPE} onKeyUp={(key)=>key.key==="Enter"?editAPEFunction ():key.key==="Escape"?[setEditAPE (!editAPE),setAPE ("123")]:null}/>
                                <img ref={APERefIMG} src={editAPE?Check:Pen} alt="" style={{height:20, resize:"none", marginLeft:10, cursor:"pointer"}} draggable={false} onClick={()=>{editAPEFunction ()}}/>
                            </div>
                            
                            <img src={Batiments} alt="" style={{position:"absolute", right:0, bottom:0, width:"30%", opacity:"60%", zIndex:2, borderBottomRightRadius:30}} draggable={false}/>

                            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={error.length!==0} onClick={()=>setError (new Array<string>())}>
                                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 400px)", padding:20}}>
                                        <h2 style={{ marginBottom:20}}>Erreur</h2>
                                        
                                        <div style={{display:"flex", flexDirection:"column", width:"100%", maxHeight:"40vh", overflowY:"scroll"}}>
                                            {error.map ((element, index)=>
                                                <div key={index} style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"100%"}} >
                                                    
                                                    <p style={{margin:0, textAlign:"left", marginLeft:20, width:"70%"}}>{element}</p>
                                                    
                                                </div>
                                            )}
                                        </div>
                                        <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
                                            <div className="Change-Color-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setError (new Array<string>())}}>
                                                <p style={{color:"#deae5f"}}>Continuer</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </Backdrop>

                            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={editAddress} onClick={()=>cancelUpdateAddress ()}>
                                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <div className="Cadre-blanc"  onClick={(e)=>{console.log ("coucou Lucas"); e.stopPropagation()}} style={{height:"min-content", width:"min (100%, 200px)", padding:20}}>
                                        <h2 style={{ marginBottom:20}}>Modifier l'adresse</h2>
                                        <div style={{zIndex:0, display:"flex", flexDirection:"column", alignItems:"center", width:"100%", alignSelf:"flex-start"}}>
                                            <SearchAddresses address={address1} setAddress={setAddress1} setZipCode={setZipCode} setCity={setCity} setCountry={setCountry} isOpen={isAutoCompleteOpen} setIsOpen={setIsAutoCompleteOpen}/>
                                            
                                            <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Indication postale supplémentaire" value={address2} onChange={(value)=>setAddress2(value.target.value)}/>
                                        
                                            <div style={{display:"flex", flexDirection:"row", width:width>780?"80%":"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Code postal" value={zipCode} onChange={(value)=>setZipCode(value.target.value)}/>
                                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom de la ville" value={city} onChange={(value)=>setCity(value.target.value)}/>   
                                            </div>
                                            <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Pays" value={country} onChange={(value)=>setCountry(value.target.value)}/>
                                        </div>
                                        <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
                                            <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {cancelUpdateAddress ()}}>
                                                <p style={{color:"#deae5f", margin:0}}>Annuler</p>
                                            </div>
                                            <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:10, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {updateAddress ();setEditAddress (false)}}>
                                                <p style={{color:"white", margin:0}}>Confirmer</p>
                                            </div>
                                        </div>
                                            
                                    </div>
                                </div>
                            </Backdrop>

                        </div>
                    </div>
                </>
                }
                {/* /////////////////////////////////////////////////////////////////////////// */}


                {/* ///////////////////////////// Ecran numéro 2 ////////////////////////////// */}
                {nbScreen===1 &&
                    <>
                    {subAccountList.length>0?
                    <>
                    {width>1000 &&
                    <div style={{display:"flex" , flexDirection:"row", alignSelf:"center", marginTop:20, width:"100%", boxSizing:"border-box", justifyContent:"center"}}>
                        <div style={{display:"flex", flexDirection:width>1200?"row":"column", width:"100%", justifyContent:"center", alignSelf:"center"}}>
                            <div style={{display:"flex" , alignSelf:"center",  width:"95%", justifyContent:width>780?"space-evenly":"left"}}>
                                <div style={{display:"flex", flex:6.5}}>
                                    <div style={{flex:1.8, padding:0}}>
                                        <TextInput width="98%" bordercolor="#7d7d7d" label="Nom du responsable" value={nameRespoFilter} onChange={(value)=>setNameRespoFilter(value.target.value)}/>
                                    </div>
                                    <div style={{flex:2}}>
                                        <TextInput width="98%" bordercolor="#7d7d7d" label="Adresse du responsable" value={addressRespoFilter} onChange={(value)=>setAddressRespoFilter(value.target.value)}/>
                                    </div>
                                    <div style={{flex:1}}>
                                        <TextInput width="98%" bordercolor="#7d7d7d" label="Code postal" value={zipCodeRespoFilter} onChange={(value)=>{if (!Number.isNaN (+value.target.value)){setZipCodeRespoFilter(value.target.value);}}}/>
                                    </div>
                                    <div style={{flex:1.7}}>
                                        <TextInput width="98%" bordercolor="#7d7d7d" label="Numéro de SIRET" value={siretRespoFilter} onChange={(value)=>{if (!Number.isNaN (+value.target.value)){setSiretRespoFilter(value.target.value);}}}/>
                                    </div>
                                </div>
                                <div style={{flex:0.5}}></div>
                            </div>
                        </div>
                    </div>
                }
                    {reductedSubaccountList.map ((element:SubAccount, index:number) =>
                        <div key={index} style={{display:"grid" ,justifyItems:"center", flexDirection:"column", width:"100%", position:"relative"}}>
                            <SubAccountComponent element={element} index={index}/>
                            
                            {index!==reductedSubaccountList.length-1 &&
                                <div style={{width:"90%", alignSelf:"center", justifySelf:"center", height:3, backgroundColor:'#9a9a9a', borderRadius:10}}></div>
                            }
                            
                        </div>
                    )}       
                
                    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={nbGoing>0 && tryToRemoveSubAccounts} onClick={()=>setTryToRemoveSubAccounts (false)}>
                        <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 200px)", padding:20}}>
                                <h2 style={{ marginBottom:20}}>Supprimer {nbGoing} sous-compte{nbGoing>1?"s":""} ?</h2>
                                
                                <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
                                    <div className="Change-Color-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setTryToRemoveSubAccounts (false)}}>
                                        <p style={{color:"#deae5f"}}>Annuler</p>
                                    </div>
                                    <div className="Change-Color-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {deleteSubAccounts ()}}>
                                        <p style={{color:"white"}}>Confirmer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Backdrop>
                    <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>
                            
                        {nbGoing>0 &&
                        <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:"20vw", pointerEvents: "visible"}} onClick={()=>setTryToRemoveSubAccounts (true)}>
                            <p>Supprimer</p>
                        </div>
                        }

                        <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:"3px 10px", pointerEvents: "visible"}} onClick={()=>navigate ("/create_subaccount/"+account.current.id)}>
                            <p>Créer un sous-compte</p>
                        </div>
                        
                        
                    </div>
                    
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", userSelect:"none"}}>
                        {/* <span className="table" style={pageNbSubAccountList>1?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}} onClick={()=> {goToPreviousPageForSubaccount()}}>
                            <p className="table" style={{color:"white"}}>Page précédente</p>
                        </span>
                        <p style={{cursor:"default"}}>{pageNbSubAccountList}/{Math.floor((filteredSubAccountList.length-1)/20)+1}</p>
                        <span className="table" style={pageNbSubAccountList<filteredSubAccountList.length/20?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}}  onClick={()=> {goToNextPageForSubAccount()}}>
                            <p className="table" style={{color:"white"}}>Page suivante</p>
                        </span> */}
                        <Pagination count={filteredSubAccountList.length!==0? Math.floor((filteredSubAccountList.length-1)/20)+1:1} size="large"  onChange={(_event, number)=>setPageNbSubAccountList (number)}/>
                    </div>
                    </>
                    :
                    <>
                        <p>Pas de données à afficher</p>
                        <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>
                            <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:"3px 10px", pointerEvents: "visible"}} onClick={()=>navigate ("/create_subaccount/"+account.current.id)}>
                                <p>Créer un sous-compte</p>
                            </div>
                        </div>
                    </>
                    }
                </>
                }</>:isLoading?<CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>: <p>Pas de données à afficher</p>}
                {/* /////////////////////////////////////////////////////////////////////////// */}
            </div>
        </div>
        
        
    </>
    )
}
export default AccountPage;