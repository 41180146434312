import React from "react";
import Position from "../../../assets/Position.png"
import {ReactComponent as CodePostaIMG} from "../../../assets/code-postal.svg";
import { Expedition } from "../../../types";
import Check from "../../../assets/yellowCheck.svg"
import { Link } from "react-router-dom";
import { widthContext } from "../../../context/token";


export default function ExpeditionComponent (props:{element:Expedition, handleChange:Function, index:number}) {

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////
    
    const element = props.element

    const [isGoing, setIsGoing] = React.useState (props.element.checked)
    
    function handleChange (){
        props.handleChange (props.index, !isGoing)
        setIsGoing (!isGoing)
    }
    const [revealed, setRevealed] = React.useState ("")

    // Gère l'affichage des expéditions concernées
    function ToolTip2 (e:{children, style?:{}}){
        const [isHover, setIsHover] = React.useState (false)
        return (
            <div style={{...e.style, position:"relative", zIndex:3, opacity:1}} onMouseLeave={()=>setIsHover (false)} onMouseEnter={()=>setIsHover (true)}>
                {e.children}
                {isHover && <div>
                    <div className="Cadre-blanc" style={{position:"absolute", left:0, width:"max-content", padding:"0px 10px", bottom:"-100%", transform:"translate (100%, 100%);", maxWidth:"70vw"}}>
                        <div style={{width: 0, height: 0, border: "solid 30px", borderColor: "transparent transparent white transparent", position:"absolute", bottom:"100%", left:"10%"}}></div>
                        {props.element.parcels.map ((parcel)=>
                        <div key={parcel.id}>
                            <Link style={{textDecoration:"none"}} to={"/liste_des_expeditions/" + parcel.id}><p style={{color:"black"}}>{parcel.id} : {parcel.weight}kg</p></Link>
                        </div>)}
                    </div>
                
                </div>}
            </div>
        )
    }

    return (
        <div style={{ display:"flex", gap:10, flexDirection:"column"}}>

            <div style={{ display:"flex", gap:width>=1024?30:10, flexDirection:width>=1024?"row":"column", gridTemplateColumns:width>=1024?"15% 85%":"100%", boxSizing:"border-box", width:"100%", position:"relative"}} onClick={()=>{if (width<1024){element.id===revealed?setRevealed (""):setRevealed (element.id)}}}>

                <div className="Cadre-blanc" style={{flex:2, display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"row", margin:0, padding:0, position:"relative", opacity:1}} onClick={()=>{width<1024 && handleChange ()}}>
                    <p style={{fontWeight:700, color:"#464645", fontSize:20, margin:"10px 0px", overflow:"auto", textOverflow:"ellipsis"}}>{element.id}</p>
                    {width<1024 && <div style={{position:"absolute", right:20, alignSelf:"center", justifySelf:"center", alignItems:"center"}}>
                            <img src={Check} alt="" style={{objectFit:"contain", width:20, resize:"none", visibility:isGoing?"visible":"hidden"}}/>
                        
                    </div>}
                </div>

                
                <div className="Cadre-blanc" style={{display:"flex", flexDirection:"row", flex:6, position:"relative", padding:width>=1024?undefined:10, boxSizing:"border-box", margin:0, justifyContent:"space-between", opacity:1}}>
                    <div style={{display:"flex", flexDirection:"column", flex:6, color:"#575757", position:"relative", textAlign:"left", flexGrow:1}}>
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:30}}>
                            <img src={Position} style={{width:30, resize:"none", height:30}} alt=""/>
                            <p>{element.deliveryAddressLine1} {element.deliveryAddressLine2}, {element.deliveryZipCode}, {element.deliveryCity}</p>
                            
                        </div>
                        <ToolTip2 style={{display:"flex", flexDirection:"row", alignItems:"center", gap:30}}>
                            <CodePostaIMG style={{width:30, height:30, resize:"none"}}/>
                            <p>{element.numberOfPackages} colis pour {element.price}€</p>
                        </ToolTip2>
                        
                        {/* {width>=1024 && <div style={{position:"absolute", right:10, bottom:0, textAlign:"center"}}>
                            <p style={{backgroundColor:"#f7f7f7", padding:"5px 10px", borderRadius:7, cursor:"pointer", userSelect:"none"}} onClick={()=>revealed===element.id?setRevealed(""):setRevealed (element.id)}>Voir {revealed===element.id?"moins":"plus"}</p>
                            {props.real && <p style={{backgroundColor:"#f7f7f7", padding:"5px 10px", borderRadius:7, cursor:"pointer", userSelect:"none"}} onClick={()=>props.choose (element)}>Choisir</p>}
                            {(props.real !== true) && <p style={{backgroundColor:"#f7f7f7", padding:"5px 10px", borderRadius:7, cursor:"pointer", userSelect:"none"}} onClick={()=>setIsUpdatingContact (true)}>Modifier</p>}
                            <p style={{padding:"0px 10px", visibility:"hidden", height:0, margin:0}}>Voir moins</p>
                        </div>} */}
                    </div>
                    <div style={{height:"100%", display:"flex", alignItems:"center"}}>
                        {width>=1024 && <div className="Change-Color-Hover" style={{  cursor:"pointer", justifyContent:"center", display:"flex",   border: isGoing?"5px solid #e7ac4f":"2px solid #9a9a9a", width:60, height:60, paddingLeft:0, borderRadius:10, boxSizing:"border-box", flexDirection:"row", alignItems:"center", alignContent:"center"}} onClick={()=>{handleChange ()}}>
                            <img src={Check} alt='' style={{height:40, resize:"none", margin:12, paddingLeft:0, visibility:isGoing?"visible":"hidden"}} draggable={false}/>
                        </div>}
                    </div>
                </div>

            </div>

            {/* <Collapse in={revealed===element.id} style={{width:"100%", alignSelf:"center"}}>
                <div className="Cadre-blanc" style={{justifySelf:"center", padding:20, marginBottom:30, width:"100%", boxSizing:"border-box"}}>
                    <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                        <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom du destinataire" value={contactName} onChange={(value)=>setContactName(value.target.value)} disabled sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>
                        <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Référence" value={reference} onChange={(value)=>setReference(value.target.value)} disabled sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>
                    </div>
                    <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                        <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Mail" value={mail} onChange={(value)=>setMail(value.target.value)} disabled sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>
                        <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Téléphone" value={telephone} onChange={(value)=>setTelephone(value.target.value)} disabled sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>
                    </div>
                    <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                        <TextInput style={{marginBottom:0}} bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Instruction particulière 1" value={specialInstruction1} onChange={(value)=>setspecialInstruction1(value.target.value)} disabled sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>
                        <TextInput style={{marginBottom:0}} bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Instruction particulière 2" value={specialInstruction2} onChange={(value)=>setspecialInstruction2(value.target.value)} disabled sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}/>
                    </div>
                </div>
            </Collapse> */}
        </div>
    );
}
