import React from "react";

import { CSVLink } from "react-csv";

import { decodeToken } from "react-jwt";
import { UserToken, UserSetToken, url, widthContext } from "../context/token";

import { useLocation, useNavigate } from "react-router-dom";
import { Colis, Expedition } from "../types";

// Images
import {ReactComponent as ExportLogo} from "../assets/export.svg";
import {ReactComponent as RefreshLogo} from "../assets/refresh.svg";
import {ReactComponent as PDFLogo} from "../assets/pdf.svg";
import Back from "../assets/back.png"
import paquetLivre from "../assets/paquet-livre.svg"
import transporteur from "../assets/livraison-de-colis.svg"
import position from "../assets/Position.png"
import pdf from "../assets/pdf.png"
import ColisImage from "../assets/colis.svg";
import Check from "../assets/yellowCheck.svg"
import {ReactComponent as Chevron} from "../assets/chevron.svg";
import bonhomme from "../assets/bonhomme.svg"
import {ReactComponent as Price} from "../assets/price.svg";

import { isStillConnected } from "../utils/areYouStillConnected";

// import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { TextInput } from "../components/input";
import { SelectInput } from "../components/select";
import { Backdrop, Collapse, MenuItem, Pagination, Tooltip } from "@mui/material";
import { Connexion } from "../utils/connexion";


const headerCSV = [
    {label : "Numéro du colis", key : "parcelNumber"}, 
    {label : "Dernier statut du colis", key : "lastStatus"}, 
    {label : "Transporteur en charge de l'expédition", key : "carrier"}, 
    {label : "Poids du colis", key : "weight"}, 
    {label : "Adresse de ramassage 1", key :"pickupAddressLine1"},
    {label : "Adresse de ramassage 2", key :"pickupAddressLine2"},
    {label : "Code postal de ramassage", key :"pickupZipCode"},
    {label : "Ville de ramassage", key : "pickupCity"},
    {label : "Ville d'expédition 1", key : "deliveryAddressLine1"},
    {label : "Ville d'expédition 2", key : "deliveryAddressLine2"},
    {label : "Code postal d'expédition", key : "deliveryZipCode"},
    {label : "Ville d'expédition", key : "deliveryCity"},
    {label : "Date d'expédition", key : "deliveryDate"},
]


const listFile = ["Expeditions_En_Cours_", "Expeditions_Passees_", "Expeditions_En_Retour_"]

function ListeDesExpeditions  ()  {
    // Pour gérer l'authentification
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)
    let decodedToken:any = decodeToken (token["accessToken"]) 
    const role = decodedToken["role"]
    
    ////////////////////////////////

    // Pour gérer la liste des expéditions, et le système de pagination
    const [expeditionsList, setExpeditionList] = React.useState ([
        new Array<Colis> (),
        new Array<Colis> (),
        new Array<Colis> (),
        new Array<Colis> (),
    ]) 
    
    const [pageNb, setPageNb] = React.useState (1)
    
    const pageArg =  useLocation().pathname.split ("/")
    React.useEffect (()=>{
        pageArg[2]!==undefined && setnbListOnScreen (+pageArg[2])
    }, [pageArg])
    // const [nbListOnScreen, setnbListOnScreen] = React.useState (+pageArg[2]!==undefined?+pageArg[2]:0)
    const [nbListOnScreen, setnbListOnScreen] = React.useState (0)

    const [filteredList, setFilteredList] = React.useState (expeditionsList[nbListOnScreen])
    const reductedList = filteredList.slice ((pageNb-1)*20, (pageNb*20))

    function changeListOnScreen (nbListToPrint:number){
        setnbListOnScreen (nbListToPrint); 
        setPageNb (1); 
        navigate (`/liste_des_expeditions/${nbListToPrint}`)
        setFilteredList (expeditionsList[nbListToPrint]);
    }


    ///////////////////////////////////////////////////////////////////

    // Pour gérer le système de suppression de masse d'expédition
    const [nbGoing, setNbGoing] = React.useState (0)
    const [isSelectingAll, setIsSelectingAll] = React.useState (false)
    const [isHoverSelectAll, setIsHoverSelectAll] = React.useState (false)
    function SelectAll () {
        const temp = expeditionsList.slice ()
        for (let i=0; i<temp.length; i++){
            if (filteredList.includes (temp[nbListOnScreen][i])){
                temp[nbListOnScreen][i]["isGoing"]=!isSelectingAll
            }
        }
        // expeditionsList[nbListOnScreen].map (element => element.isGoing=!isSelectingAll)
        setExpeditionList (temp)
        setFilteredList (temp[nbListOnScreen])
        
        setNbGoing (temp[nbListOnScreen].filter ((element)=>{return element.isGoing===true}).length)
        
        setIsSelectingAll (!isSelectingAll)
    }
    function isEverythingChecked (list:Array<Colis>):React.SetStateAction<boolean>{
        for (let i=0; i<list.length; i++){
            if (list[i]["isGoing"]===false){
                return false
            }
        }
        return true
    }
    React.useEffect (()=>{
        setIsSelectingAll (isEverythingChecked(filteredList)) // eslint-disable-next-line
    }, [filteredList])
    const [tryToRemove, setTryToRemove] = React.useState (false)
    async function archiveExpedition(id:string){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/expedition/archive', {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
                "expeditionId":id
            })
        })
        const json = await (await response).json();
        if (json.statusCode===undefined){
            getExpeditions ()
            setNbGoing (0)
        } else {
            console.log (json)
        }
    }    
    async function archiveExpeditions (){
        const listToArchive:Array<Colis> = expeditionsList[nbListOnScreen].filter((obj) => {return obj["isGoing"] === true})
        const listOfExpeditionsId:Array<string> = listToArchive.map(item => item.expeditionId).filter((value, index, self) => self.indexOf(value) === index)
        listOfExpeditionsId.map ((element:string)=>archiveExpedition (element))
    }
    ///////////////////////////////////////////////////////////////////

    // Gère l'affichage du pop-up de l'exportation des données en CSV
    const [tryToExport, setTryToExport] = React.useState (false)
    /////////////////////////////////////////////////////////////////


    // Pour gérer si on affiche la liste des expéditions complète ou seulement une expédition
    const [elementIdToPrint, setElementIdToPrint] = React.useState (-1)
    const elementToPrint = elementIdToPrint !== -1 ? 
                                expeditionsList[nbListOnScreen][elementIdToPrint]
                                :
                                {} as Colis
    const navigate = useNavigate ()
    // React.useEffect (()=>{
    //     if (location !== "/liste_des_expeditions"){
    //         const id = location.slice (23)
    //         const index = expeditionsList[nbListOnScreen].indexOf (expeditionsList[nbListOnScreen].filter((obj) => {return obj["id"] === id})[0])
    //         setElementIdToPrint (index)
    //     } else {
    //         setElementIdToPrint (-1)
    //     }
    // }, [location, expeditionsList, nbListOnScreen])
    React.useEffect (()=>{
        if (pageArg[3]!==undefined){
            const id = pageArg[3]
            const index = expeditionsList[nbListOnScreen].indexOf (expeditionsList[nbListOnScreen].filter((obj) => {return obj["id"] === id})[0])
            setElementIdToPrint (index)
        } else {
            setElementIdToPrint (-1)
        }
    }, [pageArg, expeditionsList, nbListOnScreen])
    const [isSeeingHistory, setIsSeeingHistory] = React.useState (false)


    //////////////////////////////////////////////////////////////////////////////////////////////

    // Pour gérer les filtres
    const [numeroFiltre, setNumeroFiltre] = React.useState ("");
    function handleChangeNumeroFiltre (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (!Number.isNaN (+event.target.value)){
            setNumeroFiltre (event.target.value)
        }
    }

    // var statutList = expeditionsList[nbListOnScreen].map(item => item["lastStatus"].content).filter((value, index, self) => self.indexOf(value) === index)
    var statutList = ["1", "2"]
    const [statutFiltre, setStatutFiltre] = React.useState ("");

    const [dateFiltre, setDateFiltre] = React.useState ("");
    
    var carrierList = expeditionsList[nbListOnScreen].map(item => item.carrier).filter((value, index, self) => self.indexOf(value) === index)
    const [carrierFiltre, setCarrierFiltre] = React.useState ("");

    const [zipCodeFiltre, setZipCodeFiltre] = React.useState ("")
    function handleChangeZipCodeFiltre (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
        if (!Number.isNaN (+event.currentTarget.value)){
            setZipCodeFiltre (event.currentTarget.value)
        }
    }
    const [referenceFiltre, setReferenceFiltre] = React.useState ("");
    function handleChangeReferenceFiltre (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setReferenceFiltre (event.currentTarget.value)
    }
    
    React.useEffect (()=>{
        var temp = expeditionsList[nbListOnScreen].slice ()
        if (carrierFiltre!==""){            
            temp = temp.filter((obj) => {return obj['carrier'] === carrierFiltre})
        }
        if (numeroFiltre!==""){
            temp = temp.filter((obj) => {return obj.parcelNumber.toString ().slice (0, numeroFiltre.length) === numeroFiltre}) 
        }
        if (statutFiltre!==""){
            temp = temp.filter((obj) => {return obj["lastStatus"] === statutFiltre}) 
        } 
        if (dateFiltre!==""){
            temp = temp.filter((obj) => {return obj["pickupDate"].toString ().slice (0, dateFiltre.length) === dateFiltre}) 
        }
        if (zipCodeFiltre!==""){
            temp = temp.filter((obj) => {return obj["deliveryZipCode"].slice (0, zipCodeFiltre.length) === zipCodeFiltre}) 
        } 
        // if (referenceFiltre!==""){
        //     temp = temp.filter((obj) => {return obj["Reference"].slice (0, referenceFiltre.length) === referenceFiltre}) 
        // } 
        setFilteredList (temp)
    }, [expeditionsList, nbListOnScreen, carrierFiltre, numeroFiltre, statutFiltre, dateFiltre, zipCodeFiltre]) //referenceFiltre
    ///////////////////////////////////////////////

    // Pour gérer l'obtention et l'actualisation des expéditions
    const [isLoading, setIsLoading] = React.useState (true)
    async function getExpeditions () {
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/expedition/true', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token["accessToken"]}`
            },
        });
        const json = await (await response).json();
        defineList (json)
    }

    const [hasRefreshed, setHasRefreshed] = React.useState (false)
    async function refreshExpeditionsStatus (){
        setIsLoading (true)
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/expedition/status', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token["accessToken"]}`
            },
        });
        const json = await (await response).json();
        defineList (json)
        setHasRefreshed (true)
        
    }
    function compare (a:Expedition, b:Expedition){
        const date1 = new Date (a.pickupDate)
        const date2 = new Date (b.pickupDate)
        if (date1<date2){
            return 1
        } else {
            return -1
        }
    }
    async function defineList (json:Array<Expedition>){
        await json.sort (compare)
        const list1 =  new Array<Colis> ()
        const list2 =  new Array<Colis> ()
        const list3 =  new Array<Colis> ()
        const list4 =  new Array<Colis> ()
        for (let i=0; i<json.length; i++) {
            const expedition = json[i]
            for (let i=0; i<expedition.parcels.length; i++) {
                const colis = expedition.parcels[i]
                var element = {
                    id:                   colis.id,
                    expeditionId:         expedition.id,
                    parcelNumber:         colis.parcelNumber,
                    weight:               colis.weight,
                    dimensions:           colis.dimensions,
                    expeditionPath:       colis.expeditionPath,
                    lastStatus:           colis.lastStatus,
                    statusHistory:        colis.statusHistory,
                    isGoing:              false,
                    carrier:              expedition.carrier.name,
                    deliveryName:         expedition.deliveryName,
                    deliveryAddressLine1: expedition.deliveryAddressLine1,
                    deliveryAddressLine2: expedition.deliveryAddressLine2,
                    deliveryZipCode:      expedition.deliveryZipCode,
                    deliveryCity:         expedition.deliveryCity,
                    pickupDate:           expedition.pickupDate,
                    pickupName:           expedition.pickupName,
                    pickupAddressLine1:   expedition.pickupAddressLine1,
                    pickupAddressLine2:   expedition.pickupAddressLine2,
                    pickupZipCode:        expedition.pickupZipCode,
                    pickupCity:           expedition.pickupCity,
                    pickupPhoneNumber:    expedition.pickupPhoneNumber,
                    pickupClosingTime:    expedition.pickupClosingTime,
                    deliveryDate:         expedition.deliveryDate,
                    pickupNumber:         expedition.pickupNumber,
                    subAccount:           expedition.subAccount,
                    price:                expedition.price
                }

                
                if (element["lastStatus"]===undefined && element["pickupNumber"]!==null) {
                    // element["lastStatus"] = "À ramasser"
                    element["lastStatus"] = "À ramasser"
                    list1.push (element)
                } else {
                    if (element["lastStatus"]==="Le colis a été livré"){
                        list3.push (element)
                    } else {
                        if (element["lastStatus"]!==null){
                            list2.push (element)
                        }
                    }
                }
            }
        }
        setExpeditionList ([list1, list2, list3, list4])
        setFilteredList ([list1, list2, list3, list4][nbListOnScreen]) 
        setIsLoading (false)
    }
    
    React.useEffect (()=>{
        Connexion ()
        getExpeditions ()
         // eslint-disable-next-line
    }, [])
    /////////////////////////////////////////////////

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////

    // Pour obtenir les PDF des étiquettes
    async function getPDF (pdf:string){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/expedition/parcellabel/'+pdf, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
                },
            });
        const url2 = (await (await response).json()).url;
        console.log(url2)
        const response2 = fetch (url2, {
            method: 'GET',
        });
        const file = new Blob(
            [await (await response2).blob()],
            {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    ///////////////////////////////////////


    const [isHover3, setIsHover3] = React.useState (false)
    const [isHover, setIsHover] = React.useState (false)
    const [isHover2, setIsHover2] = React.useState (false)

    const [tryToFilter, setTryToFilter] = React.useState (false)
    function removeAllFilter (){
        setNumeroFiltre ("")
        setStatutFiltre ("")
        setDateFiltre ("")
        setCarrierFiltre ("")
        setZipCodeFiltre ("")
        setReferenceFiltre ("")
    }

    // Component Expedition à boucler
    function ExpeditionComponent (props:{element:Colis}){
        const element:Colis = props.element
        const index:number = expeditionsList[nbListOnScreen].indexOf (element)
       
        const [isGoing, setIsGoing] = React.useState (element["isGoing"])
        
        function handleChangeIsGoing (){
            var temp:Array<Array<Colis>> = expeditionsList.slice ()
            temp[nbListOnScreen][index+(pageNb-1)*20]["isGoing"] = !isGoing
            if (!isGoing){
                setNbGoing (nbGoing+1)
            } else {
                setNbGoing (nbGoing-1)
            }
            temp[nbListOnScreen].indexOf (element)
            var temp2 = temp[nbListOnScreen]
            setExpeditionList(temp)
            if (carrierFiltre!==""){            
                temp2 = temp2.filter((obj) => {return obj['carrier'] === carrierFiltre})
            }
            if (numeroFiltre!==""){
                temp2 = temp2.filter((obj) => {return obj.parcelNumber.toString ().slice (0, numeroFiltre.length) === numeroFiltre}) 
            }
            if (statutFiltre!==""){
                temp2 = temp2.filter((obj) => {return obj["lastStatus"] === statutFiltre}) 
            } 
            if (dateFiltre!==""){
                temp2 = temp2.filter((obj) => {return obj["pickupDate"].toString ().slice (0, dateFiltre.length) === dateFiltre}) 
            }
            if (zipCodeFiltre!==""){
                temp2 = temp2.filter((obj) => {return obj["deliveryZipCode"].slice (0, zipCodeFiltre.length) === zipCodeFiltre}) 
            } 
            // if (referenceFiltre!==""){
            //     temp = temp.filter((obj) => {return obj["Reference"].slice (0, referenceFiltre.length) === referenceFiltre}) 
            // } 
            
            setFilteredList(temp2)
            setIsGoing (!isGoing)
        }
        return (
            <div className="hoverClickable" key={element["id"]}  style={{cursor:"pointer", width:"100%", margin:0, padding:20, boxSizing:"border-box", display: "flex", flexDirection: "row", alignItems: "center", justifyItems: "center"}}>
                <div style={{display:"flex", flexDirection:width>1200?"row":"column", width:"100%"}}>
                <div style={{display:"flex", flexDirection:width>780?'row':"column", width:"100%", justifyContent:width>780?"space-evenly":"left"}} onClick={()=>{window.scrollTo(0, 0); navigate ("/liste_des_expeditions/" + nbListOnScreen + "/" + element["id"].toString())}}>
                    <div style={{textAlign:"left",  display: "flex", flexDirection:"column", justifyContent:"center"}}>
                        <span style={{color:"#e7ac4f", fontSize:22, fontWeight:700, marginLeft:0}}>{element.parcelNumber}</span>
                        <span style={{color:"#747474", fontWeight:600}}>Numéro du colis</span>
                    </div>
                
                
                    <Tooltip title={element.lastStatus===null?"":element.lastStatus}>
                        <div className="table4" style={{alignItems:"center",  justifyContent:"left", display:"flex", flexDirection:"row"}}>
                        
                            <img src={paquetLivre} alt='' style={{height:40, width:40, resize:"none", margin:12}} draggable={false}/>
                            <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left"}}>{element.lastStatus}</p>
                        
                        </div>
                    </Tooltip>
                
                
                    <div className="table4" style={{ alignItems:"center",  justifyContent:"left", display:"flex", flexDirection:"row"}}>
                        <img src={transporteur} alt='' style={{width:60, resize:"none", margin:12}} draggable={false}/>
                        <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left"}}>{element.carrier}</p>
                    </div>
                
                
                    <div className="table4" style={{ justifyItems:"center", display:"flex", flexDirection:"row",justifyContent:"left", alignItems:"center"}}>
                        <img src={position} alt='' style={{height:40, resize:"none", margin:12}} draggable={false}/>
                        <p style={{color:"#747474", fontWeight:600, fontSize:20, textAlign:"left"}}>{element.deliveryAddressLine1} {element.deliveryAddressLine2}</p>
                    </div>
                </div>

                    {width>780 ?
                        <div className="table4" style={{ cursor:"pointer", flex:0.3, justifyContent:"center", display:"flex", flexDirection:"row", alignItems:"center", alignContent:"center"}} onClick={()=>{getPDF (element.expeditionPath)}}>
                            <PDFLogo style={{width: "20px", height: "20px", resize: "none", marginRight:20, }}/>
                        </div>
                        :
                        <div style={{display:"grid", gridTemplateColumns:"20% 80%", flexDirection:"row", alignItems:"center", cursor:"pointer"}} className="JE" onClick={()=>getPDF(element.expeditionPath)}>
                            <img src={pdf} alt='' style={{height:50, resize:"none", margin:12, justifySelf:"center"}} draggable={false}/>
                            <p style={{display:"flex", flexDirection:"row", alignItems:"center"}}>Afficher l'étiquette</p>
                        </div>
                    }
                </div>
                
                
                
                {nbListOnScreen===0 &&
                <div className="table4 Change-Color-Hover" style={{  cursor:"pointer", justifyContent:"center", display:"flex",   border: isGoing?"5px solid #e7ac4f":"2px solid #9a9a9a", width:60, height:60, paddingLeft:0, borderRadius:10, boxSizing:"border-box", flexDirection:"row", alignItems:"center", alignContent:"center"}} onClick={()=>{handleChangeIsGoing()}}>
                    <img src={Check} alt='' style={{height:40, resize:"none", margin:12, paddingLeft:0, visibility:isGoing?"visible":"hidden"}} draggable={false}/>
                </div>}
                
            </div>
        )
    }
    /////////////////////////////////////////////////////////////

    // Système de gestion de la liste affichée à l'écran pour mobile
    const listOfScreen = [
        "Expéditions à ramasser",
        "Expéditions en cours de livraison",
        "Expéditions livrées",
        "Expéditions en retour"
    ]
    function goToPreviousScreen():void{
        var nbTemp = nbListOnScreen - 1
        if (nbTemp < 0) {
            nbTemp += 4
        }
        changeListOnScreen(nbTemp)
    }
    function goToNextScreen ():void{
        var nbTemp = nbListOnScreen + 1
        if (nbTemp > 3) {
            nbTemp -= 4
        }
        changeListOnScreen(nbTemp)
    }
     ///////////////////////////////////////////////////////////////

    const [headerHeight, setHeaderHeight] = React.useState (60)
    React.useEffect(() => {
        let header = document.getElementById("header");
        if (header) {
            setHeaderHeight (header.clientHeight)
        }
    
      }, [])
    return (
        <>
        {(elementIdToPrint===-1) &&
        <div className="table2" style={{margin: "0px 20px 20px 20px"}}>
            {width>1200 ?
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%", justifySelf:'center', position:"sticky", top:headerHeight+40, zIndex:3, pointerEvents: "none", userSelect:"none"}}>
                    <div style={nbListOnScreen===0?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={() => {!isLoading && changeListOnScreen(0)}}>
                        <p>Expéditions à ramasser</p>
                    </div>
                    <div style={nbListOnScreen===1?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={() => {changeListOnScreen(1)}}>
                        <p>Expéditions en cours de livraison</p>
                    </div>
                    <div style={nbListOnScreen===2?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={() => {changeListOnScreen(2)}}>
                        <p>Expéditions livrées</p>
                    </div>
                    <div style={nbListOnScreen===3?{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}:{color:"#767675", padding:"10px 20px", cursor:"pointer", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition Change-Color-Hover" onClick={() => {changeListOnScreen(3)}}>
                        <p>Expéditions en retour</p>
                    </div>
                </div>
                :
                <div style={{display:"flex", flexDirection:"row", position:"sticky", top:headerHeight+40, alignItems:"center", zIndex:3, userSelect:"none", pointerEvents:"none"}}>
                    <Chevron style={{margin:"0px 30px", rotate:"90deg", cursor:"pointer", pointerEvents:"visible"}} onClick={()=>goToPreviousScreen()}/>
                    <div style={{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents:"visible"}} className="Cadre-blanc Header-expedition Change-Color-Hover">
                        <p>{listOfScreen[nbListOnScreen]}</p>
                    </div>
                    <Chevron style={{margin:"0px 30px", rotate:"270deg", cursor:"pointer", pointerEvents:"visible"}} onClick={()=>goToNextScreen()}/>
                </div>
            }
            {nbGoing>0 && nbListOnScreen===0 &&
                <div style={{zIndex:5,bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column", justifyContent: "space-between", boxSizing:"content-box", userSelect:"none"}}>

                    <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw"}} onClick={()=>{setTryToRemove (true)}}>
                        <p>Supprimer</p>
                    </div>
                    
                </div>
            }
            
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={tryToRemove && nbGoing>0} onClick={()=>setTryToRemove (false)}>
                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 200px)", padding:20}}>
                        <h2 style={{ marginBottom:20}}>Supprimer {nbGoing} expédition{nbGoing>1?"s":""} ?</h2>
                        
                        <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
                            <div className="Change-Color-Hover" style={{backgroundColor:"white", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setTryToRemove(false)}}>
                                <p style={{color:"#deae5f"}}>Annuler</p>
                            </div>
                            <div className="Change-Color-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setTryToRemove (false); archiveExpeditions()}}>
                                <p style={{color:"white"}}>Confirmer</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Backdrop>
            
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={tryToExport} onClick={()=>setTryToExport (false)}>
                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 200px)", padding:20}}>
                        <h2 style={{ marginBottom:20}}>Que voulez vous exporter en CSV ?</h2>
                        
                            <div className='Logout-button' onClick={() => setTryToExport (false)} style={{cursor:"pointer", padding:5}}>
                                {/* <p style={{margin:"15px 20px", color:"white"}}>Exporter les éléments à l'écran</p> */}
                                <CSVLink data={filteredList} headers={headerCSV} style={{textDecoration: 'none'}} filename={listFile[nbListOnScreen]+ Date().split(" ").join("_").slice(0, 21)}>
                                    <p style={{margin:"15px 20px", color:"white"}}>Exporter les éléments à l'écran</p>
                                </CSVLink>
                            </div>
                            <div className='Logout-button' onClick={() => {setTryToExport (false)}} style={{cursor:"pointer", padding:5}}>
                                {/* <p style={{margin:"15px 20px", color:"white"}}>Exporter toutes les tables</p> */}
                                <CSVLink data={expeditionsList[0].concat(expeditionsList[1]).concat(expeditionsList[2])} headers={headerCSV}  style={{textDecoration: 'none'}} filename={"Expeditions_"+ Date().split(" ").join("_").slice(0, 21)}>
                                    <p style={{margin:"15px 20px", color:"white"}}>Exporter toutes les tables</p>
                                </CSVLink>
                            </div>
                    </div>
                </div>
            </Backdrop>
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={hasRefreshed} onClick={()=>setHasRefreshed (false)}>
                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div className="Cadre-blanc"  onClick={(e)=>e.stopPropagation()} style={{height:"min-content", width:"min (100%, 200px)", padding:20}}>
                        <h2 style={{ marginBottom:20}}>Actualisation des statuts effectuée</h2>
                        
                        <div style={{display:"flex", flexDirection:"row-reverse", width:"100%", justifyContent:"space-between"}}>
                            <div className="Change-Color-Hover Grow-Hover" style={{backgroundColor:"#deae5f", cursor:"pointer", padding:5, paddingLeft:20, paddingRight:20, borderRadius:15}}  onClick={()=> {setHasRefreshed(false)}}>
                                <p style={{color:"white", margin:0}}>OK</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Backdrop>
            <div className="table" style={{margin:0, width:"100%", marginTop:20}}>
                {expeditionsList[nbListOnScreen].length>0 && !isLoading?
                <>
                
                <div style={{ display:"flex" ,alignItems:"flex-end", flexDirection:width>780?"row-reverse":"row", overflowX:width<780?"scroll":"unset", userSelect:"none"}}>
                    
                    {width<1000 &&
                        <div onClick={()=>setTryToFilter(true)} style={{display:"flex", flexDirection:"column",  alignItems:"center", margin:30, width:"min-content"}} >
                            <div className="test Input-password" style={{display:"flex", flexDirection:"row", alignItems:"center", width:"max-content"}} onMouseEnter={()=>setIsHover3 (true)} onMouseLeave={()=>setIsHover3 (false)}>
                                <p style={{marginLeft:20, marginRight:20, fontWeight:500}}>Filtrer</p>
                            </div>
                            <div  style={{width:"100%", height:3, backgroundColor:isHover3?"#f9b233":"#747474"}}></div>
                        </div>
                    }

                    <div onClick={()=>setTryToExport(true)} style={{display:"flex", flexDirection:"column",  alignItems:"center", margin:30, width:"min-content"}} >
                        <div className="test Input-password" style={{display:"flex", flexDirection:"row", alignItems:"center", width:"max-content"}} onMouseEnter={()=>setIsHover (true)} onMouseLeave={()=>setIsHover (false)}>
                            <p style={{marginLeft:20, marginRight:10, fontWeight:500}}>Exporter en CSV</p>
                            <ExportLogo style={{width: "20px", height: "20px", resize: "none", marginRight:20, fill:isHover?"#f9b233":"#747474"}}/>
                        </div>
                        <div  style={{width:"100%", height:3, backgroundColor:isHover?"#f9b233":"#747474"}}></div>
                    </div>
                    {/* Bouton pour "Tout sélectionner" */}
                    {nbListOnScreen===0 && 
                    <div onClick={()=>SelectAll()}  style={{display:"flex", flexDirection:"column",  alignItems:"center", margin:30, width:"min-content", justifyContent:"center", justifyItems:"center"}} >
                        <div className="test" style={{display:"flex",  alignItems:"center", width:"max-content"}} onMouseEnter={()=>setIsHoverSelectAll (true)} onMouseLeave={()=>setIsHoverSelectAll (false)}>
                            <p style={{marginLeft:20, marginRight:20, fontWeight:500}}>{isSelectingAll?"Tout désélectionner":"Tout sélectionner"}</p>
                            </div>
                        <div  style={{width:"100%", height:3, backgroundColor:isHoverSelectAll?"#f9b233":"#747474"}}></div>
                    </div>
                    }
                    {/* /////////////////////////////// */}
                    {/* Bouton pour actualiser les statuts */}
                    {role==="admin" && 
                    <div style={{display:"flex", flexDirection:"column",  alignItems:"center", margin:30, width:"min-content"}} >
                        <div className="test Input-password" style={{display:"flex", flexDirection:"row", alignItems:"center", width:"max-content"}} onMouseEnter={()=>setIsHover2 (true)} onMouseLeave={()=>setIsHover2 (false)} onClick = {()=> refreshExpeditionsStatus ()}>
                            <p style={{marginLeft:20, marginRight:10, fontWeight:500}}>Mettre à jour les statuts</p>
                            <RefreshLogo  style={{width: "20px", height: "20px", resize: "none", marginRight:20, fill:isHover2?"#f9b233":"#747474"}}/>
                        </div>
                        <div  style={{width:"100%", height:3, backgroundColor:isHover2?"#f9b233":"#747474"}}></div>
                    </div>
                    }
                    {/* ///////////////////////////////// */}
                </div>
                {width>1000 &&
                    <div style={{display:"flex" ,justifyItems:"center", flexDirection:"row", width:"95%", alignSelf:"center", userSelect:"none", justifyContent:"space-evenly"}}>
                        
                        <div style={{marginRight:10, flex:1}}>
                            <TextInput width="100%" label="Numéro" bordercolor="#747474" value={numeroFiltre}  onChange={handleChangeNumeroFiltre} />
                        </div>
                        <div  style={{marginLeft: 10, marginRight:10, flex:1}}>
                            <SelectInput width="100%" label="Statut" bordercolor="#747474" value={statutFiltre} onChange={(props: { target: { value: React.SetStateAction<string>; }; })=>setStatutFiltre (props.target.value)}>
                                <MenuItem value="">Pas de filtre</MenuItem>
                                {statutList.map ((element:string)=>
                                    <MenuItem style={{backgroundColor:(statutFiltre===element)?"#ffe695":""}} value={element} key={element}>{element}</MenuItem>
                                    )}
                            </SelectInput>
                        </div>
                        <div style={{marginLeft: 10, marginRight:10, cursor:"pointer", flex:1}}>
                            <TextInput width="100%" label={"Date"} bordercolor="#747474" value={dateFiltre} onChange={(props)=>setDateFiltre (props.target.value)} type="date" InputProps={{startAdornment:<></>}}/>
                        </div>
                        <div style={{marginLeft: 10, marginRight:10, flex:1}}>
                            <SelectInput width="100%" label="Transporteur" bordercolor="#747474" value={carrierFiltre} onChange={(props: { target: { value: React.SetStateAction<string>; }; })=>setCarrierFiltre (props.target.value)}>
                                <MenuItem value="">Pas de filtre</MenuItem>
                                {carrierList.map ((element:string)=>
                                    <MenuItem style={{backgroundColor:(statutFiltre===element)?"#ffe695":""}} value={element} key={element}>{element}</MenuItem>
                                )}
                            </SelectInput>
                        </div>
                        <div style={{marginLeft: 10, marginRight:10, flex:1}}>
                            <TextInput width="100%" label="Code Postal" bordercolor="#747474" value={zipCodeFiltre}  onChange={handleChangeZipCodeFiltre}/>
                        </div>
                        <div style={{marginLeft: 10, flex:1}}>
                            <TextInput width="100%" label="Référence" bordercolor="#747474" value={referenceFiltre}  onChange={handleChangeReferenceFiltre}/>
                        </div>
                    </div>
                }
                {width<=1000 && tryToFilter &&
                <div style={{display:"flex" ,justifyItems:"center", flexDirection:"column", width:"100vw",height:"100vh", top:0,alignSelf:"center", position:"fixed", backgroundColor:"white", zIndex:5, justifyContent:"center", borderRadius:30, padding:20, boxSizing:"border-box", overflowY:'auto', userSelect:"none"}}>
                    <h2>Filtres</h2>
                    <TextInput width="100%" label="Numéro" bordercolor="#747474" value={numeroFiltre}  onChange={handleChangeNumeroFiltre} />
                    <SelectInput style={{marginBottom:20}} width="100%" label="Statut" bordercolor="#747474" value={statutFiltre} onChange={(props: { target: { value: React.SetStateAction<string>; }; })=>setStatutFiltre (props.target.value)}>
                        <MenuItem value="">Pas de filtre</MenuItem>
                        {statutList.map ((element:string)=>
                            <MenuItem style={{backgroundColor:(statutFiltre===element)?"#ffe695":""}} value={element} key={element}>{element}</MenuItem>
                        )}
                    </SelectInput>
                    <TextInput width="100%" label={dateFiltre===""?"":"Date"} bordercolor="#747474" value={dateFiltre} onChange={(props)=>setDateFiltre (props.target.value)} type="date"/>
                    <SelectInput style={{marginBottom:20}} width="100%" label="Transporteur" bordercolor="#747474" value={carrierFiltre} onChange={(props: { target: { value: React.SetStateAction<string>; }; })=>setCarrierFiltre (props.target.value)}>
                        <MenuItem value="">Pas de filtre</MenuItem>
                        {carrierList.map ((element:string)=>
                            <MenuItem style={{backgroundColor:(statutFiltre===element)?"#ffe695":""}} value={element} key={element}>{element}</MenuItem>
                        )}
                    </SelectInput>
                    <TextInput width="100%" label="Code Postal" bordercolor="#747474" value={zipCodeFiltre}  onChange={handleChangeZipCodeFiltre}/>
                    <TextInput width="100%" label="Référence" bordercolor="#747474" value={referenceFiltre}  onChange={handleChangeReferenceFiltre}/>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                    <span className="table" style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center"}} onClick={()=> {removeAllFilter()}}>
                        <p className="table" style={{color:"white"}}>Supprimer tous les filtres</p>
                    </span>
                    <span className="table" style={{backgroundColor:"#deae5f", cursor:"pointer", display:'flex', alignItems:"center", justifyContent:"center"}}  onClick={()=> {setTryToFilter(false)}}>
                        <p className="table" style={{color:"white"}}>Appliquer</p>
                    </span>
                </div>
                </div>
                }
                {reductedList.map ((element:Colis, index:number) =>
                    <div key={index} style={{display:"flex" ,justifyItems:"center", flexDirection:"column", width:"100%", position:"relative"}}>
                        <ExpeditionComponent element={element}/>
                        
                        {index!==reductedList.length-1 &&
                            <div style={{width:"90%", alignSelf:"center", justifySelf:"center", height:3, backgroundColor:'#9a9a9a', borderRadius:10}}></div>
                        }
                        
                    </div>
                )}       
            
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", userSelect:"none", marginTop:10, marginBottom:20}}>
                    {/* <span className="table" style={pageNb>1?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}} onClick={()=> {goToPreviousPage()}}>
                        <p className="table" style={{color:"white"}}>Page précédente</p>
                    </span>
                    <p style={{cursor:"default"}}>{pageNb}/{Math.floor((filteredList.length-1)/20)+1}</p>
                    <span className="table" style={pageNb<filteredList.length/20?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}}  onClick={()=> {goToNextPage()}}>
                        <p className="table" style={{color:"white"}}>Page suivante</p>
                    </span> */}
                    <Pagination count={filteredList.length!==0? Math.floor((filteredList.length-1)/20)+1:1} size="large"  onChange={(_event, number)=>setPageNb (number)}/>
                </div>
                </>:isLoading?<CircularProgress sx={{color:"#deae5f", alignSelf:"center", margin:15}}/>: <p>Pas de données à afficher</p>}
                
                
            </div>
        </div>
        }
        {(elementIdToPrint!==-1) &&
        <div style={{margin:"0px 20px"}}>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", width:"100%", position:"relative"}}>
                <div style={{ marginRight:20, boxSizing:"border-box", display:"grid", alignContent:"center", justifyContent:"center", cursor:"pointer", width:width>780?"unset":60}} className="Cadre-blanc Change-Color-Hover Input-password" onClick={()=>{navigate (`/liste_des_expeditions/${nbListOnScreen}`)}}>
                    <img src={Back} style={{height:40, resize:"none"}} alt="go back" draggable={false}/>
                </div>
                <div style={{backgroundColor:"#e7ac4f", color:"#fefbf8",  marginRight:20, padding:10}} className="Cadre-blanc Header-expedition" onClick={() => {setnbListOnScreen (0)}}>
                    <p>Détails de l'expédition</p>
                </div>
                
            </div>
            <div className="Cadre-blanc" style={{position:"relative",  overflowX:"unset", width:"100%", margin:"20px 0px", display:"flex", flexDirection:"column", justifyContent:"center", }}>
                <div style={{textAlign:"left", width:width>750?"50%":"100%", alignSelf:"center", display:"flex", flexDirection:"column", justifyContent:"center", padding:30, boxSizing:"border-box"}}>
                    
                    {(role==="admin" || role==="account") &&
                        <div style={{display:"grid", gridTemplateColumns:width>600?"20% 40% 40%":"20% 80%", flexDirection:"row", alignItems:"center"}}>
                            <img src={bonhomme} alt='' style={{height:40, width:40, resize:"none", margin:12, justifySelf:"center"}} draggable={false}/>
                            {width>600 && <p style={{display:"flex", flexDirection:"row", alignItems:"center"}}>Compte : </p>}
                            <p style={{color:"#747474", fontWeight:600, fontSize:20, marginLeft:10, cursor:"pointer"}} className="JE" onClick={()=>navigate ("/subaccount/"+elementToPrint.subAccount.id)}>{elementToPrint["subAccount"]["name"]}</p>
                        </div>
                    }

                    <div style={{display:"grid", gridTemplateColumns:width>600?"20% 40% 40%":"20% 80%", flexDirection:"row", alignItems:"center"}}>
                        <img src={ColisImage} alt='' style={{height:40, width:40, resize:"none", margin:12, justifySelf:"center"}} draggable={false}/>
                        {width>600 && <p style={{display:"flex", flexDirection:"row", alignItems:"center"}}>Numéro du colis : </p>}
                        <p style={{color:"#747474", fontWeight:600, fontSize:20, marginLeft:10}}>{elementToPrint["parcelNumber"]}</p>
                    </div>


                    <Tooltip style={{width:"100%", height:"100%", margin:0}} title={isSeeingHistory?"Masquer l'historique des statuts":"Voir l'historique des statuts"}>
                        <div className="Change-Color-Hover" style={{display:"grid", gridTemplateColumns:width>600?"60% 40%":"20% 80%", flexDirection:"row", alignItems:"center", cursor:"pointer", borderRadius:15}} onClick={()=>setIsSeeingHistory (!isSeeingHistory)}>
                                <div style={{display:"grid", gridTemplateColumns:width>600?"33% 66% ":"20% 80%"}}>
                                    <img src={paquetLivre} alt='' style={{height:40, width:40, resize:"none", margin:12, justifySelf:"center"}} draggable={false}/>
                                    {width>600 && <p style={{display:"flex", flexDirection:"row", alignItems:"center"}}>{isSeeingHistory?"Historique des statuts de ce colis :":"Dernier statut du colis : "}</p>}
                                </div>
                            
                            <div>
                                <p style={{color:"#747474", fontWeight:600, fontSize:20, marginLeft:10}}>{elementToPrint.lastStatus}</p>
                                <Collapse in={isSeeingHistory} style={{width:"100%", alignSelf:"center"}}>
                                    {elementToPrint.statusHistory.map ((element:string)=>
                                        <p style={{color:"#747474", fontWeight:600, fontSize:20, marginLeft:10}} key={element}>{element}</p>
                                    )}
                                </Collapse>
                                
                            </div>
                        </div>
                    </Tooltip>

                    {/* <div style={{display:"grid", gridTemplateColumns:width>600?"20% 40% 40%":"20% 80%", flexDirection:"row", alignItems:"center", cursor:"pointer"}} onClick={()=>setIsSeeingHistory (!isSeeingHistory)}>
                        <img src={paquetLivre} alt='' style={{height:40, width:40, resize:"none", margin:12, justifySelf:"center"}} draggable={false}/>
                        {width>600 && <p style={{display:"flex", flexDirection:"row", alignItems:"center"}}>{isSeeingHistory?"Historique des statuts de ce colis :":"Dernier statut du colis : "}</p>}
                        <div style={{transitionDuration: "0.1s"}}>
                            <p style={{color:"#747474", fontWeight:600, fontSize:20, marginLeft:10}}>{elementToPrint["lastStatus"]}</p>
                            {isSeeingHistory &&
                            <>
                                {statusList.map((item) => (
                                    
                                    <p key={item.id} style={{color:"#747474", fontWeight:600, fontSize:20, marginLeft:10}}>{item.status}</p>
                                    
                                ))}
                            </>
                            
                            }
                        </div>
                    </div> */}

                            
                            
                    <div style={{display:"grid", gridTemplateColumns:width>600?"20% 40% 40%":"20% 80%", flexDirection:"row", alignItems:"center"}}>
                        <img src={transporteur} alt='' style={{width:60, resize:"none", margin:12, justifySelf:"center"}} draggable={false}/>
                        {width>600 && <p style={{display:"flex", flexDirection:"row", alignItems:"center"}}>Transporteur en charge de l'expédition : </p>}
                        <p style={{color:"#747474", fontWeight:600, fontSize:20, marginLeft:10}}>{elementToPrint["carrier"]}</p>
                    </div>
                            
                    <div style={{display:"grid", gridTemplateColumns:width>600?"20% 40% 40%":"20% 80%", flexDirection:"row", alignItems:"center"}}>
                        {/* <img src={position} alt='' style={{height:40, resize:"none", margin:12, justifySelf:"center"}} draggable={false}/> */}
                        <Price style={{width:60, resize:"none", margin:12, justifySelf:"center"}} />
                        {width>600 && <p style={{display:"flex", flexDirection:"row", alignItems:"center"}}>Adresse de l'expédition :</p>}
                        <p style={{color:"#747474", fontWeight:600, fontSize:20, marginLeft:10}}>{elementToPrint.price} €</p>
                    </div>
                            
                    <div style={{display:"grid", gridTemplateColumns:width>600?"20% 40% 40%":"20% 80%", flexDirection:"row", alignItems:"center"}}>
                        <img src={position} alt='' style={{height:40, resize:"none", margin:12, justifySelf:"center"}} draggable={false}/>
                        {width>600 && <p style={{display:"flex", flexDirection:"row", alignItems:"center"}}>Adresse de l'expédition :</p>}
                        <p style={{color:"#747474", fontWeight:600, fontSize:20, marginLeft:10}}>{elementToPrint.deliveryAddressLine1} {elementToPrint.deliveryAddressLine2}</p>
                    </div>
                    

                    <div style={{display:"grid", gridTemplateColumns:"20% 80%", flexDirection:"row", alignItems:"center", cursor:"pointer"}} className="JE" onClick={()=>getPDF(elementToPrint.expeditionPath)}>
                        <img src={pdf} alt='' style={{height:50, resize:"none", margin:12, justifySelf:"center"}} draggable={false}/>
                        <p style={{display:"flex", flexDirection:"row", alignItems:"center"}}>Afficher l'étiquette</p>
                    </div>
                </div>
            </div>
        </div>
        }
    </>
    )
}
export default ListeDesExpeditions;