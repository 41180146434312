import React from 'react';
import logo from '../assets/logo.png';
import logo_black from '../assets/logo_black.png';
import hamburger from '../assets/hamburger.png'
import MailFooter from "../assets/enveloppe_footer.svg"
import Phone_footer from "../assets/phone_footer.png"
import juniorisep from '../assets/juniorisep.png'
import {ReactComponent as LogOut} from "../assets/logout.svg";

import '../App.css';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Main from './Main';
import { UserToken, UserSetToken, widthContext } from "../context/token";
import { useCookies } from 'react-cookie';
import { decodeToken } from 'react-jwt';
import { Backdrop } from '@mui/material';

const listRespo = [
  {name: "Statistiques"}, 
  {name: "Contact"}, 
  {name: "Mon profil"}, 
  {name: "Liste des expéditions"},
  {name: "Mes sous-comptes"},
  {name: "Facturation"}
]
const listSousCompte = [
  {name: "Statistiques"},
  {name: "Carnet d'adresses"},
  {name: "Mon profil"},
  {name: "Liste des expéditions"},
  {name: "Demande de ramassage"},
  {name: "Demande d'une expédition"},
  {name: "Facturation"},

]
const listAdmin = [
  {name: "Profils des transporteurs"},
  {name: "Liste des expéditions"},
  {name: "Liste des clients inscrits"}
]

const listHeader = [listRespo, listSousCompte, listAdmin]
// const listHeader = { "Responsable":listRespo, "Sous-compte":listSousCompte, "Administrateur":listAdmin}


function Connected() {
  const width = React.useContext (widthContext)
  const [ispressed, setIsPressed] = React.useState (false)
  let location = useLocation();
  const setToken = React.useContext(UserSetToken);
  const navigate = useNavigate ()
  const [, setCookie] = useCookies(['accessToken', 'refreshToken'])
  const token = React.useContext(UserToken);
  let decodedToken:any = decodeToken (token["accessToken"]) 
  const role = decodedToken["role"]
  const [tryToDeco, setTryToDeco] = React.useState (false)
  
  
  
  React.useEffect (() => {
    if (width > 1150) {
      setIsPressed (false)
    }
    
  }, [width])

  function getLink (link:string):string{
    link = link.split (" ").join ("_")
    link = link.split ("é").join ("e")
    link = link.split ("'").join ("_")
    link = link.toLocaleLowerCase ()
    return link
  }

  

  function deco () {
    setCookie('accessToken', "");
    setCookie('refreshToken', "");
    setToken ({"accessToken":"", "refreshToken":""})
    navigate ("/")
  }

  const [role_id, setRole_id] = React.useState (0)
  
  React.useEffect (() => {
    if (role==="account") { setRole_id (0)}
    if (role==="sub_account") { setRole_id (1)}
    if (role==="admin") { setRole_id (2)}
  }, [token, role])

  function HeaderButton (props:any) {
    const [isHover, setIsHover] = React.useState (false)
    const isOnThisPage = (location.pathname.split ("/")[1]===getLink (props.element.name)) || (location.pathname.split ("/")[1]==="account" && getLink (props.element.name)==="liste_des_clients_inscrits") || (location.pathname.split ("/")[1]==="subaccount" && getLink (props.element.name)==="liste_des_clients_inscrits")
    
    return (
        <Link to={getLink (props.element.name)} 
              style={{marginLeft:10, marginRight:10, color: !isOnThisPage ? isHover ? "#B0A5A5":"#5a5a59":"#edc482", fontWeight:450, letterSpacing:0.6}}
              className='header-button'
              onMouseEnter={() => setIsHover (true)} 
              onMouseLeave={() => setIsHover (false)} 
              // onClick={() => console.log (props.element.name)} 
              key={props.element.name}>
          {props.element.name}
        </Link>
    )
  }

  function MenuDerourant () {
    
    return (
      <>
      <div style={{backgroundColor:"transparent", width:"100vw", height:"100vh", position:"fixed", top:0, left:0}} onClick={()=> setIsPressed (false)}></div>
      <div className='menu-deroulant Cadre-blanc' onClick={()=>setIsPressed (false)} style={{zIndex:998, backgroundColor:"white", padding:0, marginTop:20}}>
        {listHeader[role_id].map (element =>
          <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
            <HeaderButton element={element} key={element.name}/>
            <div style={{backgroundColor:"grey", width:"20%", justifySelf:"center", height:2, alignSelf:"center"}}></div>
          </div>
        )}
          {/* <img src={logout} alt="Deconnexion" style={{width:30, height:30, alignSelf:"center", marginRight:20, marginLeft:20}} draggable={false}/> */}
        <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignContent:"center", alignItems:"center"}} onClick={()=>setTryToDeco(true)}>
          <p>Deconnexion</p>
          <LogOut style={{maxHeight:30, maxWidth:30, resize:"none",  objectFit:"contain", margin:"0px 10px"}} className="header-logout"/>
        </div>
      </div>
      </>
    )
  }
  
  return (
    <div className='App'>
      <div style={{minHeight:"100vh"}}>
      <header id="header" style={{position:"sticky", userSelect:"none", height:"min-content", margin:20, top:20, zIndex:15}} >
        <div className='App-header'>
          <Link to="/" className='App-logo' onClick={() => setIsPressed (false)} style={{marginRight:width>1150?30:0}}>
            <img src={logo} className='App-logo' alt='logo' draggable={false}/>
          </Link>
            <div className="header-bande">
              {listHeader[role_id].map (element =>
                <HeaderButton element={element} key={element.name}/>
              )}
              <div className="header-button" onClick={() => setTryToDeco (true)} style={{cursor:"pointer", marginLeft:20}}>
                {/* <img src={logout} alt="Deconnexion" style={{width:30, height:30, alignSelf:"center", marginRight:20, marginLeft:20}} draggable={false}/> */}
                <LogOut style={{maxHeight:30, maxWidth:30, resize:"none",  objectFit:"contain"}} className="header-logout"/>
              </div>

            </div>
            <div className="hamburger-container">
              <img onClick={() => setIsPressed (!ispressed)} src={hamburger} alt='menu' className="hamburger"/>
            </div>
          </div>
          {ispressed && (<MenuDerourant/>)}
      </header>

      {/* <div style={{minHeight:"100vh", position:"relative", zIndex:0}}> */}
        <Main/>

      </div>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={tryToDeco}
          onClick={()=>setTryToDeco (false)}
        >
          <div style={{position: "fixed", left: "50%", top:"40%", zIndex:999}}  onClick={(e)=>e.stopPropagation()}>
            <div className='logout' style={{position: "relative", left: "-50%", top:"-50%", border: "dotted #deae5f 1px"}}>
              <p style={{margin:10, color:"black"}}>Voulez-vous vraiment vous déconnecter ?</p>
              <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <div className='Logout-button' onClick={() => setTryToDeco (false)} style={{cursor:"pointer"}}>
                  <p style={{margin:"15px 20px", color:"white"}}>Non</p>
                </div>
                <div className='Logout-button' onClick={() => {deco ();setTryToDeco (false)}} style={{cursor:"pointer"}}>
                  <p style={{margin:"15px 20px", color:"white"}}>Oui</p>
                </div>
              </div>
            </div>
          </div>
        </Backdrop>
        <footer className="App-footer">
          
          <img className="Footer-logo" alt="SupplyZen logo" style={(width > 1150)?{position:"absolute", left:30, height:30, margin:24, userSelect:"none"}:{height:30, margin:24, userSelect:"none"}} src={logo_black} draggable={false}/>
          <div className="Footer-column" style={(width > 780)?{marginLeft: "15vw"}:{}}>
            <h3 className='Footer-title'>Informations de contact</h3>
            <div style={{alignItems:"center", display:"flex", cursor:"pointer"}} onClick={()=> window.open("mailto:contact.sz@supplyzen.fr?subject=&body=")}>
              <img alt="Mail logo" src={MailFooter} className="Footer-logo" style={{userSelect:"none"}} draggable={false}/>
              
              <span className='Footer-text'>contact.sz@supplyzen.fr</span>
            </div>
            <div style={{alignItems:"center", display:"flex"}}>
              <img alt="Phone logo" src={Phone_footer} className="Footer-logo" style={{userSelect:"none"}} draggable={false}/>
            <span className='Footer-text'>01 83 90 13 14</span>
            </div>
          </div>
          {/* <div className='Footer-column' style={(width > 780)?{marginLeft: "15vw", userSelect:"none"}:{userSelect:"none"}}>
            <h3 className='Footer-title' >Pages utiles</h3>
            <span className='Footer-text2' style={{cursor:"pointer", userSelect:"none"}}>Plan du site</span>
            <span className='Footer-text2' style={{cursor:"pointer", userSelect:"none"}}>Conditions Générales d'Utilisation</span>
            <span className='Footer-text2' style={{cursor:"pointer", userSelect:"none"}}>Mentions Légales</span>
          </div> */}
          
          {/* <div className='Footer-column' style={{ alignItems:"center", }}>
            <img  src={juniorisep} className="Footer-logo" alt="Logo Junior ISEP"/>
            <span style={{textAlign: "center"}}>Créé par Junior ISEP</span>
          </div> */}
        </footer>
        <div className='JE ' style={{display:"flex", alignItems:"center",justifyContent:"center",  backgroundColor:'#deae5f', padding:10, cursor:"pointer"}} onClick={()=> window.open("https://juniorisep.com/accueil-new/")} >
          <img  src={juniorisep} className="Footer-logo" alt="Logo Junior ISEP" style={{userSelect:"none"}} draggable={false}/>
          
          <span style={{textAlign: "center"}}>Créé par Junior ISEP</span>
        </div>
        
    </div>
  );
}

export default Connected;
