import React from "react";
import { UserToken, UserSetToken,url, widthContext } from "../../context/token";
import {ReactComponent as Chevron} from "../../assets/chevron.svg";
import {ReactComponent as Share} from "../../assets/share.svg";
import Back from "../../assets/back.png"
import Check from "../../assets/yellowCheck.svg"
import Clipboard from "../../assets/clipboard.png"
import Check2 from "../../assets/check.png"
import TNT from "../../assets/TNT.png"
import UPS from "../../assets/UPS.png"
import DHL from "../../assets/DHL.png"

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { Account, CreateSubAccountResponse, PlaceResponse, PlaceResponseFeature, PlaceResponseFeatureProperties, Carrier, PriceList } from "../../types";
import { isStillConnected } from "../../utils/areYouStillConnected";
import { TextInput } from "../../components/input";
import Autocomplete from '@mui/material/Autocomplete';
import { useLocation, useNavigate } from "react-router-dom";
import { MenuItem, Pagination } from "@mui/material";
import { SelectInput } from "../../components/select";
import { Connexion } from "../../utils/connexion";
const GOUV_ADRESSE_ENDPOINT = "https://api-adresse.data.gouv.fr/search/?"

function CreateSubAccount  ()  {

    // Pour gérer l'authentification
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)

    React.useEffect (()=>{
        Connexion () // eslint-disable-next-line
    }, [])
    ////////////////////////////////////////////////////////


    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////


    // Pour gérer l'autocomplétion d'adresse pour le ramassage
    async function searchFunction(search: string){
        if (search.length>3){
            const lien = GOUV_ADRESSE_ENDPOINT + new URLSearchParams({"q": search})
            const response = fetch (lien, {
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                }
              })
            const resp:PlaceResponse = await (await response).json();
            const temp:any = resp.features.map ((element:PlaceResponseFeature) => element.properties)
            setResult1 (temp)
            return resp
        } else {
            setResult1 (new Array<PlaceResponseFeatureProperties> ())    
        }
    }
    const [result1, setResult1] = React.useState<Array<PlaceResponseFeatureProperties>> ([]) //1 pour le ramassage
    const [isAutoCompleteOpen1, setIsAutoCompleteOpen1] = React.useState (false)
    ////////////////////////////////////////////

    // Pour gérer le compte pour qui on crée un sous-compte
    const accountId = useLocation ().pathname.slice (19)
    
    
  
    async function getInfo (){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/account', {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            }
          })
        const json:Array<Account> = await (await response).json();
        for (let i=0; i<json.length; i++){
            if (json[i].id===accountId){
                
                setAccountName (json[i].name)
                setAccountActivity (json[i].activity)
                setAccountSiret (json[i].siret)
                setAccountAddress1 ("28 rue notre dame des champs")
                setAccountZipCode (JSON.stringify (75006))
                setAccountCity ("Paris")

                const depotName:any = json[i].subAccounts===undefined ? 0 : json[i].subAccounts?.length
                
                setSubAccountName (json[i].name + " Dépôt " + JSON.stringify(depotName + 1))
                setSubAccountActivity (json[i].activity)
                setSubAccountSiret (json[i].siret)
            }
        }
        
    }

    React.useEffect (()=>{
        getInfo () // eslint-disable-next-line
    }, [])
    //////////////////////////////////////////////////////////


    // Info du compte responsable
    const [accountName, setAccountName] = React.useState ("")
    const [accountActivity, setAccountActivity] = React.useState ("")
    const [accountSiret, setAccountSiret] = React.useState ("")
    const [accountAdress1, setAccountAddress1] = React.useState ("")
    const [accountZipCode, setAccountZipCode] = React.useState ("")
    const [accountCity, setAccountCity] = React.useState ("")

    // Info du sous-compte à créer
    const [subAccountName, setSubAccountName] = React.useState ("")
    const [subAccountActivity, setSubAccountActivity] = React.useState ("")
    const [subAccountSiret, setSubAccountSiret] = React.useState ("")
    const [subAccountLegalStatus, setSubAccountLegalStatus] = React.useState ("")
    const [subAccountPhysicalSign, setSubAccountPhysicalSign] = React.useState ("")
    const [subAccountTVANumber, setSubAccountTVANumber] = React.useState ("")
    const [subAccountAdressRamassage1, setSubAccountAdressRamassage1] = React.useState ("")
    const [subAccountAdressRamassage2, setSubAccountAdressRamassage2] = React.useState ("")
    const [subAccountZipCodeRamassage, setSubAccountZipCodeRamassage] = React.useState ("")
    const [subAccountCityRamassage, setSubAccountCityRamassage] = React.useState ("")
    const [subAccountCountryRamassage, setSubAccountCountryRamassage] = React.useState ("")

    //////////////////////////////////////////////////////////
  
    const ref1 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref2 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref3 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref4 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref5 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref6 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref7 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref8 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref9 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref10 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref11 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref12 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref13 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref14 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref15 = React.useRef() as React.MutableRefObject<HTMLInputElement>;




    const [isAccountAndSubAccountRevealed, setIsAccountAndSubAccountRevealed] = React.useState (false);
    const [isTransporteurRevealed, setTransporteurRevealed] = React.useState (false);
    const [isResultRevealed, setIsResultRevealed] = React.useState (false);

    const navigate = useNavigate ()

    // Pour gérer la liste des transporteurs
    const [carrierList, setCarrierList] = React.useState (
        new Array<Carrier> ()
    ) 
    const [pageNbCarrierList, setPageNbCarrierList] = React.useState (1)
    
    const [filteredCarrierList, setFilteredCarrierList] = React.useState (carrierList)
    const reductedCarrierList = filteredCarrierList.slice ((pageNbCarrierList-1)*20, (pageNbCarrierList*20))

    async function getCarrier (){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/carrier', {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            }
          })
        const json:Array<Carrier> = await (await response).json();
        const temp = new Array<Carrier> ()
        for (let i=0; i<json.length; i++){
            const element:Carrier = structuredClone (json[i])
            element.isChecked = false
            // element.type = "Quotidien"
            element.selectedPriceListId = ""
            temp.push (element)
        }
        // for (let i=0; i<temp.length; i++){
        //     const element:Carrier2 = structuredClone (temp[i])
        //     element.isChecked = false
        //     element.type = "Ponctuel"
        //     temp.push (element)
        // }
        setCarrierList (temp)
        console.log (temp)
        setFilteredCarrierList (temp)

    }
    React.useEffect (()=>{
        getCarrier () // eslint-disable-next-line
    }, [])
    

    const indexList = ["TNT", "UPS", "DHL"]
    const logoList = [TNT, UPS, DHL]

    ///////////////////////////////////////////////////////////////////


    

    // Component Transporteur à boucler 
    function CarrierComponent (props:{element:Carrier, index:number}){
        const element:Carrier = props.element
        
        const indexLogo = indexList.indexOf (element.name)
        const [isChecked, setIsChecked] = React.useState (props.element["isChecked"])
        
        
        function handleChangeIsGoing (){
            const temp:Array<Carrier> = carrierList.slice ()
            temp[props.index+(pageNbCarrierList-1)*20]["isChecked"] = !isChecked
            
            setCarrierList(temp)
            setFilteredCarrierList (temp)
            setIsChecked (!isChecked)
        }
        const [selectedPriceList, setSelectedPriceList] = React.useState (element.selectedPriceListId===undefined?"":element.selectedPriceListId)
        function selectPriceList (e:string){
            const temp:Array<Carrier> = carrierList.slice ()
            temp[props.index+(pageNbCarrierList-1)*20]["selectedPriceListId"] = e
            console.log (temp)
            setCarrierList(temp)
            setFilteredCarrierList (temp)
            setSelectedPriceList (e)
        }

        const [arrayPriceList, ] = React.useState (new Array<PriceList> ())
        return (
            <div className="table3" key={element["id"]} style={{margin:0, justifyContent:width>1200?"space-evenly":"flex-start", display:"flex", flexDirection:width>1200?"row":"column",  alignItems:"center", width:"90%"}}>           
                                
                <div style={{textAlign:"center",  display: "flex", flexDirection:"row",  alignItems:"center", flex:2, boxSizing:"border-box"}}>
                    <div style={{textAlign:"left",  display: "flex", flexDirection:"row",  alignItems:"center"}}>
                        {/* <img src={transporteur} alt='' style={{height:60, resize:"none", marginRight:10}} draggable={false}/> */}
                        <img src={logoList[indexLogo]} alt='' style={{width:60, resize:"none", margin:12}} draggable={false}/>
                        <div style={{display:"flex", flexDirection:"column", marginLeft:5}}>
                            <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{element.name}</span>
                            <span style={{color:"#747474"}}>Nom du transporteur</span>
                        </div>
                        {/* {width>1200 && <span style={{color:"#747474", fontWeight:600}}>Numéro d'expédition</span>} */}
                    </div>
                </div>

                <div style={{textAlign:"center", display: "flex", justifyContent:"center", justifyItems:"center", alignContent:"center", alignItems:"center", marginRight:0, flex:2}}>
                    <div style={{textAlign:"left",  display: "flex", flexDirection:"column", justifyContent:"center", marginRight:0, marginLeft:"7%"}}>
                        <span style={{color:"#e7ac4f", fontSize:22, fontWeight:700, marginRight:0}}>{element.id}</span>
                        <span style={{color:"#747474", fontWeight:600}}>Numéro de transporteur</span>
                    </div>
                </div>


                {/* <div style={{textAlign:"center",  display: "flex", flexDirection:"row",  alignItems:"center", flex:2}}>
                    <div style={{textAlign:"left",  display: "flex", flexDirection:"row",  alignItems:"center", marginLeft:"7%"}}>
                        <img src={Truck2} alt='' style={{height:40, resize:"none", marginRight:10}} draggable={false}/>
                        <div style={{display:"flex", flexDirection:"column", marginLeft:5}}>
                            <span style={{color:"#747474", fontSize:22, fontWeight:700, marginRight:0}}>{type}</span>
                            <span style={{color:"#747474"}}>Mode de ramassage</span>
                        </div>
                        
                    </div>
                </div> */}

                <div className="Change-Color-Hover" style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center",  cursor:"pointer", userSelect:"none", flex:1.5, width:"max-content"}}>
                    <SelectInput bordercolor="#7d7d7d" label="Grille " style={{width:"100%"}} value={selectedPriceList} onChange={(e)=>selectPriceList (e.target.value)}>
                        <MenuItem value="" style={{fontStyle:"italic"}}>Pas d'options</MenuItem>
                        {/* <MenuItem value="AZERTY">Grille AZERTY</MenuItem>
                        <MenuItem value="QWERTY">Grille QWERTY</MenuItem>
                        <MenuItem value="POIUYT">Grille POIUYT</MenuItem> */}
                        {arrayPriceList.map ((e)=>
                            <MenuItem key={e.id} value={e.id}>{e.priceListName}</MenuItem>
                        )}
                    </SelectInput>
                    {/* <SelectInput bordercolor="#7d7d7d" label="Grille " style={{width:"100%"}} value={selectedPriceList} onChange={(e)=>selectPriceList (e.target.value)}>
                        <MenuItem value="" style={{fontStyle:"italic"}}>Pas d'options</MenuItem>
                        <MenuItem value="AZERTY">Grille AZERTY</MenuItem>
                        <MenuItem value="QWERTY">Grille QWERTY</MenuItem>
                        <MenuItem value="POIUYT">Grille POIUYT</MenuItem>
                        
                        
                        
                    </SelectInput> */}
                </div>
                <div style={{flex:1, display:"flex", justifyContent:"center"}}>
                    <div className="table4 Change-Color-Hover" style={{  cursor:"pointer", justifyContent:"center", display:"flex",   border: isChecked?"5px solid #e7ac4f":"2px solid #9a9a9a", width:60, height:60, paddingLeft:0, borderRadius:10, boxSizing:"border-box", flexDirection:"row", alignItems:"center", alignContent:"center"}} onClick={()=>{handleChangeIsGoing()}}>
                        <img src={Check} alt='' style={{height:40, resize:"none", margin:12, paddingLeft:0, visibility:isChecked?"visible":"hidden"}} draggable={false}/>
                    </div> 
                </div>
                
            </div>
        )
    }
    ///////////////////////////////////////////////////////////////////////////////////////

    const [errorForTheCreation, setErrorForTheCreation] = React.useState (new Array<string> ())

    // Pour gérer la page de confirmation de la création du sous-compte
    const [isDone, setIsDone] = React.useState (false)
    const [doWeChange, ] = React.useState (false)
    const [copyLink, setCopyLink] = React.useState (false)
    const [isHoverCopyLink, setIsHoverCopyLink] = React.useState (false)
    const [response, setResponse] = React.useState ({} as CreateSubAccountResponse)
    async function Create (){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response:Promise<Response> = fetch (url + '/account/subaccount', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
                "siret":subAccountSiret,
                "name": subAccountName,
                "address": {
                    "streetLine1": subAccountAdressRamassage1,
                    "streetLine2": subAccountAdressRamassage2,
                    "city": subAccountCityRamassage,
                    "zip": +subAccountZipCodeRamassage,
                    "country": subAccountCountryRamassage
                },
                "activity": subAccountActivity,
                "parentAccountId":accountId,
                "legalStatus": subAccountLegalStatus,
                "physicalSign": subAccountPhysicalSign,
                "tvaNumber": +subAccountTVANumber,
                "pickupChoice": "blabla",
                "pickupHours": "12h00 - 15h00",
                "forecastVolume": "blabla",
                "recipient": "blablabla"
            })
          })   
          await response.catch(function(_error) {
            setErrorForTheCreation (["Serveur inatteignable"])
          });;
        if ((await response).status===400){
            const json = await (await response).json()
            setErrorForTheCreation (json.message)
        } else {
            const json:CreateSubAccountResponse = await (await response).json()
            setResponse (json)
            setIsDone (true)
            console.log (json)
        }
    }
    ////////////////////////////////////////////////////////////
    return (
        <div className="table2" style={{marginTop:30, width:"100%"}}>
            {!isDone ?
            <>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"left", justifyItems:"center", width:"90vw", justifySelf:'center', position:"sticky", top:"19vh", zIndex:3, pointerEvents: "none", userSelect:"none"}}>
                <div style={{ marginRight:20, boxSizing:"border-box", display:"grid", alignContent:"center", justifyContent:"center", cursor:"pointer", width:width>780?"unset":60, pointerEvents: "visible"}} className="Cadre-blanc Change-Color-Hover Input-password" onClick={()=>{navigate (-1)}}>
                    <img src={Back} style={{height:40, resize:"none"}} alt="go back" draggable={false}/>
                </div>
                <div style={{display:"flex", flexDirection:"row-reverse", width:"100%", justifyContent:"space-between", pointerEvents: "none"}}>
                    <div style={{backgroundColor:"#e7ac4f", color:"#fefbf8", padding:"10px 20px", cursor:"default", pointerEvents: "visible"}} className="Cadre-blanc Header-expedition">
                        <p>Créer un sous-compte pour {accountName}</p>
                    </div>  
                </div>
            </div>

            

            <div>
                
                <Accordion className='DemandeExpeditionBox1' style={{borderRadius:30, padding:0, display:"flex", margin:30, width:"90vw", marginRight:0, marginLeft:0, marginTop:60}}expanded={isTransporteurRevealed}>

                    <AccordionSummary style={{width:"100%", borderRadius:30}}  className="Change-Color-Hover">
                        <div className='Input-password' onClick={() => setTransporteurRevealed (!isTransporteurRevealed)} style={{width:"100%", textAlign:"left", display:"flex", flexDirection:"row", alignItems:"center"}}>
                            <Chevron style={{margin:width>780?"0px 30px":"0px 10px 0px 0px", rotate:isTransporteurRevealed?"-180deg":"0deg", transitionDuration: "0.2s"}}/>
                            <h1 style={{fontSize:width>780?30:20, fontWeight:500}}>Compte responsable / Sous-compte</h1>
                        </div>
                    </AccordionSummary>

                    <AccordionDetails >
                        <div style={{display:"grid", gridTemplateColumns: width>780?'49.5% 2% 49.5%':"100%", alignContent:"center", width:"100%", justifyItems:"center"}}>
                        
                            <div style={{zIndex:0, display:"flex", flexDirection:"column", alignItems:"center", width:"100%"}}>
                                <h1 style={{fontSize:25, fontWeight:650, textDecoration:"underline"}}>Compte responsable</h1>
     
                                
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Nom" value={accountName} onChange={(value)=>setAccountName(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref1.current.focus()} disabled/>
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Activité" value={accountActivity} onChange={(value)=>setAccountActivity(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref2.current.focus()} inputRef={ref1} disabled/>
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Numéro de SIRET" value={accountSiret} onChange={(value)=>setAccountSiret(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref3.current.focus()} inputRef={ref3} disabled/>
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Nom de rue" value={accountAdress1} onChange={(value)=>setAccountAddress1(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref4.current.focus()} inputRef={ref3} disabled/>
                            
                                <div style={{display:"flex", flexDirection:"row", justifySelf:"center", width:width>780?"80%":"100%", justifyContent:"space-between"}}>
                        
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Code postal" value={accountZipCode} onChange={(value)=>setAccountZipCode(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref5.current.focus()} inputRef={ref4} disabled/>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom de la ville" value={accountCity} onChange={(value)=>setAccountCity(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref6.current.focus()} inputRef={ref5} disabled/>
                                    
            
                                </div>
                            </div>
                            {width>780 && <div style={{width:3, backgroundColor:"#a2a2a2", height:"100%", alignSelf:"center", justifySelf:"center", borderRadius:10, margin:"50px 0px"}}></div>}
                            <div style={{zIndex:0, display:"flex", flexDirection:"column", alignItems:"center", width:"100%"}}>
                                <h1 style={{fontSize:25, fontWeight:650, textDecoration:"underline"}}>Sous-compte à créer</h1>
                                
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Nom" value={subAccountName} onChange={(value)=>setSubAccountName(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref7.current.focus()} inputRef={ref6}/>
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Activité" value={subAccountActivity} onChange={(value)=>setSubAccountActivity(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref8.current.focus()} inputRef={ref7}/>
                                <div style={{display:"flex", flexDirection:"row", justifySelf:"center", width:width>780?"80%":"100%", justifyContent:"space-between"}}>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Numéro de SIRET" value={subAccountSiret} onChange={(value)=>setSubAccountSiret(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref9.current.focus()} inputRef={ref8}/>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Statut légal" value={subAccountLegalStatus} onChange={(value)=>setSubAccountLegalStatus(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref10.current.focus()} inputRef={ref9}/>
                                </div>
                                <div style={{display:"flex", flexDirection:"row", justifySelf:"center", width:width>780?"80%":"100%", justifyContent:"space-between"}}>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Signe physique" value={subAccountPhysicalSign} onChange={(value)=>setSubAccountPhysicalSign(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref9.current.focus()} inputRef={ref8}/>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Numéro de TVA" value={subAccountTVANumber} onChange={(value)=>setSubAccountTVANumber(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref10.current.focus()} inputRef={ref9}/>
                                </div>
                                <Autocomplete
                                    freeSolo={true}
                                    style={{width:width>780?"80%":"100%"}}
                                    disableClearable={true}
                                    options={result1.map ((option)=>option)}
                                    autoComplete={true}
                                    open={isAutoCompleteOpen1}
                                    onOpen={()=>setIsAutoCompleteOpen1 (true)}
                                    onClose={()=>setIsAutoCompleteOpen1 (false)}
                                    noOptionsText="Pas d'adresses"
                                    getOptionLabel={(option) =>
                                        typeof option === 'string' ? option : option.name}
                                    filterOptions={(x) => x}
                                    inputValue={ subAccountAdressRamassage1 }
                                    onInputChange={async (_event, newInputValue) => {setSubAccountAdressRamassage1(newInputValue)}}
                                    disableCloseOnSelect={false}
                                    renderOption={(_props, option) => (
                                        <div 
                                            className="Change-Color-Hover"
                                            onClick={()=>{
                                                setSubAccountAdressRamassage1 (option.name); 
                                                setSubAccountZipCodeRamassage (option.postcode)
                                                setSubAccountCityRamassage (option.city)
                                                setSubAccountCountryRamassage ("France")
                                                setIsAutoCompleteOpen1 (false);
                                            }}
                                            // sx={{ "& ": { mr: 2, flexShrink: 0, cursor:"pointer", width:"80%" } }}
                                            style={{width:width>780?"100%":"100%", cursor:"pointer", padding:10, boxSizing:"border-box"}}
                                            key={option.id}
                                            
                                        >
                                            <p style={{margin:0}} key={option.id}>{option.name} ({option.postcode} - {option.city})</p>
                                        </div>
                                    )}
                                    renderInput={(params) => (
                                        <TextInput
                                            {...params}
                                            onChange={async (event)=>await searchFunction(event.target.value)}
                                            bordercolor="#f0ba61"
                                            label="Adresse de ramassage"
                                            width={"100%"}
                                            onKeyPress={event => event.key === 'Enter' && ref11.current.focus()} 
                                            inputRef={ref10}
                                        />
                                    )}
                                />
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Indication supplémentaire" value={subAccountAdressRamassage2} onChange={(value)=>setSubAccountAdressRamassage2(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref12.current.focus()} inputRef={ref11}/>
                            
                                <div style={{display:"flex", flexDirection:"row", width:width>780?"80%":"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Code postal pour le ramassage" value={subAccountZipCodeRamassage} onChange={(value)=>setSubAccountZipCodeRamassage(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref13.current.focus()} inputRef={ref12}/>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom de la ville pour le ramassage" value={subAccountCityRamassage} onChange={(value)=>setSubAccountCityRamassage(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref14.current.focus()} inputRef={ref13}/>   
                                </div>
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Pays" value={subAccountCountryRamassage} onChange={(value)=>setSubAccountCountryRamassage(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref15.current.focus()} inputRef={ref14}/>
                                
                            </div>
                           
                        </div>                    
                    </AccordionDetails>

                </Accordion>
            </div>

            <div>
                <Accordion className='DemandeExpeditionBox1' style={{borderRadius:30, padding:0, display:"flex", margin:30, width:"90vw", marginRight:0, marginLeft:0}} expanded={isAccountAndSubAccountRevealed}>

                    <AccordionSummary style={{width:"100%", borderRadius:30}}  className="Change-Color-Hover" onClick={() => {setIsAccountAndSubAccountRevealed (!isAccountAndSubAccountRevealed);}}>
                        <div  style={{width:"100%", textAlign:"left", display:"flex", flexDirection:"row", alignItems:"center"}}>
                            <Chevron style={{margin:width>780?"0px 30px":"0px 10px 0px 0px", rotate:isAccountAndSubAccountRevealed?"-180deg":"0deg", transitionDuration: "0.2s"}}/>
                            <h1 style={{fontSize:width>780?30:20, fontWeight:500}}>Transporteur à attribuer au sous-compte</h1>
                        </div>
                    </AccordionSummary>
                        
                    <AccordionDetails>
                        <div style={{width:"100%", display:"grid", gridTemplateColumns:"100%", justifyItems:"center"}}>

                            {reductedCarrierList.map ((element:Carrier, index:number) =>
                            <div key={index} style={{display:"grid" ,justifyItems:"center", flexDirection:"column", width:"100%", position:"relative", gridTemplateColumns:"100%", padding:0}}>
                                <CarrierComponent element={element} index={index}/>
                                
                                {index!==reductedCarrierList.length-1 &&
                                    <div style={{width:"90%", alignSelf:"center", justifySelf:"center", height:3, backgroundColor:'#9a9a9a', borderRadius:10}}></div>
                                }
                                
                            </div>
                            )} 
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", userSelect:"none", textAlign:"center"}}>
                                {/* <span className="table" style={pageNbCarrierList>1?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}} onClick={()=> {goToPreviousPageForCarrier()}}>
                                    <p className="table" style={{color:"white"}}>Page précédente</p>
                                </span> */}
                                
                                <Pagination count={filteredCarrierList.length!==0? Math.floor((filteredCarrierList.length-1)/20)+1:1} size="large"  onChange={(_event, number)=>setPageNbCarrierList (number)}/>
                                {/* <p style={{cursor:"default"}}>{pageNbCarrierList}/{Math.floor((filteredCarrierList.length-1)/20)+1}</p>
                                <span className="table" style={pageNbCarrierList<filteredCarrierList.length/20?{backgroundColor:"#deae5f", cursor:"pointer"}:{backgroundColor:"#DED1D1", cursor:"default"}}  onClick={()=> {goToNextPageForCarrier()}}>
                                    <p className="table" style={{color:"white"}}>Page suivante</p>
                                </span> */}
                            </div>
                        </div>
                    </AccordionDetails>
                    
                </Accordion>
            </div> 
            {(errorForTheCreation.length!==0) &&
                
                <div style={{position: "fixed", left: "50%", top:"10%", zIndex:5}}>
                    <div className='logout' style={{position: "relative", left: "-50%", top:"-50%", border: "dotted #deae5f 1px", padding:30, width:450, paddingLeft:0, paddingRight:0}}>
                        
                        <p style={{margin:10, fontSize:25, fontWeight:500}}>Erreur</p>
                        <div style={{display:'flex', justifyContent:"center", width:"100%"}}>
                            <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                {errorForTheCreation.map ((element, index)=>
                                    <div key={index} style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"100%"}} >
                                        
                                        <p style={{margin:0, textAlign:"left", marginLeft:20, width:"70%"}}>{element}</p>
                                        
                                    </div>
                                )}
                            </div>
                            
                        </div>
                        
                        <div style={{display:"grid", gridTemplateColumns:"100%", justifyContent:"center", padding:30, paddingBottom:0, paddingTop:20}}>
                            <div style={{cursor:"pointer", backgroundColor:"#deae5f", margin:10, textAlign:"center", borderRadius:20, marginBottom:0,marginRight:10}} className="Grow-Hover" onClick={()=>setErrorForTheCreation([])}>
                                <p style={{ color:"white", width:"100%", height:"100%"}}>Continuer</p>
                            </div>
                           
                        </div>
                    </div>
                </div>
                }
            <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>         
                <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:"20vw", pointerEvents: "visible"}} onClick={()=>{Create ()}}>
                    <p>Créer</p>
                </div>                  
            </div>
            
            </>
            :
            <>
                <div style={{display:"flex", flexDirection:'row', alignItems:"center", width:"100%", justifyContent:"center"}}>
                    <h1>Sous-compte créée :</h1>
                    <img src={Check} alt='' style={{height:40, resize:"none", margin:12, paddingLeft:0}}/>
                </div>
                <div>
                    <Accordion className='DemandeExpeditionBox1' style={{borderRadius:30, padding:0, display:"flex", margin:30, width:"90vw", marginRight:0, marginLeft:0}} expanded={isResultRevealed}>
                        <AccordionSummary  style={{width:"100%", borderRadius:30}}  className="Change-Color-Hover" onClick={() => {setIsResultRevealed (!isResultRevealed);}}>
                        <div  style={{width:"100%", textAlign:"left", display:"flex", flexDirection:"row", alignItems:"center"}}>
                            <Chevron style={{margin:width>780?"0px 30px":"0px 10px 0px 0px", rotate:isResultRevealed?"-180deg":"0deg", transitionDuration: "0.2s"}}/>
                            <h1 style={{fontSize:width>780?30:20, fontWeight:500}}>Info du sous-compte</h1>
                        </div>
                        </AccordionSummary>
                        <AccordionDetails>
                        <div style={{width:"100%", display:"grid", gridTemplateColumns:"100%", justifyItems:"center"}}>
                            <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Nom" value={subAccountName} onChange={(value)=>setSubAccountName(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref7.current.focus()} inputRef={ref6} disabled={!doWeChange}/>
                            <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Activité" value={subAccountActivity} onChange={(value)=>setSubAccountActivity(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref8.current.focus()} inputRef={ref7} disabled={!doWeChange}/>
                            <div style={{display:"flex", flexDirection:"row", justifySelf:"center", width:width>780?"80%":"100%", justifyContent:"space-between"}}>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Numéro de SIRET" value={subAccountSiret} onChange={(value)=>setSubAccountSiret(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref9.current.focus()} inputRef={ref8} disabled={!doWeChange}/>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Statut légal" value={subAccountLegalStatus} onChange={(value)=>setSubAccountLegalStatus(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref10.current.focus()} inputRef={ref9} disabled={!doWeChange}/>
                            </div>
                            <div style={{display:"flex", flexDirection:"row", justifySelf:"center", width:width>780?"80%":"100%", justifyContent:"space-between"}}>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Signe physique" value={subAccountPhysicalSign} onChange={(value)=>setSubAccountPhysicalSign(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref9.current.focus()} inputRef={ref8} disabled={!doWeChange}/>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Numéro de TVA" value={subAccountTVANumber} onChange={(value)=>setSubAccountTVANumber(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref10.current.focus()} inputRef={ref9} disabled={!doWeChange}/>
                            </div>
                            <Autocomplete
                                freeSolo={true}
                                style={{width:width>780?"80%":"100%"}}
                                disableClearable={true}
                                options={result1.map ((option)=>option)}
                                autoComplete={true}
                                open={isAutoCompleteOpen1}
                                onOpen={()=>setIsAutoCompleteOpen1 (true)}
                                onClose={()=>setIsAutoCompleteOpen1 (false)}
                                noOptionsText="Pas d'adresses"
                                getOptionLabel={(option) =>
                                    typeof option === 'string' ? option : option.name}
                                filterOptions={(x) => x}
                                inputValue={ subAccountAdressRamassage1 }
                                onInputChange={async (_event, newInputValue) => {setSubAccountAdressRamassage1(newInputValue)}}
                                disableCloseOnSelect={false}
                                renderOption={(_props, option) => (
                                    <div 
                                        className="Change-Color-Hover"
                                        onClick={()=>{
                                            setSubAccountAdressRamassage1 (option.name); 
                                            setSubAccountZipCodeRamassage (option.postcode)
                                            setSubAccountCityRamassage (option.city)
                                            setSubAccountCountryRamassage ("France")
                                            setIsAutoCompleteOpen1 (false);
                                        }}
                                        // sx={{ "& ": { mr: 2, flexShrink: 0, cursor:"pointer", width:"80%" } }}
                                        style={{width:width>780?"100%":"100%", cursor:"pointer", padding:10, boxSizing:"border-box"}}
                                        key={option.id}
                                        
                                    >
                                        <p style={{margin:0}} key={option.id}>{option.name} ({option.postcode} - {option.city})</p>
                                    </div>
                                )}
                                renderInput={(params) => (
                                    <TextInput
                                        {...params}
                                        onChange={async (event)=>await searchFunction(event.target.value)}
                                        bordercolor="#f0ba61"
                                        label="Adresse de ramassage"
                                        width={"100%"}
                                        onKeyPress={event => event.key === 'Enter' && ref11.current.focus()} 
                                        inputRef={ref10}
                                        disabled={!doWeChange}
                                    />
                                )}
                            />
                            <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Indication supplémentaire" value={subAccountAdressRamassage2} onChange={(value)=>setSubAccountAdressRamassage2(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref12.current.focus()} inputRef={ref11} disabled={!doWeChange}/>
                        
                            <div style={{display:"flex", flexDirection:"row", width:width>780?"80%":"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Code postal pour le ramassage" value={subAccountZipCodeRamassage} onChange={(value)=>setSubAccountZipCodeRamassage(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref13.current.focus()} inputRef={ref12} disabled={!doWeChange}/>
                                <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom de la ville pour le ramassage" value={subAccountCityRamassage} onChange={(value)=>setSubAccountCityRamassage(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref14.current.focus()} inputRef={ref13} disabled={!doWeChange}/>   
                            </div>
                            <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Pays" value={subAccountCountryRamassage} onChange={(value)=>setSubAccountCountryRamassage(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref15.current.focus()} inputRef={ref14} disabled={!doWeChange}/>
                            
                            </div>
                        </AccordionDetails>

                    </Accordion>
                </div>
                <div style={{width:"80%"}}>
                    <TextInput value={"https://supplyzen.juniorisep.com/create_user/sub_account/"+response.id} 
                        
                        disabled
                        label="Lien pour créer un compte associé à ce compte"
                        onMouseEnter={()=>setIsHoverCopyLink (true)}
                        onMouseLeave={()=>{setIsHoverCopyLink (false);setCopyLink (false)}}
                        onClick={()=>{navigator.clipboard.writeText ("https://supplyzen.juniorisep.com/create_user/sub_account/"+response.id); setCopyLink (true)}}
                        
                        InputProps={{
                            endAdornment: isHoverCopyLink ?<img alt='clipboard' src={copyLink ? Check2 : Clipboard} className="Contact" style={{position:"absolute", right:0, cursor:'pointer'}} draggable={false}/>:undefined
                        }}
                    />
                    <Share style={{cursor:"pointer"}} onClick={()=>navigator.share({title: 'Supplyzen', text: 'Créez votre compte', url: "https://supplyzen.juniorisep.com/create_user/sub_account/"+response.id})}/>
                </div>

            </>
            }
            
        </div>
            
    )}

export default CreateSubAccount