import { createContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";

let UserToken = createContext( { "accessToken":"", "refreshToken":""});
const UserSetToken = createContext(setTokenAndCookies);

const widthContext = createContext (0);

const url = "https://api.supplyzen.juniorisep.com"
function UserContext({ children }:any) {
  const [token, setToken] = useState({ "accessToken":"", "refreshToken":""});
  const [, setCookie] = useCookies(['accessToken', 'refreshToken'])
  function setTokenAndCookies (_setToken: any){
    setToken (_setToken)
    setCookie('accessToken', _setToken["accessToken"])
    setCookie('refreshToken', _setToken["refreshToken"])
  }
  // Pour gérer la largeur de la fenêtre dans React
  const [width, setWindowWidth] = useState (0)
  useEffect(() => { 
  updateDimensions();
  window.addEventListener("resize", updateDimensions);
  
  return () => 
    window.removeEventListener("resize",updateDimensions); 
  // eslint-disable-next-line
  }, [])
  const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
  }
  //////////////////////////////////////////////////
  return (
    <UserToken.Provider value={token}>
      <UserSetToken.Provider value={setTokenAndCookies}>
        <widthContext.Provider value={width}>
          {children}
        </widthContext.Provider>
      </UserSetToken.Provider>
    </UserToken.Provider>
  );
}

export { UserToken, UserContext, UserSetToken, url, widthContext };

function setTokenAndCookies(_setToken: any) {}
