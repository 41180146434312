import React from "react";
// import Colis from "../../assets/colis.svg";
// import Bin from "../../assets/bin.png"
import { UserToken, UserSetToken,url, widthContext } from "../../../context/token";
import {ReactComponent as Bin} from "../../../assets/bin.svg";
import {ReactComponent as Chevron} from "../../../assets/chevron.svg";
import {ReactComponent as Contact} from "../../../assets/contact.svg";
import {ReactComponent as Plus} from "../../../assets/plus.svg";
import Check from "../../../assets/yellowCheck.svg"
import {ReactComponent as PDFLogo} from "../../../assets/pdf.svg";


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import { CarrierRegistration, Contact as ContactType, Feasability, ServerResponse, responseCreateExpedition } from "../../../types";
import { isStillConnected } from "../../../utils/areYouStillConnected";
import { TextInput } from "../../../components/input";
import { SelectInput } from "../../../components/select";
import { MenuItem } from "@mui/material";
import ContactSelection from "../../../components/contactSelection";
import { FileHandler } from "./fileHandler";
import { ErrorList } from "../../../components/errorList";
import { SearchAddresses } from "../../../components/searchAddresses";

const newResponseCreateExpedition = {
    "carrierId": "",
        "deliveryAddressLine1": "",
        "deliveryAddressLine2": "",
        "deliveryCity": "",
        "deliveryDate": "",
        "deliveryName": "",
        "deliveryZipCode":"",
        "id": "",
        "isArchived": false,
        "numberOfPackages": 0,
        "parcels": new Array<{
            "parcelNumber": string,
            "trackingURL": string,
            "weight": number,
            "expeditionPath": string,
            "expeditionId": string,
            "dimensions": string,
            "lastStatus": string,
            "id": string
        }> (),
        "pickupAddressLine1": "",
        "pickupAddressLine2": "",
        "pickupCity": "",
        "pickupClosingTime": "",
        "pickupDate": "",
        "pickupName": "",
        "pickupNumber": "",
        "pickupPhoneNumber": "",
        "pickupZipCode": "",
        "price":0,
        "shippingDate":"",
        "subAccountId":"",
        "userId":"",
    }
function DemandeUneExpédition  ()  {
    const token = React.useContext (UserToken)
    const setToken = React.useContext (UserSetToken)
    

    // Pour gérer la largeur de la fenêtre dans React
    const width = React.useContext (widthContext)
    //////////////////////////////////////////////////


    const [selectedFile, setSelectedFile] = React.useState(null as unknown as File);
    function handleChange(selectorFiles: FileList|null){
        console.log(selectorFiles);
        
        if (selectorFiles!==null){
            if (selectorFiles[0]!==null && selectorFiles[0]!==undefined){
                setSelectedFile (selectorFiles[0])
            } else {
                setSelectedFile (null as unknown as File)
            }
        } else {
            setSelectedFile (null as unknown as File)
        }
    }
    const [isMakingAFileDemand, setIsMakingAFileDemand] = React.useState (false)

    const [senderName, setSenderName] = React.useState ("")
    const [senderAdress1, setSenderAddress1] = React.useState ("")
    const [senderZipCode, setSenderZipCode] = React.useState ("")
    const [senderCity, setSenderCity] = React.useState ("")

    const [receiverName, setReceiverName] = React.useState ("")
    const [receiverAdress1, setReceiverAddress1] = React.useState ("")
    const [receiverAdress2, setReceiverAddress2] = React.useState ("")
    const [receiverZipCode, setReceiverZipCode] = React.useState ("")
    const [receiverCity, setReceiverCity] = React.useState ("")
    const [receiverCountry, setReceiverCountry] = React.useState ("")
    const [date, setDate] = React.useState (new Date().toJSON().slice (0, 10))
  
    function handleChangeDate (event:React.FormEvent<HTMLInputElement>){
        setDate (event.currentTarget.value)
    }
   
    const [societyName, setSocietyName] = React.useState ("")
    const [reference, setReference] = React.useState ("")
    const [mail, setMail] = React.useState ("")
    const [telephone, setTelephone] = React.useState ("")
    const [instruction1, setInstruction1] = React.useState ("")
    const [instruction2, setInstruction2] = React.useState ("")
    
    async function Connexion () {
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/auth/refresh', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token["refreshToken"]}`
            }
          })
        const json = await (await response).json();
        setSenderName (json.user.firstName + " " + json.user.lastName)
        setSenderAddress1 (json.user.subAccount.address.streetLine1)
        // setSenderAddress2 (json.user.subAccount.address.streetLine2)
        setSenderZipCode (json.user.subAccount.address.zip.toString())
        setSenderCity (json.user.subAccount.address.city)
        setTransporteurList (json.user.subAccount.carrierRegistrations)
        setCheck1 (json.user.subAccount.sundayExpedition)
        setIsAbleToChange2 (false)
        setIsAbleToChange3 (false)
        setIsAbleToChange4 (false)
        if (json.user.subAccount.carrierRegistrations.length===1){
            setTransporteur (json.user.subAccount.carrierRegistrations[0].carrier.name)
        }
        const temp = token
        temp.accessToken = json.accessToken
        temp.refreshToken = json.refreshToken
        setToken (temp)
    }


    React.useEffect (()=>{
        Connexion ()
         // eslint-disable-next-line
    }, [])
    
    const ref1 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref2 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref3 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref4 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref5 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref6 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref7 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref8 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref9 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref10 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref11 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref12 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref13 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const ref14 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    // const ref8 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [listExpedition, setListExpedition] = React.useState ([{"type":"colis", "weight":0, "sequenceNumber":0, "saturdayDelivery":false}])
    // const [listExpedition2, setListExpedition2] = React.useState ([])
    function Expedition (props:any) {
        const [poids, setPoids] = React.useState (listExpedition[props.index].weight)
        function handleChangePoids (event:React.FormEvent<HTMLInputElement>){
            const temp = listExpedition
            temp[props.index].weight = +event.currentTarget.value
            setPoids (+event.currentTarget.value)
            setListExpedition (temp)
        }
        React.useEffect (()=> {
            const temp = listExpedition
            temp[props.index].sequenceNumber = props.index +1
            setListExpedition (temp)
             // eslint-disable-next-line
        },[])
        const [tryToRemove, setTryToRemove] = React.useState (false)
        return (
            <div className="Cadre-blanc" style={{width:width>780?"90%":"100%",  marginBottom:20, padding:10, paddingLeft:width>780?30:10, paddingRight:width>780?30:10, boxSizing:"border-box"}}>
                <div style={{display:"flex", gridTemplateColumns:"20% 20% 20% 20% 20%", justifyContent:"space-around"}}>
                    <p>{props.element.type} n°{props.index+1}</p>
                    {width>780 && <p>avec un poids de</p>}
                    <input type="number" min="0" placeholder="Poids du colis (en kg)" style={{ margin:"10px 0px", textAlign:"center", width:60, border: "2px solid #005fcc", borderRadius:5}} value={poids} onChange={handleChangePoids} />
                    {width>780 ? <p>Kilogrammes</p> :<p>kg</p>}
                    <Bin style={{height:30,width:30, resize:"none", alignSelf:"center", fill:tryToRemove?"#FA1205":"#a2a2a2", cursor:"pointer"}} onClick={()=>removeToList(props.index)} onMouseEnter={()=>setTryToRemove(true)} onMouseLeave={()=>setTryToRemove(false)}/>
                </div>
            </div>
        )
    }

    function appendColisToList (index:number) {
        setListExpedition([...listExpedition, {type:"colis", weight:0, sequenceNumber:index+1, "saturdayDelivery":false}])
    }
    function appendPaletteToList (index:number) {
        setListExpedition([...listExpedition, {type:"palette", weight:0, sequenceNumber:index+1, "saturdayDelivery":false}])
    }
    function removeToList (index:number) {
        setListExpedition(listExpedition.filter(element => element.sequenceNumber-1 !== index));
    }

    
    const [transporteur, setTransporteur] = React.useState ("");
    const [codeService, setCodeService] = React.useState ("J")
    function handleChangeTransporteur (event:any) {
        setTransporteur (event.target.value)
    }
    const [transporteurList, setTransporteurList] = React.useState (Array<CarrierRegistration>
    )
    const [checked1, setCheck1] = React.useState (false)
    const [checked2, setCheck2] = React.useState (false)
    const [checked3, setCheck3] = React.useState (false)
    const [checked4, setCheck4] = React.useState (false)
    function handleChangeCheck1 (event:any){
        setCheck1 (event.target.checked)
    }
    function handleChangeCheck2 (event:any){
        setCheck2 (event.target.checked)
    }
    function handleChangeCheck3 (event:any){
        setCheck3 (event.target.checked)
    }
    function handleChangeCheck4 (event:any){
        setCheck4 (event.target.checked)
    }

    const [saveContact, setSaveContact] = React.useState (false)
    function handleSaveContact (event:any){
        setSaveContact (event.target.checked)
    }
    const [isAbleTochange2, setIsAbleToChange2] = React.useState (false)
    const [isAbleTochange3, setIsAbleToChange3] = React.useState (false)
    const [isAbleTochange4, setIsAbleToChange4] = React.useState (false)
    
    const [isTransporteurAndDateRevealed, setTransporteurAndDateRevealed] = React.useState (false);
    const [isPersonRevealed2, setPersonRevealed2] = React.useState (false);
    const [isExpeditionRevealed, setExpeditionRevealed] = React.useState (false)
    const [feasability, setFeasability] = React.useState (new Array<Feasability> ())
    
    const [tryToChangeFeasability, setTryToChangeFeasability] = React.useState (false)
    const [errorFeasability, setErrorFeasability] = React.useState (new Array<string> ())


    async function sendExpedition () {
                
        const areTheZipCodeGood = !Number.isNaN(+senderZipCode) && !Number.isNaN(+receiverZipCode) && senderZipCode.length===5 && receiverZipCode.length===5 
        if (areTheZipCodeGood && 
            listExpedition.filter((obj) => {return obj["weight"] === 0}).length===0 &&
            listExpedition.filter((obj) => {return obj["weight"] > 30}).length===0 &&
            (listExpedition.filter((obj) => {return obj["type"] ==="palette"}).length===0 && transporteur==="TNT") 
            ) {  // &&
            // (await t1)) {

        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/expedition/feasibility', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
                "carrierName": transporteur,
                "shippingDate": date,
                "shippingDelay":4,
                "sender": {
                    "zipCode": senderZipCode,
                    "city": senderCity
                },
                "receiver":{
                    "zipCode": receiverZipCode,
                    "city": receiverCity
                }
                })
            })
        await response.catch (function (error) {console.log (error)})
        const json = await (await response).json()
        
        // const json = [
        //     {
        //         "afternoonDelivery": false,
        //         "dueDate": "2023-01-21",
        //         "insurance": true,
        //         "priorityGuarantee": true,
        //         "saturdayDelivery": true,
        //         "serviceCode": "T",
        //         "serviceLabel": "10:00 Express",
        //         "shippingDate": "2023-01-20"
        //     },
        //     {
        //         "afternoonDelivery": false,
        //         "dueDate": "2023-01-21",
        //         "insurance": true,
        //         "priorityGuarantee": true,
        //         "saturdayDelivery": true,
        //         "serviceCode": "M",
        //         "serviceLabel": "12:00 Express",
        //         "shippingDate": "2023-01-20"
        //     },
        //     {
        //         "afternoonDelivery": false,
        //         "dueDate": "2023-01-21",
        //         "insurance": true,
        //         "priorityGuarantee": true,
        //         "saturdayDelivery": true,
        //         "serviceCode": "J",
        //         "serviceLabel": "Express",
        //         "shippingDate": "2023-01-20"
        //     },
        //     {
        //         "afternoonDelivery": false,
        //         "dueDate": "2023-01-23",
        //         "insurance": true,
        //         "priorityGuarantee": true,
        //         "saturdayDelivery": false,
        //         "serviceCode": "T",
        //         "serviceLabel": "10:00 Express",
        //         "shippingDate": "2023-01-20"
        //     },
        //     {
        //         "afternoonDelivery": false,
        //         "dueDate": "2023-01-23",
        //         "insurance": true,
        //         "priorityGuarantee": true,
        //         "saturdayDelivery": false,
        //         "serviceCode": "M",
        //         "serviceLabel": "12:00 Express",
        //         "shippingDate": "2023-01-20"
        //     },
        //     {
        //         "afternoonDelivery": false,
        //         "dueDate": "2023-01-23",
        //         "insurance": true,
        //         "priorityGuarantee": true,
        //         "saturdayDelivery": false,
        //         "serviceCode": "J",
        //         "serviceLabel": "Express",
        //         "shippingDate": "2023-01-20"
        //     }
        // ]
        // console.log (json)
        if (json.statusCode===400 || json.statusCode===403) {
            if (json.message==="Error: soap:Server: The field 'shippingDate' is not valid.: {\"ServiceException\":null}"){
                setErrorFeasability (["Vous ne pouvez pas créer d'expéditions le week-end"])
            } else {
                setErrorFeasability (json.message)
            }
        } else {
            var passed = false
            for (let i=0; i<json.length; i++) {
                if (json[i].serviceCode===codeService){
                    // setChosenFeasability (json[i])
                    // setGotAFeasability(true)
                    if (!json[i].saturdayDelivery && checked1){
                        break
                    }
                    if (!json[i].insurance && checked2){
                        break
                    }
                    if (!json[i].priorityGuarantee && checked3){
                        break
                    }
                    if (!json[i].afternoonDelivery && checked4){
                        break
                    }
                    passed = true
                    break
                }
            }
            setTryToChangeFeasability (!passed)
            if (passed){
                sendExpedition2 (codeService)
            } else {
                var listOfService:Array<Feasability> = json
                if (checked1) {
                    listOfService = listOfService.filter ((obj) => {return obj["saturdayDelivery"] === true})
                }
                if (checked2) {
                    listOfService = listOfService.filter ((obj) => {return obj["insurance"] === true})
                }
                if (checked3) {
                    listOfService = listOfService.filter ((obj) => {return obj["priorityGuarantee"] === true})
                }
                if (checked4) {
                    listOfService = listOfService.filter ((obj) => {return obj["afternoonDelivery"] === true})
                }
                setFeasability (listOfService)
            }
        }
    } else {
        const temp = new Array<string>()
        if (!areTheZipCodeGood){
            temp.push ("Les codes postaux ne sont pas corrects")
        }
        if (listExpedition.filter((obj) => {return obj["weight"] === 0}).length!==0){
            temp.push ("Il ne peut pas y avoir de colis pesant 0 kilogrammes")
        }
        if (listExpedition.filter((obj) => {return obj["weight"] > 30}).length!==0){
            temp.push ("Le poids de chacun des colis ne peut pas excéder 30 kilogrammes")
        }
        if (listExpedition.filter((obj) => {return obj["type"] ==="palette"}).length!==0 && transporteur==="TNT"){
            temp.push ("Le transporteur TNT ne gère pas les palettes")
        }
        setErrorFeasability (temp)
    }}

    const [isAutoCompleteOpen, setIsAutoCompleteOpen] = React.useState (false)
    
    const [isDoneMSJ, setIsDoneMSJ] = React.useState (newResponseCreateExpedition)
    const [isDone, setIsDone] = React.useState (false)
    async function sendExpedition2 (codeServiceInArg:string) {
        if (checked1) {
            listExpedition.map (element=> element["saturdayDelivery"]=true)
        }
        const temp = listExpedition.slice ()
        if (checked1) {
            temp.map (element=> element["saturdayDelivery"]=true)
        }
        // console.log (listExpedition)
        if (!isStillConnected (token, setToken)) {
            return 
        }
        if (saveContact){
            const response = await fetch (url + '/contact', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token["accessToken"]}`,
                },
                body: JSON.stringify ({
                    "contactName": receiverName,
                    "companyName": societyName,
                    "email": mail, 
                    "phone": telephone,
                    "reference": reference,
                    "specialInstruction1": instruction1,
                    "specialInstruction2": instruction2,
                    "address": {
                        "streetLine1": receiverAdress1,
                        "streetLine2": receiverAdress1,
                        "city": receiverCity,
                        "zip": +receiverZipCode,
                        "country":receiverCountry
                    }
                })
              })
            
            if (!response.ok){
                const json:ServerResponse = await (response).json();
                if (typeof json.message!=="string"){
                    setErrorFeasability (json.message)
                } else {
                    setErrorFeasability ([json.message])
                }
                return
            }
        }
        const response = fetch (url + '/expedition/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
            },
            body: JSON.stringify ({
                "carrierName": transporteur,
                "shippingDate":date,
                "sender": {
                    "name":senderName,
                    "address1": senderAdress1,
                    "zipCode": senderZipCode,
                    "city": senderCity,
                    "phoneNumber": "0600000000",
                    "closingTime": "16:30"
                },
                "receiver": {
                    "name": societyName,
                    "address1":receiverAdress1,
                    "address2":receiverAdress2,
                    "zipCode": receiverZipCode,
                    "city": receiverCity
                },
                "serviceCode":codeServiceInArg,
                "quantity": listExpedition.length,
                "parcelsRequest": {
                    "parcelRequest": temp
                },
                "labelFormat": "STDA4"
                })
            })
        
        if (errorFeasability.length===0){
            if ((await response).status===400){
                const json = await (await response).json ()
                setErrorFeasability (json.message)
            } else {
                const json:responseCreateExpedition = await (await response).json();
                
                console.log (json)
                setIsDoneMSJ (json)
                window.scrollTo(0, 0);
                setIsDone (true)
            }
        }

    }
    async function getPDF (pdf:string){
        if (!isStillConnected (token, setToken)) {
            return 
        }
        const response = fetch (url + '/expedition/parcellabel/'+pdf, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token["accessToken"]}`
                },
            });
        const url2 = (await (await response).json()).url;
        const response2 = fetch (url2, {
            method: 'GET',
        });
        const file = new Blob(
            [await (await response2).blob()],
            {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
    }
    

    const [isChoosingAContact, setIsChoosingAContact] = React.useState (false)
    function chooseContact (contact:ContactType){
        setSocietyName (contact.companyName)
        setReceiverAddress1 (contact.address.streetLine1)
        setReceiverAddress2 (contact.address.streetLine2)
        setReceiverZipCode (JSON.stringify (contact.address.zip))
        setReceiverCity (contact.address.city)
        setReceiverCountry (contact.address.country)
        setReceiverName (contact.contactName)
        setReference (contact.reference===null?"":contact.reference)
        setMail (contact.email)
        setTelephone (contact.phone)
        setInstruction1 (contact.specialInstruction1===null?"":contact.specialInstruction1)
        setInstruction2 (contact.specialInstruction2===null?"":contact.specialInstruction2)
        setIsChoosingAContact (false)
    }
    
    if (!isDone) {
    return (
        <div className="table2" style={{width:"100%"}}>
            
            
            <div>
                <Accordion className='DemandeExpeditionBox1' style={{borderRadius:30, padding:0, display:"flex", margin:30, width:width>780?"80vw":"90vw", marginRight:0, marginLeft:0}}>

                    <AccordionSummary style={{width:"100%", borderRadius:30}}  className="Change-Color-Hover" onClick={() => {setPersonRevealed2 (!isPersonRevealed2);}}>
                        <div  style={{width:"100%", textAlign:"left", display:"flex", flexDirection:"row", alignItems:"center"}}>
                            <Chevron style={{margin:width>780?"0px 30px":"0px 10px 0px 0px", rotate:isPersonRevealed2?"-180deg":"0deg", transitionDuration: "0.2s"}}/>
                            <h1 style={{fontSize:width>780?30:20, fontWeight:500}}>Choix du transporteur / Date</h1>
                        </div>
                    </AccordionSummary>
                    
                    <AccordionDetails>
                        <div style={{width:"100%", display:"grid", gridTemplateColumns:width>780?"50% 50%":"100%", justifyItems:"center"}}>
                            <SelectInput value={transporteur} onChange={handleChangeTransporteur} bordercolor="#f0ba61" label="Transporteur">
                                {transporteurList.map ((element:CarrierRegistration)=>
                                    <MenuItem style={{backgroundColor:(transporteur===element.carrier?.name)?"#ffe695":""}} value={element.carrier?.name} key={element.carrier?.name}>{element.carrier?.name}</MenuItem>
                                )}
                            </SelectInput>
                            <input min={new Date().toJSON().slice (0, 10)} type="date" className='Input' style={{borderColor:"#f0ba61", cursor:"pointer", width:width>780?"":"100%"}} value={date} onChange={handleChangeDate} />
                        </div>
                    </AccordionDetails>
                    
                </Accordion>
            </div> 
            <div>
                
                <Accordion className='DemandeExpeditionBox1' style={{borderRadius:30, padding:0, display:"flex", margin:30, width:width>780?"80vw":"90vw", marginRight:0, marginLeft:0}}>

                    <AccordionSummary style={{width:"100%", borderRadius:30}}  className="Change-Color-Hover">
                        <div className='Input-password' onClick={() => setTransporteurAndDateRevealed (!isTransporteurAndDateRevealed)} style={{width:"100%", textAlign:"left", display:"flex", flexDirection:"row", alignItems:"center"}}>
                            <Chevron style={{margin:width>780?"0px 30px":"0px 10px 0px 0px", rotate:isTransporteurAndDateRevealed?"-180deg":"0deg", transitionDuration: "0.2s"}}/>
                            <h1 style={{fontSize:width>780?30:20, fontWeight:500}}>Expéditeur / Destinataire</h1>
                        </div>
                    </AccordionSummary>

                    <AccordionDetails >
                        <div style={{display:"grid", gridTemplateColumns: width>780?'49.5% 2% 49.5%':"100%", alignContent:"center", width:"100%", justifyItems:"center"}}>
                        
                            <div style={{zIndex:0, display:"flex", flexDirection:"column", alignItems:"center", width:"100%"}}>
                                <h1 style={{fontSize:25, fontWeight:650, textDecoration:"underline"}}>Expéditeur</h1>
     
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Nom de l'expéditeur" value={senderName} onChange={(value)=>setSenderName(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref1.current.focus()} />
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Nom de la rue de l'expéditeur" value={senderAdress1} onChange={(value)=>setSenderAddress1(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref2.current.focus()} inputRef={ref1}/>
                                <div style={{display:"flex", flexDirection:"row", justifySelf:"center", width:width>780?"80%":"100%", justifyContent:"space-between"}}>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Code postal de l'expéditeur" value={senderZipCode} onChange={(value)=>setSenderZipCode(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref3.current.focus()} inputRef={ref2}/>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom de la ville de l'expéditeur" value={senderCity} onChange={(value)=>setSenderCity(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref4.current.focus()} inputRef={ref3}/>
                                </div>
                            </div>
                            {width>780 && <div style={{width:3, backgroundColor:"#a2a2a2", height:"100%", alignSelf:"center", justifySelf:"center", borderRadius:10, margin:"50px 0px"}}></div>}
                            <div style={{zIndex:0, display:"flex", flexDirection:"column", alignItems:"center", width:"100%"}}>
                                <h1 style={{fontSize:25, fontWeight:650, textDecoration:"underline"}}>Destinataire</h1>
                                <TextInput bordercolor="#f0ba61" width={width>780?undefined:"100%"} label="Nom de la société" value={societyName} onChange={(value)=>setSocietyName(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref5.current.focus()} inputRef={ref4}/>
                                <div style={{display:"flex", flexDirection:"row", width:width>780?"80%":"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                    <SearchAddresses title="Adresse 1" style={{width:width>780?"47%":"49%"}} address={receiverAdress1} setAddress={setReceiverAddress1} setZipCode={setReceiverZipCode} setCity={setReceiverCity} setCountry={setReceiverCountry} setIsOpen={setIsAutoCompleteOpen} isOpen={isAutoCompleteOpen}/>
                                    
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Adresse 2" value={receiverAdress2} onChange={(value)=>setReceiverAddress2(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref7.current.focus()} inputRef={ref6}/>
                                </div>
                                <div style={{display:"flex", flexDirection:"row", width:width>780?"80%":"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Code postal destinataire" value={receiverZipCode} onChange={(value)=>setReceiverZipCode(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref8.current.focus()} inputRef={ref7}/>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom de la ville du destinataire" value={receiverCity} onChange={(value)=>setReceiverCity(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref9.current.focus()} inputRef={ref8}/>
                                </div>
                                <div style={{display:"flex", flexDirection:"row", width:width>780?"80%":"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                    <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Pays du destinataire" value={receiverCountry} onChange={(value)=>setReceiverCountry(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref8.current.focus()} inputRef={ref7}/>
                                </div>
                                <div style={{display:"flex", flexDirection:"column", width:width>780?"80%":"100%", justifySelf:"center", justifyContent:"center", textAlign:"center"}}>
                                    <p style={{fontSize:15, fontWeight:650}}>Supplyzen vous conseille de remplir cette section</p>
                                    <div style={{width:"100%", border: "solid #deae5f 1.5px", borderRadius:10, padding:10, boxSizing:"border-box"}}>
                                        <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                            <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Nom du destinataire" value={receiverName} onChange={(value)=>setReceiverName(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref10.current.focus()} inputRef={ref9}/>
                                            <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Référence" value={reference} onChange={(value)=>setReference(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref11.current.focus()} inputRef={ref10}/>
                                        </div>
                                        <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                            <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Mail" value={mail} onChange={(value)=>setMail(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref12.current.focus()} inputRef={ref11}/>
                                            <TextInput bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Téléphone" value={telephone} onChange={(value)=>setTelephone(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref13.current.focus()} inputRef={ref12}/>
                                        </div>
                                        <div style={{display:"flex", flexDirection:"row", width:"100%", justifySelf:"center", justifyContent:"space-between"}}>
                                            <TextInput style={{marginBottom:0}} bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Instruction particulière 1" value={instruction1} onChange={(value)=>setInstruction1(value.target.value)} onKeyPress={event => event.key === 'Enter' && ref14.current.focus()} inputRef={ref13}/>
                                            <TextInput style={{marginBottom:0}} bordercolor="#f0ba61" width={width>780?"47%":"49%"} label="Instruction particulière 2" value={instruction2} onChange={(value)=>setInstruction2(value.target.value)} inputRef={ref14}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div></div>
                            <div></div>
                            <div style={{ width:"80%"}}>
                                <div className="" style={{ display:"flex", flexDirection:"row", alignItems:"center", justifySelf:"center", marginBottom:10, marginTop:10}}>
                                    <Checkbox checked={saveContact} onChange={handleSaveContact}/>
                                    <p style={{margin:"0px 20px 0px 0px"}}>Ajouter au carnet d'adresse</p>
                                </div>
                                <div className="Cadre-blanc Change-Color-Hover" style={{display:"flex", flexDirection:"row", alignItems:"center", justifySelf:"center", marginBottom:10, marginTop:10, cursor:"pointer", padding:width>780?"":"10px 20px", userSelect:"none"}} onClick={()=>setIsChoosingAContact (true)}>
                                    <Contact style={{margin:"0px 20px 0px 0px"}}/>
                                    <h1 style={{fontWeight:500, fontSize:20}}>Choisir à partir du carnet d’adresse</h1>
                                </div>
                                <ContactSelection choose={chooseContact} isOpen={isChoosingAContact} setIsOpen={setIsChoosingAContact}/>        
                                            
                            </div>
                        </div>                    
                    </AccordionDetails>

                </Accordion>
            </div>

            <div>
                <Accordion className='DemandeExpeditionBox1 ' style={{borderRadius:30, padding:0, display:"flex", margin:30, width:width>780?"80vw":"90vw", marginRight:0, marginLeft:0}}>
                    <AccordionSummary style={{width:"100%", borderRadius:30}} className="Change-Color-Hover">
                        <div className='Input-password' onClick={() => setExpeditionRevealed (!isExpeditionRevealed)} style={{width:"100%", textAlign:"left", display:"flex", flexDirection:"row", alignItems:"center"}}>
                            <Chevron style={{margin:width>780?"0px 30px":"0px 10px 0px 0px", rotate:isExpeditionRevealed?"-180deg":"0deg", transitionDuration: "0.2s"}}/>
                            <h1 style={{fontSize:width>780?30:20, fontWeight:500}}>Expédition</h1>
                        </div>
                    </AccordionSummary>

                    <AccordionDetails style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyItems:"center", width:"100%", boxSizing:"border-box"}}>    
                        {listExpedition.map ((element, index) =>
                            <Expedition element={element} index={index} key={index}/>
                        )}
                        <div style={{display:"flex", width:"80%", boxSizing:"border-box", flexDirection:width>780?"row":"column"}}>
                            <div className="Cadre-blanc Grow-Hover" style={{ margin:20, padding:10, boxSizing:"unset", display:"flex", alignItems:"center", justifyContent:"center", justifyItems:"center",flex:1, cursor:"pointer"}} onClick={()=>appendColisToList (listExpedition.length)}>
                                <Plus style={{margin:"0px 10px 0px 20px"}}/>
                                <p style={{margin:0, marginRight:20, alignSelf:"center",  justifySelf:"center"}}>Ajouter un colis</p>
                            </div>
                            <div className="Cadre-blanc Grow-Hover" style={{ margin:20, padding:10, boxSizing:"unset", display:"flex", alignItems:"center", justifyContent:"center", justifyItems:"center",flex:1, cursor:"pointer"}} onClick={()=>appendPaletteToList (listExpedition.length)}>
                                <Plus style={{margin:"0px 10px 0px 20px"}}/>
                                <p style={{margin:"0px 20px 0px 0px"}}>Ajouter une palette</p>
                            </div>

                        </div>
                        <div style={{width:"12.5%", backgroundColor:"#0b0b0b", height:3, borderRadius:30}}></div>
                        <div style={{display:"flex", width:"80%", flexDirection:width>780?"row":"column"}}>
                            <div className="Cadre-blanc" style={{ margin:20, padding:10,  display:"flex", alignItems:"center", flex:1}}>
                                <Checkbox checked={checked1} onChange={handleChangeCheck1}/>
                                <p style={{margin:0, marginRight:20, alignSelf:"center",  justifySelf:"center"}}>Livraison le samedi</p>
                            </div>
                            <div className="Cadre-blanc" style={{ margin:20, padding:10, boxSizing:"unset", display:"flex", alignItems:"center", justifyContent:"left", justifyItems:"center",flex:1}}>
                                <Checkbox checked={checked2} onChange={handleChangeCheck2} disabled={!isAbleTochange2}/>
                                <p style={{margin:"0px 20px 0px 0px"}}>Assurance</p>
                            </div>
                            <div className="Cadre-blanc" style={{ margin:20, padding:10, boxSizing:"unset", display:"flex", alignItems:"center", justifyContent:"center", justifyItems:"center",flex:1}}>
                                <Checkbox checked={checked3} onChange={handleChangeCheck3} disabled={!isAbleTochange3}/>
                                <p style={{margin:"0px 20px 0px 0px"}}>Priorité garantie</p>
                            </div>
                            <div className="Cadre-blanc" style={{ margin:20, padding:10, boxSizing:"unset", display:"flex", alignItems:"center", justifyContent:"center", justifyItems:"center",flex:1}}>
                                <Checkbox checked={checked4} onChange={handleChangeCheck4} disabled={!isAbleTochange4}/>
                                <p style={{margin:"0px 20px 0px 0px"}}>Livraison le soir</p>
                            </div>
                           
                        </div>
                        </AccordionDetails>
                </Accordion>   

                {tryToChangeFeasability &&
                
                <div style={{position: "fixed", left: "50%", top:"10%", zIndex:5}}>
                    <div className='logout' style={{position: "relative", left: "-50%", top:"-50%", border: "dotted #deae5f 1px", padding:30, width:450, paddingLeft:0, paddingRight:0}}>
                        
                        <p style={{margin:10, fontSize:25, fontWeight:500}}>Le service demandé n'est pas disponible pour cette expédition. Veuillez en choisir un autre :</p>
                        <div style={{display:'flex', justifyContent:"center", width:"100%"}}>
                            <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                {feasability.map ((element:Feasability, index:number)=>
                                    <div key={index} style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", width:"100%", cursor:"pointer"}} className="Change-Color-Hover" onClick={()=>{ setCodeService(element.serviceCode);setTryToChangeFeasability(false); sendExpedition2(element.serviceCode)}}>
                                        
                                        <p style={{margin:0, textAlign:"left", marginLeft:20}}>{element.serviceCode} / {element.serviceLabel} : pour le {JSON.stringify (element.dueDate)}</p>
                                        <Chevron style={{width:30, resize:"none", rotate:"270deg", marginRight:10}}/>
                                    </div>
                                )}
                            </div>
                        </div>
                       
                        <div style={{display:"grid", gridTemplateColumns:"50% 50%", justifyContent:"center", padding:30, paddingBottom:0, paddingTop:20}}>
                            <div style={{cursor:"pointer", backgroundColor:"#deae5f", margin:10, textAlign:"center", borderRadius:20, marginBottom:0,marginRight:10}} className="Grow-Hover" onClick={()=>setTryToChangeFeasability(false)}>
                                <p style={{ color:"white", width:"100%", height:"100%"}}>Annuler</p>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>   
            <ErrorList errorArray={errorFeasability} close={()=>setErrorFeasability (new Array<string>())}/>
            
            <FileHandler isOpen={isMakingAFileDemand} setIsOpen={setIsMakingAFileDemand} file={selectedFile} setFile={setSelectedFile} handleChange={handleChange}/>

            <div style={{bottom: 20, right: 20, position:"fixed", display: "flex", flexDirection:"column", justifyContent: "space-between", boxSizing:"content-box", zIndex:3, pointerEvents: "none", userSelect:"none"}}>

                <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:"3px 10px", pointerEvents: "visible"}} onClick={()=>sendExpedition()}>
                    <p>Envoyer la demande de colis</p>
                </div>
            
                <div className="Cadre-blanc Header-expedition Confirmer" style={{ cursor:"pointer", margin:10, width:width>600?"20vw":"50vw", padding:"3px 10px", pointerEvents: "visible"}} onClick={()=>setIsMakingAFileDemand (true)}>
                    <p>Faire la demande avec un fichier</p>
                </div>
                
            </div>
           
        </div>
            
    )} else {
        return (
            <div style={{display:"grid", justifyItems:"center", width:"100%", position:"relative", margin:"6vh", marginLeft:0, marginRight:0}}>

                <div className="Cadre-blanc" style={{display:"flex", flexDirection:"column", width:"90%", padding:30}}>
                    <div style={{display:"flex", flexDirection:'row', alignItems:"center", width:"100%", justifyContent:"center"}}>
                        <h1>Expédition créée :</h1>
                        <img src={Check} alt='' style={{height:40, resize:"none", margin:12, paddingLeft:0, userSelect:"none"}} draggable={false}/>
                    </div>
                    <div style={{display:"grid", gridTemplateColumns: '49.5% 1% 49.5%', alignContent:"center", width:"100%", justifyItems:"center"}}>
                        <div style={{zIndex:0, display:"flex", flexDirection:"column", alignItems:"center", width:"100%"}}>
                            <h1 style={{fontSize:25, fontWeight:650, textDecoration:"underline"}}>Expéditeur</h1>
                            
                            <input disabled type="text" className='Input' style={{borderColor:"#f0ba61", margin:"10px 0px", width:"80%"}} value={isDoneMSJ.pickupName}/>
                            <input disabled type="text" className='Input' style={{borderColor:"#f0ba61", margin:"10px 0px"}} value={isDoneMSJ.pickupAddressLine1+" "+isDoneMSJ.pickupAddressLine2}/>
                            <div style={{display:"flex", flexDirection:"row", width:"80%", justifySelf:"center"}}>
                                <input disabled type="text" className='Input' style={{borderColor:"#f0ba61", margin:"10px 10px 10px 0px"}} value={isDoneMSJ.pickupZipCode}/>
                                <input disabled type="text" className='Input' style={{borderColor:"#f0ba61", margin:"10px 0px 10px 10px"}} value={isDoneMSJ.pickupCity}/>
                            </div>
                        </div>
                        <div></div>
                        {/* <div style={{width:3, backgroundColor:"#a2a2a2", height:"100%", alignSelf:"center", justifySelf:"center", borderRadius:10, margin:"50px 0px"}}></div> */}
                        <div style={{zIndex:0, display:"flex", flexDirection:"column", alignItems:"center", width:"100%"}}>
                            <h1 style={{fontSize:25, fontWeight:650, textDecoration:"underline"}}>Destinataire</h1>
                            <input disabled type="text" className='Input' style={{borderColor:"#f0ba61", margin:"10px 0px", width:"80%"}} value={isDoneMSJ.deliveryName}/>
                            {/* <input type="text" placeholder="Nom de la rue du destinataire" className='Input' style={{borderColor:"#f0ba61", margin:"10px 0px"}} value={receiverAdress} onChange={handleChangeReceiverAddress} ref={ref5} onKeyPress={event => event.key === 'Enter' && ref6.current.focus()}/> */}
                            <div style={{display:"flex", flexDirection:"row", width:"80%", justifySelf:"center"}}>
                                <input disabled type="text" className='Input' style={{borderColor:"#f0ba61", margin:"10px 10px 10px 0px"}} value={isDoneMSJ.deliveryAddressLine1}/>
                                <input disabled type="text" className='Input' style={{borderColor:"#f0ba61", margin:"10px 0px 10px 10px"}} value={isDoneMSJ.deliveryAddressLine2}/>
                            </div>
                            <div style={{display:"flex", flexDirection:"row", width:"80%", justifySelf:"center"}}>
                                <input disabled type="text" className='Input' style={{borderColor:"#f0ba61", margin:"10px 10px 10px 0px"}} value={isDoneMSJ.deliveryZipCode}/>
                                <input disabled type="text" className='Input' style={{borderColor:"#f0ba61", margin:"10px 0px 10px 10px"}} value={isDoneMSJ.deliveryCity}/>
                            </div>
                        </div>
                    </div>
                    <div style={{ display:"grid", justifyItems:"center",}}>
                    {isDoneMSJ.parcels.map ((element, index)=>
                        <div key={index} className="Cadre-blanc" style={{width:"90%",  marginBottom:20, padding:10, paddingLeft:30, paddingRight:30}}>
                            <div style={{display:"flex", justifyContent:"space-around", alignItems:"center"}}>
                                <p style={{display:"flex", flexDirection:"row", alignItems:"center"}}>{"Colis"} n°</p>
                                <p style={{fontWeight:500, marginRight:3,  marginLeft:3}}>{element.parcelNumber+" "}</p>
                                <p>avec un poids de {element.weight} Kilogrammes</p>
                                
                                <div style={{display:"flex", flexDirection:"row", alignItems:"center", color:"grey", cursor:"pointer"}} className="JE" onClick={()=> getPDF(element.expeditionPath)}>
                                    <PDFLogo style={{width: "20px", height: "20px", resize: "none", marginRight:5, }}/>
                                    <p>Afficher l'étiquette</p>
                                </div>
                            </div>
                        </div>
                    )}
                    <input disabled type="text" className='Input' style={{borderColor:"#f0ba61", margin:"10px 0px", width:"90%"}} value={"L'expédition sera prise en charge le " + isDoneMSJ.pickupDate + " pour un prix de " + isDoneMSJ.price + " euros"}/>                     
                    </div>
                </div>
                
            </div>
        )
    }
}
export default DemandeUneExpédition